const { useState } = require('react');

const useDropdown = (initialState = []) => {
  const [opened, setOpened] = useState(initialState);
  return (id) => ({
    opened: opened.includes(id),
    toggle: () => setOpened((items = []) => (items.includes(id) ? items.filter((i) => id !== i) : [...items, id])),
  });
};

export default useDropdown;
