import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonContainer from '@components/Common/ButtonContainer';
import { Form, useForm } from '@components/FormElement/Form';

const UpdateGroupForm = ({ group = {}, isUpdating = false, onSubmit = () => {} }) => {
  const { t } = useTranslation();
  const { name = '' } = group;

  const methods = useForm();

  return (
    <Form methods={methods} onSubmit={onSubmit} isUpdating={isUpdating}>
      <Form.Label title={t('Name')} name="name" required>
        <Form.Input name="name" required defaultValue={name} />
      </Form.Label>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton label={t('Update')} />
      </ButtonContainer>
    </Form>
  );
};

export default UpdateGroupForm;

UpdateGroupForm.propTypes = {
  isUpdating: PropTypes.bool,
  onSubmit: PropTypes.func,
  group: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  }),
};
