import classNames from 'classnames';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Hint from '@components/Common/Hint';
import Tag from '@components/Common/Tag';

import style from './style.module.css';

const AvailableKeys = ({ keys, tagColor, TagTextColor, hintMessage, hintType = 'INFO' }) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState({ state: false, id: 0 });

  const onCopy = (e, content, i) => {
    e.stopPropagation();
    setCopied({ state: true, id: i });
    copy(content);
  };

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied({ state: false, id: 0 });
      }, 1500);
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  const value = (key, copied, i, t) => {
    const copiedCondition = copied.state && copied.id === i;
    return (
      <>
        <span className={classNames({ [style.hidden]: copiedCondition })}>{key}</span>
        {copiedCondition && <span className={style.copied}>{t('Copied!')}</span>}
      </>
    );
  };

  return (
    <div>
      <h3 className={style.tagTitle}>{t('Available keys')}</h3>
      <div className={style.tagsContainer}>
        {keys.map((key, i) => (
          <Tag
            className={style.tagElement}
            key={key}
            value={value(key, copied, i, t)}
            textColor={TagTextColor}
            color={tagColor}
            action={(e) => onCopy(e, key, i)}
          ></Tag>
        ))}
      </div>
      {hintMessage && <Hint message={hintMessage} type={hintType} />}
    </div>
  );
};

AvailableKeys.propTypes = {
  keys: PropTypes.array,
  tagColor: PropTypes.string,
  TagTextColor: PropTypes.string,
  hintMessage: PropTypes.string,
  hintType: PropTypes.oneOf(['INFO', 'SUCCESS', 'WARNING', 'ERROR', 'NORMAL']),
};

export default AvailableKeys;
