import ROUTES, { compareRoute, compareStartsRoute } from '@app/routes'
import { routingMap } from '@hooks/solutions';
import useSearch from '@hooks/useSearch';

export const useGetPlatformSubmenu = () => {
  const { menu, followup: followUpId } = useSearch();

  const generatePlatformMenu = (companyId, solutions, selectedDropDown, currentRoute, _, callback, addCallback, t) => [
    ...solutions
      .filter((type) => ['PLATFORM_BASIC', 'PLATFORM_BOOK', 'PLATFORM_PRODUCT', 'PLATFORM_CHALLENGE'].includes(type.type))
      .map((s, i) => {
        const applicationFollowupSelected =
          menu === 'PLATFORM' && s.id === followUpId && currentRoute.includes('followup') && currentRoute.includes('tab=APPLICATIONS');
        const ordersFollowupSelected = menu === 'PLATFORM' && s.id === followUpId && currentRoute.includes('followup') && currentRoute.includes('tab=ORDERS');
        const creationProjectFromFollowupMatch = compareStartsRoute(`${ROUTES.CREATE_PROJECT.path({ companyId: companyId, solutionId: s.id, solutionType: 'platform' })}?previous=LIST_FOLLOWUP`, currentRoute);
        const videosFollowupSelected = (menu === 'PLATFORM' && s.id === followUpId && currentRoute.includes('followup') && currentRoute.includes('tab=VIDEOS')) || creationProjectFromFollowupMatch;

        return {
          name: s.name ? s.name : t('Platform #{{index}}', { index: i + 1 }),
          selected: false,
          open: selectedDropDown === `PLATFORM-${s.id}` || selectedDropDown === `PLATFORM-CONFIGURATION-${s.id}` || selectedDropDown === `FOLLOWUP-${s.id}`,
          onClick: () => {
            callback(`PLATFORM-${s.id}`);
          },
          children: [
            {
              name: t('Campaign overview'),
              selected: compareRoute(ROUTES.CAMPAIGN_OVERVIEW.path({ companyId, solutionId: s.id }), currentRoute),
              onClick: ROUTES.CAMPAIGN_OVERVIEW.path({ companyId, solutionId: s.id }),
            },
            {
              name: t('Follow-up'),
              icon: 'ROCKET_LAUNCH',
              selected: (menu === 'PLATFORM' && s.id === followUpId) || creationProjectFromFollowupMatch,
              open: selectedDropDown === `FOLLOWUP-${s.id}` || applicationFollowupSelected || ordersFollowupSelected || videosFollowupSelected,
              onClick: () => callback(`FOLLOWUP-${s.id}`),
              children: [
                ...(s.linkedCasting
                  ? [
                      {
                        key: `PLATFORM-APPLICATIONS-${s.id}`,
                        name: t('Applications'),
                        selected: applicationFollowupSelected,
                        onClick: ROUTES.FOLLOWUP.path({ companyId, solutionId: s.id }, { menu: 'PLATFORM', followup: s.id, tab: 'APPLICATIONS' }),
                      },
                    ]
                  : []),
                ...(s.type === 'PLATFORM_BOOK'
                  ? [
                      {
                        key: `PLATFORM-ORDERS-${s.id}`,
                        name: t('Orders'),
                        selected: ordersFollowupSelected,
                        onClick: ROUTES.FOLLOWUP.path({ companyId, solutionId: s.id }, { menu: 'PLATFORM', followup: s.id, tab: 'ORDERS' }),
                      },
                    ]
                  : []),
                {
                  key: `PLATFORM-VIDEOS-${s.id}`,
                  name: t('Videos'),
                  selected: videosFollowupSelected,
                  onClick: ROUTES.FOLLOWUP.path({ companyId, solutionId: s.id }, { menu: 'PLATFORM', followup: s.id, tab: 'VIDEOS' }),
                },
              ],
            },
            ...(s.type === 'PLATFORM_PRODUCT' || s.type === 'PLATFORM_BOOK'
              ? [
                  {
                    name: t('Products'),
                    icon: 'INVENTORY',
                    selected: compareRoute(ROUTES.PRODUCT_LIST.path({ companyId, solutionId: s.id }), currentRoute),
                    onClick: ROUTES.PRODUCT_LIST.path({ companyId, solutionId: s.id }),
                  },
                ]
              : []),
            {
              name: t('Configuration'),
              icon: 'SETTING',
              selected: false,
              open: selectedDropDown === `PLATFORM-CONFIGURATION-${s.id}`,
              onClick: () => callback(selectedDropDown === `PLATFORM-CONFIGURATION-${s.id}` ? `PLATFORM-${s.id}` : `PLATFORM-CONFIGURATION-${s.id}`),
              children: [
                {
                  name: t('Platform'),
                  selected: compareRoute(ROUTES.PLATFORM_CONFIGURATION.path({ companyId, solutionId: s.id }), currentRoute),
                  onClick: ROUTES.PLATFORM_CONFIGURATION.path({ companyId, solutionId: s.id }),
                },
                ...(s.type === 'PLATFORM_BOOK'
                  ? [
                      {
                        name: t('Orders (old)'),
                        selected: compareStartsRoute(ROUTES.ORDER_LIST.path({ companyId, solutionId: s.id }), currentRoute, true) && !followUpId,
                        onClick: ROUTES.ORDER_LIST.path({ companyId, solutionId: s.id }),
                      },
                    ]
                  : []),
                {
                  name: t('Videos (old)'),
                  selected:
                    compareStartsRoute(ROUTES.PROJECT_LIST.path({ companyId, solutionId: s.id, solutionType: 'platform' }), currentRoute, true) && !followUpId && !currentRoute.includes('previous=LIST_FOLLOWUP'),
                  onClick: ROUTES.PROJECT_LIST.path({ companyId, solutionId: s.id, solutionType: 'platform' }),
                },
                ...(s.type === 'PLATFORM_CHALLENGE'
                  ? [
                      {
                        name: t('Challenges'),
                        selected: compareRoute(ROUTES.CHALLENGE_LIST.path({ companyId, solutionId: s.id }), currentRoute),
                        onClick: ROUTES.CHALLENGE_LIST.path({ companyId, solutionId: s.id }),
                      },
                    ]
                  : []),
                {
                  name: t('Mails'),
                  selected: compareRoute(
                    ROUTES.MAILS_TEMPLATES_CONFIGURATION.path({ companyId, solutionId: s.id, solutionType: routingMap[s.type] }),
                    currentRoute
                  ),
                  onClick: ROUTES.MAILS_TEMPLATES_CONFIGURATION.path({ companyId, solutionId: s.id, solutionType: routingMap[s.type] }),
                },
                {
                  name: t('Webhooks'),
                  selected: compareRoute(ROUTES.WEBHOOKS_SOLUTION_LIST.path({ companyId, solutionId: s.id, solutionType: 'platform' }), currentRoute),
                  onClick: ROUTES.WEBHOOKS_SOLUTION_LIST.path({ companyId, solutionId: s.id, solutionType: 'platform' }),
                },
              ],
            },
          ],
        };
      }),
    {
      name: t('New platform'),
      icon: 'ADD',
      selected: false,
      onClick: addCallback,
    },
  ].filter(Boolean);

  return {
    generatePlatformMenu,
  };
};
