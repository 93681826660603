import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Block from '@components/Common/Block';
import Button from '@components/Common/Button';
import ButtonContainer from '@components/Common/ButtonContainer';
import SkeepersFlag from '@components/Common/SkeepersFlag';
import ProjectCardsRow from '@components/ProjectCards/ProjectCardsRow';

import style from './style.module.css';

const maxTitleLength = 45;
const maxDescriptionLength = 45;

const ProjectCardsTable = ({
  localCards = [],
  minSecBetweenCards,
  language,
  duration,
  currentTime,
  onImport = () => {},
  onInsertAfter = () => {},
  onRemoveLocalCard = () => {},
  onEditLocalCards = () => {},
  onSaveCards = () => {},
  onFocus = () => {},
  updating,
  canImport,
  canSubmit,
}) => {
  const { t } = useTranslation();

  return (
    <Block
      className={style.cardsBlock}
      title={
        <>
          <SkeepersFlag country={language?.country} className={style.flag} />
          {language?.name}
        </>
      }
      subtitle={t('{{count}} card', { count: localCards.length })}
      actions={[
        { icon: 'ADD', name: t('Add new card'), onClick: () => onInsertAfter(localCards[localCards.length - 1]) },
        ...(canImport ? [{ icon: 'COPY', name: t('Copy from another output'), onClick: onImport }] : []),
      ]}
      actionsDirection="VERTICAL"
    >
      {localCards.length ? (
        <table>
          <thead>
            <tr>
              <th className={style.indexColumn} />
              <th className={style.displayedInTimelineColumn}>{t('Show in timeline')}</th>
              <th className={style.titleColumn}> {t('Title')}</th>
              <th className={style.descriptionColumn}>{t('Description')}</th>
              <th className={style.positionColumn}>{t('Position')}</th>
              <th className={style.buttonsColumn} />
            </tr>
          </thead>
          <tbody>
            {localCards.map((card, index) => (
              <ProjectCardsRow
                key={card.id}
                card={card}
                isCurrent={currentTime / 1000 >= card.position && currentTime / 1000 < card.position + minSecBetweenCards}
                index={index + 1}
                duration={duration}
                maxTitleLength={maxTitleLength}
                maxDescriptionLength={maxDescriptionLength}
                onInsertAfter={onInsertAfter}
                onEditCard={onEditLocalCards}
                onRemoveCard={onRemoveLocalCard}
                onFocus={onFocus}
              />
            ))}
          </tbody>
        </table>
      ) : (
        <h2 className={style.center}>{t('Add your first card here!')}</h2>
      )}

      <ButtonContainer position="CENTER">
        <Button label={t('Save')} action={onSaveCards} loading={updating} disabled={updating || !canSubmit} />
      </ButtonContainer>
    </Block>
  );
};

ProjectCardsTable.propTypes = {
  localCards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      position: PropTypes.number,
      language: PropTypes.string,
      displayedInTimeline: PropTypes.bool,
    })
  ),
  minSecBetweenCards: PropTypes.number,
  language: PropTypes.shape({
    name: PropTypes.string,
    country: PropTypes.string,
  }),
  duration: PropTypes.number,
  currentTime: PropTypes.number,
  onImport: PropTypes.func,
  onInsertAfter: PropTypes.func,
  onRemoveLocalCard: PropTypes.func,
  onEditLocalCards: PropTypes.func,
  onSaveCards: PropTypes.func,
  onFocus: PropTypes.func,
  updating: PropTypes.bool,
  canImport: PropTypes.bool,
  canSubmit: PropTypes.bool,
};

export default ProjectCardsTable;
