import merge from 'lodash/merge';
import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getLanguages } from '@app/translations';
import SkeepersFlag from '@components/Common/SkeepersFlag';
import CustomDesign from '@components/Form/CreateVideoSpreadForm/Sections/CustomDesign';
import Extract from '@components/Form/CreateVideoSpreadForm/Sections/Extract';
import Subtitle from '@components/Form/CreateVideoSpreadForm/Sections/Subtitle';
import { useForm } from '@components/FormElement/Form';
import { LeftPart, RightPart, Section, StepForm } from '@components/FormElement/FormTemplates/StepForm';

const stepsNames = {
  EXTRACT: 'extract',
  CUSTOMDESIGN: 'customDesign',
  SUBTITLE: 'subtitle',
};

const CreateVideoSpreadForm = ({ template = {}, output = {}, onSubmit }) => {
  const { t } = useTranslation();
  const methods = useForm();
  const config = methods.watch();
  const languages = useMemo(() => getLanguages(t), [t]);

  const haveCustomeText =
    template?.customDesign?.intro?.texts.length > 0 || template?.customDesign?.layout?.texts.length > 0 || template?.customDesign?.outro?.texts.length > 0;

  const _onSubmit = (values) => {
    const defaultValues = {
      limits: { startAt: 0, endAt: null },
      customDesign: {
        layout: template?.customDesign?.layout?.texts?.map(({ value }) => value) ?? [],
        intro: template?.customDesign?.intro?.texts?.map(({ value }) => value) ?? [],
        outro: template?.customDesign?.outro?.texts?.map(({ value }) => value) ?? [],
      },
      subtitleId: 'NONE',
    };
    // Merge the two objects value and default value to get the default values if they are not changed
    const result = merge(defaultValues, values);

    return onSubmit({ ...result, subtitleId: result.subtitleId === 'NONE' ? null : result.subtitleId });
  };

  //Get the subtitle code based on the subtitleID to update left menuItem
  const subtitleLanguageCode = config.subtitleId !== 'NONE' && output.subtitles.find((s) => config.subtitleId === s.id);
  const subtitleLanguage = subtitleLanguageCode && languages[subtitleLanguageCode.language];

  //Get the duration of the video or of the cutVideo to display it in the MenuItem
  const videoDuration = config?.limits && Math.floor((config.limits.endAt - config.limits.startAt) / 1000);

  const tabsAvailable = (obj) => {
    if (!obj) {
      return [null];
    }
    Object.keys(obj).forEach((k) => !obj[k] && obj[k] !== undefined && delete obj[k]);
    //Delete videoBox to only get tabs from layout or intro or outro
    delete obj.videoBox;
    return obj;
  };

  const defaultTab = Object.keys(tabsAvailable(template?.customDesign))[0];

  //Array of the steps/steps and add it to the array if there are in the template
  const steps = [
    ...(template.cutAllowed
      ? [
          {
            title: t('Extract'),
            name: stepsNames.EXTRACT,
            content: t(`{{videoDuration}}s`, { videoDuration }),
            description: t('Select the extract you want to export'),
            component: (
              <Extract
                limits={config?.limits}
                outroDuration={template.outroPath ? template.outroDuration : null}
                introDuration={template.introPath ? template.introDuration : null}
                maxDuration={template.maxDuration}
                output={output}
              />
            ),
          },
        ]
      : []),
    ...(haveCustomeText
      ? [
          {
            title: t('Custom Text'),
            name: stepsNames.CUSTOMDESIGN,
            description: t(
              'The custom design applies to texts that are not fixed in the pre-saved template. For any change in the template, please contact the Teester team.'
            ),
            component: <CustomDesign config={config} template={template} defaultTab={defaultTab} />,
          },
        ]
      : []),
    ...(template.subtitle
      ? [
          {
            title: t('Subtitles'),
            name: stepsNames.SUBTITLE,
            description: t('The subtitles will be overlaid on the video when it is generated. Subtitles will be located at the bottom of the video.'),
            content: subtitleLanguage ? <SkeepersFlag country={subtitleLanguage.coutry} title={subtitleLanguage.name} /> : null,
            component: <Subtitle languages={languages} output={output} />,
          },
        ]
      : []),
  ];

  return (
    <StepForm steps={steps} methods={methods}>
      <LeftPart title={template.title} titleDescription={t('Template name')} steps={steps} />
      <RightPart methods={methods} onSubmit={_onSubmit} keepUnregistered={true} skipDirtyCheck={true}>
        {steps.map((step, i) => {
          return (
            <Section name={step.name} title={step.title} description={step.description} content={step.content} key={i}>
              {step.component}
            </Section>
          );
        })}
      </RightPart>
    </StepForm>
  );
};

CreateVideoSpreadForm.propTypes = {
  template: PropTypes.object,
  output: PropTypes.object,
  onSubmit: PropTypes.func,
};

export default CreateVideoSpreadForm;
