import { useTranslation } from 'react-i18next';

import HtmlPreview from '@components/Common/HtmlPreview';
import Window from '@components/Window/Window';

const HtmlPreviewWindow = ({ title, html = '', onClose = () => {}, open = false }) => {
  const { t } = useTranslation();
  const titleToUse = title ?? t('Preview');

  return (
    <Window title={titleToUse} open={open} onClose={onClose}>
      <HtmlPreview title={title} html={html} />
    </Window>
  );
};

export default HtmlPreviewWindow;
