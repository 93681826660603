import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

import style from './style.module.css';

const DragAndDropZone = ({ onEdit = () => {}, forwardRef, children, multiple, dropzone = true }) => {
  const { isDragActive, isFileDialogActive, open, getRootProps, getInputProps } = useDropzone({
    multiple,
    noClick: true,
    onDropAccepted: (files) => {
      if (files) {
        onEdit(files);
      }
    },
  });

  forwardRef.current = { open };
  const focused = isDragActive || isFileDialogActive;
  return (
    <div {...getRootProps({ className: dropzone ? `${style.dropzone} ${focused ? style.active : ''}` : '' })}>
      <input {...getInputProps()} />
      {children}
    </div>
  );
};

DragAndDropZone.displayName = 'DragAndDropZone';

DragAndDropZone.propTypes = {
  onEdit: PropTypes.func,
  forwardRef: PropTypes.object,
};

export default DragAndDropZone;
