import classNames from 'classnames';

import SkeepersIcon from '@components/Common/SkeepersIcon';
import { useLayoutContext } from '@components/Workflows/WorkflowLayout/context';

import style from './style.module.css';

const SideBar = ({ children }) => {
  const { opened, toggle } = useLayoutContext();
  return (
    <>
      {!opened && (
        <div className={style.openButtonContainer}>
          <div className={style.openButton} onClick={() => toggle((t) => !t)}>
            <SkeepersIcon type="CHEVRON_LEFT" color="white" />
          </div>
        </div>
      )}

      <nav className={classNames(style.nav, { [style.open]: opened })}>
        <div className={style.arrowContainer}>
          <SkeepersIcon type="ARROW_RIGHT" color="var(--layout-primary-color)" className={style.arrow} onClick={() => toggle((t) => !t)} />
        </div>
        <div className={style.tabContainer}>{children}</div>
      </nav>
    </>
  );
};

export default SideBar;
