import classNames from 'classnames';
import PropTypes from 'prop-types';

import style from './style.module.css';

const Header = ({ children, onClick, className = '', as: Component = 'h1' }) => (
  <Component className={classNames(style.header, className)} onClick={onClick}>
    {children}
  </Component>
);

export default Header;

Header.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  onClick: PropTypes.func,
};
