export const getYoutubeIdFromUrl = (url) => {
  // eslint-disable-next-line no-useless-escape
  const regex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  const match = url.match(regex);
  if (!match[1]) {
    throw new Error("Could't find an id in this url");
  }
  return match[1];
};

/**
 * Format the object to match the database format
 * @param {*} formData Data coming from the form to upsert externalVideo
 * @param {*} youtubeMetadata Metadata object coming from the youtube api
 * @returns
 */
export const formatExternalVideo = (videoId, formData, youtubeMetadata) => {
  return {
    videoId: videoId,
    provider: 'YOUTUBE',
    ...(youtubeMetadata
      ? {
          title: youtubeMetadata.title,
          description: youtubeMetadata.description,
          poster: youtubeMetadata.thumbnails?.high?.url || youtubeMetadata.thumbnails?.default?.url,
          duration: parseInt(youtubeMetadata.duration, 10),
          publicationDate: new Date(youtubeMetadata.publishedAt).valueOf(),
        }
      : {}),
    projectUrl: formData.url,
    productReferences: formData.productReferences ?? [],
    distributions: formData.distributors ?? [],
    categories: formData.categories ?? [],
  };
};

export const isYoutubeUrl = (url) => {
  // eslint-disable-next-line no-useless-escape
  const regExp = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;
  return !!url.match(regExp);
};

export const getEmbedVideoUrl = ({ provider, videoId }) => {
  if (provider === 'YOUTUBE') {
    return `https://www.youtube.com/embed/${videoId}`;
  }
};
