import { useTranslation } from 'react-i18next';

import DateTime from '@components/Common/DateTime';
import Window from '@components/Window/Window';

import style from './style.module.css';

const SideTitle = ({ title = '' }) => {
  return (
    <div className={style.containerTitle}>
      <div className={style.changelogTitle}>{title}</div>
      <hr className={style.changelogHR} />
    </div>
  );
};

const SideDetail = ({ title = '', description = '' }) => {
  return (
    <li className={style.changelogDetail}>
      <span className={style.changelogDetailTitle}>{title}</span> {description}
    </li>
  );
};

const Side = ({ title = '', details = [] }) => {
  return (
    <div>
      <SideTitle title={title} />
      <ul className={style.list}>
        {details.map((detail) => (
          <SideDetail title={detail.title} description={detail.description} />
        ))}
      </ul>
    </div>
  );
};

const ChangelogWindow = ({ changelog = null, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();
  const dateTime = <DateTime timestamp={changelog?.availableAt} className={style.dateHeaderColor} relative={false} />;

  return (
    <Window title={t("WHAT'S NEW")} subtitle={dateTime} open={open} onClose={onClose}>
      {changelog && (
        <div className={style.container}>
          {changelog?.features.length > 0 && <Side title={t('The future is Now')} details={changelog?.features} />}
          {changelog?.bugfixes.length > 0 && <Side title={t('These bugs are gone (for us)')} details={changelog?.bugfixes} />}
        </div>
      )}
    </Window>
  );
};

export default ChangelogWindow;
