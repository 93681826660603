import { createContext, useContext, useState } from 'react';

import { youtubeExportValidation, youtubeTemplateVariables } from '@app/utils/socialMedia';

const defaultState = { videos: [], globalConfiguration: null, updateVideos: () => {}, updateGlobalConfiguration: () => {} };

export const UploadSocialModalContext = createContext({
  ...defaultState,
});

export const useUploadSocialModal = () => useContext(UploadSocialModalContext);

export const UploadSocialModalProvider = ({ sourceVideos, children }) => {
  const [videos, setVideos] = useState(sourceVideos.map((video) => ({ ...video, privacyStatus: 'Private', subtitlesActivated: false })));
  const [globalConfiguration, setGlobalConfiguration] = useState({
    playlistId: '',
    privacyStatus: 'Private',
    templateId: '',
    subtitlesActivated: false,
  });

  const updateVideos = (videosUpdated) => {
    setVideos(videosUpdated);
  };

  const updateGlobalConfiguration = (globalConfigurationUpdated) => {
    setGlobalConfiguration((prevConfiguration) => ({ ...prevConfiguration, ...globalConfigurationUpdated }));
  };

  const getVariables = (video) => ({
    PRODUCT_NAME: video?.productTitle || video?.products?.[0]?.name,
    PRODUCT_DESCRIPTION: video?.products?.[0]?.description,
    CREATOR_FIRST_NAME: video?.user?.firstName,
    CREATOR_LAST_NAME: video?.user?.lastName,
    PRODUCT_URL: video?.productUrl || video?.products?.[0]?.pageUrl,
    PRODUCT_REF: video?.productReferences?.join(', ') || video?.products?.[0]?.reference,
    PRODUCT_EAN: video?.products?.[0]?.ean,
    CLIENT_NAME: video?.solution?.company?.name,
  });

  /**
   *
   * @param {Object} video - videoProject object
   * @param {String} value - string with variables in this format '{{{variable}}}'
   * @returns {String} - return a string where '{{{variables}}}' replaced to actual values
   *
   * @example
   *
   * // returns 'Video for Laptop (111)'
   * const appliedFilters = replaceVariables({ productTitle: 'Laptop', products: [{ ean: '111' }] }, 'Video for {{{PRODUCT_NAME}}} ({{{PRODUCT_EAN}}})');
   */
  const replaceVariables = (video, value) => {
    const variables = getVariables(video);
    return youtubeTemplateVariables.reduce((result, variable) => result.replaceAll(`{{{${variable}}}}`, variables[variable] || ''), value || '');
  };

  /**
   *
   * @param {Object} video - videoProject object
   * @param {String} value - string with variables in this format '{{{variable}}}'
   * @returns {String[]} - return array of strings that represents missed variables
   *
   * @example
   *
   * // returns ['PRODUCT_EAN']
   * const missedVariables = getMissedVariables({ productTitle: 'Laptop', products: [{ ean: '' }] }, 'Video for {{{PRODUCT_NAME}}} ({{{PRODUCT_EAN}}})');
   */
  const getMissedVariables = (video, value) => {
    const usedVariables = youtubeTemplateVariables.filter((variable) => value && value.includes(`{{{${variable}}}}`));
    const variables = getVariables(video);
    return usedVariables.filter((variable) => !variables[variable]);
  };

  const invalidVideoIds = videos
    .filter((video) => {
      const title = replaceVariables(video, video.title);
      if (
        !title ||
        !youtubeExportValidation.title.length(title) ||
        !youtubeExportValidation.title.content(title) ||
        getMissedVariables(video, video.title).length
      ) {
        return true;
      }

      const description = replaceVariables(video, video.description);
      if (
        !description ||
        !youtubeExportValidation.description.length(description) ||
        !youtubeExportValidation.description.content(description) ||
        getMissedVariables(video, video.description).length
      ) {
        return true;
      }

      return false;
    })
    .map(({ id }) => id);

  return (
    <UploadSocialModalContext.Provider
      value={{ videos, globalConfiguration, updateVideos, updateGlobalConfiguration, replaceVariables, invalidVideoIds, getMissedVariables }}
    >
      {children}
    </UploadSocialModalContext.Provider>
  );
};
