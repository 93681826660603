import PropTypes from 'prop-types';

import OrderProblemForm from '@components/Form/OrderProblemForm';
import Window from '@components/Window/Window';

const OrderProblemWindow = ({ onSubmit = () => {}, problem = {}, step = 'CREATE', isLoading = false, title = '', open = false, onClose = () => {} }) => {
  return (
    <Window title={title} open={open} onClose={onClose}>
      <OrderProblemForm problem={problem} step={step} onSubmit={onSubmit} onClose={onClose} isUpdating={isLoading} />
    </Window>
  );
};

export default OrderProblemWindow;

OrderProblemWindow.propTypes = {
  onSubmit: PropTypes.func,
  problem: PropTypes.shape({
    type: PropTypes.string,
    comment: PropTypes.string,
    resolution: PropTypes.string,
  }),
  step: PropTypes.oneOf(['CREATE', 'UPDATE', 'RESOLVE']),
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
