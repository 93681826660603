export default class Clock {
	timeout = null;
	startedAt = null;
	onProgress = null;
	interval = null;

	constructor(onProgress, interval) {
		this.timeout = null;
		this.startedAt = null;
		this.interval = interval;
		this.onProgress = onProgress;
	}

	start = () => {
		this.startedAt = Date.now();
		this.timeout = setTimeout(this.step, this.interval);
	};

	stop = () => {
		clearTimeout(this.timeout);
		this.timeout = null;
	};

	step = () => {
		const delay = Date.now() - this.startedAt;
		this.onProgress(delay);
		this.timeout = setTimeout(this.step, this.interval);
	};
};
