import { useTranslation } from 'react-i18next';

import AddMediasForm from '@components/Form/AddMediasForm';
import Window from '@components/Window/Window';

const AddMediasWindow = ({ onEdit = () => {}, isLoading = false, onClose = () => {}, open = false, filenamesMode }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Add new medias')} open={open} onClose={onClose}>
      <AddMediasForm onSubmit={onEdit} isUpdating={isLoading} filenamesMode={filenamesMode} />
    </Window>
  );
};

export default AddMediasWindow;
