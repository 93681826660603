import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonContainer from '@components/Common/ButtonContainer';
import { Form, useForm } from '@components/FormElement/Form';

const ChangelogForm = ({ changelog = {}, isUpdating = false, onSubmit = () => {} }) => {
  const { t } = useTranslation();
  const methods = useForm();

  return (
    <Form methods={methods} onSubmit={onSubmit} isUpdating={isUpdating}>
      <Form.Label title={t('Date')} name="availableAt">
        <Form.TimestampInput name="availableAt" defaultValue={changelog?.availableAt || null} />
      </Form.Label>
      <Form.Label title={t('Features')} name="features">
        <Form.AdvancedList name="features" defaultValue={changelog?.features} />
      </Form.Label>
      <Form.Label title={t('BugFixes')} name="bugfixes">
        <Form.AdvancedList name="bugfixes" defaultValue={changelog?.bugfixes} />
      </Form.Label>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton isUpdating={isUpdating} />
      </ButtonContainer>
    </Form>
  );
};

export default ChangelogForm;

ChangelogForm.propTypes = {
  onSubmit: PropTypes.func,
  isUpdating: PropTypes.bool,
};
