import KeyboardEventHandler from '@skeepers/react-keyboard-event-handler';
import PropTypes from 'prop-types';
import { Component } from 'react';

const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
const macOs = macosPlatforms.includes(window.navigator.platform);

const keyboardMap = macOs
  ? {
      PLAY: ['0', 'space', 'k'],
      FRAME_LEFT: ['left', '1', 'j'],
      FRAME_RIGHT: ['right', '2', 'l'],
      FRAME_LAST: ['up', '3'],
      FRAME_FIRST: ['down'],
      HISTORY_PREVIOUS: ['meta+z'],
      HISTORY_NEXT: ['meta+y', 'meta+shift+z'],

      SAVE: ['meta+s'],
      ANCHOR: ['a'],
      ZOOM: [], // Ctrl + Mouse handled manually
      ZOOM_LESS: ['meta+minus', 'meta+-'],
      ZOOM_MORE: ['meta+plus', 'meta+='],

      UNSELECT_ALL: ['esc'],
      SELECT_ALL: ['meta+a'],
      SELECT: [], // CTRL onKeyDown / onKeyUp handled manually

      DELETE: ['backspace', 'del'],
      VOLUME_MORE: ['+', 'meta+p'],
      VOLUME_LESS: ['-', 'meta+m'],
      VOLUME_MUTE: ['m', '/'],
      VOLUME_PROMPT: ['v'],
      DUPLICATE: ['d'],
      SPEED_PROMPT: ['s'],
      SPEED_LESS: ['z'],
      SPEED_MORE: ['e'],

      VIDEO_LEFT: ['meta+left'],
      VIDEO_RIGHT: ['meta+right'],

      DEV_EDIT: ['meta+d'],
      DEV_EXPORT: ['meta+e'],

      CUT: ['u', 'c'],
      CUT_REMOVE_BEFORE: ['i'],
      CUT_REMOVE_AFTER: ['o'],

      MERGE: ['f'],
    }
  : {
      PLAY: ['0', 'space', 'k'],
      FRAME_LEFT: ['left', '1', 'j'],
      FRAME_RIGHT: ['right', '2', 'l'],
      FRAME_LAST: ['up', '3'],
      FRAME_FIRST: ['down'],
      HISTORY_PREVIOUS: ['ctrl+z'],
      HISTORY_NEXT: ['ctrl+y', 'ctrl+shift+z'],

      SAVE: ['ctrl+s'],
      ANCHOR: ['a'],
      ZOOM: [], // Ctrl + Mouse handled manually
      ZOOM_LESS: ['ctrl+minus'],
      ZOOM_MORE: ['ctrl+plus', 'ctrl+='],

      UNSELECT_ALL: ['esc'],
      SELECT_ALL: ['ctrl+a'],
      SELECT: [], // CTRL onKeyDown / onKeyUp handled manually

      DELETE: ['backspace', 'del'],
      VOLUME_MORE: ['+', 'ctrl+p'],
      VOLUME_LESS: ['-', 'ctrl+m'],
      VOLUME_MUTE: ['m', '/'],
      VOLUME_PROMPT: ['v'],
      DUPLICATE: ['d'],
      SPEED_PROMPT: ['s'],
      SPEED_LESS: ['z'],
      SPEED_MORE: ['e'],

      VIDEO_LEFT: ['ctrl+left'],
      VIDEO_RIGHT: ['ctrl+right'],

      DEV_EDIT: ['ctrl+d'],
      DEV_EXPORT: ['ctrl+e'],

      CUT: ['u', 'c'],
      CUT_REMOVE_BEFORE: ['i'],
      CUT_REMOVE_AFTER: ['o'],

      MERGE: ['f'],
    };

class TimelineKeyboardHandler extends Component {
  constructor(props) {
    super(props);
    this.pureZoom = (event) => {
      if (this.ctrlKey) {
        const delta = Math.sign(event.deltaY);
        this.props.onAction('ZOOM', -delta);
        event.preventDefault();
      }
    };
    this.ctrlKey = false;
  }

  componentDidMount() {
    window.addEventListener('onwheel' in document ? 'wheel' : 'mousewheel', this.pureZoom, { passive: false });
  }

  componentWillUnmount() {
    window.removeEventListener('onwheel' in document ? 'wheel' : 'mousewheel', this.pureZoom, { passive: false });
  }

  render() {
    const { onAction, disabled } = this.props;
    return (
      <div>
        {Object.keys(keyboardMap).map(
          (action) =>
            keyboardMap[action].length > 0 && (
              <KeyboardEventHandler
                key={`KEY-${action}`}
                handleKeys={keyboardMap[action]}
                onKeyEvent={(key, e) => {
                  onAction(action);
                  e.preventDefault();
                  return false;
                }}
                handleFocusableElements
                isDisabled={disabled}
              />
            )
        )}
        <KeyboardEventHandler
          handleEventType="keydown"
          handleKeys={macOs ? ['meta'] : ['ctrl']}
          onKeyEvent={(key, e) => {
            onAction('SELECT', true);
            this.ctrlKey = true;
            e.preventDefault();
            return false;
          }}
          handleFocusableElements
          isDisabled={disabled}
        />
        <KeyboardEventHandler
          handleEventType="keyup"
          handleKeys={macOs ? ['meta'] : ['ctrl']}
          onKeyEvent={(key, e) => {
            onAction('SELECT', false);
            this.ctrlKey = false;
            e.preventDefault();
            return false;
          }}
          handleFocusableElements
          isDisabled={disabled}
        />
      </div>
    );
  }
}

TimelineKeyboardHandler.propTypes = {
  onAction: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

TimelineKeyboardHandler.defaultProps = {
  disabled: false,
};

export default TimelineKeyboardHandler;
