import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonContainer from '@components/Common/ButtonContainer';
import { Form, useForm } from '@components/FormElement/Form';

const OrderForm = ({ order = {}, onSubmit = () => { }, isUpdating = false }) => {
  const { t } = useTranslation();
  const methods = useForm();
  const [isOrderIdRequired, setIsOrderIdRequired] = useState(true)
  const [isOrderIdDisabled, setIsOrderIdDisabled] = useState(false);

  let orderIdInputRef = useRef(null);

  const onIgnoreOrderIdChange = (isIgnoreOrderId) => {
    setIsOrderIdRequired(!isIgnoreOrderId);
    methods.resetField('orderId');
    setIsOrderIdDisabled(isIgnoreOrderId);
  }

  const _validate = (value) => {
    if (isOrderIdRequired && (!value || value.trim().length <= 0)) {
      return t('This input is required');
    }
    return null;
  }

  const _onSubmit = (values) => {
    const toReturn = {};
    Object.keys(values).forEach((value) => {
      toReturn[value] = typeof values[value] === 'string' ? values[value].trim() : values[value];
    });
    onSubmit(toReturn);
  }

  return (
    <Form isUpdating={isUpdating} onSubmit={_onSubmit} methods={methods}>
      <Form.Label title={t('Order id:')} name="orderId">
        <Form.Input
          ref={orderIdInputRef}
          dataTestId="orderIdInput"
          name="orderId"
          defaultValue={order?.orderId}
          required={isOrderIdRequired}
          disabled={isOrderIdDisabled}
          validate={_validate}
        />
      </Form.Label>
      <Form.Label title={t('This order does not have orderId')} name="ignoreOrderId">
        <Form.Switch
          dataTestId="ignoreOrderIdSwitch"
          name="ignoreOrderId"
          checked={isOrderIdDisabled}
          onAfterChange={(value) => onIgnoreOrderIdChange(value)}
        />
      </Form.Label>
      <br />
      <Form.Label title={t('Tracking url:')} name="trackingUrl">
        <Form.UrlInput
          dataTestId="trackingUrlInput"
          name="trackingUrl"
          defaultValue={order?.trackingUrl}
        />
      </Form.Label>
      <ButtonContainer position="RIGHT">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default OrderForm;

OrderForm.propTypes = {
  order: PropTypes.shape({
    orderId: PropTypes.string,
    trackingUrl: PropTypes.string,
    ignoreOrderId: PropTypes.bool
  }),
  onSubmit: PropTypes.func,
  isUpdating: PropTypes.bool,
};

