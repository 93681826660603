/* global zE */
export function toggleSupportWidget(value) {
  if (!zE) return;
  try {
    zE('webWidget', value ? 'show' : 'hide');
  } catch (_) {
    zE('messenger', value ? 'show' : 'hide');
  }

}

export function fillFieldWidget(name, value, isReadOnly = false) {
  if (!zE) return;
  try {
    zE('webWidget', 'prefill', {
      [name]: {
        value: value,
        readOnly: isReadOnly,
      },
    });
  }
  catch (e)  {}
}

export function showSupportWidgetIfPermissions(policies, user) {
  if (policies.find((policy) => policy.role.actions['support.create_ticket'] === true)) {
    toggleSupportWidget(true);
    fillFieldWidget('email', user.email, true);
    fillFieldWidget('name', user.displayName, true);
  } else {
    toggleSupportWidget(false);
  }
}
