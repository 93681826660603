import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';

import ButtonContainer from '@components/Common/ButtonContainer';
import {Form, useForm} from '@components/FormElement/Form';

const CreateVideoTemplateForm = ({onSubmit = () => { }}) => {
  const { t } = useTranslation();
  const methods = useForm();

  return (
    <Form methods={methods} onSubmit={onSubmit}>
      <Form.Label title={t('Title')} name="title">
        <Form.Input name="title" required />
      </Form.Label>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default CreateVideoTemplateForm;

CreateVideoTemplateForm.propTypes = {
  onSubmit: PropTypes.func,
};
