import classNames from 'classnames';
import React from 'react';

import style from './style.module.css';

const sizes = {
  xsmall: '0.8rem',
  small: '0.9rem',
  medium: '1rem',
  large: '1.2rem',
  xlarge: '1.5rem',
  xxlarge: '2rem',
};

const bolder = {
  normal: 'normal',
  medium: 500,
  semiBold: 600,
  bold: 700,
  extraBold: 800,
};

const Text = ({
  bold = 'normal',
  size = 'medium',
  color = '',
  italic = false,
  uppercase = false,
  underline = false,
  children,
  className = '',
  as: Component = 'p',
  textAlign = 'left',
  cursorPointer = false,
  userSelect = 'unset',
}) => {
  return (
    <Component
      className={classNames(style.root, className)}
      style={{
        fontWeight: bolder[bold],
        fontStyle: italic ? 'italic' : 'normal',
        textTransform: uppercase ? 'uppercase' : 'none',
        textDecoration: underline ? 'underline' : 'none',
        color,
        textAlign,
        fontSize: sizes[size],
        cursor: cursorPointer ? 'pointer' : 'unset',
        userSelect,
      }}
    >
      {children}
    </Component>
  );
};

export default Text;
