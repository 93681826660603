// Use this for traditional pagination. Otherwise for infinite scroll pagination use relayStylePagination().
export const relayStyleConcatPagination = (keyArgs = false) => {
  return {
    keyArgs,
    merge(existing, incoming) {
      if (!incoming) return existing;

      return {
        ...incoming,
      };
    },
    read(existing) {
      if (!existing) return existing;

      return {
        ...existing,
      };
    },
  };
};
