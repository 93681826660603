import { useFlag } from '@unleash/proxy-client-react';

import ROUTES, { compareRoute } from '@app/routes';
import { FEATURE_FLAG_NAMES } from '@app/utils/featureFlags';

export const useGetGeneralSubmenu = () => {
  const shoppableAdminWidget = useFlag(FEATURE_FLAG_NAMES.SHOPPABLE_ADMIN_WIDGET);
  const showExportsPage = useFlag(FEATURE_FLAG_NAMES.SHOW_EXPORTS_PAGE);

  const generateGeneralMenu = (companyId, solutions, selectedDropDown, currentRoute, { qrcodeOption, externalVideoOption }, callback, addCallback, t) => [
    {
      name: t('Dashboard'),
      icon: 'DASHBOARD',
      selected: compareRoute(`company/${companyId}/general/dashboard`, currentRoute),
      onClick: `/company/${companyId}/general/dashboard`,
    },
    {
      name: t('Data'),
      icon: 'ANALYTICS',
      selected: compareRoute(`company/${companyId}/general/data`, currentRoute),
      onClick: `/company/${companyId}/general/data`,
      ...(showExportsPage
        ? {
            selected: false,
            open: selectedDropDown === 'DATA-SUBMENU',
            onClick: () => {
              callback('DATA-SUBMENU');
            },
            children: [
              {
                name: t('Dataviz'),
                selected:
                  compareRoute(ROUTES.DASHBOARD_DATA.path({ companyId }), currentRoute) ||
                  compareRoute(ROUTES.VSG_GLOBAL_DATA.path({ companyId }), currentRoute) ||
                  compareRoute(ROUTES.VIDEO_DATA.path({ companyId }), currentRoute) ||
                  compareRoute(ROUTES.QRCODE_DATA.path({ companyId }), currentRoute) ||
                  compareRoute(ROUTES.PRODUCT_DATA.path({ companyId }), currentRoute),
                onClick: ROUTES.DASHBOARD_DATA.path({ companyId }),
              },
              {
                name: t('Reports'),
                selected: compareRoute(ROUTES.EXPORTS_PAGE.path({ companyId }), currentRoute),
                onClick: ROUTES.EXPORTS_PAGE.path({ companyId }),
              },
            ],
          }
        : {}),
    },
    {
      name: t('Audits'),
      icon: 'COGS',
      selected: compareRoute(ROUTES.AUDITS_COMPANY.path({ companyId }), currentRoute),
      onClick: ROUTES.AUDITS_COMPANY.path({ companyId }),
    },
    ...(qrcodeOption
      ? [
          {
            name: t('QR generation'),
            icon: 'QRCODE',
            selected: compareRoute(ROUTES.QRCODE_GENERATION.path({ companyId }), currentRoute),
            onClick: ROUTES.QRCODE_GENERATION.path({ companyId }),
          },
        ]
      : []),
    {
      name: t('Broadcasting'),
      icon: 'PLAYER',
      selected: compareRoute(ROUTES.BROADCASTING.path({ companyId }), currentRoute),
      onClick: ROUTES.BROADCASTING.path({ companyId }),
    },
    ...(shoppableAdminWidget
      ? [
          {
            name: t('Shoppable Content'),
            icon: 'VSG',
            selected: compareRoute(ROUTES.SHOPPABLE_CONTENT_WIDGET.path({ companyId }), currentRoute),
            onClick: ROUTES.SHOPPABLE_CONTENT_WIDGET.path({ companyId }),
          },
        ]
      : []),
    {
      name: t('Developers'),
      icon: 'KEY',
      selected: compareRoute(ROUTES.DEVELOPERS.path({ companyId }), currentRoute),
      onClick: ROUTES.DEVELOPERS.path({ companyId }),
    },
    {
      name: t('Company'),
      icon: 'COMPANY',
      selected: false,
      open: selectedDropDown === 'GENERAL-CONFIGURATION',
      onClick: () => {
        callback('GENERAL-CONFIGURATION');
      },
      children: [
        {
          name: t('Configuration'),
          selected: compareRoute(ROUTES.UPDATE_COMPANY.path({ companyId }), currentRoute),
          onClick: ROUTES.UPDATE_COMPANY.path({ companyId }),
        },
        {
          name: t('Ownerships'),
          selected: compareRoute(ROUTES.OWNERSHIP.path({ companyId }), currentRoute),
          onClick: ROUTES.OWNERSHIP.path({ companyId }),
        },
        {
          name: t('Client follow-up'),
          selected: compareRoute(ROUTES.COMPANY_EVENTS.path({ companyId }), currentRoute),
          onClick: ROUTES.COMPANY_EVENTS.path({ companyId }),
        },
        {
          name: t('Social media templates'),
          selected:
            compareRoute(ROUTES.VIDEO_TEMPLATES.path({ companyId }), currentRoute) ||
            compareRoute(ROUTES.YOUTUBE_VIDEO_TEMPLATES.path({ companyId }), currentRoute),
          onClick: ROUTES.VIDEO_TEMPLATES.path({ companyId }),
        },
        {
          name: t('Injection (Skeepers integration)'),
          selected: compareRoute(ROUTES.INJECTIONS.path({ companyId }), currentRoute),
          onClick: ROUTES.INJECTIONS.path({ companyId }),
        },
        {
          name: t('External Video'),
          selected: compareRoute(ROUTES.EXTERNAL_VIDEO.path({ companyId }), currentRoute),
          onClick: ROUTES.EXTERNAL_VIDEO.path({ companyId }),
        },
        ...(qrcodeOption
          ? [
              {
                name: t('QR configuration'),
                selected: compareRoute(ROUTES.QRCODE_TEMPLATES.path({ companyId }), currentRoute),
                onClick: ROUTES.QRCODE_TEMPLATES.path({ companyId }),
              },
            ]
          : []),
      ],
    },
  ];

  return { generateGeneralMenu };
};
