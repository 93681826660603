import MenuLeft from './MenuLeft';
import MenuRight from './MenuRight';

const Menu = ({ open = true, config = {}, title = '' }) => {
  return (
    <>
      <MenuLeft menu={config?.left} open={open} />
      <MenuRight menu={config?.right} open={open} title={title} />
    </>
  );
};

export default Menu;
