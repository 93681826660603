import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getTaskDevices, getTaskTypes } from '@app/translations';
import { getSdkBaseLib } from '@app/utils/integration';
import ButtonContainer from '@components/Common/ButtonContainer';
import { Form, useForm } from '@components/FormElement/Form';

const getButtonLabel = (t, taskId, taskEnabled) => {
  if (taskId) {
    return taskEnabled ? t('Save & Run') : t('Save');
  }
  return taskEnabled ? t('Create & Run') : t('Create');
};

const AuditTaskForm = ({ task: inputTask = {}, onSubmit = () => {}, isLoading = false }) => {
  const { t } = useTranslation();
  const methods = useForm();
  const task = Object.assign(
    {
      script: 'await teesterAcceptCookies();',
      device: 'ALL',
      enabled: true,
      notificationsEnabled: true,
    },
    inputTask
  );
  const devices = getTaskDevices(t);
  const taskTypes = getTaskTypes(t);

  const enabled = methods.watch('enabled');
  const extraLib = `${getSdkBaseLib()}\nconst teesterSleep = async (ms) => {}; const teesterAcceptCookies = async () => {};`;

  return (
    <Form onSubmit={onSubmit} methods={methods} isUpdating={isLoading}>
      <Form.Label title={t('Type')} name="type">
        <Form.Select name="type" defaultValue={task.type} readOnly options={[{ label: taskTypes[task.type], value: task.type }]} />
      </Form.Label>
      <Form.Label title={t('Device')} name="device">
        <Form.Select
          required
          name="device"
          defaultValue={task.device || 'ALL'}
          options={[
            {
              label: t('All'),
              value: 'ALL',
            },
            ...Object.keys(devices).map((device) => ({
              label: devices[device],
              value: device,
            })),
          ]}
        />
      </Form.Label>
      <Form.Label title={t('Url')} name="url">
        <Form.UrlInput name="url" defaultValue={task.url} required />
      </Form.Label>
      <Form.Label title={t('Script')} name="script">
        <Form.CodeEditor name="script" defaultValue={task.script} extraLib={extraLib} />
      </Form.Label>
      <Form.Label title={t('Notifications enabled')} name="enabled">
        <Form.Switch name="notificationsEnabled" defaultValue={task.notificationsEnabled} />
      </Form.Label>
      {task.id && (
        <Form.Label title={t('Task enabled')} name="enabled">
          <Form.Switch name="enabled" defaultValue={task.enabled} />
        </Form.Label>
      )}
      <ButtonContainer position="CENTER">
        <Form.SubmitButton label={getButtonLabel(t, task.id, enabled)} />
      </ButtonContainer>
    </Form>
  );
};

export default AuditTaskForm;

AuditTaskForm.propTypes = {
  task: PropTypes.shape({
    device: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
    script: PropTypes.string,
    enabled: PropTypes.bool,
    notificationsEnabled: PropTypes.bool,
  }),
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};
