import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonContainer from '@components/Common/ButtonContainer';
import { Grid } from '@components/Common/Grid';
import { Form, useForm } from '@components/FormElement/Form';

const AddressForm = ({ address = {}, onSubmit = () => {}, isUpdating = false, searchAddress = () => {} }) => {
  const { t } = useTranslation();
  const methods = useForm();

  return (
    <Form isUpdating={isUpdating} onSubmit={onSubmit} methods={methods}>
      <Grid columns={2}>
        <Form.Label title={t('Name')} name="address.name">
          <Form.Input name="address.name" placeholder={t('home, work, ...')} defaultValue={address.name} required />
        </Form.Label>
        <Form.Label title={t('Recipient')} name="address.recipient">
          <Form.Input name="address.recipient" defaultValue={address.recipient} required />
        </Form.Label>
      </Grid>
      <Form.Label title={t('Address')} name="address.street">
        <Form.AddressAutocomplete
          name="address.street"
          search={searchAddress}
          inputValue={address.street}
          defaultValue={address.street}
          onSelect={(address) => Object.entries(address).forEach(([key, value]) => methods.setValue(`address.${key}`, value, { shouldValidate: true }))}
          required
        />
      </Form.Label>
      <Form.Label title={t('Additional info')} name="address.complement">
        <Form.Input name="address.complement" placeholder={t('apartment, floor number, ...')} defaultValue={address.complement} />
      </Form.Label>
      <Grid columns={2}>
        <Form.Label title={t('Country')} name="address.country">
          <Form.CountrySelect name="address.country" defaultValue={address.country} onAfterChange={() => methods.setValue('address.state', null)} required />
        </Form.Label>
        <Form.Label title={t('State')} name="address.state">
          <Form.StateSelect country={methods.watch('address.country')} name="address.state" defaultValue={address.state} />
        </Form.Label>
      </Grid>
      <Grid columns={2}>
        <Form.Label title={t('City')} name="address.city">
          <Form.Input name="address.city" defaultValue={address.city} required />
        </Form.Label>
        <Form.Label title={t('ZIP / Postal Code')} name="address.zipcode">
          <Form.Input name="address.zipcode" defaultValue={address.zipcode} required />
        </Form.Label>
      </Grid>
      <ButtonContainer position="RIGHT">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default AddressForm;

AddressForm.propTypes = {
  address: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    optionalInfo: PropTypes.string,
    zipcode: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  isUpdating: PropTypes.bool,
  searchAddress: PropTypes.func,
};
