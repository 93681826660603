import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getLanguages } from '@app/translations';
import ButtonContainer from '@components/Common/ButtonContainer';
import SkeepersFlag from '@components/Common/SkeepersFlag';
import { Form, useForm } from '@components/FormElement/Form';

const CreateSocialMediaVideoForm = ({ onSubmit = () => { }, templates = [], subtitles = [], url, storyboard }) => {
  const { t } = useTranslation();
  const methods = useForm();
  const languages = useMemo(() => getLanguages(t), [t]);;

  const { templateId } = methods.watch();
  const template = templates.find(({ id }) => id === templateId);

  const getTemplateTexts = (key) => {
    const texts = template.customDesign[key]?.texts;
    if (!texts) {
      return null;
    }
    return texts.map((text, idx) => {
      const name = `customDesign.${key}.${idx}`;
      return (
        <Form.Label title={t('Custom design {{key}} text {{idx}}', { key, idx: idx + 1 })} name={name} key={name}>
          <Form.Input name={name} defaultValue={text.value} />
        </Form.Label>
      );
    });
  };

  return (
    <Form onSubmit={onSubmit} methods={methods}>
      <Form.Label title={t('Template')} name="templateId">
        <Form.Select
          name="templateId"
          options={templates.map((template) => ({
            label: template.title,
            value: template.id,
          }))}
        />
      </Form.Label>
      {template?.subtitle && (
        <Form.Label title={t('Subtitle')} name="subtitleId">
          <Form.Select
            name="subtitleId"
            options={[
              {
                label: t('No subtitles'),
                value: null,
              },
              ...subtitles
                .filter(({ status }) => status === 'READY')
                .map((subtitle) => ({
                  label: languages[subtitle.language]?.name,
                  value: subtitle.id,
                  icon: <SkeepersFlag country={languages[subtitle.language]?.country} />,
                })),
            ]}
          />
        </Form.Label>
      )}
      {template?.cutAllowed && (
        <Form.Label title={t('Video limits')} name="limits">
          <Form.VideoLimits name="limits" url={url} required defaultValue={{ startAt: 0, endAt: null }} storyboard={storyboard} />
        </Form.Label>
      )}
      {template?.customDesign && [getTemplateTexts('layout'), getTemplateTexts('intro'), getTemplateTexts('outro')].flat()}
      <ButtonContainer position="CENTER">
        <Form.SubmitButton label="Generate" />
      </ButtonContainer>
    </Form>
  );
};

export default CreateSocialMediaVideoForm;

CreateSocialMediaVideoForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool,
  templates: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      ratio: PropTypes.string,
      cutAllowed: PropTypes.bool,
      subtitle: PropTypes.bool,
    })
  ),
  subtitles: PropTypes.arrayOf(
    PropTypes.shape({
      language: PropTypes.string,
      id: PropTypes.string,
    })
  ),
  videoLength: PropTypes.number,
  url: PropTypes.string,
};
