import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ProductForm from '@components/Form/ProductForm';
import Window from '@components/Window/Window';

const UpdateProductWindow = ({ product, onEdit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Update product')} open={open} onClose={onClose}>
      <ProductForm product={product} isUpdating={isLoading} onSubmit={onEdit} t={t} />
    </Window>
  );
};

export default UpdateProductWindow;

UpdateProductWindow.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    tags: PropTypes.array,
    pageUrl: PropTypes.string,
    reference: PropTypes.string,
    picture: PropTypes.string,
    description: PropTypes.string,
    quantity: PropTypes.number,
    archived: PropTypes.bool,
    briefPdf: PropTypes.array,
    briefSteps: PropTypes.array,
  }),
  onEdit: PropTypes.func,
  isLoading: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};
