import moment from 'moment';

import { projectStatuses } from '@hooks/projects';

/**
 * If the status is in DELETED, REFUSED_RUSHES, ABANDONNED we can hide inputs
 * Else if the status is VALIDATED and the last validation of the project is before today - 3 months we can hide inputs
 */
export const hideRushes = (status, logs) => {
  const projectValidation = logs
    ?.filter((l) => l.eventType === 'PROJECT_VALIDATE')
    .sort((a, b) => b.createdAt - a.createdAt)
    .shift();

  if (!projectValidation) {
    return false;
  }

  return (
    [projectStatuses.DELETED, projectStatuses.REFUSED_RUSHES, projectStatuses.ABANDONNED].includes(status) ||
    (status === projectStatuses.VALIDATED && projectValidation.createdAt < moment().subtract(3, 'months').unix())
  );
};

export const projectDiffusionState = {
  online: 'ONLINE',
  offline: 'OFFLINE',
};

export const getBroadcastingPageFilters = ({ solutions = [], tags = [], searchParams = {}, showUploadedFilter = false, t = (_) => _ }) => {
  return [
    ...(solutions.length > 1
      ? [
          {
            key: 'solutionId',
            label: t('Solution'),
            options: [{ label: t('All'), value: 'ALL' }, ...solutions.map((s) => ({ label: s.name, value: s.id }))],
          },
        ]
      : []),
    {
      key: 'subtitles',
      label: t('Subtitles'),
      options: [
        { label: t('All'), value: 'ALL' },
        { label: t('With subtitles'), value: '1' },
        { label: t('Without subtitles'), value: '0' },
      ],
    },
    {
      key: 'tag',
      label: t('Tags'),
      disabled: !tags.length,
      placeholder: searchParams?.tag,
      isMulti: true,
      options: tags.map((tag) => ({ label: tag, value: tag })),
    },
    ...(showUploadedFilter
      ? [
          {
            key: 'upload',
            label: t('Uploaded'),
            options: [
              { label: t('All'), value: 'ALL' },
              { label: t('Uploaded'), value: '1' },
              { label: t('Not uploaded'), value: '0' },
            ],
          },
        ]
      : []),
  ];
};

export const isVerticalVideo = (ratio) => ratio === '9:16';

export const PROJECT_INPUT_STATUS = {
  validated: 'VALIDATED',
  refused: 'REFUSED',
};
