import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { durationInMinutes } from '@app/utils';
import Button from '@components/Common/Button';
import Divider from '@components/Common/Divider';
import SkeepersIcon from '@components/Common/SkeepersIcon';

import style from './style.module.css';

const TimelineMenu = ({ ratio, selectedElements = [], onSpeedChange = () => {}, onVolumeChange = () => {} }) => {
  const { t } = useTranslation();

  const getSpeedValue = () => {
    if (selectedElements.length === 1) {
      return selectedElements[0].speed;
    } else {
      const speed = selectedElements[0].speed;
      if (selectedElements.filter((e) => e.speed === speed).length === selectedElements.length) {
        return speed;
      } else {
        return null;
      }
    }
  };

  const decreaseSpeed = () => {
    const speed = getSpeedValue();

    if (!speed) {
      onSpeedChange(1);
    } else {
      const newSpeed = speed - 0.25;
      if (newSpeed >= 0.5) {
        onSpeedChange(newSpeed);
      }
    }
  };

  const increaseSpeed = () => {
    const speed = getSpeedValue();

    if (!speed) {
      onSpeedChange(1);
    } else {
      const newSpeed = speed + 0.25;
      if (newSpeed <= 10) {
        onSpeedChange(newSpeed);
      }
    }
  };

  const getVolumeValue = () => {
    if (selectedElements.length === 1) {
      return selectedElements[0].volume * 100;
    } else {
      const volume = selectedElements[0].volume;
      if (selectedElements.filter((e) => e.volume === volume).length === selectedElements.length) {
        return volume * 100;
      } else {
        return 100;
      }
    }
  };

  const getDurationValue = () => {
    if (selectedElements.length === 1) {
      return selectedElements[0].duration;
    } else {
      const start = selectedElements.reduce((acc, elem) => (elem.position < acc ? elem.position : acc), selectedElements[0].position);
      const end = selectedElements.reduce((acc, elem) => (elem.position + elem.duration > acc ? elem.position + elem.duration : acc), 0);
      return end - start;
    }
  };

  return (
    <div className={classNames(style.root, ratio === '9:16' ? style.rootVertical : null)}>
      {selectedElements.length > 0 && (
        <div className={style.container}>
          <h1>{t('Speed')}</h1>
          <div className={style.speedContainer}>
            <div className={style.speedRangeContainer}>
              <div className={style.speedIconsContainer} onClick={decreaseSpeed}>
                <SkeepersIcon type="MINUS" className={style.speedIcons} />
              </div>
              <span>{getSpeedValue() ? `${getSpeedValue()}x` : '-'}</span>
              <div className={style.speedIconsContainer} onClick={increaseSpeed}>
                <SkeepersIcon type="PLUS" className={style.speedIcons} />
              </div>
            </div>
            <div className={style.btnContainer}>
              <Button className={style.btn} color="#464646" label={t('Reset')} action={() => onSpeedChange(1)} />
            </div>
          </div>

          <h1>{t('Volume')}</h1>
          <div className={style.volumeContainer}>
            <input
              type="range"
              min={0}
              max={100}
              step={1}
              value={getVolumeValue()}
              onChange={(event) => onVolumeChange(event.target.value / 100)}
              className={style.volumeInput}
            />
            <div className={style.btnContainer}>
              <Button
                className={style.btn}
                color="#464646"
                label={getVolumeValue() !== 0 ? t('Mute') : t('Unmute')}
                action={() => onVolumeChange(getVolumeValue() !== 0 ? 0 : 100)}
              />
            </div>
          </div>

          <Divider className={style.divider} />

          <h1 className={style.filename}>
            {selectedElements.length === 1 ? selectedElements[0].input.filename : `${selectedElements.length} ${t('elements')}`}
          </h1>
          <div className={style.infosContainer}>
            <h2>{t('Duration')}:</h2> <span>{durationInMinutes(getDurationValue())}</span>
          </div>

          {selectedElements.length === 1 && selectedElements[0].input.resolution && (
            <div className={style.infosContainer}>
              <h2>{t('Quality')}:</h2> <span>{selectedElements[0].input.resolution}p</span>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default TimelineMenu;
