import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useBeforeUnload } from 'react-router-dom';

export const PromptBeforeUnload = ({ when = false, message = '' }) => {
  const { t } = useTranslation();
  const displayedMessage = message || t('You have unsaved changes, are you sure you want to leave?');

  useBeforeUnload(
    useCallback(
      (event) => {
        if (when) {
          event.preventDefault();
          event.returnValue = displayedMessage;
          return displayedMessage;
        }
      },
      [when, displayedMessage],
    ),
    { capture: true },
  );

  return <div></div>;
};

PromptBeforeUnload.propTypes = {
  when: PropTypes.bool,
  message: PropTypes.string,
};

export default PromptBeforeUnload;
