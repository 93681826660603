import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import { getLanguageCode } from '@app/i18n';
import ROUTES from '@app/routes';
import Action from '@components/Common/Action';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import { useWindows, WINDOW_TYPES } from '@contexts/WindowContext';
import useSearch from '@hooks/useSearch';

import { formatTime, VIDEO_FPS } from '../Common/config';

import style from './style.module.css';

const TimelineNav = ({
  workflows = false,
  scaleX = 0,
  duration = 0,
  time = 0,
  currentTimelineId = null,
  timelines = [],
  onChangeTimeline = () => {},
  playing = false,
  magnet = true,
  canRedo = false,
  canUndo = false,
  canSave = false,
  canExport = false,
  canFitToScreen = false,
  onSwitchMagnet = () => {
    console.log('[TimelineNav] onSwitchMagnet()');
  },
  onZoomChange = (e) => {
    console.log('[TimelineNav] onZoomChange()', e);
  },
  onPlay = (e) => {
    console.log('[TimelineNav] onPlay()', e);
  },
  onSeek = (e) => {
    console.log('[TimelineNav] onSeek()', e);
  },
  onUndo = () => {
    console.log('[TimelineNav] onUndo()');
  },
  onRedo = () => {
    console.log('[TimelineNav] onRedo()');
  },
  onSave = () => {
    console.log('[TimelineNav] onSave()');
  },
  onExport = () => {
    console.log('[TimelineNav] onExport()');
  },
  onShortcuts = () => {
    console.log('[TimelineNav] onShortcuts()');
  },
  onEditingBrief = () => {
    console.log('[TimelineNav] onEditingBrief()');
  },
  checkGap = () => {
    return true;
  },
  onFitToScreen = () => {
    console.log('[TimelineNav] onFitToScreen()');
  },
}) => {
  const { t } = useTranslation();
  const params = useParams();
  const searchParams = useSearch();
  const { actions } = useWindows();

  const handleZoomChange = (e) => onZoomChange(parseInt(e.target.value, 10));
  const handlePlayPause = () => onPlay(!playing);
  const handleNextFrame = () => onSeek(time + 1 / VIDEO_FPS);
  const handlePreviousFrame = () => onSeek(time - 1 / VIDEO_FPS);

  const onWantSave = () => {
    if (!checkGap()) {
      actions.open(WINDOW_TYPES.CONFIRM, {
        action: () => onSave(),
        message: t('There is gap(s) on the video, do you want to continue?'),
        closing: 'BEFORE',
      });
    } else {
      onSave();
    }
  };

  const onWantExport = () => {
    actions.open(WINDOW_TYPES.CONFIRM, {
      action: () => onExport(),
      message: `${!checkGap() ? t('Warning: There is gap(s) on the video.') : ''} ${t('Would you want to export the video?')}`,
      closing: 'BEFORE',
    });
  };

  const onChangeSelect = (value) => {
    const timeline = timelines.find((timeline) => timeline.id === value);
    if (timeline) {
      onChangeTimeline(timeline.timeline.map((e) => ({ ...e, id: uuidv4() })));
    }
  };

  const onWantFitToScreen = () => {
    if (canFitToScreen) {
      onFitToScreen();
    }
  };

  const timeOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };

  return (
    <div className={style.controls}>
      <div className={style.left}>
        {!workflows && (
          <>
            <span className={`${style.bigIcon}`} title={t('Back to project')} onClick={() => onPlay(false)}>
              <Action action={ROUTES.PROJECT.path(params, searchParams)}>
                <SkeepersIcon type="BACK" className={`${style.backButton}`} color="white" />
              </Action>
            </span>
          </>
        )}
        <span className={`${canUndo ? '' : style.disabled} ${style.bigIcon}`} onClick={onUndo} title={t('Undo')}>
          <SkeepersIcon type="UNDO" color="white" />
        </span>
        <span className={`${canRedo ? '' : style.disabled} ${style.bigIcon}`} onClick={onRedo} title={t('Redo')}>
          <SkeepersIcon type="REDO" color="white" />
        </span>

        {!workflows && (
          <span>
            <div className={style.selectTimeline}>
              <select className={style.select} onChange={(e) => onChangeSelect(e.target.value)} value={'placeholder'}>
                <option value="placeholder" disabled>
                  {t('Reload an older version')}
                </option>
                {timelines
                  .sort((a, b) => b.version - a.version)
                  .map((timeline) => (
                    <option
                      key={timeline.id}
                      label={`${t(`Backup`)} ${timeline.version}${
                        timeline.createdAt ? ` - ${new Date(timeline.createdAt * 1000).toLocaleDateString(getLanguageCode(), timeOptions)}` : ''
                      }${timeline.exported ? ` - ${t('exported')}` : ''}`}
                      value={timeline.id}
                    />
                  ))}
              </select>
            </div>
          </span>
        )}

        <span onClick={onSwitchMagnet} className={`${style.bigIcon}`} title={magnet ? t('Disable anchors') : t('Enable anchors')}>
          {magnet ? <SkeepersIcon type="ANCHORENABLED" color="white" /> : <SkeepersIcon type="ANCHORDISABLED" color="white" />}
        </span>
        <div className={style.text} title={t('Zoom')}>
          <input className={style.zoomInput} type="range" min="5" max="512" value={scaleX} onChange={handleZoomChange} />
        </div>
      </div>
      <div className={style.right}>
        <span onClick={handlePreviousFrame} title={t('Previous frame')}>
          <SkeepersIcon type="PREVIOUSFRAME" color="white" />
        </span>
        <span onClick={handlePlayPause} title={playing ? t('Pause') : t('Play')}>
          {playing ? <SkeepersIcon type="PAUSE" color="white" /> : <SkeepersIcon type="PLAY" color="white" />}
        </span>
        <span onClick={handleNextFrame} title={t('Next frame')}>
          <SkeepersIcon type="NEXTFRAME" color="white" />
        </span>
        <p className={style.text}>{`${formatTime(time, true)} / ${formatTime(duration, false)}`}</p>
        <span className={`${canFitToScreen ? '' : style.disabled} ${style.bigIcon}`} onClick={onWantFitToScreen} title={t('Fit to screen')}>
          <SkeepersIcon type="FIT_TO_SCREEN" color="white" />
        </span>
        {!workflows && (
          <>
            <span className={style.bigIcon} onClick={onEditingBrief} title={t('Editing brief')}>
              <SkeepersIcon type="INFO" color="white" />
            </span>
            <span className={style.bigIcon} onClick={onShortcuts} title={t('Studio shortcuts')}>
              <SkeepersIcon type="KEYBOARD" color="white" />
            </span>
            <span className={`${canExport ? '' : style.disabled} ${style.bigIcon}`} onClick={onWantExport} title={t('Export the video')}>
              <SkeepersIcon type="EXPORT" color="white" />
            </span>
          </>
        )}
        <span className={`${canSave ? '' : style.disabled} ${style.bigIcon} ${style.saveButton}`} onClick={onWantSave} title={t('Save current editing')}>
          <SkeepersIcon type="SAVE" color="white" />
        </span>
      </div>
    </div>
  );
};

export default TimelineNav;
