import style from './style.module.css';

const YoutubePlayer = ({ videoId }) => {
  return (
    <div className={style.container}>
      <iframe title={videoId} className={style.video} width="100%" height="100%" allowFullscreen src={`https://www.youtube.com/embed/${videoId}`} />
    </div>
  );
};

export default YoutubePlayer;
