import classNames from 'classnames';
import isEqual from 'lodash.isequal';
import { createContext, useContext, useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@components/Common/Button';
import Flex from '@components/Common/Flex';
import Header from '@components/Common/Header';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import Text from '@components/Common/Text';
import Form from '@components/FormElement/Form/Form';

import style from './style.module.css';

const StepContext = createContext({ currentStep: '', steps: [], onPreviousStep: null, onNextStep: null });
const useStepContext = () => useContext(StepContext);

export const MenuItem = ({ title = '', name = '', content = '' }) => {
  const { currentStep, steps } = useStepContext();

  //Don't display menuItem if the step name is not in the steps array
  if (!steps.some((step) => step.name === name)) return null;

  const isCurrent = currentStep === name;

  const menuItemIndex = steps.findIndex((step) => step.name === name);
  const currentIndex = steps.findIndex((step) => step.name === currentStep);

  const isDone = !isCurrent && currentIndex > menuItemIndex;

  return (
    <Flex flexDirection="COLUMN" alignItems="STRETCH" margin="NONE">
      <Flex alignItems="CENTER" justifyContent="SPACE-BETWEEN" margin="NONE" className={style.optionTitleAndIcon}>
        <div className={classNames(style.subContainer, { [style.comingStep]: !isDone && !isCurrent })}>
          {title}
          {isDone && <span className={style.optionFinalised}>{content}</span>}
        </div>
        {isDone && <SkeepersIcon type="CHECK" color="var(--layout-primary-color)" />}
      </Flex>
      <hr className={classNames(style.divider, { [style.currentStepDivider]: isCurrent, [style.comingStepDivider]: !isDone && !isCurrent })} />
    </Flex>
  );
};

export const Container = ({ children, steps = [], methods = null }) => {
  const [currentStep, setCurrentStep] = useState(steps[0]?.name);

  const currentStepIndex = steps.findIndex((step) => currentStep === step.name);

  const onPreviousStep = currentStepIndex <= 0 ? null : () => setCurrentStep(steps[currentStepIndex - 1].name);
  // const onNextStep = currentStepIndex === steps.length - 1 ? null : () => setCurrentStep(steps[currentStepIndex + 1].name);

  const onNextStep =
    currentStepIndex === steps.length - 1
      ? null
      : () => {
          if (methods?.handleSubmit) {
            methods.handleSubmit(() => setCurrentStep(steps[currentStepIndex + 1].name))();
          } else {
            setCurrentStep(steps[currentStepIndex + 1].name);
          }
        };

  return <StepContext.Provider value={{ currentStep, steps, onNextStep, onPreviousStep }}>{children}</StepContext.Provider>;
};

export const LeftPart = ({ title, titleDescription, steps }) => {
  return (
    <section className={style.leftPart}>
      <Flex flexDirection="COLUMN" margin="NONE" className={style.titleAndDescriptionContainer}>
        <Text as="p" bold="bold" color="black">
          {titleDescription}
        </Text>
        <Header as="h1" className={style.templateTitle}>
          {title}
        </Header>
      </Flex>
      <Flex margin="NONE" flexDirection="COLUMN" alignItems="STRETCH" className={style.optionsContainer}>
        {steps.map((step, i) => (
          <MenuItem key={i} title={step.title} name={step.name} content={step.content} />
        ))}
      </Flex>
    </section>
  );
};

export const RightPart = ({ onSubmit, methods, children, keepUnregistered, skipDirtyCheck }) => {
  return (
    <section className={style.rightPart}>
      <Form methods={methods} onSubmit={onSubmit} keepUnregistered={keepUnregistered} skipDirtyCheck={skipDirtyCheck}>
        {children}
        <StepButtonContainer methods={methods} />
      </Form>
    </section>
  );
};

export const Section = ({ name, description, children, title }) => {
  const { currentStep } = useStepContext();

  return currentStep === name ? (
    <div className={style.sectionContainer}>
      {title && (
        <Header as="h2" className={style.title}>
          {title}
        </Header>
      )}
      {description && (
        <Text as="p" textAlign="center" size="xsmall" italic className={style.description}>
          {description}
        </Text>
      )}
      {children}
    </div>
  ) : null;
};

export const StepButtonContainer = ({ methods }) => {
  const { t } = useTranslation();
  const { onNextStep, onPreviousStep, currentStep } = useStepContext();
  const [nextStepDisabled, setNextStepDisabled] = useState(false);
  const values = Object.entries(methods.getValues()).reduce((acc, [key, value]) => (value === undefined ? acc : { ...acc, [key]: value }), {});
  const valuesRef = useRef(values);

  if (!isEqual(valuesRef.current, values)) {
    valuesRef.current = values;
  }

  useEffect(() => {
    const checkErrors = async () => {
      await methods.trigger();
      if (Object.keys(methods.formState.errors).length) {
        setNextStepDisabled(true);
      } else {
        setNextStepDisabled(false);
      }
      methods.clearErrors();
    };

    checkErrors();

    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [methods, currentStep, valuesRef.current]);

  return (
    <Flex justifyContent="CENTER" className={style.responsiveButtons}>
      {onPreviousStep && <Button action={onPreviousStep} label={t('< Previous step')} theme="SECONDARY" />}
      {onNextStep ? (
        <Button action={onNextStep} label={t('Next step >')} disabled={nextStepDisabled} />
      ) : (
        <Form.SubmitButton label={t('Generate')} disabled={nextStepDisabled} />
      )}
    </Flex>
  );
};

export const StepForm = ({ children, steps, methods }) => {
  return (
    <Container steps={steps} methods={methods}>
      <Flex flexDirection="ROW" alignItems="STRETCH" margin="NONE">
        {children}
      </Flex>
    </Container>
  );
};
