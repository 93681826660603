import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import style from './style.module.css';

const MIN_LOGO_SIZE = 5;

const getLogoHeightAsPercent = (logoBoundingClientRect, backgroundBoundingClientRect, newWidthPercent) => {
  const ratioLogo = logoBoundingClientRect.width / logoBoundingClientRect.height;
  const logoWidthAsPx = (newWidthPercent / 100) * backgroundBoundingClientRect.width;
  const logoHeightAsPx = logoWidthAsPx / ratioLogo;
  return (logoHeightAsPx / backgroundBoundingClientRect.height) * 100;
};

const getNewLeft = (imageRight, backgroundBoundingClientRect, newWidthPercent) => {
  return ((imageRight - backgroundBoundingClientRect.left) / backgroundBoundingClientRect.width) * 100 - newWidthPercent;
};

const getNewTop = (logoBoundingClientRect, backgroundBoundingClientRect, newWidthPercent) => {
  const logoHeightAsPercent = getLogoHeightAsPercent(logoBoundingClientRect, backgroundBoundingClientRect, newWidthPercent);
  return ((logoBoundingClientRect.bottom - backgroundBoundingClientRect.top) / backgroundBoundingClientRect.height) * 100 - logoHeightAsPercent;
};

const WatermarksEditor = ({
  watermarks = [],
  setWatermarks = () => {},
  backgroundRelativeHeight,
  selectedWatermark,
  setSelectedWatermark = () => {},
  editable = false,
  layout = null,
}) => {
  const { t } = useTranslation();
  const refBackground = useRef();
  const refLogos = useRef({});
  const eventListenersRef = useRef([]);

  useEffect(() => {
    return () => {
      removeEvents();
    };
  }, []);

  const removeEvents = () => {
    eventListenersRef.current.forEach(({ type, fnt }) => {
      document.removeEventListener(type, fnt);
    });
    eventListenersRef.current = [];
  };

  const getResizersBottomsTop = (element, watemark) => {
    if (!element) {
      return 0;
    }

    const logoHeightAsPercent = getLogoHeightAsPercent(element.getBoundingClientRect(), refBackground.current.getBoundingClientRect(), watemark.size);
    return logoHeightAsPercent + watemark.top;
  };

  const getVerticalOverflow = (logoBoundingClientRect, backgroundBoundingClientRect, newWidthPercent, newLeft, newTop, updateTop) => {
    if (newTop < 0) {
      const maxHeightAsPx = logoBoundingClientRect.bottom - backgroundBoundingClientRect.top;
      const currentHeightAsPx = logoBoundingClientRect.bottom - backgroundBoundingClientRect.top + (-newTop * backgroundBoundingClientRect.height) / 100;

      return {
        pixel: currentHeightAsPx - maxHeightAsPx,
        multiplier: currentHeightAsPx / maxHeightAsPx,
      };
    }

    const ratioLogo = logoBoundingClientRect.width / logoBoundingClientRect.height;
    const maxHeightAsPx = updateTop
      ? logoBoundingClientRect.bottom - backgroundBoundingClientRect.top
      : backgroundBoundingClientRect.bottom - logoBoundingClientRect.top;
    const widthAsPx = newWidthPercent * backgroundBoundingClientRect.width;
    const heightAsPx = widthAsPx / ratioLogo / 100;

    return heightAsPx > maxHeightAsPx
      ? {
          pixel: heightAsPx - maxHeightAsPx,
          multiplier: heightAsPx / maxHeightAsPx,
        }
      : {
          pixel: 0,
          multiplier: 1,
        };
  };

  const onMouseMoveCorner = (e, i, logoBoundingClientRect, logoLeft, logoRight, updateTop, updateLeft) => {
    const backgroundBoundingClientRect = refBackground.current.getBoundingClientRect();

    const leftPercent = ((logoLeft - backgroundBoundingClientRect.left) / backgroundBoundingClientRect.width) * 100;
    const rightPercent = ((logoRight - backgroundBoundingClientRect.left) / backgroundBoundingClientRect.width) * 100;
    const maxWidth = updateLeft ? rightPercent : 100 - leftPercent;
    const newWidthPercent = Math.min(Math.max(((logoRight - logoLeft) / backgroundBoundingClientRect.width) * 100, MIN_LOGO_SIZE), maxWidth);
    const newLeft = updateLeft ? getNewLeft(logoRight, backgroundBoundingClientRect, newWidthPercent) : watermarks[i].left;
    const newTop = updateTop ? getNewTop(logoBoundingClientRect, backgroundBoundingClientRect, newWidthPercent) : watermarks[i].top;
    const overflow = getVerticalOverflow(logoBoundingClientRect, backgroundBoundingClientRect, newWidthPercent, newLeft, newTop, updateTop);

    setWatermarks((previousWatermarks) => [
      ...previousWatermarks.slice(0, i),
      {
        ...previousWatermarks[i],
        size: newWidthPercent / overflow.multiplier,
        ...(updateLeft
          ? {
              left: newLeft + (overflow.pixel / backgroundBoundingClientRect.height) * 100,
            }
          : {}),
        ...(updateTop
          ? {
              top: Math.max(newTop, 0),
            }
          : {}),
      },
      ...previousWatermarks.slice(i + 1),
    ]);
  };

  const onMouseDownCorner = (eMouseDown, i, updateLeft, updateTop) => {
    const imageBoudingClientRect = refLogos.current[i].getBoundingClientRect();
    const fntOnMouseMove = updateLeft
      ? (eMouseMove) => onMouseMoveCorner(eMouseMove, i, imageBoudingClientRect, eMouseMove.clientX, imageBoudingClientRect.right, updateTop, updateLeft)
      : (eMouseMove) => onMouseMoveCorner(eMouseMove, i, imageBoudingClientRect, imageBoudingClientRect.left, eMouseMove.clientX, updateTop, updateLeft);
    const fntOnMouseUp = () => removeEvents();

    document.addEventListener('mousemove', fntOnMouseMove);
    document.addEventListener('mouseup', fntOnMouseUp);
    eventListenersRef.current.push(
      {
        type: 'mousemove',
        fnt: fntOnMouseMove,
      },
      {
        type: 'mouseup',
        fnt: fntOnMouseUp,
      }
    );
  };

  const onMouseMoveLogo = (e, i, logoWidth, logoHeight, baseClientX, baseClientY) => {
    const backgroundBoundingClientRect = refBackground.current.getBoundingClientRect();

    const maxLeft = 100 - 100 * (logoWidth / backgroundBoundingClientRect.width);
    const maxTop = 100 - 100 * (logoHeight / backgroundBoundingClientRect.height);
    const newLeft = Math.min(Math.max(watermarks[i].left + (100 * (e.clientX - baseClientX)) / backgroundBoundingClientRect.width, 0), maxLeft);
    const newTop = Math.min(Math.max(watermarks[i].top + (100 * (e.clientY - baseClientY)) / backgroundBoundingClientRect.height, 0), maxTop);

    setWatermarks((previousUpdatedWatermarks) => [
      ...previousUpdatedWatermarks.slice(0, i),
      {
        ...previousUpdatedWatermarks[i],
        left: newLeft,
        top: newTop,
      },
      ...previousUpdatedWatermarks.slice(i + 1),
    ]);
  };

  const onMouseDownLogo = (e, i) => {
    const logoWidth = e.target.clientWidth;
    const logoHeight = e.target.clientHeight;

    const baseClientX = e.clientX;
    const baseClientY = e.clientY;

    setSelectedWatermark(i);

    const fntOnMouseMove = (e) => onMouseMoveLogo(e, i, logoWidth, logoHeight, baseClientX, baseClientY);
    const fntOnMouseUp = () => removeEvents();

    document.addEventListener('mousemove', fntOnMouseMove);
    document.addEventListener('mouseup', fntOnMouseUp);
    eventListenersRef.current.push(
      {
        type: 'mousemove',
        fnt: fntOnMouseMove,
      },
      {
        type: 'mouseup',
        fnt: fntOnMouseUp,
      }
    );
  };

  return (
    <div ref={refBackground} className={style.previewContainer}>
      <div style={{ marginTop: `${backgroundRelativeHeight}%` }} />
      <div
        id={'backgroundPreview'}
        className={style.background}
        onMouseDown={(e) => !e.target.classList.contains(style.logo) && !e.target.classList.contains(style.resizer) && setSelectedWatermark(null)}
      >
        {layout && (
          <div
            className={style.background}
            style={{
              backgroundImage: `url(${layout})`,
              backgroundSize: 'cover',
            }}
          />
        )}
        <div>
          {watermarks.map((watermark, i) => (
            <div key={i}>
              <img
                ref={(element) => (refLogos.current[i] = element)}
                key={i}
                className={classNames(style.logo, { [style.bordered]: selectedWatermark === i, [style.logoMovable]: editable })}
                onMouseDown={(e) => (refBackground.current && onMouseDownLogo ? onMouseDownLogo(e, i) : {})}
                src={watermark.path}
                style={{
                  top: `${watermark.top}%`,
                  left: `${watermark.left}%`,
                  width: `${watermark.size}%`,
                }}
                alt={t('Logo')}
              />
              <div className={classNames({ [style.hidden]: selectedWatermark !== i })}>
                <div
                  className={classNames(style.resizer, style.resizerTopLeft)}
                  style={{
                    top: `calc(${watermark.top}% - 2px)`,
                    left: `calc(${watermark.left}% - 2px)`,
                  }}
                  onMouseDown={(e) => onMouseDownCorner(e, i, true, true)}
                />
                <div
                  className={classNames(style.resizer, style.resizerTopRight)}
                  style={{
                    top: `calc(${watermark.top}% - 2px)`,
                    left: `calc(${watermark.left + watermark.size}% - 0.6rem + 2px)`,
                  }}
                  onMouseDown={(e) => onMouseDownCorner(e, i, false, true)}
                />
                <div
                  className={classNames(style.resizer, style.resizerBottomLeft)}
                  style={{
                    top: `calc(${getResizersBottomsTop(refLogos.current[i], watermark)}% - 0.6rem + 2px)`,
                    left: `calc(${watermark.left}% - 2px)`,
                  }}
                  onMouseDown={(e) => onMouseDownCorner(e, i, true, false)}
                />
                <div
                  className={classNames(style.resizer, style.resizerBottomRight)}
                  style={{
                    top: `calc(${getResizersBottomsTop(refLogos.current[i], watermark)}% - 0.6rem + 2px)`,
                    left: `calc(${watermark.left + watermark.size}% - 0.6rem + 2px)`,
                  }}
                  onMouseDown={(e) => onMouseDownCorner(e, i, false, false)}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WatermarksEditor;

WatermarksEditor.propTypes = {
  watermarks: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      size: PropTypes.number,
      top: PropTypes.number,
      left: PropTypes.number,
    })
  ),
  setWatermarks: PropTypes.func,
  backgroundRelativeHeight: PropTypes.number,
  selectedWatermark: PropTypes.number,
  setSelectedWatermark: PropTypes.func,
  editable: PropTypes.bool,
};
