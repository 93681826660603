import { useMutation } from '@apollo/client';

import UPLOAD_FILE from '@graphql/upload/uploadFile.graphql';

export const useUploadFile = () => {
  const [updateFunction] = useMutation(UPLOAD_FILE, {
    onError: console.error,
  });

  const uploadFile = (file) => {
    return updateFunction({ variables: { file } });
  };

  return [uploadFile];
};
