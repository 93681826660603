import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonContainer from '@components/Common/ButtonContainer';
import { Form, useForm } from '@components/FormElement/Form';

const CreateGroupForm = ({ isUpdating = false, onSubmit = () => {} }) => {
  const { t } = useTranslation();
  const methods = useForm();

  return (
    <Form methods={methods} onSubmit={onSubmit} isUpdating={isUpdating}>
      <Form.Label title={t('Name')} name="name" required>
        <Form.Input name="name" required />
      </Form.Label>
      <Form.Label title={t('User')} name="userIds" required>
        <Form.UserSelect multi name="userIds" placeholder={t('Add a group member')} />
      </Form.Label>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton label={t('Create')} />
      </ButtonContainer>
    </Form>
  );
};

export default CreateGroupForm;

CreateGroupForm.propTypes = {
  isUpdating: PropTypes.bool,
  onSubmit: PropTypes.func,
};
