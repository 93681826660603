import { useEffect, useState } from 'react';

import generateLeftMenu from './generateLeftMenu';
import { useRightMenu } from './generateRightMenu';

const useGetMenuConfig = ({ company, solutionType, pathname }, addCallback, t) => {
  const [selectedMenu, setSelectedMenu] = useState(solutionType);
  const [selectedDropdownMenu, setSelectedDropdownMenu] = useState('');
  const { generateRightMenu } = useRightMenu();

  useEffect(() => {
    setSelectedMenu(() => {
      return solutionType;
    });
  }, [company?.id, solutionType]);

  const submenuAction = (v) => setSelectedDropdownMenu(selectedDropdownMenu === v ? false : v);

  const leftMenuCallback = (v) => {
    setSelectedMenu(v);
  };

  const hasSolutions = company && company.solutions;

  const menuLeftConfig = hasSolutions ? generateLeftMenu(company.solutions, selectedMenu, leftMenuCallback, addCallback(), t) : [];

  const menuRightConfig = hasSolutions
    ? generateRightMenu(company, selectedMenu, selectedDropdownMenu, pathname, submenuAction, addCallback(selectedMenu), t)
    : [];

  const title = menuLeftConfig.find((e) => e.selected)?.name ?? '';

  return { config: { left: menuLeftConfig, right: menuRightConfig }, title };
};

export default useGetMenuConfig;
