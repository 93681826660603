import { useTranslation } from 'react-i18next';

import EventForm from '@components/Form/EventForm';
import Window from '@components/Window/Window';

const EventWindow = ({ title, event = {}, onEdit = () => {}, isUpdating = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();
  const titleToUse = title ?? t('Add an event');

  return (
    <Window title={titleToUse} open={open} onClose={onClose}>
      <EventForm event={event} onSubmit={onEdit} isUpdating={isUpdating} />
    </Window>
  );
};

export default EventWindow;
