import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { TEESTER_URL } from '@app/config';
import { getBlobFromUrl, getImageRatio, getQrcodeComponent } from '@app/utils/qrcode';
import Header from '@components/Common/Header';
import Spinner from '@components/Common/Spinner';
import SpinnerContainer from '@components/Common/SpinnerContainer';
import Colors from '@components/Form/CreateQrCodeTemplateForm/Sections/Colors';
import CustomLogo from '@components/Form/CreateQrCodeTemplateForm/Sections/CustomLogo';
import Destination from '@components/Form/CreateQrCodeTemplateForm/Sections/Destination';
import TemplateName from '@components/Form/CreateQrCodeTemplateForm/Sections/TemplateName';
import TitleAndPreview from '@components/Form/CreateQrCodeTemplateForm/Sections/TitleAndPreview';
import { useForm } from '@components/FormElement/Form';
import { LeftPart, RightPart, Section, StepForm } from '@components/FormElement/FormTemplates/StepForm';

import style from './style.module.css';

const stepsNames = {
  TEMPLATENAME: 'templatename',
  CUSTOMLOGO: 'customLogo',
  COLORS: 'colors',
  TITLEANDPREVIEW: 'titleandpreview',
  DESTINATION: 'destination',
};

const PreviewQrCode = ({ catchLine, color = '#ABABAC', logoUrl }) => {
  const { t } = useTranslation();
  const [qrcode, setQrcode] = useState();

  const setupQrcode = async () => {
    const [logoBlob, logoRatio] = await (logoUrl ? Promise.all([getBlobFromUrl(logoUrl), getImageRatio(logoUrl)]) : [null, null]);

    // Rickroll
    setQrcode(getQrcodeComponent(TEESTER_URL, { logoBlob, logoRatio }, color));
  };
  useEffect(() => {
    setupQrcode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [color, logoUrl]);

  return (
    <div className={style.previewContainer}>
      <Header as="h3">{t('Preview')}</Header>
      <div className={style.preview}>
        {qrcode ? (
          <>
            {qrcode}
            <span className={style.previewCatchLine}>{catchLine ? catchLine : t('Your catchline here!')}</span>
          </>
        ) : (
          <SpinnerContainer>
            <Spinner type="SECONDARY" />
          </SpinnerContainer>
        )}
      </div>
    </div>
  );
};

const CreateQrCodeTemplateForm = ({ onSubmit, existingTemplateNames }) => {
  const { t } = useTranslation();
  const methods = useForm();
  const config = methods.watch();

  const previewQrCode = <PreviewQrCode color={config?.color} logoUrl={config?.logo} catchLine={config?.catchLine} />;

  const steps = [
    {
      title: t('Template name'),
      name: stepsNames.TEMPLATENAME,
      component: <TemplateName existingTemplateNames={existingTemplateNames} />,
      description: t(`Give a name to identify your template.`),
    },
    {
      title: t('Custom Logo'),
      name: stepsNames.CUSTOMLOGO,
      component: <CustomLogo previewQrCode={previewQrCode} />,
      description: t(`You can add a logo to your QR code to customise it.
      Your logo will take place at the center of your QR code.`),
    },
    {
      title: t('Colors'),
      name: stepsNames.COLORS,
      component: <Colors previewQrCode={previewQrCode} />,
      description: t(`You can customise the foreground color of your QR Code.`),
    },
    {
      title: t('Titles and preview'),
      name: stepsNames.TITLEANDPREVIEW,
      component: <TitleAndPreview previewQrCode={previewQrCode} />,
      description: t(`Preview the QR code this template will generate.`),
    },
    {
      title: t('Destination'),
      name: stepsNames.DESTINATION,
      component: <Destination />,
      description: t(`Choose where the users will be sent
      once the QR code will be scanned.`),
    },
  ];

  return (
    <StepForm steps={steps} methods={methods}>
      <LeftPart title={config?.title} titleDescription={t('QR code template name')} steps={steps} />
      <RightPart methods={methods} onSubmit={onSubmit} keepUnregistered={true}>
        {steps.map((step, i) => {
          return (
            <Section name={step.name} title={step.title} description={step.description} key={i}>
              {step.component}
            </Section>
          );
        })}
      </RightPart>
    </StepForm>
  );
};

CreateQrCodeTemplateForm.propTypes = {
  onSubmit: PropTypes.func,
  existingTemplateNames: PropTypes.arrayOf(PropTypes.string),
};

export default CreateQrCodeTemplateForm;
