import { SkpAlert, SkpMultiselect } from '@skeepers/skeepers-ui-react';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isYoutubeUrl } from '@app/utils/externalVideo';
import Button from '@components/Common/Button';
import ButtonContainer from '@components/Common/ButtonContainer';
import { Grid } from '@components/Common/Grid';
import Hint from '@components/Common/Hint';
import { Form, useForm } from '@components/FormElement/Form';

import style from './style.module.css';

const ExternalVideoForm = ({
  externalVideo = {},
  onSubmit = () => {},
  onClose = () => {},
  isUpdating = false,
  distributionsSolution,
  defaultCategories,
}) => {
  const { t } = useTranslation();
  let defaultDistributions = [];

  // If we have no distributiuons set in DB and only 1 platform we preselect the platform
  if (externalVideo.url) {
    defaultDistributions = externalVideo.distributions || [];
  } else if (distributionsSolution.length === 1 && !externalVideo.distributions?.length) {
    defaultDistributions = [distributionsSolution[0].id];
  }

  const methods = useForm({ ...externalVideo, distributors: defaultDistributions });
  const [errors, setErrors] = useState({});

  const validateFields = async () => {
    const { url } = methods.getValues();
    const errors = {};
    if (!isYoutubeUrl(url)) {
      errors.url = t('The url needs to be a youtube url. Please check the input and try again');
    }

    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    onSubmit(methods.getValues());
  };

  const updateContributors = async (distributors) => {
    if (!isEqual(methods.getValues().distributors, distributors?.detail)) {
      methods.setValue('distributors', distributors?.detail, { shouldDirty: true });
    }
  };

  const textProductCategories = externalVideo.productCategories.map((pc) => `${pc.reference}: ${pc.categories.join(', ')}`).join('\n');

  return (
    <>
      <Form isUpdating={isUpdating} onSubmit={validateFields} methods={methods}>
        <Form.Label name="url" title={t('Url video')} data-cy="ev-modal-url-input">
          <Form.Input name="url" defaultValue={externalVideo?.url} onBlur={() => validateFields()} required />
          {errors.url ? <Hint message={errors.url} type="ERROR" /> : ''}
        </Form.Label>
        <Grid columns={2}>
          <Form.Label className={style.labelRef} name="productReferences" title={t('Product reference')} data-cy="ev-modal-ref-input">
            <Form.TagsSelect backgroundColor="#b8b6b6" foregroundColor="#2f2e2e" name="productReferences" defaultValue={externalVideo.productReferences} />
          </Form.Label>
          {distributionsSolution.length > 0 && (
            <Form.Label className={style.label} title={t('Diffusion canal')} name="distributors" data-cy="ev-modal-diffusion-input">
              <SkpMultiselect
                activePlaceholder={t('Channels')}
                name="distributors"
                placeholder={t('Choose channels')}
                size="medium"
                options={distributionsSolution.map((solution) => ({
                  value: solution.id,
                  text: solution.name,
                }))}
                values={methods.getValues()?.distributors || []}
                onSkpSelectChange={updateContributors}
              ></SkpMultiselect>
            </Form.Label>
          )}
        </Grid>
        {defaultCategories.length > 0 && (
          <Form.Label className={style.label} title={t('Categories')} name="categories" data-cy="ev-modal-categories-input">
            <Form.TagsSelect
              backgroundColor="#da3698"
              foregroundColor="#f4f4f5"
              name="categories"
              options={defaultCategories}
              defaultValue={externalVideo.categories}
            />
          </Form.Label>
        )}
        {externalVideo.productCategories.length > 0 && (
          <div className={style.alertContainer} data-cy="ev-modal-prodcut-categories-alert">
            <SkpAlert
              size="medium"
              type="info"
              text={t('You can find below the list of categories linked to your selected product')}
              subText={textProductCategories}
            ></SkpAlert>
          </div>
        )}
        <ButtonContainer position="CENTER" data-cy="ev-modal-buttons">
          <Button label={t('Cancel')} action={onClose} theme={Button.themes.SECONDARY} color="var(--neutral-300)" />
          <Form.SubmitButton label={t('Confirm')} />
        </ButtonContainer>
      </Form>
    </>
  );
};

export default ExternalVideoForm;

ExternalVideoForm.propTypes = {
  externalVideo: PropTypes.shape({
    url: PropTypes.string,
    productReferences: PropTypes.arrayOf(PropTypes.string),
    productCategories: PropTypes.arrayOf(PropTypes.object),
  }),
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  isUpdating: PropTypes.bool,
  distributionsSolution: PropTypes.array,
  defaultCategories: PropTypes.array,
};
