import PropTypes from 'prop-types';
import Moment from 'react-moment';

export const DateTime = ({ timestamp = 0, relative = true, hour = false, className }) => (
  <Moment
    fromNow={relative}
    interval={15000}
    filter={(d) => `${d.toLocaleUpperCase().slice(0, 1)}${d.slice(1)}`}
    unix
    className={className}
    format={relative ? null : `L ${hour ? 'LT' : ''}`}
    titleFormat={'L'}
    withTitle
  >
    {timestamp}
  </Moment>
);

DateTime.propTypes = {
  timestamp: PropTypes.number,
  relative: PropTypes.bool,
};

export default DateTime;
