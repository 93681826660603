import { useRef } from 'react';

import style from './style.module.css';

const PlayerProgress = ({ currentTime, duration, onSeek = () => {}, formatTimecode = (t) => t, limits }) => {
  const ref = useRef(null);

  const position = (() => {
    if (limits) {
      return `${Math.round((100 * (currentTime - limits.startAt)) / (limits.endAt - limits.startAt)) || 0}%`;
    } else {
      return `${Math.round((100 * currentTime) / duration) || 0}%`;
    }
  })();

  const onClick = ({ clientX }) => {
    const rect = ref.current.getBoundingClientRect();
    const x = clientX - rect.left;
    if (limits) {
      onSeek(Math.round(((limits.endAt - limits.startAt) * x) / rect.width + limits.startAt));
    } else {
      onSeek(Math.round((duration * x) / rect.width));
    }
  };

  return (
    <div className={style.container}>
      {formatTimecode(limits ? currentTime - limits.startAt : currentTime)}
      <div className={style.progress} onClick={onClick} ref={ref} role="progressbar">
        <div className={style.meter} style={{ width: position }} />
        <div className={style.knob} style={{ left: position }} />
      </div>
      {formatTimecode(limits ? limits.endAt - limits.startAt : duration)}
    </div>
  );
};

export default PlayerProgress;
