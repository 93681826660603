import renderer, { verifAlpha } from '@skeepers/custom-design-renderer/lib';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import BlockTitles from '@components/Common/BlockTitles';
import Hint from '@components/Common/Hint';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import Tag from '@components/Common/Tag';
import { calcWidth, calcHeight } from '@components/CustomDesignEditor/utils';
import Switch from '@components/FormElement/Switch';
import UploadInput from '@components/FormElement/UploadInput';

import style from './style.module.css';

const getBoxStyle = (elem, canvas) => ({
  width: Math.ceil((elem?.sizeX * canvas?.clientWidth) / canvas?.width),
  height: Math.ceil((elem?.sizeY * canvas?.clientHeight) / canvas?.height),
  top: Math.ceil((elem?.y * canvas?.clientHeight) / canvas?.height),
  left: Math.ceil((elem?.x * canvas?.clientWidth) / canvas?.width + (canvas?.parentElement?.clientWidth - canvas?.clientWidth) / 2),
});

const CustomDesignEditor = ({ onEdit = () => {}, template, overlay, ratio, resolution, videoBox, allowVideoBoxEdit }) => {
  const { t } = useTranslation();
  const [mode, setMode] = useState('TEXT');
  const [selected, setSelected] = useState(null);
  const [customFonts, setCustomFonts] = useState([]);
  const [isMoving, setIsResizing] = useState({
    resizeTop: false,
    resizeRight: false,
    resizeBottom: false,
    resizeLeft: false,
    resizeTopLeft: false,
    resizeTopRight: false,
    resizeBottomLeft: false,
    resizeBottomRight: false,
  });
  const [bufferMove, setBufferMove] = useState({ x: 0, y: 0, initialX: 0, initialY: 0 });
  const [previousPos, setPreviousPos] = useState({ x: 0, y: 0 });
  const [canvasRef, setCanvasRef] = useState(null);
  const [canvasOverlay, setCanvasOverlayRef] = useState(null);
  const [displayOverlay, setDisplayOverlay] = useState(false);
  const [validCanvas, setValidCanvas] = useState(true);
  const canvasWidth = calcWidth(ratio, resolution);
  const canvasHeight = calcHeight(resolution);

  useEffect(() => {
    if (!template?.path) {
      setMode('TEXT');
    }
    if (canvasRef) {
      renderer(canvasRef, { ...template, videoBox, texts: template?.texts?.map((text) => ({ ...text, value: text.value.trim() })) }, false);
      if (mode === 'VIDEOBOX') {
        setValidCanvas(verifAlpha(canvasRef, videoBox));
      }
    }
    if (canvasOverlay) {
      renderer(canvasOverlay, { ...overlay });
    }
  }, [mode, template, canvasRef, videoBox, canvasOverlay, overlay, canvasWidth, canvasHeight]);

  const handleTextOption = (type, textId) => {
    if (type === 'TRASH') {
      onEdit({
        ...template,
        texts: template.texts.filter((text) => text.id !== textId),
      });
    } else {
      onEdit({
        ...template,
        texts: template.texts.map((text) =>
          text.id === textId
            ? {
                ...text,
                bold: type === 'BOLD' ? !text.bold : text.bold,
                italic: type === 'ITALIC' ? !text.italic : text.italic,
                underline: type === 'UNDERLINE' ? !text.underline : text.underline,
                strikethrough: type === 'STRIKETHROUGH' ? !text.strikethrough : text.strikethrough,
                align: type === 'ALIGN_LEFT' ? (text.align === 'center' ? 'right' : text.align === 'right' ? 'left' : 'center') : text.align,
                uppercase: type === 'UPPERCASE' ? !text.uppercase : text.uppercase,
                highlight: type === 'HIGHLIGHT' ? (text.highlight ? null : { color: '#FFFFFF', padding: 30, opacity: 0.7 }) : text.highlight,
              }
            : text
        ),
      });
    }
  };

  const setInputSelectionAtEnd = (textId) => {
    const input = document.getElementById(`input-${textId}`);
    input.selectionEnd = input.value.length;
    input.selectionStart = input.value.length;
    return input;
  };

  const handleTextEdit = (textId, properties) => {
    //setInputSelectionAtEnd(textId);
    onEdit({
      ...template,
      texts: template.texts.map((text) =>
        text.id === textId
          ? {
              ...text,
              ...properties,
            }
          : { ...text, cursor: false }
      ),
    });
  };

  const unselectText = () => {
    onEdit({
      ...template,
      texts: template?.texts.map((text) => ({ ...text, cursor: false })),
    });
  };

  const handleTextFocus = (textId, e) => {
    if (e.target.localName === 'div') {
      const input = document.getElementById(`input-${textId}`);
      input.focus();
      onEdit({
        ...template,
        texts: template.texts.map((text) =>
          text.id === textId
            ? {
                ...text,
                cursor: true,
              }
            : { ...text, cursor: false }
        ),
      });
    }
  };

  const handleHighlightColorEdit = (textId, color) => {
    onEdit({
      ...template,
      texts: template.texts.map((text) =>
        text.id === textId
          ? {
              ...text,
              highlight: { ...text.highlight, color },
            }
          : text
      ),
    });
  };

  const handleHighlightPaddingEdit = (textId, toAdd) => {
    onEdit({
      ...template,
      texts: template.texts.map((text) =>
        text.id === textId
          ? {
              ...text,
              highlight: { ...text.highlight, padding: text.highlight.padding + toAdd < 0 ? 0 : text.highlight.padding + toAdd },
            }
          : text
      ),
    });
  };

  const handleHighlightOpacityEdit = (textId, opacity) => {
    onEdit({
      ...template,
      texts: template.texts.map((text) =>
        text.id === textId
          ? {
              ...text,
              highlight: { ...text.highlight, opacity: parseFloat(opacity) },
            }
          : text
      ),
    });
  };

  const handleTextSizeEdit = (textId, toAdd) => {
    onEdit({
      ...template,
      texts: template.texts.map((text) =>
        text.id === textId
          ? {
              ...text,
              size: text.size + toAdd < 0 ? 0 : text.size + toAdd,
            }
          : text
      ),
    });
  };

  const handleAddText = () => {
    const newText = {
      font: null,
      size: 60,
      align: 'center',
      color: '#000000',
      bold: false,
      italic: false,
      underline: false,
      strikethrough: false,
      uppercase: false,
      highlight: null,
      value: 'New Text',
      box: {
        x: 0,
        y: 0,
        sizeX: canvasWidth,
        sizeY: 100,
        display: false,
      },
      id: uuid(),
    };
    onEdit({
      ...template,
      texts: template?.texts ? [...template.texts, newText] : [newText],
    });
  };

  const handleUploadFont = (textId, value) => {
    setCustomFonts((acc) => [...acc, ...(!acc.some(({ fileName }) => fileName === value.fileName) ? [value] : [])]);
    onEdit({
      ...template,
      texts: template.texts.map((text) => ({
        ...text,
        font: text.id === textId ? value.url : text.font,
      })),
    });
  };

  const handleFlagChange = (textId, value) => {
    onEdit({
      ...template,
      texts: template.texts.map((text) => {
        if (text.id === textId) {
          const flag = value !== '' ? value : null;
          let box = text.box;
          if (flag === 'MIDDLE_TOP') {
            box = { ...text.box, x: 0, y: 0, sizeX: canvasWidth, sizeY: canvasHeight / 2 };
          } else if (flag === 'MIDDLE_BOTTOM') {
            box = { ...text.box, x: 0, y: canvasHeight / 2, sizeX: canvasWidth, sizeY: canvasHeight / 2 };
          }
          return { ...text, flag, box };
        }
        return text;
      }),
    });
  };

  const handleTextFont = (textId, value) => {
    onEdit({
      ...template,
      texts: template.texts.map((text) => ({
        ...text,
        font: text.id === textId ? (value !== '' ? value : null) : text.font,
      })),
    });
  };

  const extractPositionDelta = (event) => {
    const x = Math.round(event.screenX);
    const y = Math.round(event.screenY);
    const delta = {
      x: Math.round(((x - previousPos.x) * canvasWidth) / canvasRef?.clientWidth),
      y: Math.round(((y - previousPos.y) * canvasHeight) / canvasRef?.clientHeight),
    };
    setPreviousPos({ x, y });
    return delta;
  };

  const resizeTopLeft = (x, y) => {
    const newX = x + bufferMove.x;
    const newY = y + bufferMove.y;
    setBufferMove((buffer) => ({ ...buffer, y: newY, x: newX }));

    let valueX = newX + selected.x;
    let valueY = newY + selected.y;
    if (valueY < 0) valueY = 0;
    if (valueY > selected.y + selected.sizeY - 40) valueY = selected.y + selected.sizeY - 40;
    if (valueX < 0) valueX = 0;
    if (selected.sizeX - valueX + selected.x < 40) valueX = selected.sizeX + selected.x - 40;

    if (mode === 'TEXT') {
      onEdit({
        ...template,
        texts: template.texts.map((text) => ({
          ...text,
          box:
            selected.id === text.id
              ? { ...text.box, x: valueX, y: valueY, sizeX: selected.sizeX - valueX + selected.x, sizeY: selected.sizeY - valueY + selected.y }
              : text.box,
        })),
      });
    } else if (mode === 'VIDEOBOX') {
      onEdit({
        ...template,
        videoBox: { ...videoBox, x: valueX, y: valueY, sizeX: selected.sizeX - valueX + selected.x, sizeY: selected.sizeY - valueY + selected.y },
      });
    }
  };

  const resizeTopRight = (x, y) => {
    const newX = x + bufferMove.x;
    const newY = y + bufferMove.y;
    setBufferMove((buffer) => ({ ...buffer, y: newY, x: newX }));

    let valueX = newX + selected.sizeX;
    let valueY = newY + selected.y;
    if (valueY < 0) valueY = 0;
    if (valueY > selected.y + selected.sizeY - 40) valueY = selected.y + selected.sizeY - 40;
    if (selected.x + valueX > canvasWidth) valueX = canvasWidth - selected.x;
    if (valueX < 40) valueX = 40;

    if (mode === 'TEXT') {
      onEdit({
        ...template,
        texts: template.texts.length
          ? template.texts.map((text) => ({
              ...text,
              box: selected.id === text.id ? { ...text.box, y: valueY, sizeY: selected.sizeY - valueY + selected.y, sizeX: valueX } : text.box,
            }))
          : [],
      });
    } else if (mode === 'VIDEOBOX') {
      onEdit({
        ...template,
        videoBox: { ...videoBox, y: valueY, sizeY: selected.sizeY - valueY + selected.y, sizeX: valueX },
      });
    }
  };

  const resizeBottomLeft = (x, y) => {
    const newX = x + bufferMove.x;
    const newY = y + bufferMove.y;
    setBufferMove((buffer) => ({ ...buffer, y: newY, x: newX }));

    let valueY = newY + selected.sizeY;
    let valueX = newX + selected.x;
    if (valueY + selected.y < selected.y + 40) valueY = 40;
    if (valueY + selected.y > canvasHeight) valueY = canvasHeight - selected.y;
    if (valueX < 0) valueX = 0;
    if (selected.sizeX - valueX + selected.x < 40) valueX = selected.sizeX + selected.x - 40;

    if (mode === 'TEXT') {
      onEdit({
        ...template,
        texts: template.texts.map((text) => ({
          ...text,
          box: selected.id === text.id ? { ...text.box, sizeY: valueY, x: valueX, sizeX: selected.sizeX - valueX + selected.x } : text.box,
        })),
      });
    } else if (mode === 'VIDEOBOX') {
      onEdit({
        ...template,
        videoBox: { ...videoBox, sizeY: valueY, x: valueX, sizeX: selected.sizeX - valueX + selected.x },
      });
    }
  };

  const resizeBottomRight = (x, y) => {
    const newX = x + bufferMove.x;
    const newY = y + bufferMove.y;
    setBufferMove((buffer) => ({ ...buffer, y: newY, x: newX }));

    let valueY = newY + selected.sizeY;
    let valueX = newX + selected.sizeX;
    if (valueY + selected.y < selected.y + 40) valueY = 40;
    if (valueY + selected.y > canvasHeight) valueY = canvasHeight - selected.y;
    if (selected.x + valueX > canvasWidth) valueX = canvasWidth - selected.x;
    if (valueX < 40) valueX = 40;

    if (mode === 'TEXT') {
      onEdit({
        ...template,
        texts: template.texts.map((text) => ({
          ...text,
          box: selected.id === text.id ? { ...text.box, sizeY: valueY, sizeX: valueX } : text.box,
        })),
      });
    } else if (mode === 'VIDEOBOX') {
      onEdit({
        ...template,
        videoBox: { ...videoBox, sizeY: valueY, sizeX: valueX },
      });
    }
  };

  const resizeTop = (y) => {
    const newY = y + bufferMove.y;
    setBufferMove((buffer) => ({ ...buffer, y: newY }));

    let value = newY + selected.y;
    if (value < 0) value = 0;
    if (value > selected.y + selected.sizeY - 40) value = selected.y + selected.sizeY - 40;

    if (mode === 'TEXT') {
      onEdit({
        ...template,
        texts: template.texts.map((text) => ({
          ...text,
          box: selected.id === text.id ? { ...text.box, y: value, sizeY: selected.sizeY - value + selected.y } : text.box,
        })),
      });
    } else if (mode === 'VIDEOBOX') {
      onEdit({
        ...template,
        videoBox: { ...videoBox, y: value, sizeY: selected.sizeY - value + selected.y },
      });
    }
  };

  const resizeBottom = (y) => {
    const newY = y + bufferMove.y;
    setBufferMove((buffer) => ({ ...buffer, y: newY }));

    let value = newY + selected.sizeY;
    if (value + selected.y < selected.y + 40) value = 40;
    if (value + selected.y > canvasHeight) value = canvasHeight - selected.y;

    if (mode === 'TEXT') {
      onEdit({
        ...template,
        texts: template.texts.map((text) => ({
          ...text,
          box: selected.id === text.id ? { ...text.box, sizeY: value } : text.box,
        })),
      });
    } else if (mode === 'VIDEOBOX') {
      onEdit({
        ...template,
        videoBox: { ...videoBox, sizeY: value },
      });
    }
  };

  const resizeRight = (x) => {
    const newX = x + bufferMove.x;
    setBufferMove((buffer) => ({ ...buffer, x: newX }));

    let value = newX + selected.sizeX;
    if (selected.x + value > canvasWidth) value = canvasWidth - selected.x;
    if (value < 40) value = 40;

    if (mode === 'TEXT') {
      onEdit({
        ...template,
        texts: template.texts.map((text) => ({
          ...text,
          box: selected.id === text.id ? { ...text.box, sizeX: value } : text.box,
        })),
      });
    } else if (mode === 'VIDEOBOX') {
      onEdit({
        ...template,
        videoBox: { ...videoBox, sizeX: value },
      });
    }
  };

  const resizeLeft = (x) => {
    const newX = x + bufferMove.x;
    setBufferMove((buffer) => ({ ...buffer, x: newX }));

    let value = newX + selected.x;
    if (value < 0) value = 0;
    if (selected.sizeX - value + selected.x < 40) value = selected.sizeX + selected.x - 40;

    if (mode === 'TEXT') {
      onEdit({
        ...template,
        texts: template.texts.map((text) => ({
          ...text,
          box: selected.id === text.id ? { ...text.box, x: value, sizeX: selected.sizeX - value + selected.x } : text.box,
        })),
      });
    } else if (mode === 'VIDEOBOX') {
      onEdit({
        ...template,
        videoBox: { ...videoBox, x: value, sizeX: selected.sizeX - value + selected.x },
      });
    }
  };

  const move = (x, y) => {
    const newX = x + bufferMove.x;
    const newY = y + bufferMove.y;
    setBufferMove((buffer) => ({ ...buffer, x: newX, y: newY }));

    // Finds the largest applicable movement
    let valueX = newX + selected.x;
    let valueY = newY + selected.y;
    if (valueX < 0) valueX = 0;
    if (valueY < 0) valueY = 0;
    if (valueX + selected.sizeX > canvasWidth) valueX = canvasWidth - selected.sizeX;
    if (valueY + selected.sizeY > canvasHeight) valueY = canvasHeight - selected.sizeY;

    // Applies the move on the selected element
    if (mode === 'TEXT') {
      onEdit({
        ...template,
        texts: template.texts.map((text) => ({
          ...text,
          box: selected.id === text.id ? { ...text.box, x: valueX, y: valueY } : text.box,
        })),
      });
    } else if (mode === 'VIDEOBOX') {
      onEdit({
        ...template,
        videoBox: { ...videoBox, x: valueX, y: valueY },
      });
    }
  };

  const handleMove = (selectedId, event, type) => {
    if (event.target.localName === 'div') {
      event.preventDefault();

      if (type === 'CLICK') {
        if (selectedId === 'VIDEOBOX') {
          setSelected({ id: selectedId, x: videoBox.x, y: videoBox.y, sizeX: videoBox.sizeX, sizeY: videoBox.sizeY });
        } else {
          const element = template.texts.find((text) => text.id === selectedId);
          setSelected({ id: selectedId, x: element.box.x, y: element.box.y, sizeX: element.box.sizeX, sizeY: element.box.sizeY });
        }

        event.target.setPointerCapture(event.pointerId);

        // We store the initial coordinates to be able to calculate the changes later on
        extractPositionDelta(event);
        setBufferMove({
          x: 0,
          y: 0,
          initialX: event.clientX - event.target.getBoundingClientRect().left,
          initialY: event.clientY - event.target.getBoundingClientRect().top,
        });
      }

      if (type === 'STOP_CLICK') {
        setIsResizing({
          resizeTop: false,
          resizeRight: false,
          resizeBottom: false,
          resizeLeft: false,
          resizeTopLeft: false,
          resizeTopRight: false,
          resizeBottomLeft: false,
          resizeBottomRight: false,
        });
        setSelected(null);
      }

      if (type === 'MOVE') {
        const { x, y } = extractPositionDelta(event);

        if (selected) {
          if (!Object.keys(isMoving).some((key) => isMoving[key])) {
            move(x, y);
          }

          if (isMoving.resizeTop) {
            resizeTop(y);
          }
          if (isMoving.resizeRight) {
            resizeRight(x);
          }
          if (isMoving.resizeBottom) {
            resizeBottom(y);
          }
          if (isMoving.resizeLeft) {
            resizeLeft(x);
          }

          if (isMoving.resizeTopLeft) {
            resizeTopLeft(x, y);
          }
          if (isMoving.resizeTopRight) {
            resizeTopRight(x, y);
          }
          if (isMoving.resizeBottomLeft) {
            resizeBottomLeft(x, y);
          }
          if (isMoving.resizeBottomRight) {
            resizeBottomRight(x, y);
          }
        }
      }

      const resize = {
        RESIZE_TOP: () => setIsResizing((state) => ({ ...state, resizeTop: true })),
        RESIZE_RIGHT: () => setIsResizing((state) => ({ ...state, resizeRight: true })),
        RESIZE_BOTTOM: () => setIsResizing((state) => ({ ...state, resizeBottom: true })),
        RESIZE_LEFT: () => setIsResizing((state) => ({ ...state, resizeLeft: true })),
        RESIZE_TOP_LEFT: () => setIsResizing((state) => ({ ...state, resizeTopLeft: true })),
        RESIZE_TOP_RIGHT: () => setIsResizing((state) => ({ ...state, resizeTopRight: true })),
        RESIZE_BOTTOM_LEFT: () => setIsResizing((state) => ({ ...state, resizeBottomLeft: true })),
        RESIZE_BOTTOM_RIGHT: () => setIsResizing((state) => ({ ...state, resizeBottomRight: true })),
      }[type];

      if (resize) {
        resize();
      }
    }
  };

  const changeMode = () => {
    const newMode = mode === 'VIDEOBOX' ? 'TEXT' : 'VIDEOBOX';
    setMode(newMode);
  };

  const copyValue = (value) => {
    const input = document.createElement('input');
    input.value = value;
    input.style = 'position: absolute; left: -1000px; top: -1000px';
    document.body.appendChild(input);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
  };

  return (
    <>
      <div className={style.action}>
        <button className={style.addTextButton} onClick={handleAddText}>
          {t('Add text')}
        </button>
        <div className={style.switchMode}>
          {allowVideoBoxEdit && template?.path && (
            <>
              <div className={style.switchText}>{t('Texts edition')}</div>
              <Switch className={style.switchButton} value={mode === 'VIDEOBOX'} onEdit={() => changeMode()}></Switch>
              <div className={style.switchText}>{t('Video box edition')}</div>
            </>
          )}
          {overlay && (
            <>
              <div className={style.switchText}>{t('Hide layout')}</div>
              <Switch className={style.switchButton} value={displayOverlay} onEdit={() => setDisplayOverlay(!displayOverlay)}></Switch>
              <div className={style.switchText}>{t('Display layout')}</div>
            </>
          )}
        </div>
      </div>
      <div className={style.wrapper}>
        {canvasRef && (
          <div onMouseDown={() => unselectText()} className={style.editor}>
            {mode === 'VIDEOBOX' && (
              <div
                style={getBoxStyle(videoBox, canvasRef)}
                className={style.videoBox}
                onPointerDown={(e) => handleMove('VIDEOBOX', e, 'CLICK')}
                onPointerMove={(e) => handleMove('VIDEOBOX', e, 'MOVE')}
                onPointerUp={(e) => handleMove('VIDEOBOX', e, 'STOP_CLICK')}
              >
                <div className={style.resizeTop} onPointerDown={(e) => handleMove('VIDEOBOX', e, 'RESIZE_TOP')} />
                <div className={style.resizeRight} onPointerDown={(e) => handleMove('VIDEOBOX', e, 'RESIZE_RIGHT')} />
                <div className={style.resizeBottom} onPointerDown={(e) => handleMove('VIDEOBOX', e, 'RESIZE_BOTTOM')} />
                <div className={style.resizeLeft} onPointerDown={(e) => handleMove('VIDEOBOX', e, 'RESIZE_LEFT')} />
                <div className={style.resizeTopLeft} onPointerDown={(e) => handleMove('VIDEOBOX', e, 'RESIZE_TOP_LEFT')} />
                <div className={style.resizeTopRight} onPointerDown={(e) => handleMove('VIDEOBOX', e, 'RESIZE_TOP_RIGHT')} />
                <div className={style.resizeBottomLeft} onPointerDown={(e) => handleMove('VIDEOBOX', e, 'RESIZE_BOTTOM_LEFT')} />
                <div className={style.resizeBottomRight} onPointerDown={(e) => handleMove('VIDEOBOX', e, 'RESIZE_BOTTOM_RIGHT')} />
              </div>
            )}
            {template?.texts?.map((text) => (
              <div key={text.id}>
                <input
                  autoComplete="off"
                  id={`input-${text.id}`}
                  type="text"
                  className={style.inputTexts}
                  value={text.value}
                  onKeyDown={() => setInputSelectionAtEnd(text.id)}
                  onChange={(e) => handleTextEdit(text.id, { value: e.target.value, cursor: true })}
                />
                {mode === 'TEXT' && (
                  <>
                    <div
                      style={getBoxStyle(text?.box, canvasRef)}
                      className={classNames(text.flag ? [style.textBox, style.disabled] : style.textBox)}
                      onPointerDown={(e) => handleMove(text.id, e, 'CLICK')}
                      onPointerMove={(e) => (text.flag ? null : handleMove(text.id, e, 'MOVE'))}
                      onPointerUp={(e) => handleMove(text.id, e, 'STOP_CLICK')}
                      onClick={(e) => handleTextFocus(text.id, e)}
                    >
                      {!text.flag && (
                        <>
                          <div className={style.resizeTop} onPointerDown={(e) => handleMove(text.id, e, 'RESIZE_TOP')} />
                          <div className={style.resizeRight} onPointerDown={(e) => handleMove(text.id, e, 'RESIZE_RIGHT')} />
                          <div className={style.resizeBottom} onPointerDown={(e) => handleMove(text.id, e, 'RESIZE_BOTTOM')} />
                          <div className={style.resizeLeft} onPointerDown={(e) => handleMove(text.id, e, 'RESIZE_LEFT')} />
                          <div className={style.resizeTopLeft} onPointerDown={(e) => handleMove(text.id, e, 'RESIZE_TOP_LEFT')} />
                          <div className={style.resizeTopRight} onPointerDown={(e) => handleMove(text.id, e, 'RESIZE_TOP_RIGHT')} />
                          <div className={style.resizeBottomLeft} onPointerDown={(e) => handleMove(text.id, e, 'RESIZE_BOTTOM_LEFT')} />
                          <div className={style.resizeBottomRight} onPointerDown={(e) => handleMove(text.id, e, 'RESIZE_BOTTOM_RIGHT')} />
                        </>
                      )}
                      <div className={style.textOptions}>
                        <input
                          autoComplete="off"
                          className={style.textColorPicker}
                          maxLength={7}
                          id={`color-${text.id}`}
                          value={text.color}
                          onChange={(e) => handleTextEdit(text.id, { color: e.target.value })}
                        ></input>
                        {['TRASH', 'BOLD', 'ITALIC' /* , 'UNDERLINE' */, 'ALIGN_LEFT', 'UPPERCASE', 'HIGHLIGHT' /* , 'STRIKETHROUGH' */].map((action) => (
                          <button
                            className={classNames([...(text[action.toLocaleLowerCase()] ? [style.buttonActive] : [style.buttonInactive]), style.buttonAction])}
                            key={action}
                            onClick={() => handleTextOption(action, text.id)}
                          >
                            <SkeepersIcon type={action} />
                          </button>
                        ))}
                        {text.highlight && (
                          <div className={style.sortButtonContainer}>
                            <input
                              autoComplete="off"
                              className={style.textColorPicker}
                              maxLength={7}
                              id={`highlight-${text.id}`}
                              value={text.highlight.color}
                              onChange={(e) => handleHighlightColorEdit(text.id, e.target.value)}
                            ></input>
                            <div>
                              <button className={style.sortButton} onClick={() => handleHighlightPaddingEdit(text.id, 2)}>
                                <SkeepersIcon type="ADD" />
                              </button>
                              <button className={style.sortButton} onClick={() => handleHighlightPaddingEdit(text.id, -2)}>
                                <SkeepersIcon type="MINUS" />
                              </button>
                            </div>
                            <input
                              autoComplete="off"
                              type="number"
                              className={style.textHighlightOpacity}
                              min={0.1}
                              max={1}
                              step={0.01}
                              value={text.highlight.opacity}
                              onChange={(e) => handleHighlightOpacityEdit(text.id, e.target.value)}
                            />
                          </div>
                        )}
                        <select
                          value={text.font || ''}
                          className={style.select}
                          name={`text-${text.id}-font`}
                          onChange={(e) => handleTextFont(text.id, e.target.value)}
                        >
                          <option value={''}>Open Sans</option>
                          {!customFonts.length && text.font && <option value={text.font}>{t('Custom font')}</option>}
                          {customFonts.map((font) => (
                            <option key={font.url} value={font.url}>
                              {font.fileName}
                            </option>
                          ))}
                        </select>
                        <button className={style.uploadFont}>
                          <UploadInput onEdit={(file) => (file ? handleUploadFont(text.id, file) : null)} multiple={false} dropzone={false}>
                            <SkeepersIcon type="FONT" />
                          </UploadInput>
                        </button>
                        <div>
                          <button className={style.sortButton} onClick={() => handleTextSizeEdit(text.id, 2)}>
                            <SkeepersIcon type="ADD" />
                          </button>
                          <button className={style.sortButton} onClick={() => handleTextSizeEdit(text.id, -2)}>
                            <SkeepersIcon type="MINUS" />
                          </button>
                        </div>
                        <select
                          value={text.flag || ''}
                          className={style.select}
                          name={`text-${text.id}-flag`}
                          onChange={(e) => handleFlagChange(text.id, e.target.value)}
                        >
                          <option value={''}>{t('Free movement')}</option>
                          <option value={'MIDDLE_TOP'}>{t('Middle-Top')}</option>
                          <option value={'MIDDLE_BOTTOM'}>{t('Middle-Bottom')}</option>
                        </select>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ))}
          </div>
        )}
        <canvas
          ref={(ref) => setCanvasRef(ref)}
          className={classNames([style.canvas, ...(!overlay || !displayOverlay ? [style.backgroundAlpha] : [])])}
          width={`${canvasWidth}px`}
          height={`${canvasHeight}px`}
          onMouseDown={() => unselectText()}
        />
        {overlay && displayOverlay && (
          <canvas ref={(ref) => setCanvasOverlayRef(ref)} className={style.canvasOverlay} width={`${canvasWidth}px`} height={`${canvasHeight}px`} />
        )}
      </div>
      {!validCanvas && <Hint className={style.center} type="ERROR" message={t('Transparent area detected')} />}
      <BlockTitles title={t('Available keys')} className={style.tagTitle} />
      <div className={style.tags}>
        {[
          '{{{THUMBNAIL_TEXT_1}}}',
          '{{{THUMBNAIL_TEXT_2}}}',
          '{{{USER_DISPLAY_NAME}}}',
          '{{{USER_FIRST_NAME}}}',
          '{{{USER_LAST_NAME}}}',
          '{{{PRODUCT_NAME}}}',
          '{{{TITLE}}}',
        ].map((key) => (
          <Tag className={style.tagElement} key={key} value={key} color={'var(--tag-green)'} action={() => copyValue(key)}></Tag>
        ))}
      </div>
      <Hint message={t('Click on the tag to copy the key.')} />
    </>
  );
};

export default CustomDesignEditor;

CustomDesignEditor.propTypes = {
  onEdit: PropTypes.func,
  template: PropTypes.shape({
    path: PropTypes.string,
    ratio: PropTypes.string,
    texts: PropTypes.arrayOf(
      PropTypes.shape({
        font: PropTypes.string,
        size: PropTypes.number,
        align: PropTypes.oneOf(['left', 'center', 'right', 'end', 'start']),
        color: PropTypes.string,
        italic: PropTypes.bool,
        underline: PropTypes.bool,
        strikethrough: PropTypes.bool,
        value: PropTypes.string,
        box: PropTypes.shape({
          x: PropTypes.number,
          y: PropTypes.number,
          sizeX: PropTypes.number,
          sizeY: PropTypes.number,
        }),
      })
    ),
    videoBox: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      sizeX: PropTypes.number,
      sizeY: PropTypes.number,
    }),
  }),
};
