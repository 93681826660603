import PropTypes from 'prop-types';

import style from './style.module.css';

const classNames = {
  RIGHT: style.right,
  LEFT: style.left,
  CENTER: style.center,
};

const ButtonContainer = ({ position = 'RIGHT', className = '', children }) => {
  const positionClassName = classNames[position] || '';
  return <div className={`${style.flex} ${positionClassName} ${className}`}>{children}</div>;
};

ButtonContainer.propTypes = {
  position: PropTypes.oneOf(['LEFT', 'RIGHT', 'CENTER']),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default ButtonContainer;
