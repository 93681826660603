import { useTranslation } from 'react-i18next';

import CreatePresetForm from '@components/Form/CreatePresetForm';
import Window from '@components/Window/Window';

const CreatePresetWindow = ({ template, onSubmit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Create a preset')} open={open} onClose={onClose}>
      <CreatePresetForm template={template} onSubmit={onSubmit} isUpdating={isLoading} />
    </Window>
  );
};

export default CreatePresetWindow;
