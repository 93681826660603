import PropTypes from 'prop-types';
import 'react-loading-skeleton/dist/skeleton.css';

import style from './style.module.css';

const AppContainer = ({ children, className = '' }) => <div className={`${style.container} ${className}`}>{children}</div>;

AppContainer.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default AppContainer;
