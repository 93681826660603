import { useTranslation } from 'react-i18next';

import { signIn } from '@app/auth';
import Button from '@components/Common/Button';
import SkeepersIcon from '@components/Common/SkeepersIcon';

import style from './style.module.css';

const WelcomePage = () => {
  const { t } = useTranslation();

  return (
    <div className={style.contentContainer}>
      <div className={style.leftColumn}>
        <SkeepersIcon type="SKEEPERS_LOGO" className={style.skeepersLogo} />
        <div className={style.leftSubColumn}>
          <h1>{t('Welcome to Consumer Videos by Skeepers: Back Office')}</h1>
          <p className={style.text}>
            {t(
              'Skeepers reinvents marketing through authentic and creative videos. Validate applications for your future ambassadors, moderate and publish videos created by your customers, monitor your performance with key indicators.'
            )}
          </p>
          <div className={style.buttonContainer}>
            <Button label={t('Log in')} action={signIn} type="INTERNAL" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomePage;
