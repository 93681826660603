import sortBy from 'lodash/sortBy';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { ClipboardCopy } from '@components/Common/ClipboardCopy';
import ImageRatio from '@components/Common/ImageRatio';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import Selector from '@components/FormElement/Selector';
import SimplePlayer from '@components/Project/SimplePlayer';
import Window from '@components/Window/Window';

import audioImg from './assets/audio.jpg';
import style from './style.module.css';

const sortOnType = (medias) =>
  sortBy(medias, ({ type }) => {
    if (type === 'VIDEO') return 0;
    if (type === 'IMAGE') return 1;
    if (type === 'AUDIO') return 2;
    return 3;
  });

const customRenderThumb = (children) =>
  children.map((item) => (
    <ImageRatio src={item.props.thumbnail} className={style.thumb} key={item.props.id}>
      {item.props.type === 'VIDEO' && <SkeepersIcon type="PLAY" className={style.playIcon} color="white" />}
    </ImageRatio>
  ));

const customRenderItem = (item, props) => <item.type {...item.props} {...props} />;

const Player = (props) => <SimplePlayer {...props} playing={props.isSelected} />;

export const MediasWindow = ({ medias = [], currentId = null, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();
  const selectedItemIndex = medias.reduce((acc, { id }, index) => (currentId === id ? index : acc), 0);

  const slides =
    sortOnType(medias)?.map((m) => ({
      id: m.id,
      videoUrl: m?.videoUrl,
      videoUrlPreview: m?.previewUrl,
      downloadUrl: m?.downloadUrl,
      thumbnail: m.type === 'AUDIO' ? audioImg : m?.defaultFrame,
      filename: m?.filename,
      type: m.type,
    })) ?? [];

  const [isPreview, setPreview] = useState(true);

  const PlayerView = (props) => (
    <div {...props} key={props.id}>
      {props.videoUrlPreview && (
        <Selector
          value={isPreview}
          onEdit={() => setPreview(!isPreview)}
          options={[
            { label: t('Preview'), value: true },
            { label: t('HD'), value: false },
          ]}
        />
      )}
      <Player
        {...props}
        key={props.id}
        videoUrl={isPreview && props.videoUrlPreview ? props.videoUrlPreview : props.videoUrl}
        downloadUrl={props.downloadUrl}
      />
      <ClipboardCopy text={props.videoUrl} mini={false} />
    </div>
  );

  return (
    <Window open={open} onClose={onClose} title={t('Medias')}>
      {slides.length > 1 && (
        <Carousel
          renderItem={customRenderItem}
          renderThumbs={customRenderThumb}
          showThumbs={slides.length > 1}
          showStatus={false}
          showIndicators={false}
          showArrows={false}
          selectedItem={selectedItemIndex}
        >
          {slides.map((s) => (
            <PlayerView {...s} />
          ))}
        </Carousel>
      )}
      {slides.length === 1 && <PlayerView className={style.player} {...slides[0]} />}
    </Window>
  );
};

export default MediasWindow;

MediasWindow.propTypes = {
  /**
   * Focus the carousel on the media with the same id.
   *
   * If not provided or no related media focus on the first media.
   */
  currentId: PropTypes.string.isRequired,
  medias: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      filename: PropTypes.string,
      type: PropTypes.oneOf(['AUDIO', 'VIDEO', 'IMAGE']),
      defaultFrame: PropTypes.string,
      videoUrl: PropTypes.string,
      previewUrl: PropTypes.string,
      downloadUrl: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    })
  ).isRequired,
};
