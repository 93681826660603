import { useQuery, useMutation } from '@apollo/client';

import DELETE_YOUTUBE_VIDEO_TEMPLATE from '@graphql/youtubeVideoTemplates/deleteYoutubeVideoTemplate.graphql';
import GET_YOUTUBE_VIDEO_TEMPLATES from '@graphql/youtubeVideoTemplates/getYoutubeVideoTemplates.graphql';
import UPSERT_YOUTUBE_VIDEO_TEMPLATE from '@graphql/youtubeVideoTemplates/upsertYoutubeVideoTemplate.graphql';

export const useGetYoutubeVideoTemplates = (companyId, { skip = false } = {}) => {
  const { loading, error, data } = useQuery(GET_YOUTUBE_VIDEO_TEMPLATES, {
    fetchPolicy: 'cache-and-network',
    variables: { companyId },
    skip: !companyId || skip,
  });

  return {
    companyName: data?.getCompany?.name,
    templates: data?.getCompany?.youtubeTemplates ?? [],
    error,
    loading,
  };
};

export const useUpsertYoutubeVideoTemplate = (companyId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [mutate, { data, error, loading }] = useMutation(UPSERT_YOUTUBE_VIDEO_TEMPLATE, {
    onCompleted,
    onError,
    refetchQueries: [{ query: GET_YOUTUBE_VIDEO_TEMPLATES, variables: { companyId } }],
    awaitRefetchQueries: true,
  });

  const upsertYoutubeVideoTemplate = (id, formData) => {
    return mutate({ variables: { id, companyId, template: formData } });
  };

  return [upsertYoutubeVideoTemplate, { data: data?.upsertYoutubeVideoTemplate, isUpdating: loading, error }];
};

export const useDeleteYoutubeVideoTemplate = (companyId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [mutate, { data, error, loading }] = useMutation(DELETE_YOUTUBE_VIDEO_TEMPLATE, {
    onCompleted,
    onError,
    refetchQueries: [{ query: GET_YOUTUBE_VIDEO_TEMPLATES, variables: { companyId } }],
    awaitRefetchQueries: true,
  });
  const deleteYoutubeVideoTemplate = (tamplateId) => {
    return mutate({ variables: { id: tamplateId } });
  };

  return [deleteYoutubeVideoTemplate, { data: data?.deleteYoutubeVideoTemplate, isUpdating: loading, error }];
};
