import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import InjectionForm from '@components/Form/InjectionForm';
import Window from '@components/Window/Window';

const InjectionWindow = ({ title, injection, onSubmit = () => {}, onDelete, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();
  const titleToUse = title ?? t('Create injection');
  const [isLoading, setIsLoading] = useState(false);

  const onBeforeSubmit = (formData) => {
    setIsLoading(true);
    onSubmit(formData);
  };

  return (
    <Window title={titleToUse} open={open} onClose={onClose}>
      <InjectionForm injection={injection} onSubmit={onBeforeSubmit} onDelete={onDelete} isLoading={isLoading} />
    </Window>
  );
};

export default InjectionWindow;
