const getRatio = (ratio) => {
  if (typeof ratio !== 'string' || !RegExp(/^([0-9]+):([0-9]+)$/gm).test(ratio)) {
    return false;
  }
  if (parseInt(ratio.split(':')[0], 10) > 0 && parseInt(ratio.split(':')[1], 10) > 0) {
    return parseInt(ratio.split(':')[0], 10) / parseInt(ratio.split(':')[1], 10);
  }
  return false;
};

const getResolution = (resolution) => {
  if (resolution.startsWith('R')) {
    return parseInt(resolution.substring(1));
  } else {
    return 1080;
  }
};

export const calcWidth = (ratio, resolution) => {
  const _ratio = getRatio(ratio);
  const _resolution = getResolution(resolution);

  if (!_ratio) {
    return 1080;
  } else {
    const width = Math.round(_resolution * _ratio);
    return width % 2 === 1 ? width - 1 : width;
  }
};

export const calcHeight = (resolution) => {
  if (resolution === 'R2160') return 2160;
  if (resolution === 'R1080') return 1080;
  if (resolution === 'R720') return 720;
  /* otherwise */ return 1080;
};
