import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../Common/Button';
import ButtonContainer from '../../Common/ButtonContainer';
import Window from '../Window';

import style from './style.module.css';

const ConfirmationWindow = ({
  action = () => {},
  hideIrreversibleMessage = false,
  confirmLabel,
  cancelLabel,
  open = false,
  onClose = () => {},
  title = '',
  message = '',
  closing = 'AFTER',
}) => {
  const { t } = useTranslation();
  const confirmLabelToUse = confirmLabel ?? t('Confirm');
  const cancelLabelToUse = cancelLabel ?? t('Cancel');
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Window open={open} onClose={onClose} title={title || t('Confirmation step')}>
      <div className={style.messageContainer}>
        <div>
          <p className={style.message}>{message}</p>
          {!hideIrreversibleMessage && <p className={style.message}>{t('This action is irreversible')}</p>}
        </div>
      </div>
      <ButtonContainer position="CENTER">
        <Button label={cancelLabelToUse} loading={isLoading} disabled={isLoading} theme={Button.themes.SECONDARY} action={onClose} className={style.marginButton} />
        <Button
          label={confirmLabelToUse}
          loading={isLoading}
          disabled={isLoading}
          action={async () => {
            try {
              setIsLoading(true);
              if (closing === 'BEFORE') {
                onClose();
              }
              await action();
              if (closing !== 'BEFORE') {
                onClose();
              }
            } catch (err) {
              //
            }
          }}
        />
      </ButtonContainer>
    </Window>
  );
};

export default ConfirmationWindow;

ConfirmationWindow.propTypes = {
  action: PropTypes.func,
  hideIrreversibleMessage: PropTypes.bool,
  confirmLabel: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  message: PropTypes.string,
  title: PropTypes.string,
  closing: PropTypes.bool,
};
