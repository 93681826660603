export const loadStonlyWidget = async () => {
  window.STONLY_WID = '7571aaf5-7086-11ed-871a-0a52ff1ec764';
  const tag = 'script';
  const scriptPath = 'https://stonly.com/js/widget/v2/';
  const scriptTag = document.createElement(tag);
  const w = (window.StonlyWidget = function () {
    w._api ? w._api.apply(w, arguments) : w.queue.push(arguments);
  });
  w.scriptPath = scriptPath;
  w.queue = [];
  scriptTag.async = true;
  const getScript = new XMLHttpRequest();
  getScript.open('GET', scriptPath + 'version?v=' + Date.now(), true);
  getScript.onreadystatechange = function () {
    if (getScript.readyState === 4) {
      scriptTag.src = scriptPath + 'stonly-widget.js?v=' + (200 === getScript.status ? getScript.responseText : Date.now());
      const l = document.getElementsByTagName(tag)[0];
      l.parentNode.insertBefore(scriptTag, l);
    }
  };
  getScript.send();
};

export const identifyStonlyUser = async (user) => {
  if (window.StonlyWidget !== undefined && typeof window.StonlyWidget === 'function') {
    window.StonlyWidget('identify', user.id);
  }
};
