import classNames from 'classnames';
import PropTypes from 'prop-types';

import BlockTitles from '@components/Common/BlockTitles';
import Breadcrumb from '@components/Common/Breadcrumb';
import LabelAction from '@components/Common/LabelAction';

import style from './style.module.css';

const Block = ({
  children,
  padding = 'MEDIUM',
  margin = 'SMALL',
  title = '',
  subtitle = '',
  actions = [],
  actionsDirection = 'HORIZONTAL',
  className = '',
  bottomAction = null,
  bgColor = '',
  boxShadow = true,
  onClick = null,
  breadcrumb = [],
  ...props
}) => {
  return (
    <div
      {...props}
      className={classNames(style.block, className, {
        [style[`padding-${padding.toString().toLowerCase()}`]]: padding,
        [style[`margin-${margin.toString().toLowerCase()}`]]: margin,
        [style.boxShadow]: boxShadow,
        [style.pointer]: !!onClick,
      })}
      style={{ ...(bgColor ? { backgroundColor: bgColor } : {}) }}
      onClick={onClick}
    >
      {breadcrumb.length > 0 && <Breadcrumb history={breadcrumb} className={style.breadcrumb} />}
      <BlockTitles
        title={title}
        subtitle={subtitle}
        actions={actions}
        actionsDirection={actionsDirection}
        className={classNames({ [style.blockTitle]: children })}
      />
      {children}
      {bottomAction && (
        <div className={style.bottomAction}>
          <LabelAction text={bottomAction.text} icon={bottomAction.icon} onClick={bottomAction.onClick} />
        </div>
      )}
    </div>
  );
};

Block.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  padding: PropTypes.oneOf(['NONE', 'SMALL', 'MEDIUM', 'BIG', 'HUGE']),
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  actions: BlockTitles.propTypes.actions,
  actionsDirection: BlockTitles.propTypes.actionsDirection,
  bottomAction: PropTypes.shape({
    text: PropTypes.string,
    icon: PropTypes.string,
    onClick: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.bool]),
  }),
  bgColor: PropTypes.string,
  boxShadow: PropTypes.bool,
  onClick: PropTypes.func,
  breadcrumb: PropTypes.arrayOf(PropTypes.shape({ url: PropTypes.string | PropTypes.func, name: PropTypes.string })),
};

export default Block;
