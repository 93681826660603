import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Button from '@components/Common/Button';
import Flex from '@components/Common/Flex';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import { useUploadSocialModal } from '@contexts/UploadSocialModalContext';

import style from './style.module.css';

const VideoListForm = ({ projects = [], onEditClick = () => {} }) => {
  const { t } = useTranslation();
  const { invalidVideoIds } = useUploadSocialModal();

  return (
    <div className={style.list}>
      {projects.map((project) => (
        <Flex key={project.id} alignItems="CENTER" className={style.listRow}>
          <img src={project.poster} className={style.rowImage} alt="thumbnails from video" />
          <p className={classNames(style.limitText, style.rowTitle)}>{project.title}</p>
          <p className={classNames(style.limitText, style.rowDescription)}>{project.description}</p>
          <div className={style.rowTags}>
            <span className={style.rowTagsPill}>{t('{{count}} tags', { count: project.tags?.length || 0 })}</span>
          </div>
          <div className={style.rowPrivacy}>
            <SkeepersIcon
              type={project.privacyStatus === 'Private' || project.privacyStatus === 'Unlisted' ? 'EYESLASH' : 'EYE'}
              color={'var(--layout-primary-color)'}
              className={style.privacyStatusIcon}
            />
            <span className={style.privacyStatusText}>{t(project.privacyStatus)}</span>
          </div>
          <div className={style.rowSubtitle}>
            <SkeepersIcon
              type="CLOSEDCAPTIONING"
              color={project.subtitlesActivated ? 'var(--layout-primary-color)' : 'var(--skp-color-neutral-500)'}
              className={style.subtitleStatusIcon}
            />
            <span className={style.subtitleStatusText}>{t('Subtitles')}</span>
          </div>
          <Flex flexDirection="ROW" alignItems="CENTER" gap="0.5rem" margin="NONE" className={style.rowEdit}>
            {invalidVideoIds.includes(project.id) && <SkeepersIcon type="WARNING" color="var(--layout-primary-color)" />}
            <Button
              className={style.buttonEdit}
              action={() => onEditClick(project)}
              theme="PRIMARY"
              label={<SkeepersIcon color={'var(--white)'} type="PEN" />}
            />
          </Flex>
        </Flex>
      ))}
    </div>
  );
};

export default VideoListForm;

VideoListForm.propTypes = {
  projectsSource: PropTypes.array,
  onEditClick: PropTypes.func,
};
