import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LinesEllipsis from 'react-lines-ellipsis';

import style from './style.module.css';

export const MultilineText = ({ content = '', className = '', maxLine = null }) => {
  const { t } = useTranslation();
  const [closed, setClosed] = useState(true);
  const toggleEllipsis = () => {
    setClosed((p) => !p);
  };

  if (maxLine) {
    return (
      <div className={classNames(style.container, className)}>
        {closed ? (
          <LinesEllipsis
            text={content || ''}
            maxLine={maxLine}
            ellipsis={
              <span className={style.readMoreContainer} onClick={toggleEllipsis}>
                {t('... read more')}
              </span>
            }
            trimRight
            basedOn="words"
          />
        ) : (
          <>
            {content || ''}
            <span className={style.readMoreContainer} onClick={toggleEllipsis}>
              {t('read less')}
            </span>
          </>
        )}
      </div>
    );
  }

  return <div className={classNames(style.container, className)}>{content}</div>;
};

export default MultilineText;

MultilineText.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
  maxLine: PropTypes.number,
};
