import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getLanguages } from '@app/translations';
import ButtonContainer from '@components/Common/ButtonContainer';
import SkeepersFlag from '@components/Common/SkeepersFlag';
import { Form, useForm } from '@components/FormElement/Form';

const ImportCardsForm = ({ availableOutputsCards = [], isLoading = false, onImport = () => { } }) => {
  const { t } = useTranslation();
  const methods = useForm();
  const languages = useMemo(() => getLanguages(t), [t]);

  const onSubmit = ({ outputId }) => onImport(availableOutputsCards.find((o) => o.id === outputId)?.cards ?? []);

  return (
    <Form onSubmit={onSubmit} isUpdating={isLoading} methods={methods}>
      <Form.Label title={t('Base output')} name="outputId">
        <Form.Select
          options={availableOutputsCards.map((c) => ({
            label: c.filename,
            value: c.id,
            icon: <SkeepersFlag country={languages[c.language]?.country} />,
          }))}
          name="outputId"
          required
        />
      </Form.Label>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default ImportCardsForm;

ImportCardsForm.propTypes = {
  availableOutputsCards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      filename: PropTypes.string,
      language: PropTypes.string,
      cards: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
          language: PropTypes.string,
          position: PropTypes.number,
          displayedInTimeline: PropTypes.bool,
        })
      ),
    })
  ),
  isLoading: PropTypes.bool,
  onImport: PropTypes.func,
};
