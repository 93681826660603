import { useEffect, useState } from 'react';

import { WINDOW_TYPES } from '@contexts/WindowContext';
import { getAllPresetValues } from '@hooks/configuration';
import { useExtractFromCsv } from '@hooks/extractFromCsv';

export const getImportPresetHint = (t) => (values, validValues) => {
  const notMatchedValues = values.filter(({ matched }) => !matched);
  const excludedValues = values.filter(({ matched, included }) => matched && !included);
  const emptyValues = values.filter(({ empty }) => empty);
  const unchangedValues = values.filter(({ value, matchedKey }) => matchedKey && value === matchedKey.value);

  if (validValues.length === 0) {
    return {
      type: 'ERROR',
      message: t('No keys can be imported.'),
    };
  }

  if (validValues.length < values.length) {
    return {
      type: 'WARNING',
      message: t('Only {{count}} out of {{total}} values will be imported ({{notMatched}} not matched, {{excluded}} excluded and {{empty}} empty).', {
        count: validValues.length,
        notMatched: notMatchedValues.length,
        empty: emptyValues.length,
        excluded: excludedValues.length,
        unchanged: unchangedValues.length,
        total: values.length,
      }),
    };
  }

  return {
    type: 'SUCCESS',
    message: t('{{count}} values can be imported.', { count: validValues.length }),
  };
};

export const useAllPresetKeyValues = (presetId, actions) => {
  const [keys, setKeys] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!presetId) return;

    setLoading(true);
    getAllPresetValues({ presetId, filters: { onlyUnsetValues: false }, chunkSize: 4500 })
      .then(data => {
        if (data.error || !data.values) {
          console.error('Failed to fetch preset values:', data.error);
          actions.open(WINDOW_TYPES.BASIC_WINDOW, {
            content: <h2>Error occurred while fetching data.</h2>,
            title: 'Fetch Error',
          });
          return;
        }
        setKeys(data.values.map(item => ({
          [item.node.key.name]: {
            value: item.node.value || item.node.defaultValue,
            key: item.node.key
          }
        })));
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  }, [presetId, actions]);

  return { keys, loading };
}

export const useFileProcessor = (onKeysParsed) => {
  const [fileLoading, setFileLoading] = useState(false);
  const [fileError, setFileError] = useState(null);
  const [file, setFile] = useState(null);

  const [, loadFile] = useExtractFromCsv([], true);

  const onEdit = async (file) => {
    setFile(file);
    setFileError(null);
    if (!file) return;

    setFileLoading(true);

    switch (file.type) {
      case 'application/json':
        file.text()
          .then(JSON.parse)
          .then(onKeysParsed)
          .catch(error => {
            console.error('Error parsing file:', error);
            setFileError('Invalid file content');
          })
          .finally(() => setFileLoading(false));
        break;
      case 'text/csv':
        const { data } = await loadFile(file);
        setFileLoading(false);
        if (!data) {
          setFileError('Invalid CSV file');
          return;
        }
        const keys = data.reduce((acc, item) => {
          if (item.length < 2 || (item[0].trim() === '' && item[1].trim() === '')) {
            return acc;
          }

          const formatCsvStringRegex = /^"|"$/g;
          const key = item[0].replace(formatCsvStringRegex, '').trim();
          let value = item[1].replace(formatCsvStringRegex, '').trim();

          if (key === '') {
            return acc;
          }

          switch (value) {
            case 'null':
              value = null;
              break;
            case 'undefined':
              value = undefined;
              break;
            default:
              break;
          }
          acc[key] = value;
          return acc;
        }, {});
        onKeysParsed(keys);
        break;
      default:
        setFileLoading(false);
        setFileError('Invalid file format');
        break;
    }
  };

  return {
    file,
    fileLoading,
    fileError,
    onEdit
  };
}