import { createContext, useContext } from 'react';

const FormContext = createContext({
  register: () => {},
  unregister: () => {},
  isUpdating: false,
  skipDirtyCheck: false,
  readOnly: false,
  requiredFields: [],
  isLocked: false,
  lock: () => {},
  unlock: () => {},
  retriggeredComponents: [],
});

export const useInternalFormContext = () => useContext(FormContext);

export default FormContext;
