import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { useCallback, useState } from 'react';
import { FormProvider } from 'react-hook-form';

import FormContext from '@components/FormElement/Form/FormContext';

const onKeyPress = (e) => {
  if (e.key === 'Enter' && e.target.type !== 'textarea') {
    e.preventDefault();
  }
};

const add = (name) => (array) => [...new Set([...array, name])];
const remove = (name) => (array) => array.filter((f) => f !== name);

const Form = ({ methods, onSubmit = () => {}, keepUnregistered = false, skipDirtyCheck = false, isUpdating = false, readOnly = false, children }) => {
  const [fields, setFields] = useState([]);
  const [requiredFields, setRequiredFields] = useState([]);
  const [locks, setLock] = useState([]);
  const [retriggeredComponents, setRetriggeredComponents] = useState([]);

  const onTrigger = useCallback((name) => setRetriggeredComponents((state) => (state.includes(name) ? state : [...state, name])), [setRetriggeredComponents]);

  const _onSubmit = async (values) => {
    const submitedValues = keepUnregistered ? values : pick(values, fields);
    try {
      await onSubmit(submitedValues);
      methods.reset(values, { keepDirty: false });
    } catch (error) {
      console.error(error);
    }
    return;
  };

  const lock = useCallback(
    (name) => {
      setLock(add(name));
    },
    [setLock]
  );

  const unlock = useCallback(
    (name) => {
      setLock(remove(name));
    },
    [setLock]
  );

  const register = useCallback(
    (name, required) => {
      setFields(add(name));
      if (required) {
        setRequiredFields(add(name));
      }
    },
    [setFields]
  );

  const unregister = useCallback(
    (name) => {
      setFields(remove(name));
      setRequiredFields(remove(name));
    },
    [setFields]
  );

  return (
    <FormProvider {...methods}>
      <FormContext.Provider
        value={{
          register,
          unregister,
          skipDirtyCheck,
          isUpdating,
          readOnly,
          requiredFields,
          isLocked: !!locks.length,
          lock,
          unlock,
          retriggeredComponents,
          onTrigger,
        }}
      >
        <form onSubmit={methods?.handleSubmit(_onSubmit)} onKeyPress={onKeyPress} noValidate>
          {children}
        </form>
      </FormContext.Provider>
    </FormProvider>
  );
};

Form.propTypes = {
  /**  the methods object is provided by useForm */
  methods: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  /**  when set to true submit the value of fields that are not displayed */
  keepUnregistered: PropTypes.bool,
  /** when set to true authorise the user to submit when the form is not dirty */
  skipDirtyCheck: PropTypes.bool,
  /** when set to true  disable form and set the submit button to loading*/
  isUpdating: PropTypes.bool,
  /** when set to true all the field are set to read only */
  readOnly: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Form;
