import { SkpAlert } from '@skeepers/skeepers-ui-react';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';

import { getCompanyIdFromUrl } from '@app/utils';
import Button from '@components/Common/Button';
import ButtonContainer from '@components/Common/ButtonContainer';
import { Grid } from '@components/Common/Grid';
import { Form, useForm } from '@components/FormElement/Form';
import { useUploadSocialModal } from '@contexts/UploadSocialModalContext';
import { useGetYoutubeVideoTemplates } from '@hooks/socialMedia/youtubeVideoTemplates';

import VideoList from '../VideoList';

import style from './style.module.css';

const VideoListForm = ({ playlists = [], onCancel = () => {}, onEditClick = () => {}, onSubmit = () => {} }) => {
  const { t } = useTranslation();
  const { videos, updateVideos, globalConfiguration, updateGlobalConfiguration, invalidVideoIds } = useUploadSocialModal();
  const { templates, loading: templatesLoading } = useGetYoutubeVideoTemplates(getCompanyIdFromUrl());
  const [isUpdating, setIsUpdating] = useState(false);
  const methods = useForm();

  const _onSubmit = (formValues) => {
    setIsUpdating(true);
    onSubmit({
      ...formValues,
      videos,
    });
  };

  const handleConfigurationChange = (name) => (value) => {
    updateGlobalConfiguration({ [name]: value });
    const template = name === 'templateId' && value ? templates.find(({ id }) => id === value) : null;
    updateVideos(
      videos.map((video) => ({
        ...video,
        ...(template ? { title: template.videoTitle, description: template.videoDescription, tags: template.videoTags } : {}),
        [name]: value,
      }))
    );
  };

  const showPrivateWarning = videos.some((v) => v.privacyStatus === 'Private');

  return (
    <Form onSubmit={_onSubmit} methods={methods} isUpdating={isUpdating} skipDirtyCheck>
      <Grid columns={2}>
        <Form.Label title={t('Playlist')} name="playlistId">
          {playlists?.length <= 0 ? (
            <p>{t('No playlists found')}</p>
          ) : (
            <Form.Select
              name="playlistId"
              options={playlists.map((playlist) => ({
                label: playlist.title,
                value: playlist.id,
              }))}
              defaultValue={globalConfiguration?.playlistId}
              onAfterChange={handleConfigurationChange('playlistId')}
            />
          )}
        </Form.Label>
        <Form.Label title={t('Privacy status')} name="privacyStatus">
          <Form.Select
            name="privacyStatus"
            options={[
              { label: t('Public'), value: 'Public' },
              { label: t('Private'), value: 'Private' },
              { label: t('Unlisted'), value: 'Unlisted' },
            ]}
            defaultValue={globalConfiguration?.privacyStatus}
            onAfterChange={handleConfigurationChange('privacyStatus')}
            required
          />
          {showPrivateWarning && <SkpAlert size="small" type="info" text={t("Videos with private status won't be visible for viewers.")}></SkpAlert>}
        </Form.Label>
      </Grid>
      <Form.Label title={t('Template')} name="templateId">
        {templatesLoading ? (
          <Skeleton height={40} />
        ) : (
          <Form.Select
            defaultValue={globalConfiguration?.templateId}
            name="templateId"
            options={templates.map((template) => ({
              label: template.title,
              value: template.id,
            }))}
            onAfterChange={handleConfigurationChange('templateId')}
          />
        )}
      </Form.Label>
      <div className={style.listContainer}>
        <div className={style.listHeader}>
          <h2 className={style.listHeaderTitle}>{t('Your videos selection')}</h2>
          <Form.Label className={style.listHeaderSubtitle} title={t('Subtitles')} name="subtitlesActivated">
            <Form.Switch
              name="subtitlesActivated"
              defaultValue={globalConfiguration?.subtitlesActivated}
              onAfterChange={handleConfigurationChange('subtitlesActivated')}
            />
          </Form.Label>
        </div>
        <VideoList projects={videos} onEditClick={onEditClick} />
      </div>
      {!!invalidVideoIds.length && (
        <SkpAlert
          size="small"
          type="warning"
          text={t('Please check the video details as some errors were detected in the title and(or) description.')}
        ></SkpAlert>
      )}
      <ButtonContainer position="CENTER">
        <Button label={t('Cancel')} action={onCancel} theme={Button.themes.SECONDARY} color="var(--neutral-300)" />
        <Form.SubmitButton label={t('Upload')} disabled={!!invalidVideoIds.length} />
      </ButtonContainer>
    </Form>
  );
};

export default VideoListForm;

VideoListForm.propTypes = {
  playlists: PropTypes.array,
  videosSource: PropTypes.array,
  onCancel: PropTypes.func,
  onEditClick: PropTypes.func,
  onSubmit: PropTypes.func,
};
