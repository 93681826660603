import PropTypes from 'prop-types';
import { createRef, Component } from 'react';

import styles from './style.module.css';

class TimelineBase extends Component {
  constructor(props) {
    super(props);
    this.refCanvas = createRef();
    this.refContainer = createRef();
    this.timer = false;
    this.state = { width: 0 };
    this._pureResize = () => {
      if (this.state.width !== this.refContainer.current.clientWidth) {
        this.setState(
          {
            width: this.refContainer.current.clientWidth,
          },
          () => {
            this._updateTimelineMarks();
          }
        );
      }
    };
  }

  _updateTimelineMarks() {
    const canvas = this.refCanvas.current;
    const scale = this.props.scaleX;
    const duration = this.props.duration;
    const scale4 = scale / 4;
    const width = duration * scale;

    // Define canvas width
    canvas.width = this.state.width;

    // Init time codes
    const context = canvas.getContext('2d', { alpha: false });
    context.fillStyle = '#191919';
    context.fillRect(0, 0, canvas.width, canvas.height);
    context.strokeStyle = '#888';
    context.beginPath();
    context.translate(-this.props.offsetX, 0);

    // Write each time stick
    for (let i = 0.5; i <= width; i += scale) {
      context.moveTo(i + scale4 * 0, 18);
      context.lineTo(i + scale4 * 0, 26);
      if (scale > 16) {
        context.moveTo(i + scale4 * 1, 22);
        context.lineTo(i + scale4 * 1, 26);
      }
      if (scale > 8) {
        context.moveTo(i + scale4 * 2, 22);
        context.lineTo(i + scale4 * 2, 26);
      }
      if (scale > 16) {
        context.moveTo(i + scale4 * 3, 22);
        context.lineTo(i + scale4 * 3, 26);
      }
    }

    // Write each time code
    context.stroke();
    context.font = '10px "Open Sans"';
    context.fillStyle = '#888';
    context.textAlign = 'center';
    const step = Math.max(1, Math.floor(64 / scale));
    for (let i = 0; i < duration; i += step) {
      const minute = Math.floor(i / 60);
      const second = Math.floor(i % 60);
      const text = (minute > 0 ? `${minute}:` : '') + `0${second}`.slice(-2);
      context.fillText(text, i * scale, 13);
    }
  }

  _onMove(e) {
    const rect = e.target.getBoundingClientRect();
    let bufferX = 0;
    const initialX = e.clientX - rect.left;

    const calc = () => Math.round(((initialX + bufferX + this.props.offsetX) / this.props.scaleX + Number.EPSILON) * 100) / 100;

    const end = () => {
      document.removeEventListener('mousemove', move);
      document.removeEventListener('mouseup', end);
      this.props.onSeek({ time: calc(), isMoving: false });
    };

    const move = (evt) => {
      // Update buffer
      bufferX += evt.movementX;

      // Patch element
      this.props.onSeek({ time: calc(), isMoving: true });
    };
    document.addEventListener('mousemove', move);
    document.addEventListener('mouseup', end);

    // Patch element
    this.props.onSeek({ time: calc(), isMoving: true });
  }

  componentDidUpdate() {
    this._updateTimelineMarks();
  }

  componentDidMount() {
    window.addEventListener('resize', this._pureResize);
    this._pureResize();
    this.timer = setInterval(this._pureResize, 250);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this._pureResize);
    clearInterval(this.timer);
  }

  shouldComponentUpdate(prevProps) {
    return (
      `${prevProps.scaleX}-${prevProps.scaleY}-${prevProps.offsetX}-${prevProps.duration}` !==
      `${this.props.scaleX}-${this.props.scaleY}-${this.props.offsetX}-${this.props.duration}`
    );
  }

  render() {
    const handleMove = (e) => {
      this._onMove(e);
    };

    return (
      <div className={styles.container} onMouseDown={handleMove} ref={this.refContainer}>
        <canvas className={styles.canvas} height={32} ref={this.refCanvas} />
      </div>
    );
  }
}

TimelineBase.propTypes = {
  scaleX: PropTypes.number,
  scaleY: PropTypes.number,
  offsetX: PropTypes.number,
  duration: PropTypes.number,
  onSeek: PropTypes.func,
};

TimelineBase.defaultProps = {
  scaleX: 0,
  scaleY: 0,
  offsetX: 0,
  duration: 0,
  onSeek: (e) => {
    console.log('[TimelineBase] onSeek()', e);
  },
};

export default TimelineBase;
