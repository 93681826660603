import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { durationInMinutes } from '@app/utils';
import Flex from '@components/Common/Flex';
import Hint from '@components/Common/Hint';
import Text from '@components/Common/Text';
import Form from '@components/FormElement/Form/Form';

import style from './style.module.css';

const Extract = ({ limits, outroDuration, introDuration, maxDuration, output }) => {
  const { t } = useTranslation();
  const [startAt, setStartAt] = useState(0);
  const [endAt, setEndAt] = useState(output.duration);

  //Update the startAt and endAt with the limits wich base on the cropVideo component
  useEffect(() => {
    if (limits) {
      setStartAt(limits.startAt);
      setEndAt(limits.endAt);
    }
  }, [limits]);

  // Set the info of maxDuration in minute or second depending of the duration of maxDuration
  const maxDurationInfo = (maxDuration) =>
    maxDuration > 60
      ? t(`Maximum recommended duration : {{count}} min`, { count: durationInMinutes(maxDuration) })
      : t(`Maximum recommended duration : {{count}} sec`, { count: maxDuration });

  // Duration of the video baed on the limits
  const extractDuration = Math.floor((endAt - startAt) / 1000);

  // Set the info of extract duration info in minute or second depending of the duration of extract
  let extractInfo = (data) => {
    return data > 60
      ? t('extract ({{count}} min)', { count: durationInMinutes(Math.round(data)) })
      : t('extract ({{count}} sec)', { count: Math.round(data) });
  };

  // Get the total duration in minute for the video, included intro and outro
  const totalDurationInMinutes = (durationTotal) =>
    durationTotal > 60
      ? t('= {{count}} min', { count: durationInMinutes(extractDuration + introDuration + outroDuration) })
      : t('= {{count}} sec', { count: Math.floor(extractDuration + introDuration + outroDuration) });

  return (
    <div className={style.videoLimitsContainer}>
      <Flex flexDirection="COLUMN" alignItems="CENTER" className={style.flex}>
        <Flex justifyContent="CENTER">
          {!!introDuration && <Text>{t('Intro ({{count}} sec) +', { count: Math.round(introDuration) })}</Text>}
          {<Text>{extractInfo(extractDuration)}</Text>}
          {!!outroDuration && <Text>{t('+ outro ({{count}} sec)', { count: Math.round(outroDuration) })}</Text>}
          <Text>{totalDurationInMinutes(extractDuration + introDuration + outroDuration)}</Text>
        </Flex>
        {!!maxDuration && <Hint message={maxDurationInfo(maxDuration)} />}
      </Flex>
      <Form.VideoLimits name="limits" required url={output.url} defaultValue={{ startAt: 0, endAt: null }} storyboard={output.assets.highStoryboard} />
    </div>
  );
};

export default Extract;
