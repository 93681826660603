import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ImportPresetValuesForm from '@components/Form/ImportPresetValuesForm/ImportPresetValuesForm';
import Window from '@components/Window/Window';

const ImportPresetValuesWindow = ({
  presetId,
  onClose = () => { },
  open = false,
  onSuccess = () => { },
  onError = () => { },
}) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Import values')} open={open} onClose={onClose}>
      <ImportPresetValuesForm
        presetId={presetId}
        onSuccess={onSuccess}
        onError={onError}
      />
    </Window>
  );
};

ImportPresetValuesWindow.propTypes = {
  presetId: PropTypes.string,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onSuccess: PropTypes.func,
  onError: PropTypes.func,
};

export default ImportPresetValuesWindow;
