import { IFM_CSAT_URL } from '@app/config';

/* global MFB */
export const loadCSATModule = () => {
  if (window && typeof MFB === 'undefined') {
    window._Mfb_useCookie = true;
    window._Mfb_ud = {
      Skeepers_Product: 'UGV BO',
    };
    var mfb = document.createElement('script');
    mfb.type = 'text/javascript';
    mfb.charset = 'UTF-8';
    mfb.async = true;
    mfb.id = 'MFBActor';
    mfb.src = IFM_CSAT_URL;
    var s = document.getElementsByTagName('script')[0];
    s.parentNode.insertBefore(mfb, s);
    console.log('MFB script loaded');
  }
};

export const updateCSATUserVariableAndStart = (startDate, email, name, lang, company) => {
  window._Mfb_ud = {
    ...window._Mfb_ud,
    Company: company,
    Start_Date: startDate,
    Customer_Email: email,
    Customer_Name: name,
    _context: {
      lang: lang || 'fr',
      _page: {
        // eslint-disable-next-line
        url: location.pathname,
      },
    },
  };
  if (typeof MFB !== 'undefined') {
    console.log('MFB module started');
    MFB.start();
  }
};
