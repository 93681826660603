import { useTranslation } from 'react-i18next';

import Spinner from '@components/Common/Spinner';
import CreateCardsForm from '@components/Form/CreateCardsForm';
import Window from '@components/Window/Window';

import style from './style.module.css';

const CreateCardsWindow = ({
  cardsLanguages = [],
  availableOutputsCards = [],
  onSubmit = () => {},
  isLoading = false,
  availableOutputsCardsLoading = false,
  onClose = () => {},
  open = false,
}) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Add cards')} open={open} onClose={onClose}>
      <div className={style.root}>
        {availableOutputsCardsLoading && <Spinner type="SECONDARY" />}
        <div className={availableOutputsCardsLoading ? style.hidden : ''}>
          <CreateCardsForm cardsLanguages={cardsLanguages} availableOutputsCards={availableOutputsCards} onSubmit={onSubmit} isLoading={isLoading} />
        </div>
      </div>
      {availableOutputsCardsLoading}
    </Window>
  );
};

export default CreateCardsWindow;
