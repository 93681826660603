import { lazy, Suspense, useState } from 'react';

import Spinner from '@components/Common/Spinner';
import SpinnerContainer from '@components/Common/SpinnerContainer';

const withLazyLoading =
  (callback = (_) => _) =>
  (componentLoader) => {
    let Component;

    const promise = new Promise((resolve) => {
      Component = callback(
        lazy(() => {
          const promise = componentLoader().then((loaded) => {
            resolve({
              transformOnBlur: loaded?.default?.transformOnBlur,
              transform: loaded?.default?.transform,
              customValidate: loaded?.default?.customValidate,
              customRequired: loaded?.default?.customRequired,
              displayName: loaded?.default?.displayName || loaded?.default?.name,
            });
            return loaded;
          });
          return promise;
        })
      );
    });

    const LazyLoadedComponent = (props) => {
      const [componentOptions, setComponentOptions] = useState(null);

      if (!componentOptions) {
        promise.then((componentOptions) => {
          setComponentOptions((state) => state || componentOptions);
        });
      }

      return (
        <Suspense
          fallback={
            <SpinnerContainer>
              <Spinner type="SECONDARY" />
            </SpinnerContainer>
          }
        >
          {Component && <Component componentOptions={componentOptions} {...props} />}
        </Suspense>
      );
    };

    return LazyLoadedComponent;
  };

export default withLazyLoading;
