import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { countryToLanguages, findSubtitleCodeByLang, solutionTranslations } from '@app/translations';
import { getTemplateFromSolutionType } from '@app/utils';
import ButtonContainer from '@components/Common/ButtonContainer';
import { Grid } from '@components/Common/Grid';
import Hint from '@components/Common/Hint';
import { Form, useForm } from '@components/FormElement/Form';
import { PLATFORM_BASIC, PLATFORM_BOOK, PLATFORM_CHALLENGE, PLATFORM_PRODUCT } from '@hooks/solutions';

const TYPE = 'TYPE';
const NAME = 'NAME';
const ROUTE = 'ROUTE';

const translations = {
  ...solutionTranslations,
  [TYPE]: (t) => t('Type'),
  [NAME]: (t) => t('Name'),
  [ROUTE]: (t) => t('Route'),
};

const getPlatformOptions = (t = () => {}) =>
  [PLATFORM_BASIC, PLATFORM_BOOK, PLATFORM_CHALLENGE, PLATFORM_PRODUCT].map((o) => ({ value: o, label: translations[o](t) }));

/**
 * If type is set, display the list of available configuration presets
 */
export const filterLanguages = (type, templates) => {
  if (!type) {
    return () => true;
  }

  const template = getTemplateFromSolutionType(type, templates);

  if (!template || !template.presets || !template.presets.length) {
    return () => true;
  }

  return (language) => template.presets.some((preset) => preset.language.code === findSubtitleCodeByLang(language));
};

const CreateSolutionForm = ({ castings = [], templates = [], solutionType = '', isUpdating = false, onSubmit = () => {} }) => {
  const { t } = useTranslation();
  const NONE = { label: t('None'), value: null };

  const methods = useForm();

  const [selectedType, platformType, castingId] = methods.watch(['type', 'platformType', 'castingId']);

  const type = selectedType || solutionType;

  const _submit = (values) => {
    const language = values.language ? countryToLanguages[values.language][0] : null;

    onSubmit({
      ...values,
      language,
      type: type === 'PLATFORM' ? values.platformType : type,
    });
};

  return (
    <Form methods={methods} onSubmit={_submit} isUpdating={isUpdating}>
      <Grid>
        <Form.Label title={t('Name')} name="name">
          <Form.Input name="name" required />
        </Form.Label>
        {!solutionType && (
          <Form.Label title={t('Type')} name="type">
            <Form.SolutionSelect name="type" required />
          </Form.Label>
        )}
        {type === 'PLATFORM' && (
          <>
            <Form.Label title={t('Casting')} name="castingId">
              <Form.Select
                name="castingId"
                options={[NONE, ...castings.map(({ name, id }) => ({ label: name, value: id }))]}
                defaultValue={null}
                hint={<Hint type="INFO" message={t('A casting is needed to create book platforms.')} />}
                onAfterChange={(castingId) => {
                  if (platformType === PLATFORM_BOOK && !castingId) {
                    methods.setValue('platformType', PLATFORM_BASIC);
                  }
                }}
              />
            </Form.Label>
            <Form.Label title={t('Platform type')} name="platformType">
              <Form.Select
                name="platformType"
                options={getPlatformOptions(t)}
                defaultValue={PLATFORM_BASIC}
                isOptionDisabled={({ value }) => (value === PLATFORM_BOOK ? !castingId : false)}
                required
              />
            </Form.Label>
          </>
        )}
        {['PLATFORM', 'CASTING', 'VSG'].includes(type) && (
          <Form.Label title={t('Language')} name="language">
            <Form.LanguageSelect
              name="language"
              filterLanguages={filterLanguages(type, templates)}
              required
              hint={<Hint type="INFO" message={t('Your solution will be pre-configured in the selected language.')} />}
            />
          </Form.Label>
        )}
        {['PLATFORM', 'CASTING'].includes(type) && (
          <Form.Label title={t('Route')} name="route">
            <Form.PlatformRouteInput name="route" required />
          </Form.Label>
        )}
      </Grid>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default CreateSolutionForm;

CreateSolutionForm.propTypes = {
  castings: PropTypes.array,
  templates: PropTypes.array,
  /**
   * Pre-selected solution type
   */
  solutionType: PropTypes.string,
  isUpdating: PropTypes.bool,
  onSubmit: PropTypes.func,
};
