import { useTranslation } from 'react-i18next';

import CreateGroupForm from '@components/Form/CreateGroupForm';
import Window from '@components/Window/Window';

const CreateGroupWindow = ({ onSubmit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Create user group')} open={open} onClose={onClose}>
      <CreateGroupForm onSubmit={onSubmit} isUpdating={isLoading} />
    </Window>
  );
};

export default CreateGroupWindow;
