import { useTranslation } from 'react-i18next';

import CreateCompanyForm from '@components/Form/CreateCompanyForm';
import Window from '@components/Window/Window';

const CreateCompanyWindow = ({ onSubmit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Add a client')} open={open} onClose={onClose}>
      <CreateCompanyForm role="CREATE" onSubmit={onSubmit} isUpdating={isLoading} />
    </Window>
  );
};

export default CreateCompanyWindow;
