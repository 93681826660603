import classNames from 'classnames';
import { debounce } from 'lodash';
import PropTypes from 'prop-types';
import { forwardRef, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';
import ReactAsyncSelect from 'react-select/async';

import style from './style.module.css';

const formatOptionLabelWithIcon = ({ label, icon }) => (
  <div className={style.option}>
    {icon && <div className={style.icon}>{icon}</div>}
    <div className={style.label}>{label}</div>
  </div>
);

//Add style for Select to match Skeepers theme
const customStyles = {
  option: (styles, { isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      fontWeight: isSelected ? 'bold' : 'normal',
      color: isDisabled ? 'grey' : (isFocused && isSelected) || isSelected ? 'var(--color-black)' : 'var(--color-white)',
      backgroundColor: (isFocused && isSelected) || isSelected ? 'var(--neutral-100)' : isFocused ? 'var(--neutral-50)' : 'unset',
      cursor: isDisabled ? 'not-allowed' : 'default',
    };
  },
};

const Select = forwardRef(
  (
    {
      name = '',
      options = [],
      selectKey = null,
      className = '',
      value = null,
      withBackground = false,
      readOnly = false,
      inputPlaceholder,
      placeholder = null,
      isOptionDisabled = () => {},
      formatOptionLabel = null,
      isClearable = false,
      onEdit = () => {},
      onBlur = () => {},
      onFocus = () => {},
      error = false,
      menuPlacement = 'bottom',
    },
    forwardedRef
  ) => {
    const { t } = useTranslation();
    const inputPlaceholderToUse = inputPlaceholder ?? t('Type to search');

    return (
      <ReactSelect
        {...(selectKey ? { key: selectKey } : {})}
        {...(name ? { 'aria-label': name } : {})}
        placeholder={inputPlaceholderToUse}
        className={classNames(style.select, className, { [style.background]: withBackground, [style.error]: error })}
        classNamePrefix="select"
        isOptionDisabled={isOptionDisabled}
        isDisabled={readOnly}
        isClearable={isClearable}
        onChange={(value) => onEdit(value?.value || null)}
        onBlur={onBlur}
        tabSelectsValue={false}
        onFocus={onFocus}
        options={options}
        formatOptionLabel={formatOptionLabel || formatOptionLabelWithIcon}
        value={options?.find(({ value: v }) => (value || placeholder) === v)}
        innerRef={forwardedRef}
        menuPortalTarget={document.body}
        menuShouldBlockScroll
        menuPlacement={menuPlacement}
        styles={customStyles}
      />
    );
  }
);

Select.displayName = 'Select';

export const AsyncSelect = forwardRef(
  (
    {
      className = '',
      value = null,
      withBackground = false,
      loadOptions = () => {},
      onEdit = () => {},
      defaultOptions = [],
      formatOptionLabel = null,
      placeholder,
      selectKey = null,
      error = false,
      inputValue = '',
      onInputChange = () => {},
      saveLoadedOptions = false,
      onBlur = () => {},
    },
    forwardedRef
  ) => {
    const { t } = useTranslation();
    const inputPlaceholderToUse = placeholder ?? t('Type to search');
    const [lastLoadedOptions, setLastLoadedOptions] = useState(defaultOptions);
    const [loading, setLoading] = useState(false);
    const lastQuery = useRef(null);

    const debouncedLoadOptions = useMemo(
      () =>
        debounce((searchString, setOptions) => {
          setLoading(true);
          lastQuery.current = searchString;
          loadOptions(searchString)
            .catch((err) => {
              console.error(err);
              return [];
            })
            .then((options) => {
              if (lastQuery.current === searchString) {
                setOptions(options);
                setLastLoadedOptions(options);
                setLoading(false);
              }
            });
        }, 500),
      [loadOptions]
    );

    return (
      <ReactAsyncSelect
        key={selectKey}
        className={classNames(style.select, className, { [style.background]: withBackground, [style.error]: error })}
        placeholder={inputPlaceholderToUse}
        classNamePrefix="select"
        cacheOptions
        defaultOptions={saveLoadedOptions ? lastLoadedOptions : defaultOptions}
        loadOptions={debouncedLoadOptions}
        defaultInputValue={value || ''}
        formatOptionLabel={formatOptionLabel}
        onChange={(a) => {
          onEdit(a.value);
        }}
        onBlur={onBlur}
        {...(inputValue ? { inputValue } : {})}
        {...(saveLoadedOptions ? { isLoading: loading } : {})}
        onInputChange={onInputChange}
        ref={forwardedRef}
      />
    );
  }
)

AsyncSelect.displayName = 'AsyncSelect';

Select.propTypes = {
  selectKey: PropTypes.string, // this props is used to force the re-render when selectKey change
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      icon: PropTypes.node,
    })
  ),
  value: PropTypes.string,
  className: PropTypes.string,
  isOptionDisabled: PropTypes.func,
  formatOptionLabel: PropTypes.func,
  isClearable: PropTypes.bool,
  onEdit: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

export default Select;
