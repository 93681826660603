import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonContainer from '@components/Common/ButtonContainer';
import Hint from '@components/Common/Hint';
import ValuesPreview from '@components/Configuration/ValuesPreview';
import { getTypes } from '@components/Form/TemplateKeysForm';
import { Form, useForm } from '@components/FormElement/Form';

const getHint = (t, mappedValues) => {
  const pivotValues = mappedValues.filter(({ pivotValue }) => pivotValue !== null);

  if (pivotValues.length === 0) {
    return {
      type: 'ERROR',
      message: t('Pivot language does not define any of the missing values.'),
    };
  } else if (pivotValues.length < mappedValues.length) {
    return {
      type: 'WARNING',
      message: t('Pivot language only defines {{count}} out of {{total}} values ({{missing}} missing).', {
        count: pivotValues.length,
        missing: mappedValues.length - pivotValues.length,
        total: mappedValues.length,
      }),
    };
  } else {
    return {
      type: 'SUCCESS',
      message: t('Pivot language defines all of the missing values.'),
    };
  }
};

const getMissingValue = (key, behaviour) => {
  if (behaviour === 'FALLBACK') {
    return key.name;
  } else if (behaviour === 'BLANK') {
    return '';
  } else if (behaviour === 'EXCLUDE') {
    return null;
  }
};

const ExportPresetValuesForm = ({ presets = [], values = [], isUpdating = false, onSubmit = () => {} }) => {
  const { t } = useTranslation();
  const methods = useForm();
  const [language, behaviour] = methods.watch(['language', 'behaviour']);
  const pivot = presets.find((r) => r.language.code === language) || null;

  const keyTypes = getTypes(t);
  // Original requested values associated with the corresponding computed value: pivot or behaviour-defined fallback
  const mappedValues = values.map(({ key }) => ({
    key,
    pivotValue: pivot?.values.find((pivotValue) => pivotValue.key.id === key.id)?.value || null,
    /**
     * Final exported value
     */
    get value() {
      return this.pivotValue ?? getMissingValue(this.key, behaviour);
    },
  }));
  // The final pivot values to export
  const validValues = mappedValues.filter(({ value }) => value !== null);

  return (
    <Form methods={methods} onSubmit={() => onSubmit(validValues, { pivot, behaviour })}>
      <Form.Label title={t('Missing value behaviour')}>
        <Form.SelectCard
          defaultValue={'EXCLUDE'}
          options={[
            { title: t('Fallback'), description: t('The key names acts as fallback value'), value: 'FALLBACK', icon: 'KEY' },
            { title: t('Blank'), description: t('Leave blank (empty string)'), value: 'BLANK', icon: 'UNKNOWN' },
            { title: t('Exclude'), description: t('Exclude the key from the output file'), value: 'EXCLUDE', icon: 'CLOSE' },
          ]}
          hint={<Hint type={'INFO'} message={t('This defines how unset values should be exported')} />}
          name="behaviour"
          required
        />
      </Form.Label>
      <Form.Label title={t('Pivot language')} name="language">
        <Form.LanguageSelect
          name="language"
          filterLanguages={(language) => presets.some((preset) => preset.language.code === language)}
          isClearable
          hint={pivot ? <Hint {...getHint(t, mappedValues)} /> : <Hint message={t('Optional pivot language to extract values from')} type="INFO" />}
        />
      </Form.Label>
      <h3>{t('Export preview')}</h3>
      <ValuesPreview>
        {mappedValues.map(({ key, pivotValue, value }) => (
          <ValuesPreview.Item
            key={key.name}
            icon={keyTypes[key?.type]?.icon || (value !== null ? 'UNKNOWN' : 'CLOSE')}
            name={key.name}
            value={value}
            success={pivotValue !== null} // Pivot-defined values
            warning={pivotValue === null} // Behaviour-defined fallback values
            ignored={value === null} // Excluded values
          />
        ))}
      </ValuesPreview>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton isUpdating={isUpdating} />
      </ButtonContainer>
    </Form>
  );
};

export default ExportPresetValuesForm;

ExportPresetValuesForm.propTypes = {
  isUpdating: PropTypes.bool,
  onSubmit: PropTypes.func,
};
