import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { PAGINATION_DEFAULT } from '@app/config';
import Block from '@components/Common/Block';
import Divider from '@components/Common/Divider';
import SkeepersIcon from '@components/Common/SkeepersIcon';

import style from './style.module.css';

const getIconType = (sort, order, currentKey) => {
  if (sort === currentKey) {
    return order === 'ASC' ? 'CHEVRON_DOWN' : 'CHEVRON_UP';
  }
  return 'CHEVRON_DOWN';
};

const sortOn = (data = [], { sortBy, order }) => {
  const multi = order === 'ASC' ? 1 : -1;
  return data?.sort((a, b) => multi * (sortBy(b) > sortBy(a) ? 1 : -1));
};

const paginate = (data = [], page = 1, size) => {
  const min = (page - 1) * size;
  const max = min + size;
  return data.slice(min, max);
};

export const useTable = (data = [], { page, sortBy, order, size = PAGINATION_DEFAULT.size }) =>
  useMemo(() => {
    const sortedData = sortBy ? sortOn(data, { sortBy, order }) : data;
    return page ? paginate(sortedData, page, size) : sortedData;
  }, [page, data, order, size, sortBy]);

export const Table = ({ overflow = false, children = null }) => {
  return <div className={classNames({ [style.tableOverflow]: overflow })}>{children}</div>;
};

export const DropdownTable = ({ children = null }) => {
  return children;
};

const Header = ({ children, className = '' }) => <div className={classNames(style.header, className)}>{children}</div>;

const DropdownHeader = ({ children }) => (
  <div className={style.header}>
    {children}
    <HeaderElement className={style.dropdownContainer} />
  </div>
);

const HeaderElement = ({ children, id, title, onSortChange = null, order = null, className = '' }) => (
  <div className={classNames(style.headerElement, className)} onClick={onSortChange}>
    {title && <span>{title}</span>}
    {children && <>{children}</>}
    {onSortChange && <SkeepersIcon className={classNames(style.sortingIcon, { [style.sortingSelection]: order })} type={getIconType(id, order, id)} />}
  </div>
);

const Row = ({ children, className = '', ...props }) => (
  <Block className={classNames(style.row, className)} padding="SMALL" {...props}>
    {children}
  </Block>
);

const DropdownIconElement = ({ opened }) => (
  <RowElement className={classNames(style.dropdownIcon, style.dropdownContainer)}>
    <SkeepersIcon type={opened ? 'CHEVRON_UP' : 'CHEVRON_DOWN'} color={'var(--layout-primary-color)'} />
  </RowElement>
);

const DropdownRow = ({ opened = false, toggle = () => {}, dropdown, children }) => (
  <Block padding="SMALL">
    <div className={style.row} onClick={toggle}>
      {children}
      <DropdownIconElement opened={opened} />
    </div>
    {dropdown && opened && (
      <>
        <Divider />
        {dropdown}
      </>
    )}
  </Block>
);

const RowElement = ({ children, className = '', onClick = null, leftBorder = true }) => (
  <div className={classNames(style.rowElement, { [style.leftBorder]: leftBorder }, className)} onClick={onClick}>
    {children}
  </div>
);

DropdownTable.Header = DropdownHeader;
DropdownTable.Row = DropdownRow;
DropdownTable.RowElement = RowElement;
DropdownTable.HeaderElement = HeaderElement;

Table.Row = Row;
Table.RowElement = RowElement;
Table.Header = Header;
Table.HeaderElement = HeaderElement;

Table.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired, //used a key to render the column
      name: PropTypes.string,
      path: PropTypes.string, //used to extract the property from the data
      Component: PropTypes.func, //component to render in place of the default
      breakpoint: PropTypes.oneOf(['s', 'm', 'l']),
      sortable: PropTypes.bool,
      sortBy: PropTypes.func,
    }).isRequired
  ),
  sortParams: PropTypes.shape({
    sort: PropTypes.string.isRequired,
    order: PropTypes.string,
  }),
  page: PropTypes.number,
  dropdown: PropTypes.func,
  data: PropTypes.array,
};
