import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import Flex from '@components/Common/Flex';

import style from './style.module.css';

const Switch = forwardRef(({
  value,
  name,
  placeholder,
  title,
  readOnly = false,
  className = '',
  onEdit = () => { },
  dataTestId = '',
}, ref) => {
  return (
    <Flex alignItems="CENTER" justifyContent="FLEX-START">
      <div className={`${style.button} ${style.r} ${className}`}>
        <input
          id={name}
          type="checkbox"
          data-testid={dataTestId}
          readOnly={readOnly}
          disabled={readOnly}
          className={style.checkbox}
          checked={(value === null ? placeholder : value) || false}
          onChange={({ target: { checked } }) => onEdit(checked)}
          ref={ref}
        />
        <div className={style.knobs}></div>
        <div className={style.layer}></div>
      </div>
      {title && <p className={style.title}>{title}</p>}
    </Flex>
  );
});

Switch.displayName = 'Switch';
Switch.transform = (value, required) => (required ? !!value : value);
Switch.customRequired = (value) => [true, false].includes(value);

export default Switch;

Switch.propTypes = {
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  onEdit: PropTypes.func,
};
