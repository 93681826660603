import { SMART_UPLOADER_URL } from '@app/config';
import { durationInMinutes } from '@app/utils';

export const smartUploaderOrientation = {
  horizontal: 'HORIZONTAL',
  vertical: 'VERTICAL',
};

export const sequenceType = {
  audio: 'AUDIO',
  picture: 'PICTURE',
  video: 'VIDEO',
};

export const getVideoInstructionExamples = (t) => [
  t('Stabilize your camera or use a tripod'),
  t('Shot the step horizontally'),
  t('Take care of the framing and avoid backlighting'),
  t('Check the background, it must be clean and tidy'),
];

export const getAudioInstructionExamples = (t) => [
  t('Record your voice in a quiet place'),
  t('Speak clearly and distinctly'),
];

export const displaySequenceInterval = ({ split, durationMin = 0, durationMax = 0 } = {}) => {
  const splitValue = split?.value || 1;

  return `${durationInMinutes(Math.round(durationMin / splitValue))} - ${durationInMinutes(Math.round(durationMax / splitValue))}`;
};

export const getTemplateInterval = (template) => {
  if (Array.isArray(template.scheme?.mainTimeline) && template.scheme.mainTimeline.length) {
    const durationMin = template.scheme.mainTimeline.reduce((acc, elem) => {
      return acc + (elem.sequence?.durationMin / (elem.split?.value || 1) || 0);
    }, 0);
    const durationMax = template.scheme.mainTimeline.reduce((acc, elem) => {
      return acc + (elem.sequence?.durationMax / (elem.split?.value || 1) || 0);
    }, 0);
    return `${durationInMinutes(Math.round(durationMin))} - ${durationInMinutes(Math.round(durationMax))}`;
  } else {
    return 0;
  }
}

export const SMART_UPLOADER_POLICY = {
  private: 'PRIVATE',
  embed: 'EMBED'
};

export const getSmartUploaderUrl = (solution = {}) => {
  const url = new URL(SMART_UPLOADER_URL);

  url.searchParams.append('solutionId', solution.id);

  if (solution.config?.policy === SMART_UPLOADER_POLICY.private) {
    url.searchParams.append('params', JSON.stringify({ meta: { PRODUCT_ID: 'test-product-id' } }));
  }

  return url.toString();
}
