import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonContainer from '@components/Common/ButtonContainer';
import { Form, useForm } from '@components/FormElement/Form';

const CommentFeelingForm = ({ companies = [], onSubmit = () => {}, isLoading = false }) => {
  const { t } = useTranslation();
  const methods = useForm();

  return (
    <Form onSubmit={onSubmit} methods={methods} isUpdating={isLoading}>
      <Form.Label title={t('Company')} name="company">
        <Form.Select
          name="company"
          options={companies.map((company) => ({
            label: company.name,
            value: company.name,
          }))}
        />
      </Form.Label>
      <Form.Label title={t('Message')} name="message">
        <Form.Textarea name="message" defaultValue={''} required />
      </Form.Label>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default CommentFeelingForm;

CommentFeelingForm.propTypes = {
  companies: PropTypes.array,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};
