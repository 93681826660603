import PropTypes from 'prop-types';

import { ReactComponent as NoDataSvg } from './assets/noData.svg';
import style from './style.module.css';

const NoDataMessage = ({ message = '' }) => (
  <div className={style.root}>
    <h2 className={style.message}>{message}</h2>
    <NoDataSvg className={style.svg} />
  </div>
);

export default NoDataMessage;

NoDataMessage.propTypes = {
  message: PropTypes.string,
};
