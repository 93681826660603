import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import ROUTES from '@app/routes';
import Block from '@components/Common/Block';
import Breadcrumb from '@components/Common/Breadcrumb';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import BulkImportForm from '@components/Form/BulkImportForm';
import DistributionsSolutionsForm from '@components/Form/DistributionsSolutionsForm/DistributionsSolutionsForm';
import UploadInput from '@components/FormElement/UploadInput';
import { useNotificationModal } from '@contexts/NotificationModalContext';
import { useRedirect } from '@contexts/RedirectContext';
import { useWindows, WINDOW_TYPES } from '@contexts/WindowContext';
import { useGetCompany } from '@hooks/companies';
import { useImportExternalVideos } from '@hooks/externalVideo';
import { useExtractFromCsv } from '@hooks/extractFromCsv';

const ExternalVideosImportPage = () => {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const { redirect } = useRedirect();
  const { addNotification } = useNotificationModal();
  const { actions } = useWindows();

  const [dataTable, setDataTable] = useState(null);
  const [headerWithPlayer, setHeaderWithPlayer] = useState(null);

  const { company } = useGetCompany(companyId);
  const distributionsSolutions = company?.solutions.filter((s) => s.type === 'PLAYER' || s.type === 'VSG');

  const externalVideosPageUrl = ROUTES.EXTERNAL_VIDEO.path({ companyId });

  // Same order as the excel file
  const headersOrdered = ['youtube video url', 'product ref', 'channels'];
  const configuration = [
    { label: t('Youtube URL'), value: 'externalVideoURL', type: 'externalVideoURL', isRequired: true },
    { label: t('Product reference(s)'), value: 'productReferences', type: 'STRING' },
    { label: t('Channel(s)'), value: 'distributions', type: 'STRING' },
  ];

  const headerValues = [
    { value: 'youtube video url', configurationName: t('Youtube URL') },
    { value: 'product ref', configurationName: t('Product reference(s)') },
    { value: 'distributions', configurationName: t('Channel(s)') },
  ];

  const [{ loading, error, data, headers, file }, loadFile] = useExtractFromCsv(headersOrdered);
  const [importExternalVideos, { isUpdating }] = useImportExternalVideos(companyId, {
    onCompleted: ({ importExternalVideos: results }) => {
      let message = `${results.importSuccess} successful imports`;
      if (results.importFailed) {
        message += ` and ${results.importFailed} failed`;
      }
      if (results.importSkip) {
        message += ` and ${results.importSkip} skipped`;
      }
      addNotification({
        title: t('External videos import with success'),
        message: t(message),
        type: 'success',
      });
      redirect(externalVideosPageUrl);
    },
    onError: () => {
      addNotification({
        title: t('Error during videos importation'),
        message: t('An error occured during the importation of external videos'),
        type: 'alert',
      });
    },
  });

  // Need to add an extra columns in data for the channels
  // And need to add the new header "Channels" at the last position
  if (data && headers && !dataTable && !headerWithPlayer) {
    if (distributionsSolutions?.length) {
      setDataTable(data.map((d) => [...d, '']));
      setHeaderWithPlayer([...headers, { name: 'distributions', position: headers.length }]);
    } else {
      setDataTable(data);
      setHeaderWithPlayer(headers);
    }
  }
  if (!data && !headers && dataTable && headerWithPlayer) {
    setDataTable(null);
    setHeaderWithPlayer(null);
  }

  const mapDistributionsByName = (distributions) =>
    distributions?.split('; ').map((dist) => distributionsSolutions.find((solution) => solution.name === dist).id);
  const mapDistributionsById = (distributions) => distributions.map((dist) => distributionsSolutions.find((solution) => solution.id === dist).name).join('; ');

  const _updateList = (values) => setDataTable(dataTable.map((d) => [...d.slice(0, -1), values]));

  const _bulkFormSubmit = (values) => {
    importExternalVideos(
      values.map((value) => {
        const distributions = mapDistributionsByName(value.distributions);
        delete value.distributions;
        return {
          ...value,
          distributions,
        };
      })
    );
  };

  const _updateRow = (indexRow, values) => {
    setDataTable((oldData) =>
      distributionsSolutions?.length
        ? oldData.map((d, index) => (indexRow === index ? [values.externalVideoURL, values.productReferences, values.distributions] : d))
        : oldData.map((d, index) => (indexRow === index ? [values.externalVideoURL, values.productReferences] : d))
    );
  };

  const _updateData = (normalizedVideo, index) => {
    actions.open(WINDOW_TYPES.UPSERT_EXTERNALVIDEO_WINDOW, {
      video: {
        projectUrl: normalizedVideo.externalVideoURL,
        products: normalizedVideo.productReferences?.split(';').map((reference) => ({ reference })),
        distributions: mapDistributionsByName(normalizedVideo.distributions),
      },
      distributionsSolution: distributionsSolutions,
      onSubmit: (values) => {
        actions.loading();
        _updateRow(index, {
          externalVideoURL: values.url,
          productReferences: values.productReferences?.join(';'),
          distributions: mapDistributionsById(values.distributors),
        });
        actions.close();
      },
    });
  };

  return (
    <>
      <Breadcrumb history={[{ name: t('External Videos'), url: externalVideosPageUrl }, { name: t('Import external videos') }]} />
      <Block id="top" title={t('Import videos')} subtitle={t('Please upload a file (expected file type: CSV or XLSX)')}>
        <UploadInput value={file} onEdit={loadFile} local filenamesMode="HIDDEN" />
        {error && <p>{t('Invalid file')}</p>}
        {loading && (
          <div>
            <SkeepersIcon type="SPINNER" color="var(--layout-primary-color)" spin />
            {t('Reading file contents...')}
          </div>
        )}
      </Block>
      {dataTable && headerWithPlayer && distributionsSolutions?.length > 0 && (
        <DistributionsSolutionsForm distributionsSolutions={distributionsSolutions} onFormSubmit={_updateList} />
      )}
      {dataTable && headerWithPlayer && (
        <BulkImportForm
          importType="EXTERNAL_VIDEOS"
          config={configuration}
          headerValues={headerValues}
          isUpdating={isUpdating}
          onSubmit={_bulkFormSubmit}
          data={dataTable}
          headers={headerWithPlayer}
          onUpdateData={_updateData}
        />
      )}
    </>
  );
};

export default ExternalVideosImportPage;
