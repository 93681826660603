import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ROUTES from '@app/routes';
import Block from '@components/Common/Block';
import Button from '@components/Common/Button';
import Flex from '@components/Common/Flex';
import LabelAction from '@components/Common/LabelAction';
import { useRedirect } from '@contexts/RedirectContext';
import { useWindows, WINDOW_TYPES } from '@contexts/WindowContext';

import style from './style.module.css';

export const ValidateButton = ({ as: Component = Button, action, disabled }) => {
  const { t } = useTranslation();

  return (
    <Component label={t('Validate')} color="var(--color-success)" className={style.buttonPadding} action={action} disabled={disabled} />
  );
}

export const SignalButton = ({ as: Component = Button, action, disabled }) => {
  const { t } = useTranslation();

  return (
    <Component label={t('Signal')} color={'var(--tag-orange)'} className={style.buttonPadding} action={action} disabled={disabled} />
  );
}

const WorkflowValidationBlock = ({ onExit = () => {}, children }) => {
  const { t } = useTranslation();
  const { actions } = useWindows();
  const { redirect } = useRedirect();

  return (
    <Block padding="SMALL">
      <Flex>{children}</Flex>
      <Flex justifyContent="CENTER" className={style.quit}>
        <LabelAction
          icon="DOOR"
          text={t('Quit')}
          action={() =>
            actions.open(WINDOW_TYPES.WORKFLOW_EXIT, {
              onExit: () => {
                onExit();
                redirect(ROUTES.WORKFLOW_OVERVIEW_OPERATOR.path());
              },
            })
          }
        />
      </Flex>
    </Block>
  );
};

export default WorkflowValidationBlock;

WorkflowValidationBlock.propTypes = {
  onFinish: PropTypes.func,
  onExit: PropTypes.func,
};
