import { useQuery, useMutation } from '@apollo/client';

import CHANGELOGS from '@graphql/changelogs/changelogs.graphql';
import CREATE_CHANGELOG from '@graphql/changelogs/createChangelog.graphql';
import DELETE_CHANGELOG from '@graphql/changelogs/deleteChangelog.graphql';
import MARK_CHANGELOG_AS_READ from '@graphql/changelogs/markChangelogAsRead.graphql';
import UPDATE_CHANGELOG from '@graphql/changelogs/updateChangelog.graphql';

export const useMarkChangelogAsRead = () => {
  const [mutate, { data, error, loading }] = useMutation(MARK_CHANGELOG_AS_READ);

  const updateChangelogUser = (changelogId) => {
    return mutate({
      variables: {
        input: {
          changelogId,
        },
      },
    });
  };

  return [updateChangelogUser, { changelog: data?.markChangelogAsRead, loading, error }];
};

const addType = (array, type) => {
  return (array || []).map((element) => ({ ...element, type }));
};

export const useCreateChangelog = ({ first, after, last, before, onCompleted = () => {} }) => {
  const [mutate, { data, error, loading }] = useMutation(CREATE_CHANGELOG, {
    onCompleted,
    refetchQueries: [{ query: CHANGELOGS, variables: { first, after, last, before } }],
  });

  const createChangelog = ({ availableAt, features, bugfixes }) => {
    const details = [...addType(features, 'FEATURE'), ...addType(bugfixes, 'BUGFIX')];
    return mutate({
      variables: {
        input: {
          changelogInput: {
            availableAt: availableAt || null,
            details,
          },
        },
      },
    });
  };

  return [createChangelog, { changelog: data?.changelog, updating: loading, error }];
};

export const useUpdateChangelog = ({ first, after, last, before, onCompleted = () => {} }) => {
  const [mutate, { data, error, loading }] = useMutation(UPDATE_CHANGELOG, {
    onCompleted,
    refetchQueries: [{ query: CHANGELOGS, variables: { first, after, last, before } }],
  });

  const updateChangelog = ({ availableAt, features, bugfixes }, changelogId) => {
    const details = [...addType(features, 'FEATURE'), ...addType(bugfixes, 'BUGFIX')];

    return mutate({
      variables: {
        input: {
          changelogInput: {
            availableAt,
            details,
          },
          changelogId: changelogId,
        },
      },
    });
  };

  return [updateChangelog, { changelog: data?.changelog, updating: loading, error }];
};

export const useGetChangelogs = ({ first, after, last, before }) => {
  const { data, loading, error, fetchMore } = useQuery(CHANGELOGS, {
    variables: { first, after, last, before },
    notifyOnNetworkStatusChange: true,
  });

  return { data: data?.changelogs, loading, error, fetchMore };
};

export const useDeleteChangelog = ({ first, after, last, before, onCompleted = () => {} }) => {
  const [mutate, { data, error, loading }] = useMutation(DELETE_CHANGELOG, {
    onCompleted,
    refetchQueries: [{ query: CHANGELOGS, variables: { first, after, last, before } }],
  });

  const deleteChangelog = (changelogId) => {
    return mutate({
      variables: {
        input: {
          changelogId: changelogId,
        },
      },
    });
  };

  return [deleteChangelog, { data: data?.deleteChangelog, isUpdating: loading, error }];
};
