import { useQuery, useMutation } from '@apollo/client';
import isEqual from 'lodash.isequal';
import { useState, useMemo, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { Mixpanel } from '@app/mixpanel';
import ROUTES from '@app/routes';
import { Page500, Page404 } from '@components/Layout/PageError';
import PageLoading from '@components/Layout/PageLoading';
import Timeline from '@components/Studio/Timeline';
import WorkflowBriefBlock from '@components/Workflows/WorkflowBriefBlock';
import { WorkflowLayout } from '@components/Workflows/WorkflowLayout/Layout';
import SideBar from '@components/Workflows/WorkflowLayout/Sidebar';
import WorkflowNotesBlock from '@components/Workflows/WorkflowNotesBlock';
import WorkflowTimer from '@components/Workflows/WorkflowTimer';
import WorkflowValidationBlock, { SignalButton, ValidateButton } from '@components/Workflows/WorkflowValidationBlock';
import { useRedirect } from '@contexts/RedirectContext';
import { useWindows, WINDOW_TYPES } from '@contexts/WindowContext';
import { useAcquireOutsourcingTask } from '@hooks/outsourcing/task';
import { projectStatuses, useSetProjectStatus } from '@hooks/projects';
import { useTimer } from '@hooks/useTimer';
import { useFinishTask, useOpenBriefWindow, useResetTask } from '@hooks/workflow';

import ADD_PROJECT_TIMELINE from './addProjectTimeline.graphql';
import GET_OUTSOURCING_TASK from './getOutsourcingTaskWithActions.graphql';

const WorkflowStudio = ({ taskId }) => {
  const { redirect } = useRedirect();
  const {
    loading: getTaskLoading,
    error: getTaskError,
    data: getTaskData,
  } = useQuery(GET_OUTSOURCING_TASK, {
    fetchPolicy: 'network-only',
    variables: { id: taskId },
  });

  // Prevent operators from working unexpectingly on a task already DONE
  if (getTaskData?.getOutsourcingTask?.status === 'DONE') {
    redirect(ROUTES.WORKFLOW_OVERVIEW_OPERATOR.path());
  }

  const [isEnded, setIsEnded] = useState(false);
  const [addProjectTimeline, { loading: addProjectTimelineLoading }] = useMutation(ADD_PROJECT_TIMELINE);
  const project = getTaskData?.getOutsourcingTask?.project;
  const previousActions = useMemo(() => getTaskData?.getOutsourcingTask?.actions || [], [getTaskData]);
  const { actions } = useWindows();
  const [preventKeyboardShortcuts, setPreventKeyboardShortcuts] = useState(false);
  const [error, setError] = useState(false);
  const [updateStatus] = useSetProjectStatus(project?.id);
  const [timeline, setTimeline] = useState(null);

  const { timer, time, onPause } = useTimer({
    taskId,
    previousActions,
    onPause: () => setPreventKeyboardShortcuts(true),
    onResume: () => setPreventKeyboardShortcuts(false),
    skip: !project,
  });

  const { resetTask } = useResetTask(taskId);

  const { noteText, onFinish, onNoteEdit } = useFinishTask({
    taskId,
    error,
    onValidate: async () => {
      await handleSaveTimeline(
        timeline.map((element) => ({
          duration: element.duration,
          inputId: element.inputId,
          line: element.line,
          position: element.position,
          speed: element.speed,
          start: element.start,
          volume: element.volume,
        })),
        true,
        true
      );
      updateStatus(projectStatuses.PENDING_METADATA);
    },
    onSignal: () => {
      handleSaveTimeline(
        timeline.map((element) => ({
          duration: element.duration,
          inputId: element.inputId,
          line: element.line,
          position: element.position,
          speed: element.speed,
          start: element.start,
          volume: element.volume,
        })),
        false,
        true
      );
    },
    onCancel: () => {
      timer?.start();
      setPreventKeyboardShortcuts(false);
    },
    onOpen: () => {
      timer?.stop();
      setPreventKeyboardShortcuts(true);
    },
  });

  const setTimeLine = (value) => {
    setTimeline(value);
  };

  useEffect(() => {
    return () => {
      actions.close();
    };
  }, [actions]);

  // when the user close the tab or the window
  useEffect(() => {
    window.addEventListener('beforeunload', resetTask);

    return () => {
      window.removeEventListener('beforeunload', resetTask);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onBriefClick = useOpenBriefWindow({ project, type: 'EDITING', error: getTaskError });

  const handleAcquired = (res) => {
    const task = res.acquireOutsourcingTask;
    if (task && task.id) {
      redirect(ROUTES.WORKFLOW_TASK_STUDIO.path({ taskId: task.id }));
    } else {
      redirect(ROUTES.WORKFLOW_OVERVIEW_OPERATOR.path());
    }
  };

  const [acquireOutsourcingTask] = useAcquireOutsourcingTask({ onCompleted: handleAcquired });

  const handleSaveTimeline = async (timeline, exported, ended) => {
    setIsEnded(ended);
    if (!getTaskError) {
      // don't save the timeline if there is error while getting the task
      // no task => no timeline to save
      await addProjectTimeline({ variables: { timeline, projectId: project.id, render: exported } });
    }
    if (ended) {
      Mixpanel.track('CV_PROJECT_EDITING_END', {
        taskId,
        date: new Date().toString(),
        projectId: project.id,
      });
      acquireOutsourcingTask('VIDEO_EDITING');
    }
  };

  if (getTaskLoading || (addProjectTimelineLoading && isEnded)) return <PageLoading />;
  if (!project) return <Page404 />;

  const timelines = [...project.timelines].sort((a, b) => b.version - a.version);

  // Find the latest version
  const lastVersion = timelines.length > 0 ? timelines[0].version : null;

  const lastExported = timelines.find(({ exported }) => exported)?.timeline;

  // Retrieve the latest timeline from timeline's project or use an empty timeline if none are found
  let timelineObject = timelines?.find((timeline) => timeline.version === lastVersion) || { timeline: [] };

  // Change all duration in milliseconds and add an id for each element
  timelineObject = {
    ...timelineObject,
    timeline: timelineObject.timeline.map((elem) => ({
      ...elem,
      id: uuidv4(),
    })),
  };

  if (timeline === null) {
    setTimeLine(timelineObject.timeline);
  }

  const onSave = () => {
    handleSaveTimeline(
      timeline.map((element) => ({
        duration: element.duration,
        inputId: element.inputId,
        line: element.line,
        position: element.position,
        speed: element.speed,
        start: element.start,
        volume: element.volume,
      })),
      false,
      false
    );
  };

  /**
   * If the user decide to exit the application without finishing the task
   * We must reset the task status
   */
  const onExit = () => {
    resetTask();
    timer?.stop();
  };

  return (
    <div>
      <WorkflowLayout
        menu={
          <SideBar>
            <WorkflowTimer
              time={time}
              onPause={() => {
                onPause();
              }}
              isPlaying={timer?.isPlaying()}
            />
            <WorkflowBriefBlock
              onBriefClick={onBriefClick}
              onShortcutClick={() => actions.open(WINDOW_TYPES.STUDIO_SHORTCUTS, {})}
              checklist={getTaskData?.getOutsourcingTask?.checklist}
            />
            <WorkflowNotesBlock
              text={noteText}
              onEdit={onNoteEdit}
              onBlur={() => setPreventKeyboardShortcuts(false)}
              onFocus={() => setPreventKeyboardShortcuts(true)}
            />
            <WorkflowValidationBlock onExit={onExit}>
              <SignalButton action={onFinish('SIGNAL')} />
              <ValidateButton
                action={onFinish('VALIDATION')}
                disabled={
                  error ||
                  isEqual(
                    lastExported,
                    timeline?.map(({ id, ...e }) => e)
                  )
                }
              />
            </WorkflowValidationBlock>
          </SideBar>
        }
      >
        {getTaskError ? (
          <Page500 />
        ) : (
          <Timeline
            workflows={true}
            preventKeyboardShortcuts={preventKeyboardShortcuts}
            timelines={timelines?.map((timeline) => ({ ...timeline, current: lastVersion === timeline.version }))}
            timelineObject={timelineObject}
            ratio={project.ratio}
            inputs={project?.inputs?.map((input) => ({
              id: input?.id,
              defaultFrame: input?.defaultFrame,
              normalizedUrl: input?.normalizedUrl,
              originalUrl: input?.originalUrl,
              normalizedPreviewUrl: input?.normalizedPreviewUrl,
              isRendering: input?.isRendering,
              duration: input?.duration,
              filename: input?.filename,
              type: input?.type,
              __typename: input?.__typename,
            }))}
            updateInputTitle={() => {}}
            onSave={onSave}
            onExport={() => {}}
            onChange={(timeline, infos) => {
              setTimeLine(timeline);
              setError(infos.withGap || infos.empty);
            }}
            onShortcuts={() => {}}
            onEditingBrief={() => {
              actions.open(WINDOW_TYPES.EDITING_BRIEF, { editingBrief: project?.solution?.editingBrief });
            }}
            projectId={project.id}
            onPlay={() => timer?.stopIdle()}
            onPause={() => timer?.startIdle()}
          />
        )}
      </WorkflowLayout>
    </div>
  );
};

export default WorkflowStudio;
