import PropTypes from 'prop-types';

import Action from '@components/Common/Action';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import { icons } from '@components/Common/SkeepersIcon/icons';

import style from './style.module.css';

const themeClassNames = {
  PRIMARY: style.primary,
  SECONDARY: style.secondary,
};

const SquaredButton = ({ type = 'PRIMARY', id = '', icon = '', className = '', title = '', onClick = () => {} }) => {
  const themeClassName = themeClassNames[type] || themeClassNames.PRIMARY;
  return (
    <Action action={onClick}>
      <div id={id} className={`${className} ${style.button} ${themeClassName} `}>
        <SkeepersIcon className={style.icon} type={icon} color={type === 'PRIMARY' ? '#fff' : 'var(--layout-primary-color)'} title={title} />
      </div>
    </Action>
  );
};

SquaredButton.propTypes = {
  type: PropTypes.oneOf(['PRIMARY', 'SECONDARY']),
  icon: PropTypes.oneOf(Object.keys(icons)).isRequired,
  title: PropTypes.string,
};

export default SquaredButton;
