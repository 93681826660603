/* eslint-disable */
import { v4 as uuidv4 } from 'uuid';
import isEqual from 'lodash/isEqual';

export class TimelineJS {
  constructor(timeline = [], options = { gridSize: 25, scaleX: 32, scaleY: 32, clientWidth: 1920, onChange: () => {}, timelineId: uuidv4() }) {
    this._timelineId = options.timelineId;
    this._gridSize = options.gridSize;
    this._scaleX = options.scaleX;
    this._scaleY = options.scaleY;
    this._ctrlKeyPressed = false;
    this._clientWidth = options.clientWidth;
    this._cursor = 0;
    this._magnet = true;
    this._onChange = options.onChange;
    this._timeline = timeline.map((element) => ({
      ...{
        ...element,
        // Multiplies all seconds by this._gridSize to convert them to a frame
        position: Math.round(element.position * this._gridSize),
        duration: Math.round(element.duration * this._gridSize),
        maxDuration: Math.round(element.maxDuration * this._gridSize),
        start: Math.round(element.start * this._gridSize),
        isMoving: false,
        isResizing: false,
        selected: false,
        borderLeftWarning: false,
        borderRightWarning: false,
      },
    }));
    this._buffer = { x: 0, y: 0, initialY: 0, initialX: 0, timeline: this._timeline };
    this._historyIdx = 0;
    this._history = [this._timeline];
  }

  get scaleY() {
    return this._scaleY;
  }

  get scaleX() {
    return this._scaleX;
  }

  get history() {
    return this._history;
  }

  get historyIdx() {
    return this._historyIdx;
  }

  set onChange(callback) {
    this._onChange = callback;
  }

  set ctrlKeyPressed(bool) {
    this._ctrlKeyPressed = bool;
  }

  get magnet() {
    return this._magnet;
  }

  set cursor(time) {
    this._cursor = Math.round(time * this._gridSize);
  }

  get cursor() {
    return this._cursor / this._gridSize;
  }

  set timeline(newTimeline) {
    this._applyTimelineChange(
      newTimeline.map((element) => ({
        ...{
          ...element,
          // Multiplies all seconds by this._gridSize to convert them to a frame
          position: Math.round(element.position * this._gridSize),
          duration: Math.round(element.duration * this._gridSize),
          maxDuration: Math.round(element.maxDuration * this._gridSize),
          start: Math.round(element.start * this._gridSize),
          isMoving: false,
          isResizing: false,
          selected: false,
          borderLeftWarning: false,
          borderRightWarning: false,
        },
      }))
    );
  }

  get timeline() {
    return this._timeline.map((element) => {
      return {
        ...element,
        // Divide all values by this._gridSize to convert them to seconds
        position: element.position / this._gridSize,
        duration: element.duration / this._gridSize,
        start: element.start / this._gridSize,
        maxDuration: element.maxDuration / this._gridSize,
      };
    });
  }

  _applyTimelineChange(timeline, skipHistory = false) {
    this._timeline = timeline.map((element) => ({
      ...element,
      position: Math.round(element.position),
      duration: Math.round(element.duration),
      start: Math.round(element.start),
      maxDuration: Math.round(element.maxDuration),
    }));
    if (!skipHistory && this._needToSaveHistory(this._timeline)) {
      this._historyUpdate(this._timeline);
    }
    this._onChange();
  }

  _historyUpdate(timeline) {
    let history = [...this._history.reduce((acc, elem, i) => (i <= this._historyIdx ? [...acc, elem] : [...acc]), [])];
    history = [...history, timeline.map((e) => ({ ...e, isMoving: false, isResizing: false, selected: false }))];
    return {
      history: (this._historyIdx += 1),
      historyIdx: (this._history = history),
    };
  }

  _needToSaveHistory(timeline) {
    return (
      !timeline.find((element) => element.isMoving || element.isResizing) &&
      !isEqual(
        this._history[this._history.length - 1].map((e) => ({
          position: e.position,
          duration: e.duration,
          start: e.start,
          speed: e.speed,
          line: e.line,
          volume: e.volume,
        })),
        timeline.map((e) => ({
          position: e.position,
          duration: e.duration,
          start: e.start,
          speed: e.speed,
          line: e.line,
          volume: e.volume,
        }))
      )
    );
  }

  resetHistory() {
    this._history = [this._timeline];
    this._historyIdx = 0;
    this._onChange();
  }

  // Reset all internal properties
  _resetInternalProperties() {
    this._timeline = this._timeline.map((element) => ({
      ...element,
      isMoving: false,
      isResizing: false,
      selected: false,
      borderLeftWarning: false,
      borderRightWarning: false,
    }));
  }

  zoom(zoom) {
    const value = zoom < 5 ? 5 : zoom > 512 ? 512 : zoom;
    this._scaleX = value;
    this._onChange();
    return value;
  }

  // Add the value to the speed of all selected elements as long as the volume does not exceed 10 and does not fall below 0.5
  addToSpeedElement(value) {
    const newTimeline = this._timeline.map((element) => {
      if (element.selected) {
        let speed = (parseFloat(element.speed) || 1) + parseFloat(value);
        if (speed < 0.5) speed = 0.5;
        if (speed > 10) speed = 10;
        return {
          ...element,
          speed,
          duration: Math.round((element.duration * element.speed) / speed),
          maxDuration: Math.round((element.maxDuration * element.speed) / speed),
        };
      }
      return element;
    });

    // Cuts the overlapping elements
    this._applyTimelineChange(
      newTimeline.reduce((acc, elem) => {
        if (elem.selected) return this._cropElements(acc, elem);
        return [...acc];
      }, newTimeline)
    );
  }

  // Set the speed of all selected elements to the value. Min: 0.5, Max: 10
  setSpeedElement(value) {
    const newTimeline = this._timeline.map((element) => {
      if (element.selected) {
        let speed = parseFloat(value) || 1;
        if (speed < 0.5) speed = 0.5;
        if (speed > 10) speed = 10;
        return {
          ...element,
          speed,
          duration: Math.round((element.duration * element.speed) / speed),
          maxDuration: Math.round((element.duration * element.speed) / speed),
        };
      }
      return element;
    });

    // Cuts the overlapping elements
    this._applyTimelineChange(
      newTimeline.reduce((acc, elem) => {
        if (elem.selected) return this._cropElements(acc, elem);
        return [...acc];
      }, newTimeline)
    );
  }

  // Undoes the last changes by reapplying the previous timeline in the history
  undo() {
    if (this._historyIdx > 0) {
      this._timeline = this._history[this._historyIdx - 1];
      this._historyIdx -= 1;
      this._resetInternalProperties();
      this._onChange();
      return true;
    }
    return false;
  }

  // Redoes the last changes by reapplying the last timeline in the history
  redo() {
    if (this._history.length > this._historyIdx + 1) {
      this._timeline = this._history[this._historyIdx + 1];
      this._historyIdx += 1;
      this._resetInternalProperties();
      this._onChange();
      return true;
    }
    return false;
  }

  // Select all timeline elements
  selectAllElements() {
    this._resetInternalProperties();
    this._applyTimelineChange(
      this._timeline.map((element) => ({ ...element, selected: true })),
      true
    );
  }

  // Unselect all timeline elements
  unselectAllElements() {
    this._resetInternalProperties();
    this._applyTimelineChange(
      this._timeline.map((element) => ({ ...element, selected: false })),
      true
    );
  }

  // Activates or deactivates the magnetic effect of the elements.
  switchMagnet() {
    this._magnet = !this._magnet;
    this._onChange();
    return this._magnet;
  }

  // Set the volume of all selected elements
  volumeElement(value) {
    this._applyTimelineChange(
      this._timeline.map((element) => {
        if (element.selected) {
          const volume = Math.round((parseFloat(value) + Number.EPSILON) * 10) / 10;
          return { ...element, volume: volume > 1 ? 1 : volume < 0 ? 0 : volume };
        }
        return element;
      })
    );
  }

  // Increases the volume of all selected elements by 0.1 as long as the volume does not exceed 1
  volumeElementUp() {
    this._applyTimelineChange(
      this._timeline.map((element) => {
        if (element.selected) {
          const volume = Math.round((parseFloat(element.volume) + Number.EPSILON) * 10) / 10;
          return { ...element, volume: volume + 0.1 > 1 ? 1 : volume + 0.1 };
        }
        return element;
      })
    );
  }

  // Decreases the volume of all selected elements by 0.1 as long as the volume does not fall below 0
  volumeElementDown() {
    this._applyTimelineChange(
      this._timeline.map((element) => {
        if (!element.selected) {
          return element;
        }
        const volume = Math.round((parseFloat(element.volume) + Number.EPSILON) * 10) / 10;
        return { ...element, volume: volume - 0.1 < 0 ? 0 : volume - 0.1 };
      })
    );
  }

  // Mute all selected elements
  muteElement() {
    this._applyTimelineChange(
      this._timeline.map((element) => ({
        ...element,
        volume: element.selected && element.volume === 0 ? 1 : element.selected ? 0 : element.volume,
      }))
    );
  }

  // Duplicate all selected elements
  duplicateElement() {
    this._applyTimelineChange(
      this._timeline.reduce((acc, element) => {
        if (!element.selected) {
          return [...acc, element];
        }
        return [
          ...acc,
          element,
          {
            ...{ ...element, selected: false },
            id: uuidv4(),
            position: Math.max(...this._timeline.filter((e) => e.line === element.line).map((e) => e.position + e.duration)),
          },
        ];
      }, [])
    );
  }

  // Add a new input element
  addInputElement = (input) => {
    const maxDuration = parseFloat(input.duration);
    let useLine = Math.max(0, ...this._timeline.map((e) => e.line + 1));
    let usePosition = this._cursor;
    // For each line with at least one input element
    for (let i = 0; i < useLine; i++) {
      // If there is no elements overlapping with the new one starting at cursor position
      if (
        !this._timeline
          .filter((e) => e.line === i)
          .find(
            (e) =>
              (this._cursor >= e.position && this._cursor < e.position + e.duration) ||
              (this._cursor + maxDuration >= e.position && this._cursor + maxDuration < e.position + e.duration)
          )
      ) {
        // use this line
        useLine = i;
        break;
      } // else, use Max line + 1
    }

    this._applyTimelineChange([
      ...this._timeline,
      {
        duration: Math.round(maxDuration * this._gridSize),
        maxDuration: Math.round(maxDuration * this._gridSize),
        start: 0,
        inputId: input.id,
        type: input.type,
        name: input.filename,
        borderLeftWarning: false,
        borderRightWarning: false,
        position: usePosition,
        line: useLine,
        volume: 1,
        id: uuidv4(),
        selected: false,
        isMoving: false,
        isResizing: false,
        speed: 1,
      },
    ]);
  };

  // Delete all selected elements
  deleteElement() {
    this._applyTimelineChange(
      this._timeline.reduce((acc, element) => {
        if (element.selected) return [...acc];
        return [...acc, element];
      }, [])
    );
  }

  // Cuts all selected elements that match the cursor position and remove their left part
  cutElementAndRemoveBefore() {
    const selected = this._timeline.some((element) => element.selected);
    this._applyTimelineChange(
      this._timeline.map((element) => {
        if (element.selected || !selected) {
          const time = this._cursor - element.position;
          if (time < 1 || element.duration - time < 1) return element;
          return {
            ...element,
            id: uuidv4(),
            start: element.start + time,
            duration: element.duration - time,
            position: element.position + time,
          };
        }
        return element;
      })
    );
  }

  // Cuts all selected elements that match the cursor position and remove their right part
  cutElementAndRemoveAfter() {
    const selected = this._timeline.some((element) => element.selected);
    this._applyTimelineChange(
      this._timeline.map((element) => {
        if (element.selected || !selected) {
          const time = this._cursor - element.position;
          if (time < 1 || element.duration - time < 1) return element;
          return {
            ...element,
            duration: time,
          };
        }
        return element;
      })
    );
  }

  // Cuts all selected elements that match the cursor position
  cutElement() {
    const selected = this._timeline.some((element) => element.selected);
    this._applyTimelineChange(
      this._timeline.reduce((acc, element) => {
        if (element.selected || !selected) {
          const time = Math.round(this._cursor - element.position);
          if (time < 1 || element.duration - time < 1) return [...acc, element];
          return [
            ...acc,
            {
              ...element,
              duration: time,
            },
            {
              ...element,
              id: uuidv4(),
              start: Math.round(element.start + time),
              duration: Math.round(element.duration - time),
              position: Math.round(element.position + time),
            },
          ];
        }
        return [...acc, element];
      }, [])
    );
  }

  // Merge timeline elements that can be grouped together
  mergeTimeline(timeline = this._timeline) {
    const timelineSorted = [...timeline].sort((a, b) => a.position - b.position);

    let prevElem = false;

    const newTimeline = timelineSorted.reduce((acc, currElem) => {
      if (prevElem) {
        const prevElemEnd = prevElem.position + prevElem.duration;
        const prevElemTimeEnd = prevElem.start + prevElem.duration;
        if (
          prevElem.video === currElem.video &&
          prevElem.line === currElem.line &&
          prevElem.speed === currElem.speed &&
          prevElemEnd === currElem.position &&
          prevElemTimeEnd === currElem.start &&
          prevElem.volume === currElem.volume
        ) {
          const newAcc = acc.filter((e) => e.id !== prevElem.id);
          const result = { ...prevElem, duration: prevElem.duration + currElem.duration };
          prevElem = result;
          return [...newAcc, result];
        }
      }
      prevElem = currElem;
      return [...acc.filter((e) => e.id !== currElem.id), currElem];
    }, []);

    // TODO: Check if this line is needed.
    if (isEqual(timeline, this._timeline)) this._applyTimelineChange(newTimeline);
    return newTimeline;
  }

  // Applies the magnetic effect between close elements in the timeline
  _appliesMagneticEffect() {
    const padding = 250;
    let diff = 0;

    // Retrieves the beginning position of the first selected block and the end of the last selected block
    const boundStart = Math.min(...this._timeline.filter((e) => e.selected).map((e) => e.position));
    const boundEnd = Math.max(...this._timeline.filter((e) => e.selected).map((e) => e.position + e.duration));

    this._timeline
      .filter((e) => !e.selected)
      .forEach((element) => {
        const elementStart = element.position;
        const elementEnd = element.position + element.duration;

        // Condition that determines whether the start boundary is in the anchor area of the beginning of an unselected element
        if (
          (boundStart >= elementStart && boundStart * this._scaleX - padding <= elementStart * this._scaleX) ||
          (boundStart <= elementStart && boundStart * this._scaleX + padding >= elementStart * this._scaleX)
        ) {
          // Calc the gap to applied to anchor the selected elements
          diff = elementStart - boundStart;
        }
        // Condition that determines whether the start boundary is in the anchor area of the ending of an unselected element
        if (
          (boundStart >= elementEnd && boundStart * this._scaleX - padding <= elementEnd * this._scaleX) ||
          (boundStart <= elementEnd && boundStart * this._scaleX + padding >= elementEnd * this._scaleX)
        ) {
          diff = elementEnd - boundStart;
        }
        // Condition that determines whether the end boundary is in the anchor area of the beginning of an unselected element
        if (
          (boundEnd <= elementStart && boundEnd * this._scaleX + padding >= elementStart * this._scaleX) ||
          (boundEnd >= elementStart && boundEnd * this._scaleX - padding <= elementStart * this._scaleX)
        ) {
          diff = elementStart - boundEnd;
        }
        // Condition that determines whether the end boundary is in the anchor area of the ending of an unselected element
        if (
          (boundEnd <= elementEnd && boundEnd * this._scaleX + padding >= elementEnd * this._scaleX) ||
          (boundEnd >= elementEnd && boundEnd * this._scaleX - padding <= elementEnd * this._scaleX)
        ) {
          diff = elementEnd - boundEnd;
        }
      });

    // Applies the previously calculated value to the position of the selected elements
    this._applyTimelineChange(
      this._timeline.map((element) => {
        if (element.selected)
          return {
            ...element,
            position: Math.max(0, element.position + diff),
          };
        return element;
      })
    );
  }

  // Increase dest's start and position, decrease duration according to the src
  _cropLeft = (src, dest) => {
    const offSet = src.position + src.duration - dest.position;
    return {
      ...dest,
      position: src.position + src.duration,
      start: dest.start + offSet,
      duration: dest.duration - offSet,
    };
  };

  // Reduce dest's duration according to the src
  _cropRight = (src, dest) => ({ ...dest, duration: src.position - dest.position });

  // Cuts the overlapping elements according to one and returns a new timeline
  _cropElements(timeline, elem) {
    return timeline.reduce((acc, dest) => {
      if (elem.id !== dest.id && dest.line === elem.line) {
        if (dest.position < elem.position && dest.position + dest.duration > elem.position + elem.duration)
          return [...acc, { ...this._cropRight(elem, dest), id: uuidv4() }, { ...this._cropLeft(elem, dest), id: uuidv4() }];
        if (elem.position > dest.position && elem.position < dest.position + dest.duration) return [...acc, this._cropRight(elem, dest)];
        if (elem.position + elem.duration > dest.position && elem.position + elem.duration < dest.position + dest.duration)
          return [...acc, this._cropLeft(elem, dest)];
        if (elem.position <= dest.position && elem.position + elem.duration >= dest.position + dest.duration) return [...acc];
      }
      return [...acc, dest];
    }, []);
  }

  _calcMove = (position, line, buffer) => {
    let newLine = line;
    let newPosition = position;

    // Values
    let delta = Math.round(buffer.x * (this._gridSize / this._scaleX));

    // Update position
    newPosition += delta;

    // Out of timeline on left
    if (newPosition < 0) newPosition = 0;

    // Calc block relative position
    const changeY = (buffer.initialY + buffer.y - 0.1) / this._scaleY;

    // Based on block position apply positive or negative transform
    newLine += changeY < 0 ? ~~changeY - 1 : ~~changeY;

    // Reset out of range line
    if (newLine < 0) newLine = 0;

    // Return the difference
    return { position: newPosition - position, line: newLine - line };
  };

  // Finds the largest applicable movement on all selected elements
  _calcMaxMove = (buffer) => {
    let value = { position: 0, line: 0 };

    this._buffer.timeline
      .filter((e) => e.selected)
      .forEach((elem, id) => {
        const params = this._calcMove(elem.position, elem.line, buffer);
        if (id === 0) value = params;
        if (Math.abs(params.line) < Math.abs(value.line)) value.line = params.line;
        if (Math.abs(params.position) < Math.abs(value.position)) value.position = params.position;
      });

    return value;
  };

  pointerDownElement = (elementId, initialX, initialY) => {
    const element = this._timeline.find((input) => input.id === elementId);
    if (!element) return;

    this._applyTimelineChange(
      this._timeline.map((elem) => {
        // The chunk on which the user clicked
        if (elem.id === elementId) return { ...elem, selected: this._ctrlKeyPressed ? !elem.selected : true };
        // All other elements
        return { ...elem, selected: this._ctrlKeyPressed || element.selected ? elem.selected : false };
      })
    );

    this._buffer = {
      timeline: this._timeline,
      x: 0,
      y: 0,
      initialX,
      initialY,
    };
  };

  moveElement = (x, y) => {
    this._buffer = {
      ...this._buffer,
      x: this._buffer.x + x,
      y: this._buffer.y + y,
    };

    if (this._ctrlKeyPressed) return;

    // Retrieves the largest applicable move on the selected elements before one is out of range
    const value = this._calcMaxMove(this._buffer);

    // Applies the move on the selected elements
    this._applyTimelineChange(
      this._buffer.timeline.map((elem) => {
        if (elem.selected) return { ...elem, position: elem.position + value.position, line: elem.line + value.line, isMoving: true };
        return elem;
      })
    );

    if (this._magnet && value.position) this._appliesMagneticEffect();
  };

  pointerUpElement = (elementId) => {
    const hasMoved = !!this._timeline.find((e) => e.isMoving);

    // Cuts the overlapping elements
    this._applyTimelineChange(
      this._timeline.reduce(
        (acc, elem) => {
          if (elem.selected) return this._cropElements(acc, elem);
          return [...acc];
        },
        this._timeline.map((e) => ({
          ...e,
          selected: !hasMoved && e.id !== elementId && !this._ctrlKeyPressed ? false : e.selected,
          isMoving: false,
          isResizing: false,
          borderRightWarning: false,
          borderLeftWarning: false,
        }))
      )
    );
  };

  // Finds the largest applicable movement on all selected elements
  _calcResizeRight = (elementId, buffer) => {
    const { duration, maxDuration, start, type } = this._buffer.timeline.find((e) => e.id === elementId);
    let newDuration = duration;
    let borderRightWarning = false;

    const delta = Math.round(buffer.x * (this._gridSize / this._scaleX));

    if (delta < 0) {
      // Apply changes
      newDuration += delta;
      // Duration too short
      if (newDuration < 1) newDuration = 1;
    } else if (delta > 0) {
      // Apply changes
      newDuration += delta;
      // Out of file
      if (type !== 'IMAGE') {
        borderRightWarning = newDuration + start > maxDuration;
        if (newDuration + start > maxDuration) newDuration = maxDuration - start;
      }
    }

    return { position: 0, duration: newDuration - duration, start: 0, borderRightWarning };
  };

  _calcResizeLeft(elementId, buffer) {
    const { position, duration, start, type } = this._buffer.timeline.find((e) => e.id === elementId);
    let newPosition = position;
    let newDuration = duration;
    let newStart = start;
    let borderLeftWarning = false;

    const delta = Math.round(buffer.x * (this._gridSize / this._scaleX));

    let maxDelta = delta;

    if (delta < 0) {
      // Out of timeline
      if (position + maxDelta < 0) maxDelta = 0 - position;

      // Out of file
      if (type !== 'IMAGE') if (start + maxDelta < 0) maxDelta = 0 - start;

      // Apply changes
      newDuration -= maxDelta;
      newPosition += maxDelta;
      if (type !== 'IMAGE') newStart += maxDelta;

      // Extreme border
      borderLeftWarning = maxDelta !== delta;
    } else if (delta > 0) {
      // Duration too short
      if (duration - maxDelta < 1) {
        maxDelta = duration - 1;
      }

      // Apply changes
      newDuration -= maxDelta;
      newPosition += maxDelta;
      if (type !== 'IMAGE') newStart += maxDelta;
    }

    return { position: newPosition - position, duration: newDuration - duration, start: newStart - start, borderLeftWarning };
  }

  _resize(elementId, values) {
    // Applies the resize on the selected elements
    this._applyTimelineChange(
      this._buffer.timeline.map((elem) => {
        if (elem.id === elementId)
          return {
            ...elem,
            position: elem.position + values.position,
            duration: elem.duration + values.duration,
            borderLeftWarning: values.borderLeftWarning,
            borderRightWarning: values.borderRightWarning,
            start: elem.start + values.start,
            selected: true,
            isMoving: true,
            isResizing: true,
          };
        return elem;
      })
    );
  }

  resizeElementLeft(elementId, x) {
    this._buffer = {
      ...this._buffer,
      x: this._buffer.x + x,
    };

    // Retrieves the largest applicable resize on the selected elements before one is out of range
    const values = this._calcResizeLeft(elementId, this._buffer);

    this._resize(elementId, values);
  }

  resizeElementRight(elementId, x) {
    this._buffer = {
      ...this._buffer,
      x: this._buffer.x + x,
    };

    // Retrieves the largest applicable resize on the selected elements before one is out of range
    const values = this._calcResizeRight(elementId, this._buffer);

    this._resize(elementId, values);
  }

  pointerDownResizeElement(elementId) {
    const element = this._timeline.find((input) => input.id === elementId);
    if (!element) return;

    this._applyTimelineChange(
      this._timeline.map((elem) => {
        // The chunk on which the user clicked
        if (elem.id === elementId) return { ...elem, selected: true };
        // All other elements
        return { ...elem, selected: false };
      })
    );

    this._buffer = {
      timeline: this._timeline,
      x: 0,
      y: 0,
    };
  }

  checkGap() {
    if (!this._timeline.some((input) => input.position === 0)) {
      return false;
    }
    let [input, lastInput] = this._timeline.reduce((previous, current) => {
      if (!previous) {
        return [current, current];
      } else {
        return [previous[0].position > current.position ? current : previous[0], previous[1].position < current.position ? current : previous[1]];
      }
    }, null);

    while (input.position !== lastInput.position) {
      const lastInput = input;
      input = this._timeline.find((elem) => elem.position > lastInput.position && elem.position <= lastInput.position + lastInput.duration);
      if (!input) {
        return false;
      }
    }

    return true;
  }
}
