import classNames from 'classnames';
import PropTypes from 'prop-types';

import { icons, oldIcons } from '@components/Common/SkeepersIcon/icons.js';
import SquaredButton from '@components/Common/SquareButton';

import style from './style.module.css';

const Banner = ({ children, className = '', icon = 'ARROW_UP', iconTitle = 'Top', onClick = null }) => {
  return (
    <div className={classNames(style.bannerContainer, className)}>
      <div className={style.banner}>{children}</div>
      {onClick && icon && <SquaredButton className={style.buttonTop} icon={icon} title={iconTitle} onClick={onClick} />}
    </div>
  );
};

Banner.propTypes = {
  /** Children passed to the container */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  /** Add a className on the container */
  className: PropTypes.string,
  /** type of the icon for the square button */
  icon: PropTypes.oneOf(Object.keys(icons).concat(Object.keys(oldIcons))),
  /** title for the icon on hover */
  iconTitle: PropTypes.string,
  /** onClick for the square button */
  onClick: PropTypes.func,
};

export default Banner;
