import { useState } from 'react';

import { LayoutContext } from '@components/Workflows/WorkflowLayout/context';

import style from './style.module.css';

export const WorkflowLayout = ({ children, menu = null }) => {
  const [opened, setOpened] = useState(true);
  const toggle = () => setOpened((s) => !s);
  return (
    <LayoutContext.Provider value={{ opened, toggle }}>
      <div className={`${style.content} ${opened ? style.navLarge : style.navHidden} `}>{children}</div>
      {menu}
    </LayoutContext.Provider>
  );
};
