import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getStringInBytes, youtubeExportValidation } from '@app/utils/socialMedia';
import Breadcrumb from '@components/Common/Breadcrumb';
import Button from '@components/Common/Button';
import ButtonContainer from '@components/Common/ButtonContainer';
import { Grid } from '@components/Common/Grid';
import Text from '@components/Common/Text';
import { Form, useForm } from '@components/FormElement/Form';
import { useUploadSocialModal } from '@contexts/UploadSocialModalContext';

import style from './style.module.css';

const YoutubeForm = ({ video, onUpload = () => {}, cancelUpdate = () => {} }) => {
  const { t } = useTranslation();
  const { getMissedVariables, globalConfiguration, replaceVariables } = useUploadSocialModal();
  const methods = useForm();
  const [valuesWithTags, setValuesWithTags] = useState({ title: '', description: '' });
  const [preview, title, description] = methods.watch(['preview', 'title', 'description']);
  const readySubtitles = video.lastOutput?.subtitles?.filter((subtitle) => subtitle.status === 'READY');

  const _onUpload = () => {
    onUpload({
      ...video,
      ...methods.getValues(),
      ...(preview ? valuesWithTags : {}),
    });
  };

  const showPreview = (showVariables) => {
    if (showVariables) {
      // save values with tags
      setValuesWithTags({ title, description });
      methods.setValue('title', replaceVariables(video, title));
      methods.setValue('description', replaceVariables(video, description));
    } else {
      methods.setValue('title', valuesWithTags.title);
      methods.setValue('description', valuesWithTags.description);
    }
  };

  const checkEmptyValues = (field) => (value) => {
    const valueWithTags = preview ? valuesWithTags[field] : value;
    const missedVariables = getMissedVariables(video, valueWithTags);
    return (
      !globalConfiguration.templateId ||
      !missedVariables.length ||
      t('Used variables are empty for this project: {{variables}}. Please enter these values manually.', {
        variables: missedVariables.join(', '),
      })
    );
  };

  const validateTitle = {
    emptyVariables: checkEmptyValues('title'),
    length: (title) => youtubeExportValidation.title.length(replaceVariables(video, title)) || t('Title should contain less than 100 characters.'),
    content: (title) =>
      youtubeExportValidation.title.content(replaceVariables(video, title)) || t('Title should contain valid UTF-8 characters except < and >.'),
  };

  const validateDescription = {
    emptyVariables: checkEmptyValues('description'),
    length: (description) =>
      youtubeExportValidation.description.length(replaceVariables(video, description)) ||
      t(`Description is ${getStringInBytes(replaceVariables(video, description))} characters that is longer than Youtube limitation (5000)`),
    content: (description) =>
      youtubeExportValidation.description.content(replaceVariables(video, description)) ||
      t('Description should contain valid UTF-8 characters except < and >.'),
  };

  useEffect(() => {
    // to see empty values error on the first render
    methods.trigger();
  }, [methods]);

  return (
    <div className={style.formContainer}>
      <Breadcrumb className={style.breadcrumb} history={[{ name: t('Upload social media'), func: cancelUpdate }, { name: t('Edit video') }]} />
      <Form methods={methods} skipDirtyCheck onSubmit={_onUpload}>
        {globalConfiguration.templateId && (
          <Form.Label className={style.label} name="preview" title={t('Preview template values')}>
            <Form.Switch name="preview" defaultValue={false} onAfterChange={showPreview} />
          </Form.Label>
        )}
        <Form.Label className={style.label} name="title" title={t('Title')}>
          <Form.Input name="title" required defaultValue={video.title} disabled={preview} validate={validateTitle} />
        </Form.Label>
        <Form.Label className={style.label} name="description" title={t('Description')}>
          <Form.Textarea name="description" required defaultValue={video.description} disabled={preview} validate={validateDescription} />
        </Form.Label>
        <Form.UploadInput title={t('Thumbnail')} name="thumbnail" defaultValue={video.poster} filenamesMode="HIDDEN" validTypes={['IMAGE']} required />
        <Grid columns={2} className={style.grid}>
          <Form.Label name="tags" title={t('Tags')}>
            <Form.TagsSelect backgroundColor="#832DAB" foregroundColor="#ffffff" name="tags" defaultValue={video.tags} />
          </Form.Label>
          <Form.Label title={t('Privacy status')} name="privacyStatus">
            <Form.Select
              name="privacyStatus"
              options={[
                { label: t('Public'), value: 'Public' },
                { label: t('Private'), value: 'Private' },
                { label: t('Unlisted'), value: 'Unlisted' },
              ]}
              required
              defaultValue={video.privacyStatus}
            />
          </Form.Label>
        </Grid>
        <Grid columns={2} className={style.grid}>
          <Form.Label name="publicStatsViewable" title={t('Public stats viewable')}>
            <Form.Switch name="publicStatsViewable" defaultValue={video.publicStatsViewable} />
          </Form.Label>
          <Form.Label name="subtitlesActivated" title={t('Subtitles')}>
            <div className="d-flex align-center flex-3">
              <Form.Switch name="subtitlesActivated" defaultValue={video.subtitlesActivated} readOnly={!readySubtitles?.length} />
            </div>
            {!readySubtitles?.length && (
              <Text size="xsmall" color="var(--neutral-500)">
                {t('No subtitles available for this video yet.')}
              </Text>
            )}
          </Form.Label>
        </Grid>
        <ButtonContainer>
          <Button label={t('Back')} theme={Button.themes.SECONDARY} action={cancelUpdate} />
          <Form.SubmitButton label={t('Save')} />
        </ButtonContainer>
      </Form>
    </div>
  );
};

export default YoutubeForm;

YoutubeForm.propTypes = {
  video: PropTypes.object.isRequired,
  onUpload: PropTypes.func.isRequired,
  cancelUpdate: PropTypes.func.isRequired,
};
