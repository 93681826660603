import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import NormalizeOptionsForm from '@components/Form/NormalizeOptionsForm';
import Window from '@components/Window/Window';

const SelectNormalizeOptionsWindow = ({ action = () => {}, input = {}, isLoading = false, open = false, onClose = () => {} }) => {
  const { t } = useTranslation();

  return (
    <Window open={open} onClose={onClose} title={t('Normalize parameters')}>
      <NormalizeOptionsForm onSubmit={action} isUpdating={isLoading} input={input} />
    </Window>
  );
};

export default SelectNormalizeOptionsWindow;

SelectNormalizeOptionsWindow.propTypes = {
  action: PropTypes.func,
  input: PropTypes.object,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
