import PropTypes from 'prop-types';
import { useState } from 'react';

import { TabContext } from '@components/Common/Tabs/TabContext';

const Tabs = ({ defaultTab = null, children }) => {
  const [current, setCurrent] = useState(defaultTab);

  return <TabContext.Provider value={{ current, onChange: setCurrent }}>{children}</TabContext.Provider>;
};

Tabs.propTypes = {
  defaultTab: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Tabs;
