export const VIDEO_FPS = 25;

export const GRID = 1 / VIDEO_FPS;

export const formatTime = (time, addFrameNumber = false) => {
  const frames = addFrameNumber
    ? `.${Math.round((time % 1) * VIDEO_FPS)
        .toString()
        .padStart(2, '0')}`
    : '';
  return `${(~~(time / 60)).toString().padStart(2, '0')}:${(~~(time % 60)).toString().padStart(2, '0')}${frames}`;
};
