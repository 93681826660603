import classNames from 'classnames';
import PropTypes from 'prop-types';

import Flex from '@components/Common/Flex';
import Header from '@components/Common/Header';
import LabelAction from '@components/Common/LabelAction';
import Tag from '@components/Common/Tag';

import style from './style.module.css';

const directionClassNames = {
  HORIZONTAL: style.horizontal,
  VERTICAL: style.vertical,
};

const BlockTitles = ({ title = '', subtitle = '', tagText = '', tagColor = '', actions = [], actionsDirection = 'HORIZONTAL', className = '' }) => {
  const directionClassName = directionClassNames[actionsDirection] || '';

  if (title || subtitle) {
    return (
      <div className={`${style.group} ${className}`}>
        <div data-cy="search-block-title">
          <Flex alignItems="CENTER">
            {title && (
              <Header className={style.title} as={'h1'}>
                {title}
              </Header>
            )}
            {tagText && <Tag color={tagColor} value={tagText} />}
          </Flex>
          {subtitle && (
            <Header as="h2" className={style.subtitle}>
              {subtitle}
            </Header>
          )}
        </div>
        {actions && actions.length > 0 && (
          <div className={classNames(style.actions, directionClassName)}>
            {actions.map(({ icon, name, onClick, disabled }, n) => (
              <LabelAction icon={icon} text={name} onClick={onClick} disabled={disabled} key={n} />
            ))}
          </div>
        )}
      </div>
    );
  }
  return null;
};

BlockTitles.propTypes = {
  title: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  subtitle: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  tagText: PropTypes.string,
  tagColor: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      name: PropTypes.string,
      onClick: LabelAction.propTypes.onClick,
    })
  ),
  actionsDirection: PropTypes.oneOf(['HORIZONTAL', 'VERTICAL']),
  className: PropTypes.string,
};

export default BlockTitles;
