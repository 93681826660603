import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { getCompanyIdFromUrl } from '@app/utils';
import VideoListForm from '@components/Form/UploadSocialMedia/VideoListForm';
import YoutubeForm from '@components/Form/UploadSocialMedia/YoutubeForm';
import UploadSocialMediaAuth from '@components/Project/UploadSocialMedia/Auth/Select';
import UploadLoading from '@components/Project/UploadSocialMedia/UploadLoading';
import { useUploadSocialModal } from '@contexts/UploadSocialModalContext';
import { useBulkUploadYoutube } from '@hooks/projects/upload';

const UploadSocialMedia = ({ canUpload = {}, onEnded = () => {}, onClose = () => {} }) => {
  const { videos, updateVideos, replaceVariables } = useUploadSocialModal();
  const [loggedTo, setLoggedTo] = useState();
  const [tasks, setTasks] = useState(false);
  const [tasksToCheck, setTasksToCheck] = useState(false);
  const [isEditionMode, setIsEditionMode] = useState(false);
  const [videoEdit, setVideoEdit] = useState(null);

  const [bulkUploadYoutube] = useBulkUploadYoutube(loggedTo?.data, {
    onCompleted: ({ tasks }) => {
      setTasksToCheck(tasks);
    },
  });

  const onLogin = (socialMedia, data, playlists = []) => {
    setLoggedTo({
      type: socialMedia,
      data: data,
      playlists,
    });
  };

  const editForm = () => {
    switch (loggedTo.type) {
      case 'YOUTUBE':
        return <YoutubeForm video={videoEdit} credentials={loggedTo.data} onUpload={_onUpload} cancelUpdate={_cancelUpdate} />;
      default:
        return <div />;
    }
  };

  const _cancelUpdate = () => {
    setIsEditionMode(false);
    setVideoEdit(null);
  };

  const _onEditClick = (video) => {
    setVideoEdit(video);
    setIsEditionMode(true);
  };

  const _onUpload = (video) => {
    updateVideos([...videos.filter((v) => v.id !== video.id), video]);
    setIsEditionMode(false);
    setVideoEdit(null);
  };

  useEffect(() => {
    if (tasks) {
      bulkUploadYoutube(
        tasks.videos.map((video) => ({ ...video, title: replaceVariables(video, video.title), description: replaceVariables(video, video.description) })),
        tasks.playlistId,
        getCompanyIdFromUrl()
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tasks]);

  if (tasksToCheck) {
    return <UploadLoading tasks={tasksToCheck} onEnded={onEnded} />;
  }

  return (
    <>
      <UploadSocialMediaAuth onLogin={onLogin} onLogout={() => setLoggedTo(null)} />
      {loggedTo &&
        loggedTo.type &&
        (isEditionMode ? (
          editForm()
        ) : (
          <VideoListForm playlists={loggedTo.playlists} videosSource={videos} onEditClick={_onEditClick} onCancel={onClose} onSubmit={setTasks} />
        ))}
    </>
  );
};

export default UploadSocialMedia;

UploadSocialMedia.prototype = {
  canUpload: PropTypes.func,
  onEnded: PropTypes.func,
  onClose: PropTypes.func,
};
