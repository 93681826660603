(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("react"), require("prop-types"));
	else if(typeof define === 'function' && define.amd)
		define("@skeepers/react-keyboard-event-handler", ["react", "prop-types"], factory);
	else if(typeof exports === 'object')
		exports["@skeepers/react-keyboard-event-handler"] = factory(require("react"), require("prop-types"));
	else
		root["@skeepers/react-keyboard-event-handler"] = factory(root["react"], root["prop-types"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__156__, __WEBPACK_EXTERNAL_MODULE__99__) => {
return 