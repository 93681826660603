import { useTranslation } from 'react-i18next';

import AssignRoleForm from '@components/Form/AssignRoleForm';
import Window from '@components/Window/Window';

const AssignRoleWindow = ({ onSubmit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Assign role')} open={open} onClose={onClose}>
      <p>{t('Roles give users access to certain general-purpose actions, not linked to a particular resource. A user can have only one role.')}</p>
      <AssignRoleForm isUpdating={isLoading} onSubmit={onSubmit} />
    </Window>
  );
};

export default AssignRoleWindow;
