import { useQuery, useMutation, useLazyQuery, useSubscription } from '@apollo/client';
import { useEffect, useMemo } from 'react';

import { PAGINATION_DEFAULT } from '@app/config';
import GET_STATS_FOR_PROJECTS from '@graphql/campaign/campaign/getStatsForProjects.graphql';
import CAMPAIGN_PROJECTS from '@graphql/campaign/campaign/projects.graphql';
import ADD_PROJECT_INPUTS from '@graphql/projects/addProjectInputs.graphql';
import ADD_PROJECT_OUTPUT from '@graphql/projects/addProjectOutput.graphql';
import ADD_PROJECT_TIMELINE from '@graphql/projects/addProjectTimeline.graphql';
import CREATE_PROJECT from '@graphql/projects/createProject.graphql';
import DELETE_PROJECT from '@graphql/projects/deleteProject.graphql';
import DIFFUSION_STATS from '@graphql/projects/diffusionStats.graphql';
import DUPLICATE_PROJECT from '@graphql/projects/duplicateProject.graphql';
import GET_ALL_CARDS from '@graphql/projects/getAllCards.graphql';
import GET_COMPANY_FOR_SUBTITLE_PAGE from '@graphql/projects/getCompanyForSubtitlePage.graphql';
import GET_FAVORITE_PROJECTS from '@graphql/projects/getFavoriteProjects.graphql';
import GET_PRODUCT_AND_PROJECT_CATEGORIES from '@graphql/projects/getProductAndProjectCategories.graphql';
import GET_PROJECT from '@graphql/projects/getProject.graphql';
import GET_PROJECT_FOR_STUDIO from '@graphql/projects/getProjectForStudioPage.graphql';
import GET_PROJECT_FOR_SUBTITLE_PAGE from '@graphql/projects/getProjectForSubtitlePage.graphql';
import GET_PROJECT_HISTORIES from '@graphql/projects/getProjectHistories.graphql';
import GET_PROJECTS from '@graphql/projects/getProjects.graphql';
import GET_PROJECTS_BROADCASTING from '@graphql/projects/getProjectsBroadcasting.graphql';
import GET_TASKS from '@graphql/projects/getTasks.graphql';
import GET_TOP_PROJECTS from '@graphql/projects/getTopProjects.graphql';
import MERGE_PROJECTS from '@graphql/projects/mergeProjects.graphql';
import NORMALIZE_PROJECT_INPUT from '@graphql/projects/normalizeProjectInput.graphql';
import RENDER_PROJECT_OUTPUT from '@graphql/projects/renderProjectOutput.graphql';
import RETRY_TASK from '@graphql/projects/retryTask.graphql';
import SET_PROJECT_BOOST from '@graphql/projects/setProjectBoost.graphql';
import SET_PROJECT_FAVORITE_STATUS from '@graphql/projects/setProjectFavoriteStatus.graphql';
import SET_PROJECT_STATUS from '@graphql/projects/setProjectStatus.graphql';
import SET_PROJECT_VISIBILITY from '@graphql/projects/setProjectVisibility.graphql';
import SET_THUMBNAIL from '@graphql/projects/setThumbnail.graphql';
import TASK_UPDATED from '@graphql/projects/tasksUpdated.graphql';
import TRANSLATE_SUBTITLE from '@graphql/projects/translateSubtitle.graphql';
import UPDATE_META from '@graphql/projects/updateMeta.graphql';
import UPDATE_PROJECT from '@graphql/projects/updateProject.graphql';
import UPDATE_PROJECT_CATEGORIES from '@graphql/projects/updateProjectCategories.graphql';
import UPDATE_PROJECT_DIFFUSION_STATE from '@graphql/projects/updateProjectDiffusionState.graphql';
import UPDATE_PROJECT_DISTRIBUTIONS from '@graphql/projects/updateProjectDistributions.graphql';
import UPDATE_PROJECT_INPUT_TITLE from '@graphql/projects/updateProjectInputTitle.graphql';
import UPLOAD_UPDATED from '@graphql/projects/uploadUpdated.graphql';

import { formatInputs, formatOutputs, formatOutput, addOutputFilename, formatLogs } from './utils';

export const projectStatuses = {
  PENDING: 'PENDING',
  PENDING_RUSHES: 'PENDING_RUSHES',
  REFUSED_RUSHES: 'REFUSED_RUSHES',
  PENDING_EDITING: 'PENDING_EDITING',
  EDITING_REPORTED: 'EDITING_REPORTED',
  PENDING_METADATA: 'PENDING_METADATA',
  PENDING_TEAM_VALIDATION: 'PENDING_TEAM_VALIDATION',
  PENDING_CLIENT_VALIDATION: 'PENDING_CLIENT_VALIDATION',
  PENDING_TRIAGE: 'PENDING_TRIAGE',
  VALIDATED: 'VALIDATED',
  REFUSED: 'REFUSED',
  DELETED: 'DELETED',
  ABANDONNED: 'ABANDONNED',
};

export const projectSteps = ['RUSHES', 'EDITING', 'META', 'REVIEW', 'PUBLICATION'];
export const projectPseudoSteps = [
  projectStatuses.VALIDATED,
  projectStatuses.PENDING,
  projectStatuses.PENDING_RUSHES,
  projectStatuses.PENDING_METADATA,
  projectStatuses.PENDING_TEAM_VALIDATION,
  projectStatuses.PENDING_CLIENT_VALIDATION,
  projectStatuses.PENDING_TRIAGE,
];

export const useCreateProject = (solutionId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [create, { data, error, loading }] = useMutation(CREATE_PROJECT, { onCompleted: (data) => onCompleted(data?.createProject), onError });

  const createProject = ({ userId, medias, ratio, orderId, productId }) => {
    return create({
      variables: {
        project: {
          solutionId,
          userId,
          ratio,
          ...(orderId ? { orderId } : {}),
          ...(productId ? { productId } : {}),
          inputs: medias.map((media) => ({
            title: media.fileName,
            url: media.url,
          })),
        },
      },
    });
  };

  return [createProject, { data: data?.createProject, isUpdating: loading, error }];
};

const subtitlesAreGenerating = (output) => output?.subtitles?.some((subtitle) => subtitle.status === 'GENERATING');
const socialMediasAreGenerating = (project) => project?.lastOutput?.exportedVideos?.some((exportedVideo) => exportedVideo.isRendering);
const isProcessingOutput = (m) => m.isRendering || (m.type !== 'OTHER' && !m?.assets?.firstFrame);

const validateVideoOrImage = (input) => input.isRendering;
const validateAudioOrOther = (input) => input.tasks.some((task) => task.status !== 'ENDED');

const validationType = {
  VIDEO: validateVideoOrImage,
  IMAGE: validateVideoOrImage,
  AUDIO: validateAudioOrOther,
  OTHER: validateAudioOrOther,
}

export const isProcessingInput = (input) => input && validationType[input.type](input);

const projectNeedPolling = (project = {}) =>
  subtitlesAreGenerating(project?.lastOutput) ||
  project?.inputs?.some(isProcessingInput) ||
  project?.outputs?.some((o) => isProcessingOutput(o)) ||
  socialMediasAreGenerating(project);

export const useGetProject = (id, t = (_) => _) => {
  const { loading, error, data, startPolling, stopPolling } = useQuery(GET_PROJECT, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (projectNeedPolling(data?.getProject)) {
      startPolling(5000);
    }
    return stopPolling;
  }, [startPolling, stopPolling, data]);

  return useMemo(() => {
    const project = data?.getProject;
    return {
      ...(project
        ? {
            project: {
              ...project,
              lastOutput: addOutputFilename(t)(formatOutput(project?.lastOutput)),
              outputs: formatOutputs(project, t).map(addOutputFilename(t)),
              inputs: formatInputs(project?.inputs),
              logs: formatLogs(project, t),
            },
          }
        : {}),
      loading,
      //Ignore errors when polling
      error: projectNeedPolling(project) ? null : error,
    };
  }, [loading, error, data, t]);
};

export const useGetProjectForSubtitlePage = (projectId) => {
  const { loading, error, data } = useQuery(GET_PROJECT_FOR_SUBTITLE_PAGE, {
    variables: { projectId },
  });

  return {
    project: data?.getProject,
    loading,
    error,
  };
};

export const useGetCompanyForSubtitlePage = (companyId) => {
  const { loading, error, data } = useQuery(GET_COMPANY_FOR_SUBTITLE_PAGE, {
    variables: { id: companyId },
  });

  return {
    company: data?.getCompany,
    loading,
    error,
  };
};

export const useLazyGetAllCards = (projectId) => {
  const [load, { loading, error, data, called }] = useLazyQuery(GET_ALL_CARDS, {
    variables: { projectId },
  });

  return useMemo(() => {
    return [
      load,
      {
        outputs: data?.getProject?.outputs,
        loading,
        error,
        called,
      },
    ];
  }, [load, loading, error, data, called]);
};

export const useGetGetAllCards = (projectId) => {
  const { loading, error, data } = useQuery(GET_ALL_CARDS, {
    variables: { projectId },
  });

  return {
    outputs: data?.getProject?.outputs,
    loading,
    error,
  };
};

export const formatProjects = (projects = [], t) => projects?.map((p) => ({ ...p, lastOutput: p.lastOutput ? formatOutput(p.lastOutput, t) : null }));

export const useGetCampaignProjects = ({
  solutionIds,
  timerange,
  first,
  after,
  last,
  before,
  fullText = '',
  status = [],
  visibleByClient = false,
  orderBy = [],
  skip = false,
}) => {
  const filters = ({ fullText, status, visibleByClient }) => {
    return {
      ...(fullText ? { fullText } : {}),
      ...(status.length ? { status } : {}),
      visibleByClient,
    };
  };

  const { data, loading, error, fetchMore, refetch } = useQuery(CAMPAIGN_PROJECTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      solutionIds,
      timerange,
      first,
      after,
      last,
      before,
      orderBy,
      filter: filters({ fullText, status, visibleByClient }),
    },
    notifyOnNetworkStatusChange: true,
    skip: !solutionIds?.length || skip,
  });

  return {
    data: data?.campaign?.projects,
    stats: data?.campaign?.globalStats?.project,
    loading,
    error,
    fetchMore,
    refetch,
    filters,
  };
};

export const useGetStatsForProjects = ({ solutionIds = [], timerange, isClient = false, skip = false }) => {
  const { data, loading, error, fetchMore, refetch } = useQuery(GET_STATS_FOR_PROJECTS, {
    variables: {
      solutionIds,
      timerange,
      isClient,
    },
    notifyOnNetworkStatusChange: true,
    skip: !solutionIds.length || skip,
  });

  return {
    data: data?.campaign?.globalStats?.project,
    loading,
    error,
    fetchMore,
    refetch,
  };
};

export const useGetProjectHistories = (solutionIds = [], t = (_) => _) => {
  const { loading, error, data } = useQuery(GET_PROJECT_HISTORIES, {
    variables: {
      solutionIds,
    },
    skip: solutionIds.length === 0,
  });

  return {
    projects: data?.getProjects?.projects,
    loading,
    error,
  };
};

export const useGetProjects = (solutionId, { page, size, fullText = '', step = 'ALL', visibleByClient = false }, t = (_) => _) => {
  const { loading, error, data } = useQuery(GET_PROJECTS, {
    fetchPolicy: 'cache-and-network',
    variables: {
      page:
        page && size
          ? {
              page,
              size,
            }
          : PAGINATION_DEFAULT,
      filter: {
        ...(fullText ? { fullText } : {}),
        ...(step && step !== 'ALL' && !projectPseudoSteps.includes(step) ? { steps: [step] } : {}),
        ...(step && projectPseudoSteps.includes(step) ? { status: step } : {}),
        visibleByClient,
      },
      solutionId,
    },
    skip: solutionId === undefined,
  });

  return {
    projects: formatProjects(data?.getProjects?.projects, t),
    pageInfo: data?.getProjects?.pageInfo,
    loading,
    error,
  };
};

export const useGetProjectsBroadcasting = ({ solutionIds, page, filter }) => {
  const { loading, error, data, refetch } = useQuery(GET_PROJECTS_BROADCASTING, {
    variables: {
      solutionIds,
      page,
      filter,
      orderBy: [
        {
          field: 'UPDATED_AT',
          direction: 'DESC',
        },
      ],
    },
    notifyOnNetworkStatusChange: true,
    skip: !solutionIds?.length,
  });

  return {
    projects: data?.getProjectsBroadcasting?.projects,
    pageInfo: data?.getProjectsBroadcasting?.pageInfo,
    loading,
    refetch,
    error: error,
  };
};

export const useGetDiffusionStats = ({ companyId, solutionIds = [] }) => {
  const { loading, error, data, refetch } = useQuery(DIFFUSION_STATS, {
    variables: {
      companyId,
      solutionIds,
    },
    notifyOnNetworkStatusChange: true,
    skip: !companyId || !solutionIds.length,
  });

  return {
    stats: data?.diffusionStats,
    loading,
    refetch,
    error,
  };
};

export const useGetFavoriteProjects = ({ page, size }) => {
  const { loading, error, data } = useQuery(GET_FAVORITE_PROJECTS, {
    variables: {
      page:
        page && size
          ? {
              page,
              size,
            }
          : PAGINATION_DEFAULT,
    },
  });

  return {
    projects: formatProjects(data?.getFavoriteProjects?.projects),
    pageInfo: data?.getFavoriteProjects?.pageInfo,
    loading,
    error,
  };
};

export const useRetryTask = () => {
  const [retryFunction, { data, error, loading }] = useMutation(RETRY_TASK);

  const retryTask = (taskId) => {
    return retryFunction({ variables: { taskId } });
  };

  return [retryTask, { data: data?.retryTask, isUpdating: loading, error }];
};

export const useGetTasks = ({ page, size, status, type, id, isSucceeded }) => {
  const { loading, error, data, subscribeToMore, refetch } = useQuery(GET_TASKS, {
    // fetchPolicy: 'cache-and-network',
    variables: {
      page:
        page && size
          ? {
              page,
              size,
            }
          : PAGINATION_DEFAULT,
      filter: {
        ...(status && status !== 'ALL' ? { status } : {}),
        ...(type && type !== 'ALL' ? { type } : {}),
        ...(id && id !== 'ALL' ? { projectId: id } : {}),
        ...(isSucceeded && isSucceeded !== 'ALL' ? { isSucceeded: isSucceeded === 'true' } : {}),
      },
    },
  });

  const tasks = data?.getTasks;
  if (!tasks) {
    return { loading, error };
  }

  return {
    tasks,
    loading,
    error,
    pageInfo: data?.getTasks?.pageInfo,
    subscribeToMore,
    refetch,
  };
};

export const useUpdateProjectInputTitle = (projectId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [updateFunction, { data, error, loading }] = useMutation(UPDATE_PROJECT_INPUT_TITLE, { onCompleted, onError });

  const updateProjectInputTitle = (inputId, title) => {
    return updateFunction({ variables: { projectId, inputId, title } });
  };

  return [updateProjectInputTitle, { data: data?.updateProjectInputTitle, isUpdating: loading, error }];
};

export const useAddProjectTimeline = (projectId) => {
  const [addFunction, { data, error, loading }] = useMutation(ADD_PROJECT_TIMELINE);

  const addProjectTimeline = ({ timeline, exported }) => {
    return addFunction({ variables: { timeline, projectId, render: exported } });
  };

  return [addProjectTimeline, { data: data?.addProjectTimeline, isUpdating: loading, error }];
};

const cleanText = (text = '') => text?.trim() ?? '';

export const useUpdateProjectMeta = (project, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [updateFunction, { data, error, loading }] = useMutation(UPDATE_META, { onCompleted, onError });

  const mutate = ({ cards, ...updatedProject }) => {
    return updateFunction({
      variables: {
        projectId: project.id,
        outputId: project?.lastOutput?.id,
        cards: cards?.map(({ id, errors, __typename, ...c }) => c),
        project: {
          ...updatedProject,
          thumbnailText1: cleanText(updatedProject?.thumbnailText1),
          thumbnailText2: cleanText(updatedProject?.thumbnailText2),
        },
      },
    });
  };

  return [mutate, { data: data?.updateProject, isUpdating: loading, error }];
};

export const useUpdateProject = (project, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [updateFunction, { data, error, loading }] = useMutation(UPDATE_PROJECT, { onCompleted, onError });
  const mutate = ({ ...updatedProject }) => {
    return updateFunction({
      variables: {
        projectId: project.id,
        outputId: project?.lastOutput?.id,
        project: {
          ...updatedProject,
        },
      },
    });
  };

  return [mutate, { data: data?.updateProject, isUpdating: loading, error }];
};

export const useSetProjectStatus = (projectId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [updateFunction, { data, error, loading }] = useMutation(SET_PROJECT_STATUS, {
    onCompleted: (data) => onCompleted(data?.setProjectStatus),
    onError,
  });

  const updateStatus = (status, message, checklist, sequencesMessages) => {
    return updateFunction({
      variables: { status, message, projectId, checklist, sequencesMessages },
      refetchQueries: [projectStatuses.VALIDATED, projectStatuses.PENDING_RUSHES].includes(status) ? [{ query: GET_PROJECT, variables: { id: projectId } }] : [],
      awaitRefetchQueries: true,
    });
  };

  return [updateStatus, { data: data?.setProjectStatus, isUpdating: loading, error }];
};

export const useToggleProjectFavoriteStatus = () => {
  const [updateFunction, { data, error, loading }] = useMutation(SET_PROJECT_FAVORITE_STATUS, {
    refetchQueries: [{ query: GET_FAVORITE_PROJECTS, variables: { page: PAGINATION_DEFAULT } }],
  });

  const toggleFavorite = ({ id, isFavorite }) => {
    return updateFunction({
      variables: {
        projectId: id,
        isFavorite: !isFavorite,
      },
    });
  };

  return [toggleFavorite, { data: data?.setProjectIsFavorite, isUpdating: loading, error }];
};

export const useAddProjectInputs = (projectId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [mutate, { data, error, loading }] = useMutation(ADD_PROJECT_INPUTS, {
    onError,
    onCompleted: (data) => onCompleted(data?.addProjectInputs),
  });

  const updateInputs = ({ medias, rotateDelta, fitNormalize, audioNormalize, videoStabilization, autoCrop }) => {
    return mutate({
      variables: {
        projectId,
        inputs: medias.map((media) => ({
          url: media.url,
          title: media.fileName,
          rotateDelta,
          fitNormalize,
          audioNormalize,
          videoStabilization,
          autoCrop,
        })),
      },
    });
  };

  return [updateInputs, { data: data?.addProjectInputs, error, loading }];
};

export const useNormalizeInput = (projectId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [mutate, { data, error, loading }] = useMutation(NORMALIZE_PROJECT_INPUT, {
    onError,
    onCompleted: (data) => onCompleted(data?.normalizeProjectInput),
  });

  const normalizeInput = (
    inputId,
    { audioNormalize = true, rotateDelta = 0, fitNormalize = 'LIGHT', reverse, autoCrop = 1, videoStabilization = true, manualCrop = null }
  ) => mutate({ variables: { inputId, projectId, audioNormalize, rotateDelta, fitNormalize, autoCrop, videoStabilization, manualCrop, reverse } });

  return [normalizeInput, { data: data?.normalizeProjectInput, error, loading }];
};

export const useAddProjectOutput = (projectId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [addOutputFunction, { data, error, loading }] = useMutation(ADD_PROJECT_OUTPUT, {
    onCompleted: (data) => onCompleted(data?.addProjectOutput),
    onError,
  });

  const updateOutput = ({ url }) => {
    //TODO fix the api to use a URL instead of a ProjectMediaInput.
    //The title is not needed and should not be ask to the user.
    return addOutputFunction({ variables: { output: { url, title: 'NOT_NEEDED_BY_USER_NEED_REWORK' }, projectId } });
  };

  return [updateOutput, { data: data?.addProjectOutput, error, loading }];
};

export const useRenderOutput = (projectId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [mutate, { data, error, loading }] = useMutation(RENDER_PROJECT_OUTPUT, {
    onError,
    onCompleted: (data) => onCompleted(data?.renderProjectOutput),
  });

  const renderOutput = (version) => {
    return mutate({ variables: { version, projectId } });
  };

  return [renderOutput, { data: data?.renderProjectOutput, error, loading }];
};

export const useSetThumbnail = (projectId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [mutate, { data, error, loading }] = useMutation(SET_THUMBNAIL, {
    onError,
    onCompleted: (data) => onCompleted(data?.setProjectThumbnail),
  });

  const updateThumbnail = (formData) => {
    const url = formData.frame[0];
    return mutate({ variables: { thumbnail: url, projectId } });
  };

  return [updateThumbnail, { data: data?.setProjectThumbnail, error, loading }];
};

export const useUpdateProjectDistributions = (projectId, companyId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [update, { loading, error }] = useMutation(UPDATE_PROJECT_DISTRIBUTIONS, { onCompleted, onError });

  const updateProjectDistributions = ({ distributors, productReferences }) =>
    update({
      variables: {
        companyId,
        projectId,
        distributors,
        productReferences,
      },
    });

  return [updateProjectDistributions, { updating: loading, error }];
};

export const useSetProjectVisibility = (projectId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [mutate, { data, error, loading }] = useMutation(SET_PROJECT_VISIBILITY, { onCompleted, onError });

  const call = (visible) => {
    return mutate({ variables: { projectId, visible } });
  };

  return [call, { data: data?.setProjectVisibility, isUpdating: loading, error }];
};

export const useSetProjectBoost = ({ onCompleted = () => {}, onError = () => {} } = {}) => {
  const [mutate, { data, error, loading }] = useMutation(SET_PROJECT_BOOST, { onCompleted, onError });

  const setProjectOutput = (projectId, boost) =>
    mutate({
      variables: { projectId, boost },
    });

  return [setProjectOutput, { data: data?.setProjectBoost, isUpdating: loading, error }];
};

export const useDeleteProject = ({ projectId, companyId }, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [mutate] = useMutation(DELETE_PROJECT, { onCompleted, onError });

  return () =>
    mutate({
      variables: {
        projectId,
        companyId,
      },
    });
};

export const useTasksUpdated = (ids) => {
  const { data } = useSubscription(TASK_UPDATED, {
    variables: {
      ids,
    },
  });

  return data?.tasksUpdated;
};

export const useUploadUpdated = (id) => {
  const { data } = useSubscription(UPLOAD_UPDATED, {
    variables: {
      id,
    },
  });

  return data?.uploadUpdated;
};

export const useMergeProjects = ({ onCompleted = () => {} }) => {
  const [mutate, { data }] = useMutation(MERGE_PROJECTS, { onCompleted });

  const mergeProjects = ({ projectId1, projectId2 }) =>
    mutate({
      variables: {
        projectId1,
        projectId2,
      },
    });

  return [mergeProjects, { data: data?.mergeProjects }];
};

export const useDuplicateProject = (projectId, { onCompleted = () => {}, onError = () => {} }) => {
  const [mutate] = useMutation(DUPLICATE_PROJECT, { onCompleted, onError });

  const duplicateProject = () =>
    mutate({
      variables: {
        projectId,
      },
    });
  return [duplicateProject];
};

export const useGetProjectForStudioPage = (id, t = (_) => _) => {
  const { loading, error, data } = useQuery(GET_PROJECT_FOR_STUDIO, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  const project = data?.getProject;
  return {
    ...(project
      ? {
          project: {
            ...project,
            lastOutput: addOutputFilename(t)(formatOutput(project?.lastOutput)),
            outputs: formatOutputs(project, t).map(addOutputFilename(t)),
            inputs: formatInputs(project?.inputs),
          },
        }
      : {}),
    loading,
    error,
  };
};

export const useGetProductAndProjectCategories = (solutionId) => {
  const { data, loading, error } = useQuery(GET_PRODUCT_AND_PROJECT_CATEGORIES, { variables: { solutionId } });
  return { categories: data?.getProductAndProjectCategories.map((c) => ({ label: c.name, value: c.name })), loading, error };
};

export const useUpdateProjectCategories = (projectId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [update, { loading, error }] = useMutation(UPDATE_PROJECT_CATEGORIES, {
    onCompleted,
    onError,
  });

  const updateProjectCategories = (solutionId, categories) =>
    update({
      variables: {
        projectId,
        solutionId,
        categories,
      },
    });

  return [updateProjectCategories, { updating: loading, error }];
};

export const useGetTopProjects = (companyId, company) => {
  const { data, loading, error } = useQuery(GET_TOP_PROJECTS, {
    variables: { id: companyId },
    skip: !company,
  });
  return {
    topViewsProjects: data?.getTopProjects?.topViewsProjects?.projects?.map((project, index) => ({
      ...project,
      stats: data?.getTopProjects?.topViewsProjects?.stats?.[index],
    })),
    topSalesProjects: data?.getTopProjects?.topSalesProjects?.projects?.map((project, index) => ({
      ...project,
      stats: data?.getTopProjects?.topSalesProjects?.stats?.[index],
    })),
    loading,
    error,
  };
};

export const useUpdateProjectDiffusionState = (companyId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [update, { loading, error }] = useMutation(UPDATE_PROJECT_DIFFUSION_STATE, {
    onCompleted,
    onError,
  });

  const updateProjectDiffusionState = ({ projectIds, state, distributions }) =>
    update({
      variables: {
        companyId,
        projectIds,
        state,
        distributions,
      },
    });

  return [updateProjectDiffusionState, { updating: loading, error }];
};

export const useTranslateSubtitles = (projectId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [update, { loading, error }] = useMutation(TRANSLATE_SUBTITLE, {
    refetchQueries: [{ query: GET_PROJECT, variables: { id: projectId } }],
    onCompleted,
    onError,
  });

  const translateSubtitles = (outputId, translateFrom, translateInto) => update({
    variables: {
      outputId,
      translateFrom,
      translateInto,
    },
  });

  return [translateSubtitles, { updating: loading, error }];
};
