import { SkpNotificationModal } from '@skeepers/skeepers-ui-react';
import React, { createContext, useContext, useState } from 'react';

import { DEFAULT_TEXT_ERROR, DEFAULT_TEXT_SUCCESS } from '@app/utils';

import style from './NotificationModalContext.module.css';

const defaultState = { notifications: [], addNotification: () => { }, addDefaultSuccessNotification: () => { }, addDefaultAlertNotification: () => { }, removeNotification: () => { } };

export const NotificationModalContext = createContext({
  ...defaultState,
});

export const useNotificationModal = () => useContext(NotificationModalContext);

export const NotificationModalProvider = ({ children }) => {
  const [notifications, setNotifications] = useState([]);
  const [position, setPosition] = useState(0);

  const addNotification = (notification) => {
    const id = position;
    setNotifications([...notifications, { notification, id, isShow: true }]);
    setPosition(position + 1);
  };

  const addDefaultSuccessNotification = (t, title) => {
    const id = position;
    setNotifications([...notifications, {
      notification: {
        title: t(title),
        message: t(DEFAULT_TEXT_SUCCESS),
        type: 'success',
      },
      id,
      isShow: true
    }]);
    setPosition(position + 1);
  };

  const addDefaultAlertNotification = (t, title) => {
    const id = position;
    setNotifications([...notifications, {
      notification: {
        title: t(title),
        message: t(DEFAULT_TEXT_ERROR),
        type: 'alert',
      },
      id,
      isShow: true
    }]);
    setPosition(position + 1);
  };

  const removeNotification = (id) => {
    setNotifications((prevNotifications) => {
      return [...prevNotifications.filter((n) => n.id !== id)];
    });
  };

  const skpModalClosed = (id) => {
    const notification = notifications.filter((n) => n.id === id);
    if (notification.length > 0) {
      notification[0].isShow = false;
      setNotifications([...notifications.filter((n) => n.id !== id), notification[0]]);
    }

    setTimeout(() => {
      removeNotification(id);
    }, 750);
  };

  return (
    <NotificationModalContext.Provider value={{ notifications, addNotification, removeNotification, addDefaultSuccessNotification, addDefaultAlertNotification }}>
      <div className={style.notificationContainer}>
        {notifications.map((notification, pos) => (
          <SkpNotificationModal
            key={`notification-modal-${notification.id}`}
            className={`${style.notificationModal} ${notification.isShow ? style.show : style.hide}`}
            onSkpModalClosed={() => skpModalClosed(notification.id)}
            skpActionButtonTitle={notification.notification.actionButton}
            skpDismissButtonTitle={notification.notification.dismissButton}
            skpTitle={notification.notification.title}
            skpMessage={notification.notification.message}
            skpType={notification.notification.type}
            skpPosition={pos}
          />
        ))}
      </div>
      {children}
    </NotificationModalContext.Provider>
  );
};
