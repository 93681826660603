import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import Action from '@components/Common/Action';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import WatermarksEditor from '@components/Common/WatermarksEditor';
import UploadInput from '@components/FormElement/UploadInput';

import Button from '../../Common/Button';
import ButtonContainer from '../../Common/ButtonContainer';
import Window from '../Window';

import style from './style.module.css';

const DEFAULT_LOGO_SIZE = 10;
const DEFAULT_LOGO_TOP = 0;
const DEFAULT_LOGO_LEFT = 0;

const WatermarksWindow = ({ onEdit = () => {}, open = false, onClose = () => {}, watermarks = [], backgroundRelativeHeight, layout = null }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [updatedWatermarks, setUpdatedWatermarks] = useState(watermarks);
  const [selectedWatermark, setSelectedWatermark] = useState(null);
  const [keyUploader, setKeyUploader] = useState(uuid());

  const addWatermark = (files) => {
    setUpdatedWatermarks((previousUpdatedWatermarks) => [
      ...previousUpdatedWatermarks,
      ...files.map((file) => ({
        path: file,
        size: DEFAULT_LOGO_SIZE,
        top: DEFAULT_LOGO_TOP,
        left: DEFAULT_LOGO_LEFT,
      })),
    ]);
    setKeyUploader(uuid());
  };

  const deleteWatermark = (i) => {
    setUpdatedWatermarks((previousUpdatedWatermarks) => [...previousUpdatedWatermarks.slice(0, i), ...previousUpdatedWatermarks.slice(i + 1)]);
    setSelectedWatermark(null);
  };

  return (
    <Window open={open} onClose={onClose} title={t('Watermarks')}>
      <UploadInput
        key={keyUploader}
        filenamesMode={'HIDDEN'}
        onEdit={(files) => {
          if (files.length) {
            addWatermark(files);
          }
        }}
        multiple
        validTypes={['IMAGE']}
      />
      {updatedWatermarks.length > 0 && (
        <div className={style.resizerLogosContainer}>
          {updatedWatermarks.map((watermark, i) => (
            <Action key={watermark.path} className={style.resizerLogoContainer} action={() => deleteWatermark(i)}>
              <img src={watermark.path} className={style.resizerLogoPreview} alt={t('Logo')} />
              <div className={style.action}>
                <SkeepersIcon type="TRASH" color="var(--layout-primary-color)" title={t('Delete')} />
              </div>
            </Action>
          ))}
        </div>
      )}
      <WatermarksEditor
        watermarks={updatedWatermarks}
        setWatermarks={setUpdatedWatermarks}
        backgroundRelativeHeight={backgroundRelativeHeight}
        setSelectedWatermark={setSelectedWatermark}
        selectedWatermark={selectedWatermark}
        editable={true}
        layout={layout}
      />
      <ButtonContainer position="CENTER">
        <Button label={t('Cancel')} loading={isLoading} disabled={isLoading} theme={Button.themes.SECONDARY} action={onClose} className={style.marginButton} />
        <Button
          label={t('Confirm')}
          loading={isLoading}
          disabled={isLoading}
          action={async () => {
            try {
              setIsLoading(true);
              await onEdit(updatedWatermarks);
              onClose();
            } catch (err) {}
          }}
        />
      </ButtonContainer>
    </Window>
  );
};

export default WatermarksWindow;

WatermarksWindow.propTypes = {
  onEdit: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  watermarks: PropTypes.arrayOf(
    PropTypes.shape({
      path: PropTypes.string,
      size: PropTypes.number,
      top: PropTypes.number,
      left: PropTypes.number,
    })
  ),
  backgroundRelativeHeight: PropTypes.number,
};
