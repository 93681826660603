import classNames from 'classnames';
import PropTypes from 'prop-types';

import SkeepersIcon from '@components/Common/SkeepersIcon';

import style from './style.module.css';

const hints = {
  INFO: {
    colorClassName: style.info,
    icon: 'INFO',
  },
  SUCCESS: {
    colorClassName: style.success,
    icon: 'CHECK',
  },
  WARNING: {
    colorClassName: style.warning,
    icon: 'WARNING',
  },
  ERROR: {
    colorClassName: style.error,
    icon: 'CLOSE',
  },
  NORMAL: {
    colorClassName: style.normal,
    icon: 'INFO',
  },
};

const Hint = ({ type = 'INFO', message = '', background = false, className }) => {
  const { colorClassName, icon } = hints[type] || hints.INFO;

  return (
    <small className={classNames(style.hint, colorClassName, className, background ? style.background : '')}>
      {icon && <SkeepersIcon type={icon} />}
      {message}
    </small>
  );
};

Hint.propTypes = {
  type: PropTypes.oneOf(['INFO', 'SUCCESS', 'WARNING', 'ERROR', 'NORMAL']),
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export default Hint;
