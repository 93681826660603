import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getLanguages } from '@app/translations';
import ButtonContainer from '@components/Common/ButtonContainer';
import SkeepersFlag from '@components/Common/SkeepersFlag';
import { Form, useForm } from '@components/FormElement/Form';

const CreateSocialMediaVideoEditedForm = ({ onSubmit = () => { }, subtitles = [] }) => {
  const { t } = useTranslation();
  const languages = useMemo(() => getLanguages(t), [t]);
  const methods = useForm();

  return (
    <Form methods={methods} onSubmit={onSubmit} skipDirtyCheck>
      <Form.Label title={t('Subtitle')} name="subtitleId">
        <Form.Select
          name="subtitleId"
          defaultValue={null}
          options={[
            {
              label: t('No subtitles'),
              value: null,
            },
            ...subtitles.map((subtitle) => ({
              label: languages[subtitle.language]?.name,
              value: subtitle.id,
              icon: <SkeepersFlag country={languages[subtitle.language]?.country} />,
            })),
          ]}
        />
      </Form.Label>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton label={t('Generate')} />
      </ButtonContainer>
    </Form>
  );
};

export default CreateSocialMediaVideoEditedForm;

CreateSocialMediaVideoEditedForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  subtitles: PropTypes.arrayOf(
    PropTypes.shape({
      language: PropTypes.string,
      id: PropTypes.string,
    })
  ),
};
