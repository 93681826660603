import { URL_IMAGE } from './config';
let webp = null;

const detectWebpSupport = () => {
  const elem = document.createElement('canvas');
  // eslint-disable-next-line no-extra-boolean-cast
  if (!!(elem.getContext && elem.getContext('2d'))) {
    return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
  }
  return false;
};

const canUseWebp = () => {
  if (webp === null) {
    webp = detectWebpSupport();
  }
  return webp;
};

export const getPictureLink = (url = '', args = {}, alpha = false) => {
  if (!url) {
    return null;
  }

  const params = new URLSearchParams({
    ...args,
    ...(args.w ? { w: Math.round(args.w) } : {}),
    ...(args.h ? { h: Math.round(args.h) } : {}),
    url,
    output: canUseWebp() ? 'webp' : alpha ? 'png' : 'jpg',
  }).toString();

  return `${URL_IMAGE}?${params}`;
};
