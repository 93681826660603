class IdleTimeoutHandler {
  constructor({ callback = () => {}, timeout = 30000 }) {
    this.callback = callback;
    this.eventsType = ['click', 'scroll', 'load', 'keydown', 'mousemove'];
    this.lastInteractionTime = null;
    this.timer = null;
    this.timeout = timeout;
  }

  eventHandler = () => {
    this.lastInteractionTime = Date.now();
    if (!this.timer) {
      this.startTimer(this.timeout);
    }
  };

  startTimer = (timeout) => {
    this.timer = setTimeout(() => {
      const now = Date.now() - this.lastInteractionTime;
      if (now > this.timeout) {
        this.callback();
        this.removeEvents();
      } else {
        this.startTimer(this.timeout - now);
      }
    }, timeout);
  };

  addEvents = () => {
    this.eventsType.forEach((eventName) => {
      window.addEventListener(eventName, this.eventHandler);
    });
  };

  removeEvents = () => {
    this.eventsType.forEach((eventName) => {
      window.removeEventListener(eventName, this.eventHandler);
    });
  };

  start = () => {
    if (!this.timer) {
      this.addEvents();
      this.eventHandler();
    }
  };

  stop = () => {
    this.removeEvents();
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };
}

export default IdleTimeoutHandler;
