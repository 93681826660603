import { SkpAlert } from '@skeepers/skeepers-ui-react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { smartUploaderOrientation } from '@app/utils/smartUploader';
import ButtonContainer from '@components/Common/ButtonContainer';
import Flex from '@components/Common/Flex';
import Hint from '@components/Common/Hint';
import { Form, useForm } from '@components/FormElement/Form';
import { useWindows } from '@contexts/WindowContext';

const CreateTimelineTemplateForm = ({ onSubmit = () => {}, timelineTemplate = {}, buttonText, isUpdating = false }) => {
  const { t } = useTranslation();
  const { actions } = useWindows();
  const [error, setError] = useState(null)
  const buttonTextToUse = buttonText ?? t('Create');
  const methods = useForm();

  const sequenceTypes = Object.values(timelineTemplate?.scheme || {})
    .flatMap((schemaElements) => (Array.isArray(schemaElements) ? schemaElements : []))
    .map((schemaElement) => schemaElement.sequence?.__typename);

  // if template has a sequence with orientation prop (Video or Picture) that means that we could not update template's orientation
  const orientationDisabled = sequenceTypes.some((type) => ['SequenceVideo', 'SequencePicture'].includes(type));

  const handleSubmit = async (values) => {
    actions.loading();
    setError(null);
    const { errors } = await onSubmit(values);
    if (errors.length) {
      setError(t(errors[0]?.message));
      actions.disableLoading();
    }
  }

  return (
    <Form methods={methods} onSubmit={handleSubmit} isUpdating={isUpdating}>
      <Flex flexDirection="COLUMN" alignItems="STRETCH" margin="NONE" gap="0.5rem">
        <Form.Label title={t('Title')} name="title">
          <Form.Input name="title" defaultValue={timelineTemplate?.title} required />
        </Form.Label>
        <Form.Label name="orientation" title={t('Orientation')}>
          <Form.Select
            name="orientation"
            defaultValue={timelineTemplate?.orientation}
            required
            options={[
              { value: smartUploaderOrientation.horizontal, label: t('Horizontal') },
              { value: smartUploaderOrientation.vertical, label: t('Vertical') },
            ]}
            readOnly={orientationDisabled}
          />
          <Hint
            type={orientationDisabled ? 'WARNING' : 'INFO'}
            message={
              orientationDisabled
                ? t('You can not update orientation because {{orientation}} sequences are already used in this template', {
                    orientation: timelineTemplate?.orientation?.toLowerCase(),
                  })
                : t('Only sequences with the same orientation can be added to the template')
            }
          />
        </Form.Label>
        <Form.Label title={t('Auto export')} name="toRender">
          <Form.Switch name="toRender" defaultValue={timelineTemplate?.toRender || false} />
        </Form.Label>
        {error && <SkpAlert size="small" type="warning" text={error} />}
        <ButtonContainer position="CENTER">
          <Form.SubmitButton label={buttonTextToUse} />
        </ButtonContainer>
      </Flex>
    </Form>
  );
};

export default CreateTimelineTemplateForm;
