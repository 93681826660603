import PropTypes from 'prop-types';

import { getEmbedVideoUrl } from '@app/utils/externalVideo';
import Flex from '@components/Common/Flex';
import ExternalVideoForm from '@components/Form/ExternalVideoForm';
import Window from '@components/Window/Window';

const UpsertExternalVideoWindow = ({
  onSubmit = () => {},
  video = {},
  subtitle = '',
  isLoading = false,
  title = '',
  open = false,
  onClose = () => {},
  distributionsSolution = [],
  categoriesOptions = [],
  showEditForm = true,
}) => {
  const { projectUrl: url, provider, videoId, products, distributions, categories } = video;

  const productReferences = products?.map(({ reference }) => reference);
  const productCategories =
    products
      ?.map(({ categories, reference }) => ({
        reference,
        categories: categories?.map(({ name }) => name).filter((c) => !!c),
      }))
      .filter((pc) => pc.categories?.length > 0) || [];
  const src = getEmbedVideoUrl({ provider, videoId });
  const defaultCategories = categoriesOptions.map((c) => ({ label: c, value: c }));

  return (
    <Window title={title} subtitle={subtitle} open={open} onClose={onClose}>
      <Flex flexDirection="COLUMN" alignItems="STRETCH" gap="2.5rem">
        {src && (
          <iframe
            height="400"
            src={src}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          />
        )}
        {showEditForm && (
          <ExternalVideoForm
            externalVideo={{ url, productReferences, distributions, productCategories, categories }}
            onSubmit={onSubmit}
            onClose={onClose}
            isUpdating={isLoading}
            distributionsSolution={distributionsSolution}
            defaultCategories={defaultCategories}
          />
        )}
      </Flex>
    </Window>
  );
};

export default UpsertExternalVideoWindow;

UpsertExternalVideoWindow.propTypes = {
  video: PropTypes.shape({
    projectUrl: PropTypes.string,
    provider: PropTypes.oneOf(['YOUTUBE']),
    videoId: PropTypes.string,
    products: PropTypes.arrayOf(
      PropTypes.shape({
        reference: PropTypes.string,
      })
    ),
  }),
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  distributionsSolution: PropTypes.array,
  categoriesOptions: PropTypes.array,
  showEditForm: PropTypes.bool,
};
