import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getPolicyRoles, getResources } from '@app/translations';
import ButtonContainer from '@components/Common/ButtonContainer';
import Hint from '@components/Common/Hint';
import { Form, useForm } from '@components/FormElement/Form';

const CreatePolicyForm = ({ isUpdating = false, onSubmit = () => {} }) => {
  const { t } = useTranslation();
  const roles = getPolicyRoles(t);
  const resources = getResources(t);
  const methods = useForm();

  const { resourceType } = methods.watch();
  return (
    <Form methods={methods} onSubmit={onSubmit} isUpdating={isUpdating}>
      <Form.Label title={t('User or group')} name="grantee" required>
        <Form.GranteeSelect name="grantee" required />
      </Form.Label>
      <Form.Label title={t('Role')} name="role" required>
        <Form.Select name="role" required options={Object.entries(roles).map(([value, { name: label }]) => ({ value, label }))} />
      </Form.Label>
      <Form.Label title={t('Resource')} name="resourceType" required>
        <Form.Select
          name="resourceType"
          isClearable
          options={Object.entries(resources).map(([value, label]) => ({ value, label }))}
          hint={<Hint message={t('Leave blank to apply to every type of resource')} />}
        />
      </Form.Label>
      {resourceType && (
        <Form.Label title={t('Resource identifier')} name="resourceId">
          <Form.Input name="resourceId" hint={(resourceId) => (!resourceId ? <Hint message={t('Leave blank to apply to all')} /> : null)} />
        </Form.Label>
      )}
      <ButtonContainer position="CENTER">
        <Form.SubmitButton label={t('Grant')} />
      </ButtonContainer>
    </Form>
  );
};

export default CreatePolicyForm;

CreatePolicyForm.propTypes = {
  isUpdating: PropTypes.bool,
  onSubmit: PropTypes.func,
};
