import { useTranslation } from 'react-i18next';

import CommentForm from '@components/Form/CommentForm';
import Window from '@components/Window/Window';

const CommentWindow = ({ title, content = null, onEdit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();
  const titleToUse = title ?? t('Add a comment');

  return (
    <Window title={titleToUse} open={open} onClose={onClose}>
      <CommentForm content={content} onSubmit={onEdit} isLoading={isLoading} />
    </Window>
  );
};

export default CommentWindow;
