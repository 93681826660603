import PropTypes from 'prop-types';

import style from './style.module.css';

const HtmlPreview = ({ title = '', html = '', className = '' }) => {
  const onLoad = (e) => {
    e.target.style.height = e.target.contentWindow.document.body.scrollHeight + 'px';
  };

  return <iframe className={`${style.iframe} ${className}`} title={title} srcDoc={html} onLoad={onLoad} />;
};

export default HtmlPreview;

HtmlPreview.propTypes = {
  /** Add a className on the iframe */
  className: PropTypes.string,
  /** Add a title on the iframe */
  title: PropTypes.string,
  /** HTML to displayed on the iframe */
  html: PropTypes.string,
};
