import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonContainer from '@components/Common/ButtonContainer';
import { Form, useForm } from '@components/FormElement/Form';

const SetThumbnailForm = ({ videoRatio, onSubmit = () => {}, frames = [], currentThumbnail = '' }) => {
  const { t } = useTranslation();
  const methods = useForm();

  return (
    <Form onSubmit={onSubmit} methods={methods}>
      <Form.Label title={t('Thumbnail')} name="frame">
        <Form.ImageSelector name="frame" defaultValue={currentThumbnail} frames={frames} videoRatio={videoRatio} required />
      </Form.Label>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default SetThumbnailForm;

SetThumbnailForm.propTypes = {
  onSubmit: PropTypes.func,
  frames: PropTypes.arrayOf(PropTypes.shape([PropTypes.string])),
};
