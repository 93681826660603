import classNames from 'classnames';
import PropTypes from 'prop-types';
import { createRef, Component } from 'react';
import Skeleton from 'react-loading-skeleton';

import { timecodeToStr } from '@app/utils';
import Block from '@components/Common/Block';
import PlayerControls from '@components/Project/PlayerControls';
import PlayerProgress from '@components/Project/PlayerProgress';
import PlayerSubtitles from '@components/Project/PlayerSubtitles';

import style from './style.module.css';
class Player extends Component {
  constructor(props) {
    super(props);
    this.ref = createRef();
    this.state = {
      isPlaying: false,
      duration: 0,
      currentTime: this.props.currentTime || 0,
    };

    this.onPlay = this.onPlay.bind(this);
    this.onPause = this.onPause.bind(this);
    this.onTimeUpdate = this.onTimeUpdate.bind(this);
    this.onCanPlay = this.onCanPlay.bind(this);
    this.seek = this.seek.bind(this);
  }

  setEVentListener() {
    this.ref.current.addEventListener('play', this.onPlay);
    this.ref.current.addEventListener('pause', this.onPause);
    this.ref.current.addEventListener('timeupdate', this.onTimeUpdate);
    this.ref.current.addEventListener('canplay', this.onCanPlay);
  }

  onPlay() {
    this.setState({ isPlaying: true });
    if (this.props.onPlay) {
      this.props.onPlay();
    }
  }

  onPause() {
    this.setState({ isPlaying: false });
    if (this.props.onPause) {
      this.props.onPause();
    }
  }

  onTimeUpdate({ target }) {
    this.setState({ currentTime: Math.round(target.currentTime * 1000) });
    if (this.props.onTimeUpdate) {
      this.props.onTimeUpdate(Math.round(target.currentTime * 1000));
    }
  }

  onCanPlay() {
    this.setState({ duration: Math.round(this.ref.current.duration * 1000) });
    if (this.props.onCanPlay) {
      this.props.onCanPlay(Math.round(this.ref.current.duration * 1000));
    }
  }

  componentDidMount() {
    if (this.props.url) {
      this.setEVentListener();
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.url && this.props.url) {
      this.setEVentListener();
    }
  }

  componentWillUnmount() {
    if (this.ref.current) {
      this.ref.current.removeEventListener('play', this.onPlay);
      this.ref.current.removeEventListener('pause', this.onPause);
      this.ref.current.removeEventListener('timeupdate', this.onTimeUpdate);
      this.ref.current.removeEventListener('canplay', this.onCanPlay);
    }
  }

  play() {
    this.ref.current.play();
  }

  pause() {
    this.ref.current.pause();
  }

  seek(timecode) {
    this.setState({ currentTime: timecode });
    this.ref.current.currentTime = timecode / 1000;
    if (this.props.onSeek) {
      this.props.onSeek(timecode);
    }
  }

  isPlaying() {
    return this.ref.current && !this.ref.current.paused;
  }

  render() {
    return (
      <Block className={style.player}>
        <div className={style.videoContainer}>
          {
            this.props.url ? (
              <video className={classNames(style.video, this.props.ratio === "9:16" ? style.verticalVideo : null)} ref={this.ref}>
                <source src={this.props.url} />
              </video>
            ) : (
              <Skeleton height={120} />
              )
          }
          {this.props.cues && <PlayerSubtitles currentTime={this.state.currentTime} cues={this.props.cues} />}
        </div>
        {!this.props.controlsDisabled && (
          <div>
            <PlayerControls
              duration={this.state.duration}
              currentTime={this.state.currentTime}
              onPlay={() => this.play()}
              isPlaying={this.state.isPlaying}
              onPause={() => this.pause()}
              onSeek={(t) => this.seek(t)}
            />
            <PlayerProgress
              duration={this.state.duration}
              currentTime={this.state.currentTime}
              onSeek={(t) => this.seek(t)}
              formatTimecode={timecodeToStr}
              limits={this.props.limits}
            />
          </div>
        )}
        {this.props.card && this.props.card.title && this.props.card.description && (
          <div className={style.cardsContainer}>
            <div className={style.card}>
              <h5 className={style.cardTitle}>{this.props.card.title}</h5>
              <h6 className={style.cardDescription}>{this.props.card.description}</h6>
            </div>
          </div>
        )}
      </Block>
    );
  }
}

Player.propTypes = {
  ratio: PropTypes.oneOf(["9:16", "16:9"]),
  currentTime: PropTypes.number,
  controlsDisabled: PropTypes.bool,
}

export default Player;
