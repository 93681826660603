import { useTranslation } from 'react-i18next';

import Flex from '@components/Common/Flex';
import Hint from '@components/Common/Hint';
import Form from '@components/FormElement/Form/Form';

import style from './style.module.css';

const Colors = ({ previewQrCode }) => {
  const { t } = useTranslation();

  return (
    <Flex flexWrap='WRAP' className={style.flexGap}>
      <Form.Label title={t('Main Color')} name="color" className={style.colorPickerContainer}>
        <Form.ColorPicker
          name="color"
          placeholder={t('Choose your color')}
          defaultValue="#ABABAC"
          required
          hint={
            <Hint
              message={t(
                'This color will be the main color of your QR code. Your background wil always be in transparent. Please beware of the contrast of the color you will choose.'
              )}
              type="INFO"
            />
          }
        />
      </Form.Label>
      {previewQrCode}
    </Flex>
  );
};

export default Colors;
