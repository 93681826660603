import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import style from './style.module.css';

const getInputRangeBackground = (value, min, max) => {
  const percent = (100 * (value - min)) / (max - min);
  return (
    'linear-gradient(to right, var(--layout-secondary-color) 0%, var(--layout-secondary-color) ' +
    percent +
    '%, var(--input-focused-background-color) ' +
    percent +
    '%, var(--input-focused-background-color) 100%)'
  );
};

const handlePlaceholder = (value, placeholder) => (value === null ? placeholder : value);
const toString = (value) => value + '';
const parseValue = (value, placeholder) => toString(handlePlaceholder(value, placeholder));

const RangeInput = forwardRef(
  (
    {
      value = '',
      name = '',
      placeholder = '',
      readOnly = false,
      disabled = false,
      required = false,
      min = 0,
      max = 1,
      step = 0.01,
      onFocus = () => {},
      onBlur = () => {},
      onEdit = () => {},
      className = '',
    },
    ref
  ) => {
    const onChange = onEdit ? (event) => onEdit(Number(event.target.value)) : null;

    return (
      <div>
        <input
          value={parseValue(value, placeholder)}
          type="range"
          className={classNames(style.input, style.text, className, { [style.readOnly]: readOnly })}
          name={name}
          placeholder={placeholder}
          readOnly={readOnly}
          disabled={disabled || readOnly}
          required={required}
          onFocus={onFocus}
          onBlur={onBlur}
          onChange={onChange}
          ref={ref}
          min={min}
          max={max}
          step={step}
          style={{ background: getInputRangeBackground(value === null ? placeholder : value, min, max) }}
        />
        <div className={style.rangeDisplay}>
          <div>{min}</div>
          <div>{value === null ? placeholder : value}</div>
          <div>{max}</div>
        </div>
      </div>
    );
  }
);

RangeInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  /**The minimum value to accept for this input*/
  min: PropTypes.number,
  /**The maximum value to accept for this input*/
  max: PropTypes.number,
  /**A stepping interval to use when using up and down arrows to adjust the value, as well as for validation*/
  step: PropTypes.number,
  className: PropTypes.string,
};

RangeInput.displayName = 'RangeInput';

export default RangeInput;
