import style from './style.module.css';

const LinkedinPlayer = ({ postId }) => {
  return (
    <div className={style.container}>
      <iframe title={postId} className={style.video} width="100%" height="100%" allowFullScreen src={`https://www.linkedin.com/embed/feed/update/${postId}`} />
    </div>
  );
};

export default LinkedinPlayer;
