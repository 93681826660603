import PropTypes from 'prop-types';

import SolutionButton from '@components/Common/SolutionButton';

import style from './style.module.css';

const MenuLeft = ({ menu, open }) => (
  <nav className={`${style.nav} ${open ? style.open : ''}`}>
    <ul className={style.main}>
      {menu.map((e) => (
        <li key={e.id} className={`${style.elem} ${e.selected ? style.selected : ''}`} onClick={() => e.onClick && e.onClick()} title={e.name}>
          <SolutionButton isSelected={e.selected} type={e.icon} title={e.name} />
        </li>
      ))}
    </ul>
  </nav>
);

MenuLeft.propTypes = {
  menu: PropTypes.arrayOf(
    PropTypes.exact({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      icon: PropTypes.oneOf(['GENERAL', 'CASTING', 'PLATFORM', 'TRACKING', 'VSG', 'PLAYER', 'SMART_UPLOADER', 'ADD']),
      selected: PropTypes.bool.isRequired,
      background: PropTypes.string.isRequired,
      color: PropTypes.string.isRequired,
      onClick: PropTypes.func,
    })
  ),
  open: PropTypes.bool,
};

MenuLeft.defaultProps = {
  menu: [],
  open: false,
};

export default MenuLeft;
