import isEmpty from 'lodash/isEmpty';
import { lazy } from 'react';

import { Page404 } from '@components/Layout/PageError';
import ExternalVideosImportPage from '@views/ExternalVideos/ExternalVideosImportPage';
import WorkflowStudioPage from '@views/Workflows/WorkflowStudioPage';

const ProjectsBroadcasting = lazy(() => import('@views/Projects/ProjectsBroadcasting'));
const UpdateCompany = lazy(() => import('@views/General/UpdateCompany'));
const ListCompanies = lazy(() => import('@views/Other/ListCompanies'));
const Dashboard = lazy(() => import('@views/General/Dashboard'));
const GlobalDashboardData = lazy(() => import('@views/Data/Charts/GlobalOverview'));
const ExportsPage = lazy(() => import('@views/Data/ExportsPage'));
const ProductDashboardData = lazy(() => import('@views/Data/Charts/ProductOverview'));
const VideoDashboardData = lazy(() => import('@views/Data/Charts/VideoOverview'));
const QrcodeDashboardData = lazy(() => import('@views/Data/Charts/QrcodeOverview'));
const VsgDataOverview = lazy(() => import('@views/Data/Charts/VsgOverview'));
const DevelopersView = lazy(() => import('@views/General/DevelopersView'));
const AuditsCompanies = lazy(() => import('@views/Integration/AuditsCompanies'));
const AuditsCompany = lazy(() => import('@views/Integration/AuditsCompany'));
const AuditsSolution = lazy(() => import('@views/Integration/AuditsSolution'));
const VideoCheckers = lazy(() => import('@views/Integration/VideoCheckers'));
const Injection = lazy(() => import('@views/Integration/Injections'));
const VsgPreview = lazy(() => import('@views/Vsg/Preview'));
const ProjectPage = lazy(() => import('@views/Projects/ProjectPage'));
const ProjectTasksPage = lazy(() => import('@views/Projects/ProjectTasksPage'));
const PlatformApplication = lazy(() => import('@views/Platform/Application'));
const ApplicationListPage = lazy(() => import('@views/Platform/ApplicationListPage'));
const OrderListPage = lazy(() => import('@views/Platform/OrdersPage'));
const VideoListPage = lazy(() => import('@views/Projects/ProjectListPage'));
const ProductListPage = lazy(() => import('@views/Product/ProductListPage'));
const UpdateProductPage = lazy(() => import('@views/Product/UpdateProductPage'));
const CreateProductPage = lazy(() => import('@views/Product/CreateProductPage'));
const ProductImportPage = lazy(() => import('@views/Product/ProductImportPage'));
const Order = lazy(() => import('@views/Platform/Order'));
const ChallengeListPage = lazy(() => import('@views/Challenge/ChallengeListPage'));
const UpdateChallengePage = lazy(() => import('@views/Challenge/UpdateChallengePage'));
const CreateChallengePage = lazy(() => import('@views/Challenge/CreateChallengePage'));
const SolutionConfiguration = lazy(() => import('@views/Other/SolutionConfiguration'));
const Studio = lazy(() => import('@views/Projects/Studio'));
const SubtitlePage = lazy(() => import('@views/Projects/SubtitlePage'));
const CastingOverview = lazy(() => import('@views/Data/CastingOverview'));
const VsgOverview = lazy(() => import('@views/Data/VsgOverview'));
const TrackingOverview = lazy(() => import('@views/Tracking/TrackingOverview'));
const PlatformConfiguration = lazy(() => import('@views/Other/PlatformConfiguration'));
const SmartUploaderConfiguration = lazy(() => import('@views/SmartUploader/SmartUploaderConfiguration'));
const MailsTemplatesConfiguration = lazy(() => import('@views/Mail/MailsTemplateConfiguration'));
const WorkflowDashboard = lazy(() => import('@views/Workflows/WorkflowCusmDashboard'));
const ChangelogPage = lazy(() => import('@views/Other/ChangelogPage'));
const CompanyEvents = lazy(() => import('@views/General/CompanyEvents'));
const VideoTemplates = lazy(() => import('@views/General/VideoTemplates/SpreadVideoTemplates'));
const YoutubeVideoTemplates = lazy(() => import('@views/General/VideoTemplates/YoutubeVideoTemplates'));
const CreateProjectPage = lazy(() => import('@views/Projects/CreateProjectPage'));
const TemplatesPage = lazy(() => import('@views/Configuration/TemplatesPage'));
const TemplatePage = lazy(() => import('@views/Configuration/TemplatePage'));
const OwnershipsPage = lazy(() => import('@views/General/OwnershipsPage'));
const PresetPage = lazy(() => import('@views/Configuration/PresetPage'));
const ProjectFavoriteListPage = lazy(() => import('@views/Projects/ProjectFavoriteListPage'));
const IAMPage = lazy(() => import('@views/Permissions/IAMPage'));
const UserPage = lazy(() => import('@views/User/UserPage'));
const UsersListPage = lazy(() => import('@views/User/UsersListPage'));
const MailsWrappersConfiguration = lazy(() => import('@views/Mail/MailsWrappersConfiguration'));
const MailsDefaultTemplatesConfiguration = lazy(() => import('@views/Mail/MailsDefaultTemplatesConfiguration'));
const Mails = lazy(() => import('@views/Mail/Mails'));
const SessionPage = lazy(() => import('@views/Browser/SessionPage'));
const WorkersPage = lazy(() => import('@views/Browser/WorkersPage'));
const WorkerPage = lazy(() => import('@views/Browser/WorkerPage'));
const LegalPage = lazy(() => import('@views/Legal/LegalPage'));
const CreateLegalDocumentPage = lazy(() => import('@views/Legal/CreateLegalDocumentPage'));
const UpdateLegalDocumentPage = lazy(() => import('@views/Legal/UpdateLegalDocumentPage'));
const LegalDocumentPage = lazy(() => import('@views/Legal/LegalDocumentPage'));
const WorkflowOverviewOperator = lazy(() => import('@views/Workflows/WorkflowOverviewOperator'));
const WorkflowOverviewOperatorAdmin = lazy(() => import('@views/Workflows/WorkflowOverviewOperatorAdmin'));
const WebhookListPage = lazy(() => import('@views/Webhook/WebhookListPage'));
const WebhookPage = lazy(() => import('@views/Webhook/WebhookPage'));
const QRCodeTemplatesPage = lazy(() => import('@views/General/QRCodeTemplatesPage'));
const QRCodeListPage = lazy(() => import('@views/Projects/QRCodeListPage'));
const TimelineTemplateEditorPage = lazy(() => import('@views/TimelineTemplateEditor/TimelineTemplateEditor'));
const CampaignOverview = lazy(() => import('@views/Platform/CampaignOverview'));
const ExternalVideos = lazy(() => import('@views/ExternalVideos/ExternalVideosPage'));
const FollowUp = lazy(() => import('@views/Platform/FollowUp'));
const ShoppableContentWidget = lazy(() => import('@views/ShoppableContentWidget'));

const removeUndefinedValue = (params = {}) =>
  Object.entries(params).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(typeof value !== 'undefined' ? { [key]: value } : {}),
    }),
    {}
  );

const path = (baseUrl = '', queryParams = {}) => {
  const searchParams = removeUndefinedValue(queryParams);
  if (isEmpty(searchParams)) {
    return baseUrl;
  }

  const params = new URLSearchParams(searchParams);

  return `${baseUrl}?${params.toString()}`;
};

const ROUTES = {
  TRACKING_OVERVIEW: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId' } = {}, queryParams) =>
      path(`/company/${companyId}/tracking/${solutionId}/overview`, queryParams),
    Component: TrackingOverview,
  },
  DASHBOARD: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/general/dashboard`, queryParams),
    Component: Dashboard,
  },
  EXPORTS_PAGE: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/general/exports`, queryParams),
    Component: ExportsPage,
  },
  DASHBOARD_DATA: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/general/data`, queryParams),
    Component: GlobalDashboardData,
  },
  VSG_GLOBAL_DATA: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/general/vsgData`, queryParams),
    Component: VsgDataOverview,
  },
  VIDEO_DATA: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/general/videoData`, queryParams),
    Component: VideoDashboardData,
  },
  QRCODE_DATA: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/general/qrcodeData`, queryParams),
    Component: QrcodeDashboardData,
  },
  PRODUCT_DATA: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/general/productData`, queryParams),
    Component: ProductDashboardData,
  },
  UPDATE_COMPANY: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/general/company/configuration`, queryParams),
    Component: UpdateCompany,
  },
  INJECTIONS: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/general/company/injections`, queryParams),
    Component: Injection,
  },
  COMPANY_EVENTS: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/general/company/events`, queryParams),
    Component: CompanyEvents,
  },
  VIDEO_TEMPLATES: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/general/company/videoTemplates`, queryParams),
    Component: VideoTemplates,
  },
  YOUTUBE_VIDEO_TEMPLATES: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/general/company/youtube-video-templates`, queryParams),
    Component: YoutubeVideoTemplates,
  },
  DEVELOPERS: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/general/developer`, queryParams),
    Component: DevelopersView,
  },
  EXTERNAL_VIDEO: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/general/company/external-videos`, queryParams),
    Component: ExternalVideos,
  },
  IMPORT_EXTERNAL_VIDEOS: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/general/company/external-videos/import`, queryParams),
    Component: ExternalVideosImportPage,
  },
  AUDITS_COMPANIES: {
    path: (_, queryParams) => path(`/audits`, queryParams),
    Component: AuditsCompanies,
  },
  AUDITS_VIDEO_CHECKERS: {
    path: (_, queryParams) => path(`/audits/videoCheckers`, queryParams),
    Component: VideoCheckers,
  },
  AUDITS_COMPANY: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/general/audits`, queryParams),
    Component: AuditsCompany,
  },
  AUDITS_SOLUTION: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId', solutionType = ':solutionType' } = {}, queryParams) =>
      path(`/company/${companyId}/${solutionType}/${solutionId}/audits`, queryParams),
    Component: AuditsSolution,
  },
  FOLLOWUP: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId' } = {}, queryParams) => path(`/company/${companyId}/followup/${solutionId}`, queryParams),
    Component: FollowUp,
  },
  PLATFORM_APPLICATION_LIST: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId' } = {}, queryParams) =>
      path(`/company/${companyId}/casting/${solutionId}/applications`, queryParams),
    Component: ApplicationListPage,
  },
  PLATFORM_APPLICATION: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId', applicationId = ':applicationId' } = {}, queryParams) =>
      path(`/company/${companyId}/casting/${solutionId}/applications/${applicationId}`, queryParams),
    Component: PlatformApplication,
  },
  VSG_PREVIEW: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId' } = {}, queryParams) => path(`/company/${companyId}/vsg/${solutionId}/preview`, queryParams),
    Component: VsgPreview,
  },
  CASTING_CONFIGURATION: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId' } = {}, queryParams) =>
      path(`/company/${companyId}/casting/${solutionId}/configuration`, queryParams),
    Component: PlatformConfiguration,
  },
  PLATFORM_CONFIGURATION: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId' } = {}, queryParams) =>
      path(`/company/${companyId}/platform/${solutionId}/configuration`, queryParams),
    Component: PlatformConfiguration,
  },
  SMART_UPLOADER_CONFIGURATION: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId' } = {}, queryParams) =>
      path(`/company/${companyId}/smart-uploader/${solutionId}/configuration`, queryParams),
    Component: SmartUploaderConfiguration,
  },
  MAILS_TEMPLATES_CONFIGURATION: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId', solutionType = ':solutionType' } = {}, queryParams) =>
      path(`/company/${companyId}/${solutionType}/${solutionId}/mailsConfiguration`, queryParams),
    Component: MailsTemplatesConfiguration,
  },
  SOLUTION_CONFIGURATION: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId', solutionType = ':solutionType' } = {}, queryParams) =>
      path(`/company/${companyId}/${solutionType}/${solutionId}/configuration`, queryParams),
    Component: SolutionConfiguration,
  },
  PROJECT_LIST: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId', solutionType = ':solutionType' } = {}, queryParams) =>
      path(`/company/${companyId}/${solutionType}/${solutionId}/videos`, queryParams),
    Component: VideoListPage,
  },
  PROJECT_FAVORITE_LIST: {
    path: (_, queryParams) => path('/projects/favorites', queryParams),
    Component: ProjectFavoriteListPage,
  },
  CREATE_PROJECT: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId', solutionType = ':solutionType' } = {}, queryParams) =>
      path(`/company/${companyId}/${solutionType}/${solutionId}/videos/new`, queryParams),
    Component: CreateProjectPage,
  },
  PROJECT: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId', projectId = ':projectId', solutionType = ':solutionType' } = {}, queryParams) =>
      path(`/company/${companyId}/${solutionType}/${solutionId}/videos/${projectId}`, queryParams),
    Component: ProjectPage,
  },
  PROJECT_DEBUG: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId', projectId = ':projectId', solutionType = ':solutionType' } = {}, queryParams) =>
      path(`/company/${companyId}/${solutionType}/${solutionId}/videos/${projectId}/debug`, queryParams),
    Component: ProjectTasksPage,
  },
  MAILS: {
    path: (_, queryParams) => path('/mails', queryParams),
    Component: Mails,
  },
  STUDIO: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId', projectId = ':projectId', solutionType = ':solutionType' } = {}, queryParams) =>
      path(`/company/${companyId}/${solutionType}/${solutionId}/videos/${projectId}/studio`, queryParams),
    Component: Studio,
  },
  SUBTITLE: {
    path: (
      { companyId = ':companyId', solutionId = ':solutionId', projectId = ':projectId', subtitleId = ':subtitleId', solutionType = ':solutionType' } = {},
      queryParams
    ) => path(`/company/${companyId}/${solutionType}/${solutionId}/videos/${projectId}/subtitles/${subtitleId}`, queryParams),
    Component: SubtitlePage,
  },
  PRODUCT_LIST: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId' } = {}, queryParams) =>
      path(`/company/${companyId}/platform/${solutionId}/products`, queryParams),
    Component: ProductListPage,
  },
  CREATE_PRODUCT: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId' } = {}, queryParams) =>
      path(`/company/${companyId}/platform/${solutionId}/products/new`, queryParams),
    Component: CreateProductPage,
  },
  CREATE_PRODUCTS: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId' } = {}, queryParams) =>
      path(`/company/${companyId}/platform/${solutionId}/products/import`, queryParams),
    Component: ProductImportPage,
  },
  UPDATE_PRODUCT: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId', productId = ':productId' } = {}, queryParams) =>
      path(`/company/${companyId}/platform/${solutionId}/products/${productId}`, queryParams),
    Component: UpdateProductPage,
  },
  ORDER_LIST: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId' } = {}, queryParams) =>
      path(`/company/${companyId}/platform/${solutionId}/orders/`, queryParams),
    Component: OrderListPage,
  },
  ORDER: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId', orderId = ':orderId' } = {}, queryParams) =>
      path(`/company/${companyId}/platform/${solutionId}/orders/${orderId}`, queryParams),
    Component: Order,
  },
  CHALLENGE_LIST: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId' } = {}, queryParams) =>
      path(`/company/${companyId}/platform/${solutionId}/challenges/`, queryParams),
    Component: ChallengeListPage,
  },
  CREATE_CHALLENGE: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId' } = {}, queryParams) =>
      path(`/company/${companyId}/platform/${solutionId}/challenges/new`, queryParams),
    Component: CreateChallengePage,
  },
  UPDATE_CHALLENGE: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId', challengeId = ':challengeId' } = {}, queryParams) =>
      path(`/company/${companyId}/platform/${solutionId}/challenges/${challengeId}`, queryParams),
    Component: UpdateChallengePage,
  },
  CASTING_DATA: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId' } = {}, queryParams) => path(`/company/${companyId}/casting/${solutionId}/data`, queryParams),
    Component: CastingOverview,
  },
  VSG_DATA: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId' } = {}, queryParams) => path(`/company/${companyId}/vsg/${solutionId}/data`, queryParams),
    Component: VsgOverview,
  },
  CONFIG_TEMPLATES: {
    path: (_, queryParams) => path('/config/templates', queryParams),
    Component: TemplatesPage,
  },
  CONFIG_TEMPLATE: {
    path: ({ templateId = ':templateId' } = {}, queryParams) => path(`/config/templates/${templateId}`, queryParams),
    Component: TemplatePage,
  },
  CONFIG_PRESET: {
    path: ({ presetId = ':presetId' } = {}, queryParams) => path(`/config/presets/${presetId}`, queryParams),
    Component: PresetPage,
  },
  HOME: {
    path: (_, queryParams) => path('/', queryParams),
    Component: ListCompanies,
  },
  CHANGELOGS: {
    path: (_, queryParams) => path('/changelogs', queryParams),
    Component: ChangelogPage,
  },
  OWNERSHIP: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/general/company/ownership`, queryParams),
    Component: OwnershipsPage,
  },
  BROADCASTING: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/general/broadcasting/`, queryParams),
    Component: ProjectsBroadcasting,
  },
  CASTING_OVERVIEW: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/casting`, queryParams),
    Component: Page404,
  },
  IAM: {
    path: (_, queryParams) => path(`/iam`, queryParams),
    Component: IAMPage,
  },
  USER: {
    path: ({ id = ':id' } = {}, queryParams) => path(`/users/${id}`, queryParams),
    Component: UserPage,
  },
  USERS_LIST: {
    path: (_, queryParams) => path('/users', queryParams),
    Component: UsersListPage,
  },
  MAILS_WRAPPERS_CONFIGURATION: {
    path: (_, queryParams) => path(`/mails/wrappers`, queryParams),
    Component: MailsWrappersConfiguration,
  },
  MAILS_DEFAULT_TEMPLATES_CONFIGURATION: {
    path: (_, queryParams) => path(`/mails/templates`, queryParams),
    Component: MailsDefaultTemplatesConfiguration,
  },
  BROWSER_WORKERS: {
    path: (_, queryParams) => path('/browser', queryParams),
    Component: WorkersPage,
  },
  BROWSER_WORKER: {
    path: ({ workerId = ':workerId' } = {}, queryParams) => path(`/browser/workers/${workerId}`, queryParams),
    Component: WorkerPage,
  },
  BROWSER_SESSION: {
    path: ({ sessionId = ':sessionId' } = {}, queryParams) => path(`/browser/sessions/${sessionId}`, queryParams),
    Component: SessionPage,
  },
  LEGAL: {
    path: (_, queryParams) => path('/legal', queryParams),
    Component: LegalPage,
  },
  CREATE_LEGAL_DOCUMENT: {
    path: (_, queryParams) => path('/legal/new', queryParams),
    Component: CreateLegalDocumentPage,
  },
  UPDATE_LEGAL_DOCUMENT: {
    path: ({ legalDocumentId = ':legalDocumentId' } = {}, queryParams) => path(`/legal/${legalDocumentId}/edit`, queryParams),
    Component: UpdateLegalDocumentPage,
  },
  LEGAL_DOCUMENT: {
    path: ({ legalDocumentId = ':legalDocumentId' } = {}, queryParams) => path(`/legal/${legalDocumentId}`, queryParams),
    Component: LegalDocumentPage,
  },
  WORKFLOW_TASK_STUDIO: {
    path: ({ taskId = ':taskId' } = {}, queryParams) => path(`/workflows/studio/${taskId}`, queryParams),
    Component: WorkflowStudioPage,
  },
  WORKFLOW_OVERVIEW_OPERATOR: {
    path: (_, queryParams) => path(`/workflows/operator`, queryParams),
    Component: WorkflowOverviewOperator,
  },
  WORKFLOW_OVERVIEW_OPERATOR_ADMIN: {
    path: ({ id = ':id' } = {}, queryParams) => path(`/workflows/operator/${id}`, queryParams),
    Component: WorkflowOverviewOperatorAdmin,
  },
  DASHBOARD_CUSM: {
    path: (_, queryParams) => path(`/workflows`, queryParams),
    Component: WorkflowDashboard,
  },
  WEBHOOKS_GLOBAL_LIST: {
    path: (_, queryParams) => path('/webhooks/', queryParams),
    Component: WebhookListPage,
  },
  WEBHOOKS_GLOBAL_PAGE: {
    path: ({ webhookId = ':webhookId' } = {}, queryParams) => path(`/webhook/${webhookId}`, queryParams),
    Component: WebhookPage,
  },
  WEBHOOKS_SOLUTION_LIST: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId', solutionType = ':solutionType' } = {}, queryParams) =>
      path(`/company/${companyId}/${solutionType}/${solutionId}/webhooks`, queryParams),
    Component: WebhookListPage,
  },
  WEBHOOKS_SOLUTION_PAGE: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId', solutionType = ':solutionType', webhookId = ':webhookId' } = {}, queryParams) =>
      path(`/company/${companyId}/${solutionType}/${solutionId}/webhook/${webhookId}`, queryParams),
    Component: WebhookPage,
  },
  QRCODE_TEMPLATES: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/general/company/qrcodeConfig`, queryParams),
    Component: QRCodeTemplatesPage,
  },
  QRCODE_GENERATION: {
    path: ({ companyId = ':companyId' } = {}, queryParams) => path(`/company/${companyId}/general/qrcodeGeneration`, queryParams),
    Component: QRCodeListPage,
  },
  TIMELINE_TEMPLATE_EDITOR: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId', timelineTemplateId = ':timelineTemplateId' } = {}, queryParams) =>
      path(`/company/${companyId}/smart-uploader/${solutionId}/template/${timelineTemplateId}/editor`, queryParams),
    Component: TimelineTemplateEditorPage,
  },
  CAMPAIGN_OVERVIEW: {
    path: ({ companyId = ':companyId', solutionId = ':solutionId' } = {}, queryParams) =>
      path(`/company/${companyId}/platform/${solutionId}/overview`, queryParams),
    Component: CampaignOverview,
  },
  SHOPPABLE_CONTENT_WIDGET: {
    path: ({ companyId = ':companyId' } = {}, queryParams) =>
      path(`/company/${companyId}/general/shoppable-content`, queryParams),
    Component: ShoppableContentWidget,
  },
  SHOPPABLE_CONTENT_WIDGET_CONTENT: {
    path: ({ companyId = ':companyId' } = {}, queryParams) =>
      path(`/company/${companyId}/general/shoppable-content/contents`, queryParams),
    Component: ShoppableContentWidget,
  },
  SHOPPABLE_CONTENT_WIDGET_CONTENT_EDIT: {
    path: ({ companyId = ':companyId', identifier = ':identifier' } = {}, queryParams) =>
      path(`/company/${companyId}/general/shoppable-content/contents/${identifier}`, queryParams),
    Component: ShoppableContentWidget,
  },
  SHOPPABLE_CONTENT_WIDGET_COLLECTION: {
    path: ({ companyId = ':companyId' } = {}, queryParams) =>
      path(`/company/${companyId}/general/shoppable-content/collections`, queryParams),
    Component: ShoppableContentWidget,
  },
  SHOPPABLE_CONTENT_WIDGET_COLLECTION_EDIT: {
    path: ({ companyId = ':companyId', identifier = ':identifier' } = {}, queryParams) =>
      path(`/company/${companyId}/general/shoppable-content/collections/${identifier}`, queryParams),
    Component: ShoppableContentWidget,
  },
};

export const allRoutes = Object.values(ROUTES);

export const compareRoute = (routeMatch, currentRoute, exact = false) => {
  return exact ? routeMatch === currentRoute : currentRoute.toString().toLowerCase().indexOf(routeMatch.toString().toLowerCase()) !== -1;
};

export const matchRoute = (routeMatch, currentRoute) => {
  return routeMatch.test(currentRoute);
};

export const compareStartsRoute = (routeMatch, currentRoute) => {
  return currentRoute.toString().toLowerCase().startsWith(routeMatch.toString().toLowerCase());
};

export const REDIRECT = {
  operatorDashboard: 'OPERATOR_DASHBOARD',
  usmDashboard: 'USM_DASHBOARD'
}

export default ROUTES;
