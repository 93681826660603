import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

const Popup = ({ url, onClose = () => {}, onLogin = () => {} }) => {
  const [open, setOpen] = useState(false);
  const [popup, setPopup] = useState(null);

  useEffect(() => {
    if (popup) {
      const interval = setInterval(() => {
        if (!popup || popup.closed || popup.closed === undefined) {
          setPopup(null);
          clearInterval(interval);
          onClose();
          return;
        }

        try {
          if (popup.location.hostname.includes(window.location.hostname)) {
            if (popup.location.search) {
              const query = new URLSearchParams(popup.location.search);

              onLogin(query);

              popup.close();
              setPopup(null);
              clearInterval(interval);
            }
          }
        } catch (e) {}
      }, 500);
      return () => clearInterval(interval);
    }
  }, [popup, onClose, onLogin]);

  const openPopup = () => {
    const width = 640;
    const height = 480;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;

    const p = window.open(
      '',
      '',
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=${width}, height=${height}, top=${top}, left=${left}`
    );

    setPopup(p);
    setOpen(true);
  };

  if (!open) {
    openPopup();
  } else if (popup && url) {
    popup.location = url;
  }

  return null;
};

Popup.propTypes = {
  url: PropTypes.string.isRequired,
  onClose: PropTypes.func,
  onLogin: PropTypes.func,
};

export default Popup;
