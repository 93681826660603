import { getAuditStatus, getAuditQualities } from '@app/translations';
import DateTime from '@components/Common/DateTime';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import Tag from '@components/Common/Tag';

const getAuditType = (audit) =>
  ({
    AuditPlayer: 'PLAYER',
    AuditTracking: 'TRACKING',
    AuditVsg: 'VSG',
  }[audit.__typename]);

const getKey = (audit) => {
  const getter = {
    PLAYER: (a) => a.playerKey,
    TRACKING: (a) => a.trackingKey,
    VSG: (a) => a.vsgKey,
  }[getAuditType(audit)];

  if (!getter) {
    return null;
  }

  return getter(audit);
};

export const getAuditResult = (t, audit, { enabled: taskEnabled, type: taskType } = {}, solutionId) => {
  const auditQualities = getAuditQualities(t);

  const status = (() => {
    if (!audit || audit.status === 'AUDITING') {
      return taskEnabled ? <SkeepersIcon type="SPINNER" spin /> : '-';
    }

    if (audit.status === 'PLUGGED') {
      const color = auditQualities[audit.quality].color;
      if (color) {
        return <SkeepersIcon type={'CIRCLE'} color={color} />;
      }
    }

    return <SkeepersIcon type={'CLOSE'} color={auditQualities.BAD.color} />;
  })();

  const workWithAdblock = (() => {
    if (!audit || audit.status === 'AUDITING') {
      return taskEnabled ? <SkeepersIcon type="SPINNER" spin /> : '-';
    }
    if (audit.status !== 'PLUGGED' || audit.quality === 'NONE' || audit.isAdblocked === null) {
      return 'N/A';
    }
    if (!audit.isAdblocked) {
      return <SkeepersIcon type={'CHECK'} color={auditQualities.GOOD.color} />;
    }
    return <SkeepersIcon type={'CLOSE'} color={auditQualities.BAD.color} />;
  })();

  const keyValid = (() => {
    if (!audit || audit.status === 'AUDITING') {
      return taskEnabled ? <SkeepersIcon type="SPINNER" spin /> : '-';
    }

    const key = getKey(audit);
    if (audit.status !== 'PLUGGED' || audit.quality === 'NONE' || !key) {
      return 'N/A';
    }

    return key === solutionId ? (
      <SkeepersIcon type={'CHECK'} color={auditQualities.GOOD.color} />
    ) : (
      <SkeepersIcon type={'CLOSE'} color={auditQualities.BAD.color} />
    );
  })();

  const date = (() => {
    if (!audit && taskEnabled) {
      return <SkeepersIcon type="SPINNER" spin />;
    }
    if (audit?.finishedAt) {
      return <DateTime timestamp={audit?.finishedAt} relative={false} hour={true} />;
    }
    return '-';
  })();

  return {
    status,
    workWithAdblock,
    keyValid,
    date,
  };
};

export const getAuditsTags = (t, audits) => {
  if (!audits.length) {
    return <SkeepersIcon type={'BAN'} />;
  }
  const auditType = getAuditType(audits[0]);
  const auditQualities = getAuditQualities(t);
  return (
    <>
      {Object.keys(auditQualities).reduce((acc, quality) => {
        const qty = audits.filter((audit) => audit.quality === quality).length;
        if (qty > 0) {
          const key = auditQualities[quality].values?.[auditType] || auditQualities[quality].defaultValue;
          acc.push(<Tag key={key} value={`${qty}/${audits.length}: ${key}`} color={auditQualities[quality].color} />);
        }
        return acc;
      }, [])}
    </>
  );
};

export const getWorkWithAdblockTags = (t, audits) => {
  if (!audits.length) {
    return <SkeepersIcon type={'BAN'} />;
  }

  const { qtyNotPlugged, qtyWorking, qtyNotWorking } = audits.reduce(
    (acc, audit) => {
      if (['NOT_PLUGGED', 'FORBIDDEN', 'PAGE_NOT_FOUND'].includes(audit.status)) {
        acc.qtyNotPlugged++;
      } else if (audit.status === 'PLUGGED' && !audit.isAdblocked) {
        acc.qtyWorking++;
      } else {
        acc.qtyNotWorking++;
      }
      return acc;
    },
    {
      qtyNotPlugged: 0,
      qtyWorking: 0,
      qtyNotWorking: 0,
    }
  );

  const tags = [
    ...(qtyNotPlugged
      ? [<Tag key={'notPlugged'} value={`${qtyNotPlugged}/${audits.length} : ${getAuditStatus(t).NOT_PLUGGED}`} color={'var(--tag-red)'} />]
      : []),
    ...(qtyNotWorking ? [<Tag key={'blocked'} value={`${qtyNotWorking}/${audits.length} : ${t('Not working')}`} color={'var(--tag-red)'} />] : []),
    ...(qtyWorking ? [<Tag key={'notBlocked'} value={`${qtyWorking}/${audits.length} : ${t('Working')}`} color={'var(--tag-green)'} />] : []),
  ];
  return <>{tags}</>;
};

export const getKeysValidityTags = (t, solutionsAudits = {}) => {
  const auditsQty = Object.keys(solutionsAudits)
    .map((solutionId) => solutionsAudits[solutionId].length)
    .reduce((acc, qty) => acc + qty, 0);
  if (auditsQty === 0) {
    return <SkeepersIcon type={'BAN'} />;
  }

  const { qtyNotPlugged, qtyWorking, qtyNotWorking } = Object.keys(solutionsAudits).reduce(
    (acc, solutionId) => {
      solutionsAudits[solutionId].forEach((audit) => {
        if (['NOT_PLUGGED', 'FORBIDDEN', 'PAGE_NOT_FOUND'].includes(audit.status)) {
          acc.qtyNotPlugged++;
        } else if (audit.status === 'PLUGGED' && getKey(audit) === solutionId) {
          acc.qtyWorking++;
        } else {
          acc.qtyNotWorking++;
        }
      });
      return acc;
    },
    {
      qtyNotPlugged: 0,
      qtyWorking: 0,
      qtyNotWorking: 0,
    }
  );

  const tags = [
    ...(qtyNotPlugged ? [<Tag key={'notPlugged'} value={`${qtyNotPlugged}/${auditsQty} : ${getAuditStatus(t).NOT_PLUGGED}`} color={'var(--tag-red)'} />] : []),
    ...(qtyNotWorking ? [<Tag key={'valid'} value={`${qtyNotWorking}/${auditsQty} : ${t('Invalid')}`} color={'var(--tag-red)'} />] : []),
    ...(qtyWorking ? [<Tag key={'invalid'} value={`${qtyWorking}/${auditsQty} : ${t('Valid')}`} color={'var(--tag-green)'} />] : []),
  ];
  return <>{tags}</>;
};

export const getTaskTypeByRoutingType = (routingType) =>
  ({
    player: 'PLAYER',
    vsg: 'VSG',
    tracking: 'TRACKING',
  }[routingType]);

export const getSolutionLastAudits = (solution) => solution.tasks.reduce((acc, task) => acc.concat(task.enabled ? task.lastAudit || [] : []), []);

export const getSdkBaseLib = () =>
  'const SDK = {' +
  'getVersion: () => {},' +
  'init: () => {},' +
  'checkVideoAvailability: (hash, product_id, callback) => {},' +
  'getBestVideo: (hash, product_id, callback) => {},' +
  'getVideos: (hash, product_id, callback) => {},' +
  'getAllVideos: (hash, callback) => {},' +
  'getVideoById: (hash, id, callback) => {},' +
  'preloadVideos: (hash, force) => {},' +
  'flushCache: () => {},' +
  'isCacheReady: (hash) => {},' +
  'addEventListener: (type = "*", func) => {},' +
  'removeEventListener: (type = "*", func) => {},' +
  'isConsentGranted: () => {},' +
  'customIntegration: () => {},' +
  '};';
