import classNames from 'classnames';
import PropTypes from 'prop-types';

import style from './style.module.css';

const Flex = ({
  margin = 'SMALL',
  flexDirection = 'ROW',
  justifyContent = 'SPACE-BETWEEN',
  alignItems = 'FLEX-START',
  flexWrap = 'NOWRAP',
  children,
  className = '',
  gap = '0',
}) => {
  return (
    <div
      className={classNames(style.flex, className, {
        [style[`margin-${margin.toString().toLowerCase()}`]]: margin,
        [style[`flex-${flexDirection.toString().toLowerCase()}`]]: flexDirection,
        [style[`position-${justifyContent.toString().toLowerCase()}`]]: justifyContent,
        [style[`align-${alignItems.toString().toLowerCase()}`]]: alignItems,
        [style[`wrap-${flexWrap.toString().toLowerCase()}`]]: flexWrap,
      })}
      style={{ gap }}
    >
      {children}
    </div>
  );
};

Flex.propTypes = {
  margin: PropTypes.oneOf(['NONE', 'SMALL', 'MEDIUM', 'BIG', 'HUGE']),
  flexDirection: PropTypes.oneOf(['ROW', 'ROW-REVERSE', 'COLUMN', 'COLUMN-REVERSE']),
  justifyContent: PropTypes.oneOf(['FLEX-START', 'FLEX-END', 'CENTER', 'SPACE-BETWEEN', 'SPACE-AROUND', 'SPACE-EVENLY']),
  alignItems: PropTypes.oneOf(['FLEX-START', 'FLEX-END', 'CENTER', 'STRETCH', 'BASELINE', 'UNSET']),
  flexWrap: PropTypes.oneOf(['NOWRAP', 'WRAP', 'WRAP-REVERSE']),
  children: PropTypes.node,
  className: PropTypes.string,
  gap: PropTypes.string,
};

export default Flex;
