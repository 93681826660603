import { useTranslation } from 'react-i18next';

import CommentFeelingForm from '@components/Form/CommentFeelingForm';
import Window from '@components/Window/Window';

const CommentFeelingWindow = ({ companies = [], title, onEdit = () => {}, isUpdating = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();
  const titleToUse = title ?? t('Add a comment');

  return (
    <Window title={titleToUse} open={open} onClose={onClose}>
      <CommentFeelingForm companies={companies} onSubmit={onEdit} isUpdating={isUpdating} />
    </Window>
  );
};

export default CommentFeelingWindow;
