import { useCallback, useEffect, useRef } from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Hint from '@components/Common/Hint';
import { useInternalFormContext } from '@components/FormElement/Form/FormContext';
import withLazyLoading from '@components/FormElement/Form/withLazyLoading';

const useUpdateValue = ({ name, defaultValue, componentOptions }) => {
  const { setValue } = useFormContext();
  const componentOptionsRef = useRef(componentOptions);
  const { retriggeredComponents, onTrigger } = useInternalFormContext();

  useEffect(() => {
    if (componentOptionsRef.current !== componentOptions && !retriggeredComponents?.includes(name)) {
      setValue(name, defaultValue);
      componentOptionsRef.current = componentOptions;
      onTrigger(name);
    }
  }, [componentOptions, retriggeredComponents, setValue, onTrigger, name, defaultValue]);
};

const useRemoveErrorOnUnmount = (name) => {
  const { clearErrors } = useFormContext();
  const clearError = useCallback(() => clearErrors(name), [name, clearErrors]);
  useEffect(() => {
    clearError();
  }, [clearError]);
};

const ErrorHint = ({ error }) => {
  const { t } = useTranslation();
  const message = error.type === 'required' ? t('This input is required') : error.message;

  return <Hint message={message} type="ERROR" />;
};

const getHint = ({ error, hint, value }) => {
  if (error) {
    return <ErrorHint error={error} />;
  }
  if (typeof hint === 'function') {
    return hint(value);
  }
  return hint;
};

const withControlled = (Component) => {
  const ControlledComponent = ({ name, required, defaultValue, readOnly, validate, hint, value, noUpdate, onAfterChange, componentOptions, ...props }) => {
    const { t } = useTranslation();

    const _defaultValue = componentOptions?.transform ? componentOptions?.transform(defaultValue, required) : defaultValue;
    const customValidate = componentOptions?.customValidate ? componentOptions?.customValidate(t, props) : {};

    const {
      field,
      fieldState: { error },
    } = useController({
      name,
      defaultValue: _defaultValue,
      rules: {
        //if the component has a customRequired don't use the default require rule
        required: componentOptions?.customRequired ? false : required,
        validate: {
          ...(required && componentOptions?.customRequired ? { required: componentOptions.customRequired } : {}),
          ...(customValidate && typeof customValidate === 'function' ? { customValidate } : {}),
          ...(validate && typeof validate === 'function' ? { validate } : {}),
          ...(validate && typeof validate === 'object' ? validate : {}),
        },
      },
    });

    useUpdateValue({ name, defaultValue: _defaultValue, value: field.value, componentOptions });
    useRemoveErrorOnUnmount(name);

    const { register, unregister, readOnly: formReadOnly, lock, unlock } = useInternalFormContext();

    if (!name) {
      console.error(`No name has been provided Form.${Component.displayName || Component.name}`);
    }

    useEffect(() => {
      if (!noUpdate) {
        register(name, required);
      }
      return () => unregister(name);
    }, [name, register, unregister, required, noUpdate]);

    const _readOnly = formReadOnly || readOnly;

    const onFieldChange = field.onChange;
    const _hint = getHint({ error, hint, value: field.value });

    const _onChange = useCallback(
      (value) => {
        const _value = value === '' ? null : value;
        const res = onFieldChange(_value);

        onAfterChange && onAfterChange(_value);

        return res;
      },
      [onFieldChange, onAfterChange]
    );

    const _onBlur = componentOptions?.transformOnBlur
      ? () => {
          _onChange(componentOptions?.transformOnBlur(field.value));
          field.onBlur();
        }
      : field.onBlur;

    return (
      <div>
        <Component
          {...props}
          ref={field.ref}
          value={noUpdate ? value : field.value}
          onEdit={_readOnly ? () => {} : _onChange}
          name={name}
          readOnly={_readOnly}
          required={required}
          lock={lock}
          unlock={unlock}
          onBlur={_onBlur}
          error={!!error}
        />
        {_hint}
      </div>
    );
  };

  return ControlledComponent;
};

export default withLazyLoading(withControlled);
