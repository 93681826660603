import { useCastingSubmenu } from './menu/casting';
import { useGetGeneralSubmenu } from './menu/general';
import { useGetPlatformSubmenu } from './menu/platform';
import generatePlayerMenu from './menu/player';
import generateSmartUploaderMenu from './menu/smartUploader';
import generateTrackingMenu from './menu/tracking';
import generateVsgMenu from './menu/vsg';

// this useRightMenu is implemented like hook because other hooks are used inside it
export const useRightMenu = () => {
  // generateCastingMenu is returned from hook because other hook is used inside it
  const { generateCastingMenu } = useCastingSubmenu();
  // generatePlatformMenu is returned from hook because other hook is used inside it
  const { generatePlatformMenu } = useGetPlatformSubmenu();
  const { generateGeneralMenu } = useGetGeneralSubmenu();

  const types = {
    VSG: generateVsgMenu,
    PLAYER: generatePlayerMenu,
    GENERAL: generateGeneralMenu,
    CASTING: generateCastingMenu,
    PLATFORM: generatePlatformMenu,
    TRACKING: generateTrackingMenu,
    'SMART-UPLOADER': generateSmartUploaderMenu,
  };

  const generateRightMenu = (
    { solutions = [], id: companyId = '[NOT AVAILABLE]', qrcodeOption, externalVideoOption },
    selectedLeftMenu = '',
    selectedDropDown = '',
    currentRoute = '/',
    callback = () => {},
    addCallback = () => {},
    t = (v) => v
  ) =>
    types[selectedLeftMenu]
      ? types[selectedLeftMenu](companyId, solutions, selectedDropDown, currentRoute, { qrcodeOption, externalVideoOption }, callback, addCallback, t)
      : [];

  return {
    generateRightMenu,
  };
};
