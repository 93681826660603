import classNames from 'classnames';
import PropTypes from 'prop-types';

import Action from '@components/Common/Action';
import Flex from '@components/Common/Flex';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import Text from '@components/Common/Text';

import style from './style.module.css';

const LabelAction = ({ text = '', icon = '', onClick = () => {}, disabled = false, action = onClick, className = '' }) => {
  return (
    <Action action={disabled ? () => {} : action} className={classNames(style.action, className)}>
      <Flex alignItems="CENTER" justifyContent="FLEX-START" className={classNames(style.container, disabled ? style.disabled : null)}>
        {icon && <SkeepersIcon type={icon} color={disabled ? 'var(--neutral-600)' : 'var(--layout-primary-color)'} className={style.icon} title={text} />}
        <Text as="span" color={disabled ? 'var(--neutral-600)' : 'var(--layout-primary-color)'} size="xsmall" className={style.label}>
          {text}
        </Text>
      </Flex>
    </Action>
  );
};

LabelAction.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.string,
  onClick: Action.propTypes.action,
  action: Action.propTypes.action,
};

export default LabelAction;
