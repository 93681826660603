import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { getInjectionStatusTranslation } from '@app/translations';
import { getSdkBaseLib } from '@app/utils/integration';
import Button from '@components/Common/Button';
import ButtonContainer from '@components/Common/ButtonContainer';
import { Form } from '@components/FormElement/Form';

const InjectionForm = ({ injection = {}, onSubmit = () => {}, onDelete, isLoading = false }) => {
  const { t } = useTranslation();
  const methods = useForm();
  const status = getInjectionStatusTranslation(t);
  const extraLib = getSdkBaseLib();

  return (
    <Form onSubmit={onSubmit} isUpdating={isLoading} methods={methods}>
      <Form.Label title={t('Hostname')} name="hostname">
        <Form.Input name="hostname" required defaultValue={injection.hostname} />
      </Form.Label>
      <Form.Label title={t('Script')} name="script">
        <Form.CodeEditor required name="script" defaultValue={injection.script} extraLib={extraLib} />
      </Form.Label>
      <Form.Label title={t('Script')} name="status">
        <Form.Select
          name="status"
          required
          defaultValue={injection.status || 'DEBUG'}
          options={Object.keys(status).map((key) => ({
            label: status[key],
            value: key,
          }))}
        />
      </Form.Label>
      <ButtonContainer position="CENTER">
        {onDelete && (
          <Button
            theme="SECONDARY"
            color="red"
            label={t('Delete')}
            loading={isLoading}
            action={() => {
              onDelete(injection);
            }}
          />
        )}
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default InjectionForm;

InjectionForm.propTypes = {
  injection: PropTypes.shape({
    hostname: PropTypes.string,
    script: PropTypes.string,
    status: PropTypes.oneOf(['ENABLED', 'DEBUG', 'DISABLED']),
  }),
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  isLoading: PropTypes.bool,
};
