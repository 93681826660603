import { useTranslation } from 'react-i18next';

import Window from '@components/Window/Window';

const BasicWindow = ({ title, content = '', onClose = () => {}, open = false }) => {
  const { t } = useTranslation();
  const titleToUse = title ?? t('Basic window');

  return (
    <Window title={titleToUse} open={open} onClose={onClose}>
      {content}
    </Window>
  );
};

export default BasicWindow;
