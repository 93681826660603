import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Button from '@components/Common/Button';
import Hint from '@components/Common/Hint';
import { useInternalFormContext } from '@components/FormElement/Form/FormContext';

const SubmitButton = ({ label, ...props }) => {
  const { t } = useTranslation();
  const labelToUse = label ?? t('Save');
  const {
    formState: { dirtyFields, errors },
  } = useFormContext();

  const { skipDirtyCheck, isUpdating, isLocked } = useInternalFormContext();
  const _isDirty = skipDirtyCheck || Object.keys(dirtyFields).length > 0;
  const errorCount = Object.keys(errors).length;
  const disabled = isLocked || !!errorCount || !_isDirty;

  return (
    <div>
      <Button
        {...props}
        type="FORM"
        label={labelToUse}
        loading={isUpdating}
        disabled={props.disabled !== null && props.disabled !== undefined ? props.disabled : disabled}
      />
      {errorCount >= 1 && <Hint message={t('{{errorCount}} error', { errorCount })} type="ERROR" />}
    </div>
  );
};

export default SubmitButton;
