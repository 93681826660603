import classNames from 'classnames';
import PropTypes from 'prop-types';

import SkeepersIcon from '@components/Common/SkeepersIcon';

import style from './style.module.css';

const IconList = ({ children, className = '' }) => <ul className={classNames(style.list, className)}>{children}</ul>;

IconList.Element = ({ icon, color, children, className = '' }) => (
  <li className={classNames(style.row, className)} style={color ? { color } : null}>
    {icon && (
      <div className={style.icon}>
        <SkeepersIcon type={icon} color={color} />
      </div>
    )}
    {children}
  </li>
);

export default IconList;

IconList.propTypes = {
  /** Add a className on the component */
  className: PropTypes.string,
  /** children of the component */
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};
