import { useNavigate } from 'react-router-dom';

import ROUTES, { compareRoute, compareStartsRoute } from '@app/routes';
import { routingMap } from '@hooks/solutions';
import useSearch from '@hooks/useSearch';

export const useCastingSubmenu = () => {
  const { menu, followup: followupId } = useSearch();
  const navigate = useNavigate();

  const generateCastingMenu = (companyId, solutions, selectedDropDown, currentRoute, { qrcodeOption, followUpOption }, callback, addCallback, t) => [
    ...solutions
      .filter((t) => t.type === 'CASTING')
      .map((s, i) => ({
        name: s.name ? s.name : t('Casting #{{index}}', { index: i + 1 }),
        selected: false,
        open: selectedDropDown === `CASTING-${s.id}` || selectedDropDown === `CASTING-CONFIGURATION-${s.id}`,
        onClick: () => {
          callback(`CASTING-${s.id}`);
        },
        children: [
          {
            name: t('Data'),
            icon: 'ANALYTICS',
            selected: compareRoute(`company/${companyId}/casting/${s.id}/data`, currentRoute),
            onClick: `/company/${companyId}/casting/${s.id}/data`,
          },
          {
            name: t('Follow-up'),
            icon: 'ROCKET_LAUNCH',
            selected: menu === 'CASTING' && s.id === followupId,
            onClick: () => navigate(ROUTES.FOLLOWUP.path({ companyId, solutionId: s.id }, { menu: 'CASTING', followup: s.id, tab: 'APPLICATIONS' })),
          },
          {
            name: t('Applications'),
            icon: 'USERS',
            selected: compareStartsRoute(ROUTES.PLATFORM_APPLICATION_LIST.path({ companyId, solutionId: s.id }), currentRoute, true) && !followupId,
            onClick: ROUTES.PLATFORM_APPLICATION_LIST.path({ companyId, solutionId: s.id }),
          },
          {
            name: t('Configuration'),
            icon: 'SETTING',
            selected: false,
            open: selectedDropDown === `CASTING-CONFIGURATION-${s.id}`,
            onClick: () => {
              callback(selectedDropDown === `CASTING-CONFIGURATION-${s.id}` ? `CASTING-${s.id}` : `CASTING-CONFIGURATION-${s.id}`);
            },
            children: [
              {
                name: t('Casting'),
                selected: compareRoute(ROUTES.CASTING_CONFIGURATION.path({ companyId, solutionId: s.id }), currentRoute),
                onClick: ROUTES.CASTING_CONFIGURATION.path({ companyId, solutionId: s.id }),
              },
              {
                name: t('Mails'),
                selected: compareRoute(
                  ROUTES.MAILS_TEMPLATES_CONFIGURATION.path({ companyId, solutionId: s.id, solutionType: routingMap[s.type] }),
                  currentRoute
                ),
                onClick: ROUTES.MAILS_TEMPLATES_CONFIGURATION.path({ companyId, solutionId: s.id, solutionType: routingMap[s.type] }),
              },
              {
                name: t('Webhooks'),
                selected: compareRoute(ROUTES.WEBHOOKS_SOLUTION_LIST.path({ companyId, solutionId: s.id, solutionType: 'casting' }), currentRoute),
                onClick: ROUTES.WEBHOOKS_SOLUTION_LIST.path({ companyId, solutionId: s.id, solutionType: 'casting' }),
              },
            ],
          },
        ],
      })),
    {
      name: t('New casting'),
      icon: 'ADD',
      selected: false,
      onClick: addCallback,
    },
  ];

  return {
    generateCastingMenu,
  };
};
