import PropTypes from 'prop-types';

import style from './style.module.css';

const DropDown = ({ children, open, className }) => <div className={`${style.dropdown} ${open ? style.open : style.close} ${className}`}>{children}</div>;

DropDown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  open: PropTypes.bool,
  classname: PropTypes.string,
};

DropDown.defaultProps = {
  children: null,
  className: '',
  open: false,
};

export default DropDown;
