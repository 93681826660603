import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useValidateAfterEnd, useValidateBeforeStart, useValidateInRange } from '@app/utils/contract';
import ButtonContainer from '@components/Common/ButtonContainer';
import { Form, useForm } from '@components/FormElement/Form';

const CreateContractForm = ({ onSubmit = () => {}, isLoading = false }) => {
  const { t } = useTranslation();
  const methods = useForm();
  const { subscriptionStartDate, subscriptionEndDate } = methods.watch();

  const validateAfterEnd = useValidateAfterEnd({ subscriptionEndDate }, t);
  const validateInRange = useValidateInRange({ subscriptionEndDate, subscriptionStartDate }, t);
  const validateBeforeStart = useValidateBeforeStart({ subscriptionStartDate }, t);

  const beforeEndRange = { to: subscriptionEndDate };

  return (
    <Form onSubmit={onSubmit} methods={methods} isUpdating={isLoading}>
      <Form.Label title={t('Title')}>
        <Form.Input name="title" />
      </Form.Label>
      <Form.Label title={t('Signing Date')}>
        <Form.DateInput name="signingDate" allowedRange={beforeEndRange} validate={validateAfterEnd} />
      </Form.Label>
      <Form.Label title={t('Subscription start date')}>
        <Form.DateInput name="subscriptionStartDate" allowedRange={beforeEndRange} validate={validateAfterEnd} />
      </Form.Label>
      <Form.Label title={t('Subscription end date')}>
        <Form.DateInput name="subscriptionEndDate" allowedRange={{ from: subscriptionStartDate }} validate={validateBeforeStart} />
      </Form.Label>
      <Form.Label title={t('Termination Date')}>
        <Form.DateInput name="terminationDate" allowedRange={{ from: subscriptionStartDate, to: subscriptionEndDate }} validate={validateInRange} />
      </Form.Label>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default CreateContractForm;

CreateContractForm.propTypes = {
  contract: PropTypes.shape({
    title: PropTypes.string,
    signingDate: PropTypes.string,
    subscriptionStartDate: PropTypes.string,
    subscriptionEndDate: PropTypes.string,
    terminationDate: PropTypes.string,
    terminationClause: PropTypes.string,
    denunciationDate: PropTypes.string,
    automaticRenewal: PropTypes.bool,
    platformType: PropTypes.oneOf(['UPANDCAST', 'VCP', 'UNKNOWN']),
    options: PropTypes.string,
    videoGoal: PropTypes.number,
    videoGoalPeriod: PropTypes.oneOf(['WEEK', 'MONTH', 'YEAR', 'CONTRACT']),
    contractType: PropTypes.oneOf(['SAAS', 'PACKAGE', 'UNKNOWN']),
    pricingPlatform: PropTypes.number,
    pricingVideo: PropTypes.number,
    specifics: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};
