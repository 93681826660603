import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonContainer from '@components/Common/ButtonContainer';
import Hint from '@components/Common/Hint';
import { Form, useForm } from '@components/FormElement/Form';

const getHints = (t) => ({
  REGULAR: { type: 'INFO', message: t('Can create preset and configuration.') },
  PRESET_ONLY: { type: 'INFO', message: t('Can create presets but not configurations.') },
  CONFIG_ONLY: { type: 'INFO', message: t('Can create configuration but not presets.') },
  ABSTRACT: { type: 'INFO', message: t('Cannot create presets.') },
});

const CreateTemplateForm = ({ templates = [], isUpdating = false, onSubmit = () => {} }) => {
  const { t } = useTranslation();
  const methods = useForm();
  const hints = getHints(t);

  return (
    <Form buttonLabel={t('Create')} onSubmit={onSubmit} isUpdating={isUpdating} methods={methods}>
      <Form.Label title={t('Name')} name="name">
        <Form.Input name="name" required />
      </Form.Label>
      <Form.Label title={t('Type')} name="type">
        <Form.Select
          required
          name="type"
          options={[
            { label: t('Abstract'), value: 'ABSTRACT' },
            { label: t('Presets only'), value: 'PRESET_ONLY' },
            { label: t('Configs only'), value: 'CONFIG_ONLY' },
            { label: t('Regular'), value: 'REGULAR' },
          ]}
          defaultValue="REGULAR"
          hint={(type) => {
            const hint = hints[type];
            return hint ? <Hint {...hint} /> : null;
          }}
        />
      </Form.Label>
      <Form.Label title={t('Parent')} name="parentId">
        <Form.Select name="parentId" options={templates.map((template) => ({ label: template.name, value: template.id }))} isClearable />
      </Form.Label>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default CreateTemplateForm;

CreateTemplateForm.propTypes = {
  isUpdating: PropTypes.bool,
  onSubmit: PropTypes.func,
};
