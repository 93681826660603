import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Block from '@components/Common/Block';
import ButtonContainer from '@components/Common/ButtonContainer';
import { Form } from '@components/FormElement/Form';

const DistributionsSolutionsForm = ({ distributionsSolutions, onFormSubmit }) => {
  const { t } = useTranslation();
  const methods = useForm();

  const _onSubmit = (v) => onFormSubmit(v.distributors?.join('; '));

  return (
    <Block>
      <Form methods={methods} onSubmit={_onSubmit}>
        <Form.Label title={t('Channels')} name="distributors">
          <Form.CheckboxGroup
            name="distributors"
            options={distributionsSolutions?.map((solution) => ({
              value: solution.name,
              icon: solution.type,
              label: solution.name,
            }))}
            defaultValue={[]}
            multi
          />
        </Form.Label>
        <ButtonContainer position="LEFT">
          <Form.SubmitButton label={t('Update')} />
        </ButtonContainer>
      </Form>
    </Block>
  );
};

export default DistributionsSolutionsForm;

DistributionsSolutionsForm.propsTypes = {
  distributionsSolutions: PropTypes.array.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
};
