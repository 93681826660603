import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import SquaredButton from '@components/Common/SquareButton';

import style from './style.module.css';

/**
 * Amount of time added/subtracted when forwarding/rewinding video, in milliseconds
 */
const span = 10000;

const PlayerControls = ({ isPlaying, duration, currentTime, onPlay = () => {}, onPause = () => {}, onSeek = () => {} }) => {
  const { t } = useTranslation();
  const onRewind = () => onSeek(currentTime - span < 0 ? 0 : currentTime - span);
  const onFastForward = () => onSeek(currentTime + span > duration ? duration : currentTime + span);

  return (
    <div className={style.container}>
      <SquaredButton
        className={classNames(style.button, style.big)}
        icon="REWIND"
        onClick={onRewind}
        title={t('-{{seconds}}s', { seconds: span / 1000 })}
        disabled={currentTime - span <= 0}
      />
      <SquaredButton
        className={classNames(style.button, style.big)}
        icon={isPlaying ? 'PAUSE' : 'PLAY'}
        onClick={isPlaying ? onPause : onPlay}
        title={isPlaying ? 'Pause' : 'Play'}
      />
      <SquaredButton
        className={classNames(style.button, style.big)}
        icon="FAST_FORWARD"
        onClick={onFastForward}
        title={t('+{{seconds}}s', { seconds: span / 1000 })}
        disabled={currentTime + span >= duration}
      />
    </div>
  );
};

export default PlayerControls;
