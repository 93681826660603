import { SkpAlert, SkpText } from '@skeepers/skeepers-ui-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Flex from '@components/Common/Flex';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import Text from '@components/Common/Text';
import { useGetUploadTasksStatus } from '@hooks/projects/upload';

import style from './style.module.css';

const UploadLoading = ({ tasks, onEnded = () => {} }) => {
  const { t } = useTranslation();
  const { processingTasksStatus, uploadTasksStatus, stopPolling } = useGetUploadTasksStatus(
    tasks.map((t) => t.id),
    tasks.map((t) => t.uploadTaskId),
    'YOUTUBE',
    {
      pollInterval: 2000,
    }
  );

  const findStatusForProjectId = (taskId, taskList) => {
    const task = taskList?.find((ts) => ts.id === taskId);
    switch (task?.status) {
      case 'FAILED':
        return (
          <span className={style.itemFailed}>
            <SkeepersIcon type="CLOSE" />
            {t('Failed')}
          </span>
        );
      case 'WORKING':
        return (
          <span className={style.itemWorking}>
            <SkeepersIcon type="CLOCK" />
            {t('Working')}
          </span>
        );
      case 'ENDED':
        return (
          <span className={style.itemEnded}>
            <SkeepersIcon type="CHECK" />
            {t('Ended')}
          </span>
        );
      default:
      case 'PENDING':
        return (
          <span className={style.itemPending}>
            <SkeepersIcon type="CLOCK" />
            {t('Pending')}
          </span>
        );
    }
  };

  if (processingTasksStatus?.concat(uploadTasksStatus)?.every((t) => t.status === 'FAILED' || t.status === 'ENDED')) {
    stopPolling();
    onEnded();
  }

  return (
    <div>
      {processingTasksStatus?.some((t) => t.status !== 'FAILED' && t.status !== 'ENDED') && <p className={style.hint}>{t('Auto refresh every 2 seconds')}</p>}
      <div>
        <SkpText bold text={t('Processing tasks')}></SkpText>
        <SkpText
          skpClass={style.mt}
          text={t(
            'In order to export the video to youtube we first need to create a final version with music, logo and legal information burned into it. You can follow below the progress for each video.'
          )}
          size="xsmall"
        ></SkpText>

        <ul className={style.uploadList}>
          {tasks.map((task) => (
            <li key={task.id} className={style.uploadListItem}>
              <Flex>
                <span>{task.project_title}</span>
                {findStatusForProjectId(task.id, processingTasksStatus)}
              </Flex>
            </li>
          ))}
        </ul>
      </div>

      <div>
        <SkpText bold text={t('Youtube export tasks')}></SkpText>
        <SkpText
          skpClass={classNames(style.mt, style.mb)}
          text={t('Follow here the progress of the youtube export process for your video once the processing is over.')}
          size="xsmall"
        ></SkpText>
        {uploadTasksStatus?.length ? (
          <ul className={style.uploadList}>
            {uploadTasksStatus.map((uploadTaskStatus) => (
              <li key={uploadTaskStatus.id} className={style.uploadListItem}>
                <Flex flexDirection="COLUMN" alignItems="STRETCH" margin="NONE" gap="0.5rem">
                  <Flex>
                    <span>{tasks.find((task) => task.uploadTaskId === uploadTaskStatus.id)?.project_title}</span>
                    {findStatusForProjectId(uploadTaskStatus.id, uploadTasksStatus)}
                  </Flex>
                  {uploadTaskStatus?.error && (
                    <Text size="xsmall" color="var(--color-error)">
                      {t('Upload field because you achieved upload limit for today')}
                    </Text>
                  )}
                </Flex>
              </li>
            ))}
          </ul>
        ) : (
          <SkpAlert text={t('No youtube export process started yet. The processing to generate the final video first need to finish.')} type="info"></SkpAlert>
        )}
      </div>
    </div>
  );
};

export default UploadLoading;

UploadLoading.propTypes = {
  tasks: PropTypes.array.isRequired,
  onEnded: PropTypes.func,
};
