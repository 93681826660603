import { useLocation } from 'react-router-dom';

const useSearch = (defaultOptions = {}) => {
  const searchParams = new URLSearchParams(useLocation().search);
  const params = [...searchParams.entries()].reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  const page = Number(params.page, 10);
  const size = Number(params.size, 10);

  return {
    ...defaultOptions,
    ...params,
    ...(page && Number.isInteger(page) ? { page } : { page: defaultOptions?.page || 1 }),
    ...(size && Number.isInteger(size) ? { size } : { size: defaultOptions?.size || 25 }),
  };
};

export default useSearch;
