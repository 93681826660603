import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getPictureLink } from '@app/imageResizer';
import Action from '@components/Common/Action';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import Input from '@components/FormElement/Inputs/Input';

import style from './style.module.css';

const FileElement = ({ onChangeFileName = () => {}, onDeleteFile = () => {}, type, filenamesMode, file, local, multiple }) => {
  const { t } = useTranslation();
  const [blobUrl] = useState(file.blob || local ? URL.createObjectURL(local ? file : file.blob) : null);

  return (
    <div className={style.fileListItem} style={{ margin: type === 'IMAGE' ? '10px' : 0, minWidth: type === 'IMAGE' ? '100px' : 0 }}>
      {type === 'IMAGE' && multiple && (
        <div className={style.image} style={{ backgroundImage: `url(${file.url ? getPictureLink(file.url, { h: 450, w: 450 }) : blobUrl})` }} />
      )}
      <div className={style.mediaTitleContainer}>
        {filenamesMode === 'READONLY' && <div className={classNames(style.verticalAlign, style.mediaTitle, style.mediaTitleReadOnly)}>{file.fileName}</div>}
        {filenamesMode === 'EDITABLE' && (
          <Input
            className={classNames(style.verticalAlign, style.mediaTitle)}
            placeholder={file.fileName || t('No name')}
            value={file.fileName}
            onEdit={(fileName) => onChangeFileName(fileName)}
            name="title"
          />
        )}
        {(file.isUploaded || local) && (
          <Action className={style.link} action={local ? blobUrl : file.url}>
            {filenamesMode === 'HIDDEN' ? (
              <span className={classNames(style.verticalAlign, style.downloadText)}>{t('Download')}</span>
            ) : (
              <SkeepersIcon type="DOWNLOAD" className={style.downloadIcon} />
            )}
          </Action>
        )}
        {!file.isUploaded && !local && (
          <div className={style.link}>
            {' '}
            <span className={style.spinner} />{' '}
          </div>
        )}
        <Action className={style.link} action={onDeleteFile}>
          <SkeepersIcon type="TRASH" className={style.downloadIcon} />
        </Action>
      </div>
    </div>
  );
};

export default FileElement;
