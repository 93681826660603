import ROUTES, { compareRoute } from '@app/routes';

const submenu = (companyId, solutions, selectedDropDown, currentRoute, { qrcodeOption, followUpOption }, callback, addCallback, t) => [
  ...solutions
    .filter((t) => t.type === 'TRACKING')
    .map((s, i) => ({
      name: s.name ? s.name : t('Tracking #{{index}}', { index: i + 1 }),
      selected: false,
      open: selectedDropDown === `TRACKING-${s.id}`,
      onClick: () => {
        callback(`TRACKING-${s.id}`);
      },
      children: [
        {
          name: t('Data'),
          icon: 'ANALYTICS',
          selected: compareRoute(ROUTES.TRACKING_OVERVIEW.path({ companyId, solutionId: s.id }), currentRoute),
          onClick: ROUTES.TRACKING_OVERVIEW.path({ companyId, solutionId: s.id }),
        },
        {
          name: t('Audits'),
          icon: 'COGS',
          selected: compareRoute(ROUTES.AUDITS_SOLUTION.path({ companyId, solutionId: s.id, solutionType: 'tracking' }), currentRoute),
          onClick: ROUTES.AUDITS_SOLUTION.path({ companyId, solutionId: s.id, solutionType: 'tracking' }),
        },
        {
          name: t('Configuration'),
          icon: 'SETTING',
          selected: compareRoute(ROUTES.SOLUTION_CONFIGURATION.path({ companyId, solutionType: 'tracking', solutionId: s.id }), currentRoute),
          onClick: ROUTES.SOLUTION_CONFIGURATION.path({ solutionType: 'tracking', companyId, solutionId: s.id }),
        },
      ],
    })),
  {
    name: t('New tracking'),
    icon: 'ADD',
    selected: false,
    onClick: addCallback,
  },
];

export default submenu;
