import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getWebhookTypes } from '@app/translations';
import ButtonContainer from '@components/Common/ButtonContainer';
import { Form, useForm } from '@components/FormElement/Form';

const WebhookForm = ({ webhook = {}, solutionType = null, onSubmit = () => {}, isUpdating = false }) => {
  const { t } = useTranslation();
  const methods = useForm();
  const webhookTypes = getWebhookTypes(t);

  return (
    <Form methods={methods} onSubmit={onSubmit} isUpdating={isUpdating}>
      <Form.Label title={t('Name')} name="name">
        <Form.Input name="name" required defaultValue={webhook?.name} />
      </Form.Label>
      <Form.Label name={t('Type')} title="type">
        <Form.Select
          name="type"
          options={Object.entries(solutionType ? webhookTypes[solutionType] : { ...webhookTypes.casting, ...webhookTypes.platform }).map(([value, label]) => ({
            label,
            value,
          }))}
          defaultValue={webhook?.type}
          required
        />
      </Form.Label>
      <Form.Label title={t('Url')} name="url">
        <Form.UrlInput name="url" required defaultValue={webhook?.url} />
      </Form.Label>
      <Form.Label name={t('Active')} title="active">
        <Form.Switch name="active" defaultValue={webhook.id ? webhook.active : true} />
      </Form.Label>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default WebhookForm;

WebhookForm.propTypes = {
  webhook: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    url: PropTypes.string,
    active: PropTypes.bool,
  }),
  solutionType: PropTypes.string,
  onSubmit: PropTypes.func,
  isUpdating: PropTypes.bool,
};
