import { useTranslation } from 'react-i18next';

import Hint from '@components/Common/Hint';
import Form from '@components/FormElement/Form/Form';

import style from './style.module.css';

const TemplateName = ({ existingTemplateNames = [] }) => {
  const { t } = useTranslation();

  return (
    <div className={style.titleInputContainer}>
      <Form.Label title={t('QR code template name')} name="title">
        <Form.Input
          name="title"
          placeholder={t('exemple: "Shop PLV" or "Shop France"')}
          required
          validate={(v) => (existingTemplateNames.includes(v) ? t('This template name has already been used. Please take another one') : null)}
          hint={<Hint message={t('Please note that the naming of your template is important. It will be used in the data report.')} type="INFO" />}
        />
      </Form.Label>
    </div>
  );
};

export default TemplateName;
