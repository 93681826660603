import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Link as ReactLink } from 'react-router-dom';

import style from './style.module.css';

const Action = ({ action = false, download = null, target = null, className = '', dataCy = '', state = null, children }) => {
  const actionType = action && typeof action;

  if (actionType === 'function')
    return (
      <span data-cy={dataCy} className={classNames(style.container, className)} onClick={action}>
        {children}
      </span>
    );

  if (actionType === 'string' && action[0] === '/')
    return (
      <ReactLink state={state} className={classNames(style.container, className)} to={action} target={target} data-cy={dataCy}>
        {children}
      </ReactLink>
    );

  if (actionType === 'string' && action[0] !== '/') {
    let href = action;
    let _target = target || '_blank';
    //if the action is a email address
    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,25})+$/.test(action)) {
      href = `mailto:${action}`;
      _target = null;
    }

    return (
      <a
        data-cy={dataCy}
        className={classNames(className, style.container)}
        href={href}
        rel="noopener noreferrer"
        download={download}
        {...(_target ? { target: _target } : {})}
      >
        {children}
      </a>
    );
  }

  return (
    <span className={className} data-cy={dataCy}>
      {children}
    </span>
  );
};

Action.propTypes = {
  download: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  className: PropTypes.string,
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.bool]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default Action;
