import { useTranslation } from 'react-i18next';

import CreateSocialMediaVideoEditedForm from '@components/Form/CreateSocialMediaVideoEditedForm';
import CreateSocialMediaVideoForm from '@components/Form/CreateSocialMediaVideoForm';
import Window from '@components/Window/Window';

const CreateSocialMediaVideo = ({ templates, subtitles, url, onSubmit = () => {}, onClose = () => {}, open = false, isEdited = false, storyboard }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Generate new export')} open={open} onClose={onClose}>
      {isEdited ? (
        <CreateSocialMediaVideoEditedForm onSubmit={onSubmit} subtitles={subtitles} />
      ) : (
        <CreateSocialMediaVideoForm onSubmit={onSubmit} templates={templates} subtitles={subtitles} url={url} storyboard={storyboard} />
      )}
    </Window>
  );
};

export default CreateSocialMediaVideo;
