import moment from 'moment';
import PropTypes from 'prop-types';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import ButtonContainer from '@components/Common/ButtonContainer';
import { Form, useForm } from '@components/FormElement/Form';

const EventForm = ({ event = {}, onSubmit = () => {}, isLoading = false }) => {
  const { t } = useTranslation();
  const methods = useForm();
  const currentDateRef = useRef();

  if (!currentDateRef.current) {
    currentDateRef.current = moment().unix();
  }

  return (
    <Form methods={methods} onSubmit={onSubmit} isUpdating={isLoading}>
      <Form.Label title={t('Date')} name="date">
        <Form.TimestampInput name="date" required defaultValue={event?.date || currentDateRef.current} />
      </Form.Label>
      <Form.Label title={t('Title')} name="title">
        <Form.Input name="title" required defaultValue={event?.title} />
      </Form.Label>
      <Form.Label title={t('Comment')} name="comment">
        <Form.Textarea name="comment" defaultValue={event?.comment} />
      </Form.Label>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default EventForm;

EventForm.propTypes = {
  event: PropTypes.shape({
    date: PropTypes.number,
    title: PropTypes.string,
    comment: PropTypes.string,
  }),
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};
