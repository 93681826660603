import PropTypes from 'prop-types';

import { getPictureLink } from '@app/imageResizer';

import style from './style.module.css';

//formalae: a:b => (b/a) * 100
const paddingTops = {
  '1:1': '100%',
  '4:3': '75%',
  '16:9': '56.25%',
};

const ImageRatio = ({ src = '', ratio = '16:9', width = '100%', className = '', children, ...props }) => (
  <div style={{ width }}>
    <div
      className={`${style.image} ${className}`}
      style={{
        paddingTop: paddingTops[ratio] || paddingTops['16:9'],
        backgroundImage: `url(${getPictureLink(src, { w: 200, h: 200 })}})`,
      }}
      {...props}
    >
      {children}
    </div>
  </div>
);

export default ImageRatio;

ImageRatio.propTypes = {
  src: PropTypes.string,
  ratio: PropTypes.oneOf(['1:1', '4:3', '16:9']),
  className: PropTypes.string,
  width: PropTypes.string,
};
