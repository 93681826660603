import { useTranslation } from 'react-i18next';

import Action from '@components/Common/Action';
import CodeSnippet from '@components/Common/CodeSnippet';
import DateTime from '@components/Common/DateTime';
import DropdownBlock from '@components/Common/DropdownBlock';
import HtmlPreview from '@components/Common/HtmlPreview';
import Cell from '@components/TableElement/Cell';
import { Table } from '@components/TableElement/Table';
import Window from '@components/Window/Window';
import useDropdown from '@hooks/useDropdown';

import style from './style.module.css';

const MailWindow = ({ mail = {}, title, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();
  const titleToUse = title ?? t('Mail');
  const dropdowHandler = useDropdown();
  const { opened, toggle } = dropdowHandler(0);

  return (
    <Window title={titleToUse} open={open} onClose={onClose}>
      {mail.contentHtml && mail.contentText && mail.queuedAt && (
        <>
          <DropdownBlock title={t('Mail preview')} open={opened} toggle={toggle}>
            <HtmlPreview
              className={style.htmlPreview}
              title={title}
              html={mail.contentHtml.replace(/<img.*height="1".*width="1".*src=".*\/mails\/.*\/logo\.gif".*>/, '')}
            />
          </DropdownBlock>
          <CodeSnippet title={t('HTML content')} content={mail.contentHtml} defaultOpen={false} />
          <CodeSnippet title={t('Text content')} content={mail.contentText} defaultOpen={false} />
        </>
      )}

      <div>
        <Table>
          <Table.Header>
            <Table.HeaderElement title={t('Key')} className={style.keyContainer} />
            <Table.HeaderElement title={t('Value')} className={style.valueContainer} />
          </Table.Header>
          <Table.Row>
            <Table.RowElement className={style.keyContainer}>
              <Cell>{t('ID')}</Cell>
            </Table.RowElement>
            <Table.RowElement className={style.valueContainer}>
              <Cell>{mail.id}</Cell>
            </Table.RowElement>
          </Table.Row>
          <Table.Row>
            <Table.RowElement className={style.keyContainer}>
              <Cell>{t('Mail type')}</Cell>
            </Table.RowElement>
            <Table.RowElement className={style.valueContainer}>
              <Cell>{mail.type.name}</Cell>
            </Table.RowElement>
          </Table.Row>
          <Table.Row>
            <Table.RowElement className={style.keyContainer}>
              <Cell>{t('Solution name')}</Cell>
            </Table.RowElement>
            <Table.RowElement className={style.valueContainer}>
              <Cell>{mail.solution?.name || '-'}</Cell>
            </Table.RowElement>
          </Table.Row>
          <Table.Row>
            <Table.RowElement className={style.keyContainer}>
              <Cell>{t('Recipient')}</Cell>
            </Table.RowElement>
            <Table.RowElement className={style.valueContainer}>
              <Cell>{mail.recipientEmail || mail.recipientUser?.id}</Cell>
            </Table.RowElement>
          </Table.Row>
          <Table.Row>
            <Table.RowElement className={style.keyContainer}>
              <Cell>{t('Subject')}</Cell>
            </Table.RowElement>
            <Table.RowElement className={style.valueContainer}>
              <Cell>{mail.subject}</Cell>
            </Table.RowElement>
          </Table.Row>
          <Table.Row>
            <Table.RowElement className={style.keyContainer}>
              <Cell>{t('History')}</Cell>
            </Table.RowElement>
            <Table.RowElement className={style.valueContainer}>
              <Cell className={style.cell}>
                <DateTime timestamp={mail.scheduledAt} relative={false} hour /> : {t('Scheduled')}
                <br />
                {mail.queuedAt && (
                  <>
                    <DateTime timestamp={mail.queuedAt} relative={false} hour /> : {t('Queued')}
                    <br />
                  </>
                )}
                {mail.sentAt && (
                  <>
                    <DateTime timestamp={mail.sentAt} relative={false} hour /> : {t('Sent')}
                    <br />
                  </>
                )}
                {mail.openedAt &&
                  mail.openedAt.map((openedAt, i) => (
                    <span key={i}>
                      <DateTime timestamp={openedAt} relative={false} hour /> : {t('Opened')}
                      <br />
                    </span>
                  ))}
              </Cell>
            </Table.RowElement>
          </Table.Row>
          <Table.Row>
            <Table.RowElement className={style.keyContainer}>
              <Cell>{t('Variables')}</Cell>
            </Table.RowElement>
            <Table.RowElement className={style.valueContainer}>
              <Cell className={style.cell}>{mail.variables}</Cell>
            </Table.RowElement>
          </Table.Row>
          {mail.attachments.length > 0 && (
            <Table.Row>
              <Table.RowElement className={style.keyContainer}>
                <Cell>{t('Attachments')}</Cell>
              </Table.RowElement>
              <Table.RowElement className={style.valueContainer}>
                <Cell>
                  {mail.attachments.map((attachment, i) => (
                    <span key={i}>
                      <Action action={attachment.url} className={style.action}>
                        {attachment.name}
                      </Action>
                      <br />
                    </span>
                  ))}
                </Cell>
              </Table.RowElement>
            </Table.Row>
          )}
        </Table>
      </div>
    </Window>
  );
};

export default MailWindow;
