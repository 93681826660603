import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonContainer from '@components/Common/ButtonContainer';
import { Form, useForm } from '@components/FormElement/Form';

const UpdateAvatarForm = ({ isUpdating = false, onSubmit = () => {} }) => {
  const { t } = useTranslation();
  const methods = useForm();

  return (
    <Form methods={methods} onSubmit={onSubmit} isUpdating={isUpdating}>
      <Form.UploadInput title={t('Avatar')} name="picture" filenamesMode="HIDDEN" validTypes={['IMAGE']} required />
      <ButtonContainer position="CENTER">
        <Form.SubmitButton isUpdating={isUpdating} />
      </ButtonContainer>
    </Form>
  );
};

export default UpdateAvatarForm;

UpdateAvatarForm.propTypes = {
  user: PropTypes.shape({
    picture: PropTypes.string,
  }),
  isUpdating: PropTypes.bool,
  onSubmit: PropTypes.func,
};
