import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonContainer from '@components/Common/ButtonContainer';
import Hint from '@components/Common/Hint';
import { Form, useForm } from '@components/FormElement/Form';

const CreateBrowserWorkerForm = ({ isLoading = false, onSubmit = () => {} }) => {
  const { t } = useTranslation();
  const methods = useForm();

  return (
    <Form onSubmit={onSubmit} methods={methods} isUpdating={isLoading}>
      <Form.Label title={t('Browser')}>
        <Form.SelectCard
          name="browser"
          required
          options={[
            { title: t('Chromium'), description: t('Blink + V8'), value: 'chromium', icon: 'CHROME' },
            { title: t('Firefox'), description: t('Gecko + SpiderMonkey'), value: 'firefox', icon: 'FIREFOX', disabled: true },
            { title: t('Safari'), description: t('WebKit'), value: 'safari', icon: 'SAFARI', disabled: true },
          ]}
          hint={<Hint message={t('Idle workers are automatically terminated after ~10 minutes.')} />}
        />
        <ButtonContainer position="CENTER">
          <Form.SubmitButton />
        </ButtonContainer>
      </Form.Label>
    </Form>
  );
};

export default CreateBrowserWorkerForm;

CreateBrowserWorkerForm.propTypes = {
  isUpdating: PropTypes.bool,
  onSubmit: PropTypes.func,
};
