import { useTranslation } from 'react-i18next';

import ExportPresetValuesForm from '@components/Form/ExportPresetValuesForm';
import Window from '@components/Window/Window';

const ExportPresetValuesWindow = ({ presets = [], values = [], onSubmit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Export missing values')} open={open} onClose={onClose}>
      {values.length > 0 ? (
        <>
          <p>{t('There are {{count}} missing value to translate', { count: values.length })}</p>
          <ExportPresetValuesForm presets={presets} values={values} onSubmit={onSubmit} isUpdating={isLoading} />
        </>
      ) : (
        <p>
          <span role="img" aria-label="congratulations">
            🎉
          </span>
          {t('Congratulations, all values are set!')}
        </p>
      )}
    </Window>
  );
};

export default ExportPresetValuesWindow;
