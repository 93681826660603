import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { isValidURL } from '@app/utils';

import style from './style.module.css';

const UrlInput = forwardRef(
  (
    {
      value = '',
      name = '',
      placeholder = '',
      readOnly = false,
      disabled = false,
      required = false,
      className = '',
      onFocus = () => {},
      onBlur = () => {},
      onEdit = () => {},
      error = false,
    },
    ref
  ) => {
    const onChange = onEdit ? (event) => onEdit(event.target.value) : null;
    return (
      <input
        type="url"
        value={value || ''}
        className={classNames(style.input, className, { [style.error]: error })}
        name={name}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled || readOnly}
        required={required}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        ref={ref}
      />
    );
  }
);

UrlInput.customValidate = (t) => (value) => !value || isValidURL(value) || t('This is not a valid url');

UrlInput.displayName = 'UrlInput';

UrlInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

export default UrlInput;
