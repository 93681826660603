import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import { useInternalFormContext } from '@components/FormElement/Form/FormContext';
import LabelComponent from '@components/FormElement/Label';

const Label = ({ title, name, className, children, info }) => {
  const { requiredFields } = useInternalFormContext();
  const {
    formState: { errors },
  } = useFormContext();
  const required = requiredFields.includes(name);
  const hasError = !!errors?.[name];

  return (
    <LabelComponent title={title} className={className} name={name} info={info} required={required} error={hasError}>
      {children}
    </LabelComponent>
  );
};

export default Label;

Label.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  required: PropTypes.bool,
  children: PropTypes.any,
};
