import { useMutation, useQuery } from '@apollo/client';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { THUMBNAIL_STATUS } from '@app/utils/thumbnails';
import { useNotificationModal } from '@contexts/NotificationModalContext';
import CREATE_THUMBNAIL from '@graphql/projects/createThumbnail.graphql';
import DELETE_THUMBNAIL from '@graphql/projects/deleteThumbnail.graphql';
import GET_THUMBNAILS from '@graphql/projects/getThumbnails.graphql';

export const useCreateThumbnail = (projectId, { onCompleted = () => { }, onError = () => { } } = {}) => {
  const [update, { loading, error }] = useMutation(CREATE_THUMBNAIL, {
    refetchQueries: [{
      query: GET_THUMBNAILS,
      variables: { projectId, isDeleted: false }
    }],
    awaitRefetchQueries: true,
    onCompleted,
    onError,
  });

  const createThumbnail = (outputId, position, url) => update({
    variables: {
      projectId,
      outputId,
      position: parseFloat(position),
      url,
    },
  });

  return [createThumbnail, { updating: loading, error }];
};

export const useGetThumbnails = (projectId, skip, isDeleted) => {
  const { t } = useTranslation();
  const { addDefaultSuccessNotification } = useNotificationModal();
  const [isPolling, setIsPolling] = useState(false);

  const { data, loading, error, refetch, startPolling, stopPolling } = useQuery(GET_THUMBNAILS, {
    variables: { projectId, ...(isDeleted !== undefined && { isDeleted }) },
    fetchPolicy: 'cache-and-network',
    skip,
  });

  const thumbnails = useMemo(() => data?.getThumbnails || [], [data?.getThumbnails]);

  const isRendering = useMemo(() =>
    thumbnails.some(thumbnail => thumbnail.status === THUMBNAIL_STATUS.RENDERING) || false,
    [thumbnails]
  );

  useEffect(() => {
    if (!isPolling && isRendering) {
      setIsPolling(true);
      startPolling(10000);
    } else if (isPolling && !isRendering) {
      stopPolling();
      setIsPolling(false);
      addDefaultSuccessNotification(t, 'Thumbnail generation completed');
    }
  }, [isPolling, isRendering, startPolling, stopPolling, addDefaultSuccessNotification, t]);

  const setThumbnailsPolling = useCallback((value) => {
    setIsPolling(value);
  }, [setIsPolling]);

  return {
    thumbnails,
    loading,
    error,
    refetch,
    isRendering,
    setThumbnailsPolling,
    isPolling,
  };
};

export const useDeleteThumbnail = (projectId, { onCompleted = () => { }, onError = () => { } } = {}) => {
  const [deleteMutation, { loading, error }] = useMutation(DELETE_THUMBNAIL, {
    refetchQueries: [{
      query: GET_THUMBNAILS,
      variables: { projectId, isDeleted: false }
    }],
    awaitRefetchQueries: true,
    onCompleted,
    onError,
  });

  const deleteThumbnail = (id) => deleteMutation({
    variables: { id },
  });

  return [deleteThumbnail, { deleting: loading, error }];
};
