import { useTranslation } from 'react-i18next';

import Button from '@components/Common/Button';
import Flex from '@components/Common/Flex';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import Window from '@components/Window/Window';

import style from './style.module.css';

const WorkflowPauseWindow = ({ onClose = () => {}, open = false, idleDuration = null, onResume = () => {} }) => {
  const { t } = useTranslation();

  const onCloseWindow = () => {
    onResume();
    onClose();
  };

  return (
    <Window open={open} onClose={onCloseWindow} maxWidth="320px">
      <Flex justifyContent="SPACE-BETWEEN" flexDirection="COLUMN" alignItems="CENTER" className={style.container}>
        <SkeepersIcon type="PAUSE" className={style.icon} />
        <div className={style.textContainer}>
          <span className={style.text}>{t('Task on pause')}</span>
          <br />
          {!!idleDuration && <span>{`${t('You have been inactive for')} ${idleDuration} ${t('seconds')}`}</span>}
        </div>
        <Button label={t('Resume')} action={onCloseWindow} />
      </Flex>
    </Window>
  );
};

export default WorkflowPauseWindow;
