import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getOrderProblemType } from '@app/translations';
import Button from '@components/Common/Button';
import ButtonContainer from '@components/Common/ButtonContainer';
import { Form, useForm } from '@components/FormElement/Form';

const OrderProblemForm = ({ problem = {}, step, onSubmit = () => {}, onClose = () => {}, isUpdating = false }) => {
  const { t } = useTranslation();
  const methods = useForm();

  const typeOptions = useMemo(() => Object.entries(getOrderProblemType(t)).map(([value, label]) => ({ value, label })), [t]);
  const showComment = step === 'CREATE' || step === 'UPDATE';
  const showResolution = step === 'RESOLVE';

  return (
    <Form isUpdating={isUpdating} onSubmit={onSubmit} methods={methods} skipDirtyCheck={showResolution}>
      <Form.Label name="type" title={t('What’s the problem ?')}>
        <Form.Select name="type" defaultValue={problem?.type} options={typeOptions} readOnly={showResolution} required />
      </Form.Label>
      {showComment && <Form.Label title={t('Add a comment')} name="comment">
        <Form.Input name="comment" defaultValue={problem?.comment} />
      </Form.Label>}
      {showResolution && <Form.Label title={t('Add a resolution comment')} name="resolution">
        <Form.Input name="resolution" defaultValue={problem?.resolution} />
      </Form.Label>}
      <ButtonContainer position="CENTER">
        <Button label={t('Cancel')} action={onClose} theme={Button.themes.SECONDARY} color="var(--neutral-300)" />
        <Form.SubmitButton label={t('Yes')} />
      </ButtonContainer>
    </Form>
  );
};

export default OrderProblemForm;

OrderProblemForm.propTypes = {
  problem: PropTypes.shape({
    type: PropTypes.string,
    comment: PropTypes.string,
    resolution: PropTypes.string,
  }),
  step: PropTypes.oneOf(['CREATE', 'UPDATE', 'RESOLVE']),
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  isUpdating: PropTypes.bool,
};
