import { useTranslation } from 'react-i18next';

import RouteForm from '@components/Form/RouteForm';
import Window from '@components/Window/Window';

const CreateSolutionRouteWindow = ({ onSubmit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Add a new route')} open={open} onClose={onClose}>
      <RouteForm isUpdating={isLoading} onSubmit={onSubmit} />
    </Window>
  );
};

export default CreateSolutionRouteWindow;
