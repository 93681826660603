import { useTranslation } from 'react-i18next';

import Button from '@components/Common/Button';
import Window from '@components/Window/Window';

import style from './style.module.css';

const ErrorWindow = ({ onClose = () => {}, open = false, error = '', onCancel = () => {}, textButton = 'Retry', title = null }) => {
  const { t } = useTranslation();

  const onCloseWindow = () => {
    onCancel();
    onClose();
  };

  return (
    <Window onClose={onCloseWindow} open={open} title={title === null ? t('Error') : title} maxWidth={'320px'}>
      <span style={{ textAlign: 'center', display: 'block', marginBottom: '20px' }}>{error}</span>
      <Button label={textButton} action={onCloseWindow} className={style.button} />
    </Window>
  );
};

export default ErrorWindow;
