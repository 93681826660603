import { useUnleashContext } from '@unleash/proxy-client-react';
import moment from 'moment';
import { Suspense, useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { logout } from '@app/auth';
import { getLanguageCode } from '@app/i18n';
import ROUTES from '@app/routes';
import { updateCSATUserVariableAndStart } from '@app/utils/externalModules/ifmCSATModule';
import { showSupportWidgetIfPermissions } from '@app/utils/support';
import ContentArea from '@components/Layout/ContentArea';
import Header from '@components/Layout/Header';
import PageLoading from '@components/Layout/PageLoading';
import { RedirectProvider } from '@contexts/RedirectContext';
import { isUserClient, useGetPartialCompanies } from '@hooks/companies';
import { useGetResourcePolicies } from '@hooks/permissions';
import useSearch from '@hooks/useSearch';
import ClientMenuContainer from '@views/Layout/ClientMenuContainer';

import MenuContainer from './MenuContainer';

const getLocationParams = (location = '', { menu } = {}) => {
  const [, categoryName, companyId, solutionType, solutionId] = location.pathname.split('/');
  return { solutionType: (menu || solutionType)?.toUpperCase(), companyId: categoryName === 'company' ? companyId : null, solutionId };
};

const Layout = ({ connectedUser }) => {
  const location = useLocation();
  const { companies } = useGetPartialCompanies();
  const search = useSearch();
  const updateContext = useUnleashContext();

  const { companyId, solutionType } = getLocationParams(location, search);
  updateContext({ userId: companyId });
  const { policies } = useGetResourcePolicies([`user:${connectedUser.id}`]);
  const [menuStatus, setMenuStatus] = useState(true);
  const [key, setKey] = useState(new Date());

  const isFullScreenPage = ['/studio', '/workflows/subtitles', '/workflows/studio', '/workflows/review', '/editor'].some((partialPath) =>
    location.pathname.toLowerCase().includes(partialPath)
  );
  const company = companies.find(({ id }) => id === companyId);

  const permissions = company?.allowedActions;
  let isClient = company ? isUserClient(company) : false;

  useEffect(() => {
    if (!connectedUser || !company) return;
    const startDate = moment(new Date(connectedUser.createdAt * 1000)).format('DD/MM/YYYY');
    const companyName = isClient ? company.name : "SKEEPERS";
    updateCSATUserVariableAndStart(startDate, connectedUser.email, connectedUser.displayName, getLanguageCode(), companyName);
  }, [connectedUser, company, isClient])


  if (policies && (!isClient || (isClient && company?.followUpOption))) {
    showSupportWidgetIfPermissions(policies, connectedUser);
  }

  return (
    <RedirectProvider
      onRedirect={() => {
        setKey(new Date());
      }}
    >
      <Header
        user={connectedUser}
        canOpenMenu={!!companyId}
        open={menuStatus}
        onToogleClick={() => {
          setMenuStatus(!menuStatus);
        }}
        onLogout={logout}
        onHome={companies.length === 1 ? ROUTES.DASHBOARD.path({ companyId: companies[0].id }) : ROUTES.HOME.path()}
        companyId={companyId}
        companies={companies}
        freezeActions={isFullScreenPage}
      />
      {companyId &&
        permissions &&
        (isClient ? (
          <ClientMenuContainer key={key} open={menuStatus} company={company} pathname={location.pathname} queryParams={location.search} />
        ) : (
          <MenuContainer key={key} open={menuStatus} companyId={companyId} solutionType={solutionType} pathname={`${location.pathname}${location.search}`} />
        ))}
      <ContentArea
        menu={companyId && menuStatus ? 'LARGE' : 'HIDDEN'}
        padding={isFullScreenPage ? 'NONE' : 'AUTO'}
        commitTheme={isFullScreenPage ? 'LIGHT' : 'DARK'}
      >
        <Suspense fallback={<PageLoading />}>
          <Outlet />
        </Suspense>
      </ContentArea>
    </RedirectProvider>
  );
};

export default Layout;
