import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import Hint from '@components/Common/Hint';
import SkeepersIcon from '@components/Common/SkeepersIcon';

import style from './style.module.css';

const AddField = ({ readOnly = false, error = false, onlyUnique = false, maxItems = 100000, items = [], onFocus = () => {}, onBlur = () => {}, onAdd = () => {} }) => {
  const { t } = useTranslation();
  const [newItem, setNewItem] = useState('');
  const handleOnAdd = () => {
    if (newItem.trim() !== '') {
      onAdd(newItem.trim());
      setNewItem('');
    }
  };

  const handleKeyPress = (e) => {
    const code = e.keyCode || e.which;
    if (code === 13) {
      handleOnAdd();
    }
  };

  const needAlreadyInListWarning = onlyUnique && items.some((i) => i.title?.trim() === newItem?.trim());
  const cannotAdd = readOnly || needAlreadyInListWarning || items.length >= maxItems;

  return (
    <>
      <div className={classNames(style.addInput)}>
        <input
          className={classNames(style.input, { [style.error]: error })}
          value={newItem}
          onChange={(event) => setNewItem(event.target.value)}
          onKeyPress={cannotAdd ? () => {} : handleKeyPress}
          type="text"
          placeholder={t('My new element')}
          onFocus={onFocus}
          onBlur={onBlur}
          readOnly={readOnly}
        />
        <span className={`${style.addButton} ${cannotAdd ? style.readOnly : ''}`} onClick={cannotAdd ? null : handleOnAdd}>
          <SkeepersIcon type="ADD" color="#fff" className={style.icon} />
        </span>
      </div>
      {needAlreadyInListWarning && <Hint type="WARNING" message={t('This item is already in the list.')} />}
    </>
  );
};

const Line = ({
  title = '',
  description = '',
  userIndex = 0,
  withDescription = false,
  onChangeDescription = () => {},
  onChangeTitle = () => {},
  onDelete = () => {},
  readOnly = false,
  itemsLength = 0,
  minItems = 0,
  required = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className={style.line}>
      <div className={style.linePosition} data-trigger="position">
        {userIndex + 1}
      </div>
      <div className={style.lineContainer}>
        <input
          name={`title-${userIndex}`}
          type="text"
          data-index={userIndex}
          defaultValue={title}
          placeholder={t(`Add a title ${required ? '*' : ''}`)}
          onChange={onChangeTitle}
          className={`${style.lineInput} ${style.lineTitle}`}
          readOnly={readOnly}
        />
        {withDescription && (
          <input
            name={`description-${userIndex}`}
            type="text"
            data-index={userIndex}
            defaultValue={description}
            placeholder={`Add a description ${required ? '*' : ''}`}
            onChange={onChangeDescription}
            className={style.lineInput}
            readOnly={readOnly}
          />
        )}
      </div>
      {!readOnly && itemsLength > minItems && (
        <div onClick={() => onDelete(userIndex)}>
          <SkeepersIcon type="TRASH" color="var(--layout-primary-color)" className={style.trashIcon} />
        </div>
      )}
    </div>
  );
};

const LineList = ({
  items = [],
  withDescription = false,
  readOnly = false,
  onChangeDescription = () => {},
  onChangeTitle = () => {},
  onDelete = () => {},
  minItems = 0,
  required = false,
}) => (
  <ul className={style.list}>
    {items?.map((value, index) => (
      <Line
        key={value.id}
        title={value.title}
        description={value.description}
        userIndex={index}
        withDescription={withDescription}
        onChangeDescription={onChangeDescription}
        onChangeTitle={onChangeTitle}
        onDelete={onDelete}
        readOnly={readOnly}
        itemsLength={items.length}
        minItems={minItems}
        required={required}
      />
    ))}
  </ul>
);

const listToString = (list = []) => (Array.isArray(list) ? JSON.stringify(list.map(({ title = '', description = '' }) => ({ title, description }))) : '');

const getValues = (newValues = [], placeholder = []) => {
  // if newValues === placeholder the value has not been modified and we should return the original value
  if (listToString(newValues) === listToString(placeholder)) {
    return placeholder;
  }

  return newValues;
};

export const FormList = forwardRef(
  (
    {
      value,
      placeholder = [],
      onlyUnique = false,
      withDescription = false,
      error = false,
      readOnly = false,
      onFocus = () => {},
      onBlur = () => {},
      onEdit = () => {},
      minItems = 0,
      maxItems = 100000,
      required = false,
    },
    forwardedRef
  ) => {
    const [items, setItems] = useState((value || placeholder)?.map((e) => ({ ...e, id: uuid() })) ?? []);

    useEffect(() => {
      if (items.length < minItems) {
        for (let i = items.length; i < minItems; i++) {
          const newItems = [...items, { title: '', description: '', id: uuid() }];
          setItems(newItems);
        }
      }
    }, [items, minItems]);

    const onSubmit = (newValues) => {
      const values = getValues(newValues, placeholder);
      onEdit(values.map((item) => ({ description: item.description, title: item.title })));
    };

    const onChange = (newValue, index) => {
      const newItems = items.map((e, i) => (Number(index) === i ? { ...e, ...newValue } : e));
      setItems(newItems);
      onSubmit(newItems);
    };

    const onChangeTitle = ({ target }) => {
      onChange({ title: target.value?.trim() }, target.dataset.index);
    };

    const onChangeDescription = ({ target }) => {
      onChange({ description: target.value?.trim() }, target.dataset.index);
    };

    const onAdd = (title) => {
      const newItems = [...items, { title, description: '', id: uuid() }];
      setItems(newItems);

      if (title) {
        onSubmit(newItems);
      }
    };

    const onDelete = (index) => {
      const newItems = items.filter((_, i) => i !== index);
      setItems(newItems);
      onSubmit(newItems);
    };

    return (
      <div ref={forwardedRef}>
        {minItems !== maxItems && (
          <AddField
            onFocus={onFocus}
            onBlur={onBlur}
            onAdd={onAdd}
            readOnly={readOnly}
            error={error}
            maxItems={maxItems}
            items={items}
            onlyUnique={onlyUnique}
          />
        )}
        <LineList
          items={items}
          withDescription={withDescription}
          readOnly={readOnly}
          onChangeDescription={onChangeDescription}
          onChangeTitle={onChangeTitle}
          onDelete={items.length > minItems ? onDelete : () => {}}
          minItems={minItems}
          required={required}
        />
      </div>
    );
  }
);
FormList.propTypes = {
  error: PropTypes.bool,
  value: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
  withDescription: PropTypes.bool,
  onEdit: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
};
