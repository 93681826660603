import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import ReactSelect from 'react-select';

import style from './style.module.css';

const parseCompany = (company) => ({ value: company.id, label: company.name });

export const CompanySelect = ({ companies = [], companyId = null, onChange = () => {} }) => {
  const { t } = useTranslation();

  if (!companies.length) {
    return null;
  }

  const currentCompany = companyId ? companies.find(({ id }) => id === companyId) : null;
  const value = currentCompany ? parseCompany(currentCompany) : null;
  const options = companies.map(parseCompany);

  return (
    <ReactSelect
      placeholder={t('Select your company')}
      className={style.select}
      classNamePrefix={'select'}
      onChange={({ value }) => onChange(value)}
      options={options}
      value={value}
    />
  );
};

CompanySelect.propTypes = {
  companies: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  companyId: PropTypes.string,
  onChange: PropTypes.func,
};

export default CompanySelect;
