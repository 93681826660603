import mixpanel from 'mixpanel-browser';

import { MIXPANEL_TOKEN } from '@app/config';

const devEnv = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

mixpanel.init(MIXPANEL_TOKEN);

const actions = {
  track: (name, props) => {
    if (!devEnv) {
      mixpanel.track(name, {
        ...props,
        env: process.env.NODE_ENV,
      });
    }
  },
};

export const Mixpanel = actions;
