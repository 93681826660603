import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { socialMediaFitTranslation } from '@app/translations';
import ButtonContainer from '@components/Common/ButtonContainer';
import { Form, useForm } from '@components/FormElement/Form';

const AddMediasForm = ({ onSubmit = () => {}, isUpdating = false, filenamesMode = 'EDITABLE' }) => {
  const { t } = useTranslation();
  const methods = useForm();

  return (
    <Form methods={methods} onSubmit={(values) => onSubmit({ ...values, rotateDelta: Number(values.rotateDelta) })} isUpdating={isUpdating}>
      <Form.UploadInput
        title={t('Medias')}
        name="medias"
        type="VIDEO"
        filenamesMode={filenamesMode}
        multiple
        required
        validTypes={['VIDEO', 'IMAGE', 'AUDIO']}
      />
      <Form.Label title={t('Rotation')} name="rotateDelta">
        <Form.Select
          name="rotateDelta"
          defaultValue="0"
          options={[
            { value: '0', label: t('Disabled') },
            { value: '90', label: t('90° to the right') },
            { value: '270', label: t('90° to the left') },
            { value: '180', label: t('180°') },
          ]}
        />
      </Form.Label>
      <Form.Label title={t('Fit mode')} name="fitNormalize">
        <Form.Select
          name="fitNormalize"
          defaultValue="LIGHT"
          options={[
            { value: 'LIGHT', label: socialMediaFitTranslation.LIGHT(t) },
            { value: 'FULL', label: socialMediaFitTranslation.FULL(t) },
            { value: 'BLACK', label: socialMediaFitTranslation.BLACK(t) },
            { value: 'WHITE', label: socialMediaFitTranslation.WHITE(t) },
          ]}
        />
      </Form.Label>
      <Form.Label title={t('Auto detect and crop black borders')} name="autoCrop">
        <Form.Switch name="autoCrop" defaultValue={true} />
      </Form.Label>
      <Form.Label title={t('Normalize audio track')} name="audioNormalize">
        <Form.Switch name="audioNormalize" />
      </Form.Label>
      <Form.Label title={t('Stabilize the video')} name="videoStabilization">
        <Form.Switch name="videoStabilization" />
      </Form.Label>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default AddMediasForm;

AddMediasForm.propTypes = {
  onSubmit: PropTypes.func,
  isUpdating: PropTypes.bool,
  filenamesMode: PropTypes.oneOf(['EDITABLE', 'READONLY', 'HIDDEN']),
};
