import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@components/Common/Button';
import Flex from '@components/Common/Flex';
import { Textarea } from '@components/FormElement/Inputs';
import List from '@components/FormElement/Lists/List';
import Window from '@components/Window/Window';

import style from './style.module.css';

const FinishWorkflowTaskWindow = ({ type, value, taskType = '', onEdit = () => {}, onSubmit = () => {}, onCancel = () => {}, open = false, onClose = () => {} }) => {
  const { t } = useTranslation();
  const [_value, setValue] = useState(value);
  const [loading, setLoading] = useState(false);
  const _onEdit = (newValue) => {
    setValue(newValue);
    onEdit(newValue);
  };

  const closeWindow = () => {
    onCancel();
    onClose();
  };

  const _onSubmit = () => {
    setLoading(true);
    onSubmit(_value).then(onClose);
  };

  return (
    <Window open={open} onClose={closeWindow}>
      <div className={style.title}>{type === 'VALIDATION' ? t('Did everything go well?') : t('Oops, a problem?')}</div>
      <div className={style.subtitle}>{type === 'VALIDATION' ? t('Leave a comment if needed') : t('What difficulties have you encountered?')}</div>
      <Textarea value={_value.comment} onEdit={(comment) => _onEdit({ ..._value, comment })} />
      {type === 'SIGNAL' && taskType === 'REVIEW' && <List value={_value.checklist} onEdit={(checklist) => _onEdit({ ..._value, checklist })} />}
      <Flex justifyContent="CENTER">
        <Button
          label={type === 'VALIDATION' ? t('Validate') : t('Signal')}
          action={_onSubmit}
          color={type === 'VALIDATION' ? 'var(--color-success)' : 'var(--tag-orange)'}
          loading={loading}
        />
      </Flex>
    </Window>
  );
}

export default FinishWorkflowTaskWindow;
