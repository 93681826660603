import classNames from 'classnames';
import PropTypes from 'prop-types';

import Action from '@components/Common/Action';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import Spinner from '@components/Common/Spinner';

import styles from './style.module.css';

const PRIMARY = 'PRIMARY';
const SECONDARY = 'SECONDARY';
const TERTIARY = 'TERTIARY';
const OUTLINE = 'OUTLINE';

const themeClassNames = {
  [PRIMARY]: styles.primary,
  [SECONDARY]: styles.secondary,
  [TERTIARY]: styles.tertiary,
  [OUTLINE]: styles.outline,
};

const Button = ({
  theme = PRIMARY,
  type = '',
  disabled = false,
  color = '',
  loading = false,
  label = '',
  className = '',
  icon = null,
  action = () => {},
  dataCy = null,
}) => {
  const themeClassName = themeClassNames[theme] || themeClassNames.PRIMARY;
  const buttonClassName = classNames(styles.button, themeClassName, className, {
    [styles.loading]: loading,
    [styles.disabled]: disabled || loading,
  });

  const buttonStyle = !disabled && color ? { '--button-color': color } : {};

  const content = (
    <>
      {icon ? <SkeepersIcon type={icon} className={classNames({ [styles.hidden]: loading })} /> : ''}
      <span className={classNames({ [styles.hidden]: loading })}>{label}</span>
      {loading && <Spinner type={theme} />}
    </>
  );

  if (type === 'FORM') {
    return (
      <button type="submit" className={buttonClassName} style={buttonStyle} disabled={disabled || loading} {...(dataCy && { 'data-cy': dataCy })}>
        {content}
      </button>
    );
  }

  return (
    <Action action={disabled || loading ? () => {} : action} {...(type === 'INTERNAL' ? { target: null } : {})} dataCy={dataCy}>
      <div className={buttonClassName} style={buttonStyle}>
        {content}
      </div>
    </Action>
  );
};

Button.propTypes = {
  color: PropTypes.string,
  theme: PropTypes.oneOf([PRIMARY, SECONDARY, TERTIARY, OUTLINE]),
  type: PropTypes.string,
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.bool]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  icon: PropTypes.string,
};

Button.themes = {
  PRIMARY,
  SECONDARY,
  TERTIARY,
};

export default Button;
