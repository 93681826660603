import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { isValidEmail } from '@app/utils';

import style from './style.module.css';

const EmailInput = forwardRef(
  (
    {
      value = '',
      name = '',
      placeholder = '',
      readOnly = false,
      disabled = false,
      required = false,
      className = '',
      onFocus = () => {},
      onBlur = () => {},
      onEdit = () => {},
      error = false,
    },
    ref
  ) => {
    const onChange = onEdit ? (event) => onEdit(event.target.value) : null;

    return (
      <input
        type="email"
        value={value || ''}
        className={classNames(style.input, className, { [style.error]: error })}
        name={name}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled || readOnly}
        required={required}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        ref={ref}
      />
    );
  }
);

EmailInput.customValidate = (t) => (value) => isValidEmail(value) || t('This is not a valid email.');

EmailInput.displayName = 'EmailInput';

EmailInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  className: PropTypes.string,
};

export default EmailInput;
