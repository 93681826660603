import { useTranslation } from 'react-i18next';

import { getAuditStatus } from '@app/translations';
import { getAuditResult } from '@app/utils/integration';
import Cell from '@components/TableElement/Cell';
import { Table } from '@components/TableElement/Table';

import style from './style.module.css';

const AuditGraphTooltip = ({ value, task, audit, solutionId }) => {
  const { t } = useTranslation();
  const { status, workWithAdblock, keyValid, date } = getAuditResult(t, audit, task, solutionId);

  return (
    <>
      <h5>
        {status} {date}
      </h5>
      <Table>
        <Table.Row className={style.tooltipRow}>
          <Table.RowElement>
            <Cell>{t('Quality')}</Cell>
          </Table.RowElement>
          <Table.RowElement>
            <Cell className={style.tooltipCell}>{value}</Cell>
          </Table.RowElement>
        </Table.Row>
        <Table.Row className={style.tooltipRow}>
          <Table.RowElement>
            <Cell className={style.tooltipCell}>{t('Status')}</Cell>
          </Table.RowElement>
          <Table.RowElement>
            <Cell className={style.tooltipCell}>{getAuditStatus(t)[audit.status]}</Cell>
          </Table.RowElement>
        </Table.Row>
        <Table.Row className={style.tooltipRow}>
          <Table.RowElement>
            <Cell className={style.tooltipCell}>{t('Work with adblock')}</Cell>
          </Table.RowElement>
          <Table.RowElement>
            <Cell className={style.tooltipCell}>{workWithAdblock}</Cell>
          </Table.RowElement>
        </Table.Row>
        <Table.Row className={style.tooltipRow}>
          <Table.RowElement>
            <Cell className={style.tooltipCell}>{t('Key valid')}</Cell>
          </Table.RowElement>
          <Table.RowElement>
            <Cell className={style.tooltipCell}>{keyValid}</Cell>
          </Table.RowElement>
        </Table.Row>
      </Table>
    </>
  );
};

export default AuditGraphTooltip;
