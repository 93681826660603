import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Block from '@components/Common/Block';
import Header from '@components/Common/Header';
import { Textarea } from '@components/FormElement/Inputs';

import style from './style.module.css';

const WorkflowNotesBlock = ({ text = '', onEdit = () => {}, onBlur = () => {}, onFocus = () => {} }) => {
  const { t } = useTranslation();

  return (
    <Block padding="SMALL">
      <Header as="h1" className={style.title}>
        {t('Notes')}
      </Header>
      <Textarea value={text} onEdit={onEdit} onBlur={onBlur} onFocus={onFocus} />
    </Block>
  );
}

export default WorkflowNotesBlock;

WorkflowNotesBlock.propTypes = {
  text: PropTypes.string,
  onEdit: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};
