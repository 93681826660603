import { useTranslation } from 'react-i18next';

import CreateTranslationSubtitleForm from '@components/Form/CreateTranslationSubtitleForm';
import Window from '@components/Window/Window';

const CreateTranslationSubtitleWindow = ({ subtitles = [], onSubmit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();

  const submitForm = (from, to) => {
    const { id } = subtitles.find(({ language }) => from === language);
    onSubmit(id, to);
  }

  return (
    <Window title={t('Translate subtitles')} open={open} onClose={onClose}>
      <CreateTranslationSubtitleForm subtitles={subtitles} onSubmit={submitForm} isLoading={isLoading} />
    </Window>
  );
};

export default CreateTranslationSubtitleWindow;
