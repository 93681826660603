import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonContainer from '@components/Common/ButtonContainer';
import { Form, useForm } from '@components/FormElement/Form';

const DateForm = ({ value, allowedRange = {}, onSubmit = () => {}, isLoading = false }) => {
  const { t } = useTranslation();
  const methods = useForm();

  return (
    <Form methods={methods} onSubmit={onSubmit} isUpdating={isLoading} skipDirtyCheck={moment(value).isSameOrAfter(new Date())}>
      <Form.Label title={t('Date')} name="date">
        <Form.DateInput name="date" required defaultValue={value} allowedRange={allowedRange} />
      </Form.Label>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default DateForm;

DateForm.propTypes = {
  value: PropTypes.instanceOf(Date),
  allowedRange: PropTypes.shape({
    to: PropTypes.instanceOf(Date),
    from: PropTypes.instanceOf(Date),
  }),
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};
