import { useTranslation } from 'react-i18next';

import CreatePolicyForm from '@components/Form/CreatePolicyForm';
import Window from '@components/Window/Window';

const CreatePolicyWindow = ({ onSubmit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Create resource policy')} open={open} onClose={onClose}>
      <p>{t('Resource policies define who has access to which resources.')}</p>
      <CreatePolicyForm onSubmit={onSubmit} isUpdating={isLoading} />
    </Window>
  );
};

export default CreatePolicyWindow;
