import { useTranslation } from 'react-i18next';

import CreateTimelineTemplateForm from '@components/Form/CreateTimelineTemplateForm';
import Window from '@components/Window/Window';

const CreateTimelineTemplateWindow = ({
  onClose = () => {},
  open = false,
  onSubmit = () => {},
  title,
  timelineTemplate = null,
  buttonText,
  isLoading = false,
}) => {
  const { t } = useTranslation();
  const titleToUse = title ?? t('Add a contract');

  return (
    <Window title={titleToUse} open={open} onClose={onClose}>
      <CreateTimelineTemplateForm onSubmit={onSubmit} timelineTemplate={timelineTemplate} buttonText={buttonText} isUpdating={isLoading} />
    </Window>
  );
};

export default CreateTimelineTemplateWindow;
