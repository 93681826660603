export const PAGINATION_DEFAULT = { page: 1, size: 15 };
export const PAGINATION_DEFAULT_RELAY = 15;

// The CDN will cache the API request based on this value (in sec)
export const CDN_CACHE_DELAY = 1 * 60 * 60;

export const API_URL = process.env.API_URL;
export const WS_URL = process.env.WS_URL;
export const SIGNIN_STRATEGY = process.env.SIGNIN_STRATEGY;
export const SIGNIN_URL = process.env.SIGNIN_URL;
export const BACK_OFFICE_URL = process.env.BACK_OFFICE_URL;
export const URL_IMAGE = process.env.URL_IMAGE;
export const TEESTER_URL = process.env.TEESTER_URL;
export const DOCUMENTATION_URL = process.env.DOCUMENTATION_URL;
export const PUBLIC_SDK = process.env.PUBLIC_SDK;
export const PLAYER_URL = process.env.PLAYER_URL;
export const SHARE_VIDEO_URL = process.env.SHARE_VIDEO_URL;
export const ROUTES_DOMAIN = process.env.ROUTES_DOMAIN;
export const FACEBOOK_APP_ID = process.env.FACEBOOK_APP_ID;
export const LINKEDIN_CLIENT_ID = process.env.LINKEDIN_CLIENT_ID;
export const SOCIAL_MEDIA_CONNECT_URL = process.env.SOCIAL_MEDIA_CONNECT_URL;
export const TEESTER_LEGAL_FRONT_URL = process.env.TEESTER_LEGAL_FRONT_URL;
export const TRACKING_URL = process.env.TRACKING_URL;
export const MEDIAINFO_URL = process.env.MEDIAINFO_URL;
export const GIT_REVISION = COMMIT_HASH; // eslint-disable-line no-undef
export const MIXPANEL_TOKEN = process.env.MIXPANEL_TOKEN;
export const IFM_CSAT_URL = process.env.IFM_CSAT_URL;
export const HOTJAR_ID = process.env.HOTJAR_ID;
export const SHOPPABLE_CONTENT_WIDGET_ENV = process.env.SHOPPABLE_CONTENT_WIDGET_ENV;
export const SMART_UPLOADER_URL =  process.env.SMART_UPLOADER_URL;

export const FEATURE_FLAG_CONFIG = {
  url: `${process.env.API_URL}feature-flags/proxy`,
  clientKey: process.env.FF_CLIENT_SECRET_KEY, // A client-side API token OR one of your proxy's designated client keys (previously known as proxy secrets)
  refreshInterval: 30, // How often (in seconds) the client should poll the proxy for updates
  appName: process.env.FF_APP_NAME, // The name of your application. It's only used for identifying your application
  disableMetrics: true,
};

export const HELPCENTER = {
  en: 'https://skeepers-cv.zendesk.com/hc/en-us',
  fr: 'https://skeepers-cv.zendesk.com/hc/fr',
}
