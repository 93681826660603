import classNames from 'classnames';
import PropTypes from 'prop-types';

import SkeepersIcon from '@components/Common/SkeepersIcon';

import style from './style.module.css';

const ValuesPreview = ({ children }) => <ul className={style.list}>{children}</ul>;

ValuesPreview.Item = ({ icon, name, value, success, ignored, warning }) => (
  <li className={classNames(style.item, { [style.success]: success, [style.warning]: warning, [style.ignored]: ignored })}>
    <SkeepersIcon type={icon} />
    <div className={style.key}>{name}</div>
    <code className={style.value}>{JSON.stringify(value)}</code>
  </li>
);

ValuesPreview.propTypes = {
  children: PropTypes.node,
};

ValuesPreview.Item.propTypes = {
  icon: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.any,
  success: PropTypes.bool,
  warning: PropTypes.bool,
  ignored: PropTypes.bool,
};

export default ValuesPreview;
