import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import style from './style.module.css';

// to all decimal add step
const NumberInput = forwardRef(
  (
    {
      value = undefined,
      name = '',
      placeholder = '',
      readOnly = false,
      disabled = false,
      required = false,
      min = null,
      max = null,
      step = 1,
      onFocus = () => {},
      onBlur = () => {},
      onEdit = () => {},
      className = '',
      tabIndex,
      error = false,
    },
    ref
  ) => {
    return (
      <input
        tabIndex={tabIndex}
        value={value !== null ? value + '' : ''}
        type="number"
        className={classNames(style.input, className, { [style.error]: error })}
        name={name}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled || readOnly}
        required={required}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onEdit}
        ref={ref}
        {...{
          ...(min !== null ? { min } : {}),
          ...(max !== null ? { max } : {}),
          ...(step !== null ? { step } : {}),
        }}
      />
    );
  }
);

NumberInput.displayName = 'NumberInput';

NumberInput.customValidate =
  (t, { min = null, max = null, step = 1 }) =>
  (value) => {
    const valueParsed = Number.parseInt(value);

    if (valueParsed === null || isNaN(valueParsed)) {
      return t('The value is not a number');
    }
    if (min !== null && valueParsed < min) {
      return t('The value must be over {{min}}.', { min });
    }
    if (max !== null && valueParsed > max) {
      return t('The value must be below {{max}}.', { max });
    }
    if (step !== null && !(Math.round(valueParsed / step) / (1 / step) === valueParsed)) {
      return t('The value must have a step of {{step}}.', { step });
    }
  };

NumberInput.transformOnBlur = (value) => Number(value);

NumberInput.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  /**The minimum value to accept for this input*/
  min: PropTypes.number,
  /**The maximum value to accept for this input*/
  max: PropTypes.number,
  /**A stepping interval to use when using up and down arrows to adjust the value, as well as for validation*/
  step: PropTypes.number,
};

export default NumberInput;
