import { useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import client from '@app/apollo';
import { PAGINATION_DEFAULT } from '@app/config';
import { useWindows, WINDOW_TYPES } from '@contexts/WindowContext';
import GET_APPLICATION_WORKFLOW from '@graphql/workflows/getApplicationsWorkflow.graphql';
import GET_CUSM_DASHBOARD_STATISTICS from '@graphql/workflows/getCusmDashboardStatistics.graphql';
import GET_VIDEO_WORKFLOWS from '@graphql/workflows/getVideoWorkflows.graphql';
import GET_CUSM_DASHBOARD_PROJECTS_TO_MODERATE from '@graphql/workflows/getWorkflowCusmDashboardProjectsToModerate.graphql';
import GET_CUSM_DASHBOARD_PROJECTS_WITHOUT_SUBTITLES from '@graphql/workflows/getWorkflowCusmDashboardProjectsWithoutSubtitles.graphql';
import GET_CUSM_DASHBOARD_PROJECTS_WITH_SUBTITLES from '@graphql/workflows/getWorkflowCusmDashboardProjectsWithSubtitles.graphql';
import GET_CUSM_DASHBOARD_TASK from '@graphql/workflows/getWorkflowCusmDashboardTasks.graphql';
import OUTSOURCING_END_TASK_ACTION from '@graphql/workflows/outsourcingEndTaskAction.graphql';
import GET_OUTSOURCING_GLOBAL_STATS from '@graphql/workflows/queryOutsourcingGlobalStats.graphql';
import RESET_OUTSOURCING_TASK from '@graphql/workflows/resetOutsourcingTask.graphql';
import UPDATE_OUTSOURCING_TASK from '@graphql/workflows/updateOutsourcingTask.graphql';
import { formatProjects } from '@hooks/projects';

const useGetWorkflow = (query, userId, page) =>
  useQuery(query, {
    skip: !userId,
    pollInterval: 5000,
    fetchPolicy: 'cache-and-network',
    variables: {
      userId,
      page,
    },
  });

export const useGetApplicationsWorkflow = ({ userId, page = PAGINATION_DEFAULT } = {}) => {
  const { data, error, loading } = useGetWorkflow(GET_APPLICATION_WORKFLOW, userId, page);
  const { applications, pageInfo } = data?.getApplicationsWorkflow || {};

  return {
    error,
    loading,
    applications,
    pageInfo,
  };
};

const formatWorkflow = (w) => (w ? { projects: formatProjects(w.projects), pageInfo: w.pageInfo } : null);

export const useGetVideoWorkflow = ({ userId, page = PAGINATION_DEFAULT } = {}) => {
  const {
    data: { getVideoWorkflows: { rushesWorkflow, finalReviewWorkflow, metaWorkflow, firstReviewWorkflow, editingWorkflow } = {} } = {},
    error,
    loading,
  } = useGetWorkflow(GET_VIDEO_WORKFLOWS, userId, page);

  return {
    rushesWorkflow: formatWorkflow(rushesWorkflow),
    finalReviewWorkflow: formatWorkflow(finalReviewWorkflow),
    metaWorkflow: formatWorkflow(metaWorkflow),
    firstReviewWorkflow: formatWorkflow(firstReviewWorkflow),
    editingWorkflow: formatWorkflow(editingWorkflow),
    error,
    loading,
  };
};

export const useUpdateTask = (id) => {
  const [mutate, { data, error, loading }] = useMutation(UPDATE_OUTSOURCING_TASK);

  const update = (task) => {
    return mutate({ variables: { id, task } });
  };

  return [update, { data: data?.updateOutsourcingTask, isUpdating: loading, error }];
};

export const useEndTaskAction = (id) => {
  const [mutate, { data, error, loading }] = useMutation(OUTSOURCING_END_TASK_ACTION);

  const endAction = () => {
    return mutate({ variables: { id } });
  };

  return [endAction, { data: data?.updateOutsourcingTask, isUpdating: loading, error }];
};

export const useResetTask = (id) => {
  const [mutate] = useMutation(RESET_OUTSOURCING_TASK);

  const resetTask = () => {
    return mutate({ variables: { id } });
  };

  return { resetTask };
};

export const useFinishTask = ({ taskId, taskType = '', onSignal = () => {}, onValidate = () => {}, onCancel = () => {}, onOpen = () => {}, error = false }) => {
  const { t } = useTranslation();
  const { actions } = useWindows();
  const [value, onEdit] = useState({
    comment: '',
  });
  const [updateTask, { error: errorUpdate }] = useUpdateTask(taskId);

  const onFinish = (type) => () => {
    const onSubmit = ({ comment, checklist }) => {
      return Promise.all([
        (type === 'VALIDATION' ? onValidate : onSignal)(comment, checklist),
        updateTask({
          status: type === 'VALIDATION' ? 'DONE' : 'ISSUE',
          comment,
        }),
      ]);
    };

    onOpen();
    if (type === 'VALIDATION' && error) {
      actions.open(WINDOW_TYPES.ERROR, {
        onCancel,
        textButton: t('Resume'),
        error: t('Please, double check your task'),
      });
    } else {
      actions.open(WINDOW_TYPES.WORKFLOW_FINISH_TASK, {
        onSubmit,
        onCancel,
        onEdit: onEdit,
        value,
        type,
        taskType,
      });
    }
  };

  return { noteText: value.comment, errorUpdate, checklist: value.checklist, onNoteEdit: (comment) => onEdit({ ...value, comment }), onFinish };
};

export const useOpenBriefWindow = ({ project, type, error }) => {
  const { actions } = useWindows();
  const openBriefWindow = useCallback(() => {
    if (project) {
      actions.open(WINDOW_TYPES.WORKFLOW_BRIEF, {
        project,
        type,
      });
    }
    return () => actions.close();
  }, [project, type, actions]);

  useEffect(() => {
    if (!error) {
      openBriefWindow();
    }
  }, [openBriefWindow, error]);

  return openBriefWindow;
};

// Need to use apolloClient.query to avoid miss on the cache with useQuery
// In the component we use useEffect to refresh values only when it's need
export const getOutsourcingGlobalStat = async (userId, timerange) => {
  const { error, data } = await client.query({
    query: GET_OUTSOURCING_GLOBAL_STATS,
    variables: {
      id: userId,
      timerange,
    },
  });

  return {
    dataSums: data?.getUser?.outsourcingData?.sums,
    error,
  };
};

export const useCusmDashboardTasks = (userId, status, timerange) => {
  const { data, loading, error } = useQuery(GET_CUSM_DASHBOARD_TASK, {
    fetchPolicy: 'network-only',
    variables: {
      id: userId,
      status,
      timerange,
    },
  });

  return {
    tasks: data?.getUser?.outsourcingData?.tasks,
    loading,
    error,
  };
};

export const useCusmDashboardProjectsToModerate = (userId, timerange) => {
  const { data, loading, error } = useQuery(GET_CUSM_DASHBOARD_PROJECTS_TO_MODERATE, {
    fetchPolicy: 'network-only',
    variables: {
      id: userId,
      timerange,
    },
  });

  return {
    projects: data?.getUser?.outsourcingData?.projectsToModerate,
    loading,
    error,
  };
};

export const useCusmDashboardProjectsWithSubtitles = (userId, timerange, fullText = '', { first, after, last, before } = {}) => {
  const { data, loading, fetchMore, error } = useQuery(GET_CUSM_DASHBOARD_PROJECTS_WITH_SUBTITLES, {
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'network-only',
    variables: {
      id: userId,
      timerange,
      first,
      after,
      last,
      before,
      filter: { fullText },
    },
  });

  const mappedProjects = data?.getUser?.outsourcingData?.subtitlesPublished?.edges?.map(({ node }) => {
    const logValidate = node.logs?.find(({ eventType }) => eventType === 'PROJECT_VALIDATE');
    const logSubtitle = node.logs?.find(({ eventType }) => eventType === 'PROJECT_SUBTITLE_PUBLISH');

    return {
      ...node,
      logValidate,
      logSubtitle,
    };
  });

  return {
    data: data?.getUser?.outsourcingData?.subtitlesPublished,
    projects: mappedProjects ?? [],
    fetchMore,
    loading,
    error,
  };
};

export const useCusmDashboardProjectsWithoutSubtitles = (userId, timerange, fullText = '', { first, after, last, before } = {}) => {
  const { data, loading, fetchMore, error } = useQuery(GET_CUSM_DASHBOARD_PROJECTS_WITHOUT_SUBTITLES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: userId,
      timerange,
      first,
      after,
      last,
      before,
      filter: { fullText }
    },
  });

  const mappedProjects = data?.getUser?.outsourcingData?.subtitlesNotPublished?.edges?.map(({ node }) => {
    const logValidate = node.logs?.find(({ eventType }) => eventType === 'PROJECT_VALIDATE');

    return {
      ...node,
      logValidate,
    };
  });

  return {
    data: data?.getUser?.outsourcingData?.subtitlesNotPublished,
    projects: mappedProjects ?? [],
    fetchMore,
    loading,
    error,
  };
};

export const useGetCusmDashboardStatistics = (userId, timerange) => {
  const { data, loading, error } = useQuery(GET_CUSM_DASHBOARD_STATISTICS, {
    fetchPolicy: 'network-only',
    variables: {
      id: userId,
      timerange,
    },
    skip: !userId,
  });

  return {
    stats: data?.getUser?.outsourcingData,
    loading,
    error,
  };
}
