import classNames from 'classnames';
import PropTypes from 'prop-types';
import { createContext, useContext } from 'react';

import style from './style.module.css';

const GridContext = createContext({ columns: {} });

const getBreakpoint = (value, defaultValue) => (typeof value === 'number' ? value : defaultValue);

const generateBreakpoints = (value) => {
  if (!value || typeof value === 'number')
    return {
      sm: 1,
      md: value <= 2 ? value : 2,
      lg: value || 1,
    };

  const sm = getBreakpoint(value.sm, 1);
  const md = getBreakpoint(value.md, sm);
  const lg = getBreakpoint(value.lg, md);

  return {
    sm: sm,
    md: md,
    lg: lg,
  };
};

const getMinBreakpoints = (value1, value2) =>
  Object.entries(value1).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: Math.min(value, value2[key]),
    }),
    {}
  );

const generateColumnBreakpoints = (name, { sm, md, lg }, transform = (_) => _) => ({
  [`${name}-sm`]: transform(sm),
  [`${name}-md`]: transform(md),
  [`${name}-lg`]: transform(lg),
});

export const Grid = ({ columns = 1, minRows = 1, gap = 'SMALL', alignItems = '', className = '', children }) => {
  const columnStyle = style[`column${columns}`];
  const _columns = generateBreakpoints(columns);
  const styleObject = {
    ...generateColumnBreakpoints('--grid-columns', _columns),
    '--grid-min-rows': minRows,
    '--grid-gap': gap,
    alignItems,
  };

  return (
    <GridContext.Provider value={{ columns: _columns }}>
      <div className={classNames(style.grid, columnStyle, className)} style={styleObject}>
        {children}
      </div>
    </GridContext.Provider>
  );
};

export const GridItem = ({ rowSpan = 1, columnSpan, children }) => {
  const { columns } = useContext(GridContext);
  const columnSpans = getMinBreakpoints(generateBreakpoints(columnSpan), columns);

  const styleObject = {
    ...(rowSpan ? { gridRow: `span ${rowSpan}` } : {}),
    ...generateColumnBreakpoints('--grid-item-column', columnSpans, (value) => `span ${value}`),
  };

  return (
    <div style={styleObject} className={style.gridItem}>
      {children}
    </div>
  );
};

Grid.propTypes = {
  className: PropTypes.string,
  alignItems: PropTypes.oneOf(['flex-start', 'center', 'flex-end']),
  gap: PropTypes.oneOf(['NONE', 'SMALL']),
  columns: PropTypes.oneOf([1, 2, 3]),
  minRows: PropTypes.number,
};
