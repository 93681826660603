import { useTranslation } from 'react-i18next';

import AuditTaskForm from '@components/Form/AuditTaskForm';
import Window from '@components/Window/Window';

const AuditTaskWindow = ({ task = {}, title, onSubmit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();
  const titleToUse = title ?? t('Add an audit task');

  return (
    <Window title={titleToUse} open={open} onClose={onClose}>
      <AuditTaskForm task={task} onSubmit={onSubmit} isLoading={isLoading} />
    </Window>
  );
};

export default AuditTaskWindow;
