import { findIndex } from 'lodash';
import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { createRef, Component } from 'react';

import Spinner from '@components/Common/Spinner';
import { GRID } from '@components/Studio/Common/config';
import Clock from '@components/Studio/TimelinePlayerV2/clock';

import styles from './style.module.css';

const PLAYER_FPS = 1 / GRID;
const PLAYER_RESOLUTION_WIDTH = 640;
const PLAYER_RESOLUTION_HEIGHT = 360;
const PRELOAD_FILES = 2;

const floor = (d) => Math.round(d * PLAYER_FPS) / PLAYER_FPS;

class TimelinePlayerV2 extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = createRef();
    this.refContainer = createRef();
    this.state = {
      isLoading: false,
      width: 0,
      height: 0,
      resolution: [],
      leftMargin: '0',
      checkLoaded: PRELOAD_FILES,
    };
    this.progress = 0;
    this.progressOffSet = 0;
    this.previousProgress = Date.now();
    this.clockFrame = false;
    this.isReadeableInterval = false;
    this.refreshId = false;
    this.renderFrame = false;
    this.timer = false;
    this.streams = this.props.streams;
    this.blobUrls = {};
    this.timeline = this.props.timeline.map((element) => ({
      dom: null,
      id: element.id,
      startSetTimeout: null,
      isPreloaded: false,
      stopSetTimeout: null,
      unloadSetTimeout: null,
      element: {
        inputId: element.inputId,
        type: element.input?.type,
        filename: element.input?.filename,
        line: element.line,
        playPromise: null,
        isLoading: true,
        isLoaded: false,
        position: element.position * 1000,
        duration: element.duration * 1000,
        start: element.start * 1000,
        speed: element.speed,
        volume: element.volume,
      },
    }));
    this._pureResize = () => {
      if (
        this.state.width !== this.refContainer.current.clientWidth ||
        this.state.height !== this.refContainer.current.clientHeight ||
        !this.state.resolution.length
      ) {
        const width = this.refContainer.current.clientWidth;
        const height = this.refContainer.current.clientHeight;

        const reso1 = [Math.round(width), Math.round((width * this.ratioHeight) / this.ratioWidth)];
        const reso2 = [Math.round((height * this.ratioWidth) / this.ratioHeight), Math.round(height)];
        const resolution = reso1[0] <= width && reso1[1] <= height ? reso1 : reso2;

        this.setState({
          width,
          height,
          resolution,
          leftMargin: this.ratioWidth > this.ratioHeight ? `${Math.round((width - resolution[0]) / 2)}px` : '0',
        });
      }
    };
    const [ratioWidth, ratioHeight] = props.ratio.split(':').map((e) => parseInt(e, 10));
    this.ratioWidth = ratioWidth;
    this.ratioHeight = ratioHeight;
  }

  _fetchVideo = async (toFetch) => {
    return await fetch(toFetch)
      .then((r) => r.blob())
      .then((blob) => URL.createObjectURL(blob))
      .catch((e) => console.error('downloadVideo: Can not load file', e));
  }

  _downloadVideo = async (chunk) => {
    const blob = await this._fetchVideo(this.streams[chunk.element.inputId]);

    this.blobUrls[chunk.element.inputId] = {
      isLoading: false,
      blobUrl: blob,
    };

    chunk.element.isLoaded = true;
  };

  _loadChunks = async (position) => {
    if (this.timeline.length <= 0) {
      return;
    }
    this.setState({ isLoading: true });

    const toLoad = this.timeline
      .slice(position, position + PRELOAD_FILES)
      .map(async (chunk) => {
        if (!this.blobUrls[chunk.element.inputId]) {
          this.blobUrls[chunk.element.inputId] = {
            isLoading: true,
          };

          await this._downloadVideo(chunk);
        }
        this._preload(chunk);
        return null;
      })
      .filter(Boolean);

    await Promise.all(toLoad);

    this.setState({
      isLoading: Object.keys(this.blobUrls).some((key) => this.blobUrls[key].isLoading),
    });
  }

  _preload = (chunk) => {
    const blobUrl = this.blobUrls[chunk.element.inputId];
    if (!blobUrl) {
      return;
    }

    if (!chunk.dom) {
      chunk.dom = document.createElement('video');
    }
    chunk.dom.src = blobUrl.blobUrl;

    chunk.dom.volume = chunk.element.volume === 0 ? 0 : parseFloat(chunk.element.volume);
    chunk.dom.defaultPlaybackRate = 1;
    chunk.dom.playbackRate = parseFloat(chunk.element.speed) || 1;
    // Seek to
    chunk.dom.currentTime = chunk.element.start / 1000 + (this.progress > chunk.element.position / 1000 ? this.progress - chunk.element.position / 1000 : 0);
    chunk.isPreloaded = true;
  };

  _unloadSetTimeout = (chunk, t) => {
    const time = t < 0 ? 0 : t;
    return setTimeout(() => {
      if (chunk.dom) {
        try {
          const prom = chunk.dom.remove();
          if (prom) {
            prom.catch((e) => console.error('unloadSetTimeout: Can not get dom.remove', e));
          }
          } catch (e) {
        }
      }
      chunk.dom = false;
    }, time);
  };

  _setPreloadTimeout = (chunk) => {
    return setTimeout(async () => {
      await this._loadChunks(this.state.checkLoaded);
      this.setState({
        checkLoaded: this.state.checkLoaded + PRELOAD_FILES,
      });
    }, chunk.element.position + chunk.element.duration - 2500);
  }

  _stopSetTimeout = (chunk, t) => {
    const time = t < 0 ? 0 : t;
    return setTimeout(() => {
      try {
        const prom = chunk.element.playPromise ? chunk.element.playPromise.then((_) => chunk.dom.pause()) : chunk.dom.pause();
        if (prom) {
          prom.catch((e) => console.error('stopSetTimeout: Can not get playPromise', e));
        }
      } catch (e) {
        console.error('stopSetTimeout', e);
      }
    }, time);
  };

  _startSetTimeout = (chunk, t) => {
    const time = t < 0 ? 0 : t;
    return setTimeout(() => {
      if (this.blobUrls[chunk.element.inputId]?.isLoading) {
        this.pause();
        this.setState({ isLoading: true });
        return;
      }

      try {
        chunk.element.playPromise = chunk.dom.play();
        if (chunk.element.playPromise) {
          chunk.element.playPromise.catch((e) => console.error('startSetTimeout: Can not get dom.play', e));
        }

      } catch (e) {
        console.error('startSetTimeout', e);
      }
    }, time);
  };

  _startClock = () => {
    const timelineDuration = this._calcDuration(this.timeline);
    this.clockFrame = new Clock((delay) => {
      if (timelineDuration <= this.progress) {
        this.pause();
      } else {
        this.progress = floor(this.progressOffSet + delay / (1000 / PLAYER_FPS) / PLAYER_FPS);
        this.props.onProgress(this.progress);
      }
    }, 0);
    this.clockFrame.start();
  };

  _playController = () => {
    const progress = this.progress * 1000;
    this._startClock();
    this.timeline.forEach((chunk) => {
      // START
      if (!chunk.startSetTimeout && progress < chunk.element.position + chunk.element.duration) {
        chunk.startSetTimeout = this._startSetTimeout(chunk, chunk.element.position - progress);
      }

      // STOP
      if (!chunk.stopSetTimeout && progress < chunk.element.position + chunk.element.duration) {
        chunk.stopSetTimeout = this._stopSetTimeout(chunk, chunk.element.position + chunk.element.duration - progress);
      }

      // UNLOAD
      if (!chunk.unloadSetTimeout) {
        chunk.unloadSetTimeout = this._unloadSetTimeout(chunk, chunk.element.position + chunk.element.duration + 2000 - progress)
      };

      if (!chunk.setPreloadTimeout) {
        chunk.setPreloadTimeout = this._setPreloadTimeout(chunk);
      }
    });
  };

  _calcDuration = (timeline) => timeline.reduce((acc, { element: e }) => (acc > e.position + e.duration ? acc : e.position + e.duration), 0) / 1000;

  _drawFrame = (dom, pX = 0, pY = 0) => {
    if (!this.canvasRef.current) return;
    const ctx = this.canvasRef.current.getContext('2d', { alpha: false });

    // Black screen if we don't have a background content
    if (!dom) {
      ctx.fillStyle = '#000';
      ctx.fillRect(0, 0, PLAYER_RESOLUTION_WIDTH, PLAYER_RESOLUTION_HEIGHT);
    } else {
      ctx.drawImage(dom, (PLAYER_RESOLUTION_WIDTH * pX) / 100, (PLAYER_RESOLUTION_HEIGHT * pY) / 100, PLAYER_RESOLUTION_WIDTH, PLAYER_RESOLUTION_HEIGHT);
    }
  }

  _stop = () => {
    this.props.onStop();
    if (this.isReadeableInterval !== false) clearInterval(this.isReadeableInterval);
    if (this.clockFrame) this.clockFrame.stop();
  }

  _clearStreams = () => {
    this.timeline.forEach((chunk) => {
      if (chunk.startSetTimeout) clearTimeout(chunk.startSetTimeout);
      if (chunk.stopSetTimeout) clearTimeout(chunk.stopSetTimeout);
      if (chunk.unloadSetTimeout) clearTimeout(chunk.unloadSetTimeout);
      if (chunk.setPreloadTimeout) clearTimeout(chunk.setPreloadTimeout);
      chunk.startSetTimeout = null;
      chunk.stopSetTimeout = null;
      chunk.unloadSetTimeout = null;
      chunk.setPreloadTimeout = null;
    });
  }

  _getCurrentChunkPosition = (time) => {
    const timeMillisecond = time * 1000;
    return findIndex(
      this.timeline,
      (chunk) => chunk.element.position <= timeMillisecond  && timeMillisecond < (chunk.element.position + chunk.element.duration),
    );
  }

  _isLoading = () => {
    const isLoading = this.timeline.some((chunk) => this.blobUrls[chunk.element.inputId]?.isLoading);
    this.setState({ isLoading });
    return isLoading;
  };

  _checkDomReady = () => {
    const progress = this.progress * 1000;

    return this.timeline
      .filter((chunk) => (chunk.element.position <= progress) && (chunk.element.position + chunk.element.duration) >= progress)
      .some((chunk) => chunk.dom.readyState < 2);
  }

  play = () => {
    // Out of timeline
    if (this._calcDuration(this.timeline) <= this.progress) return;

    this.timeline.forEach((chunk) => this._preload(chunk));

    this.isReadeableInterval = setInterval(() => {
      if (!this._isLoading() && !this._checkDomReady()) {
        clearInterval(this.isReadeableInterval);
        this._playController();
      }
    }, 500);
  }

  pause = () => {
    this.progressOffSet = this.progress;
    this._stop();
    this.timeline.forEach((chunk) => {
      if (chunk.dom) this._stopSetTimeout(chunk, 0);
      if (chunk.stopSetTimeout) clearTimeout(chunk.stopSetTimeout);
    });
    this._clearStreams();
  }

  updateTimeline = async (newTimeline) => {
    // Create the new timeline with booth new and old chunks
    this.timeline = newTimeline.map((newElement) => {
      const existingPosition = this.timeline.find((chunk) => chunk.id === newElement.id);
      return {
        dom: existingPosition?.dom,
        id: newElement.id,
        startSetTimeout: null,
        isPreloaded: false,
        stopSetTimeout: null,
        unloadSetTimeout: null,
        element: {
          playPromise: null,
          inputId: newElement.inputId,
          type: newElement.input?.type,
          filename: newElement.input?.filename,
          line: newElement.line,
          position: newElement.position * 1000,
          duration: newElement.duration * 1000,
          start: newElement.start * 1000,
          speed: newElement.speed,
          volume: newElement.volume,
        },
      };
    });

    this.setState({ isLoading: true });
    const chunkToLoadPosition = this._getCurrentChunkPosition(this.progress);
    this._loadChunks(chunkToLoadPosition);
  }

  seekTo = async (time) => {
    const chunkToLoadPosition = this._getCurrentChunkPosition(time);

    if (chunkToLoadPosition >= 0) {
      await this._loadChunks(chunkToLoadPosition);
    }

    const newTime = floor(time) < 0 ? 0 : floor(time);
    this.progress = newTime;
    this.progressOffSet = newTime;
    this._clearStreams();
  }

  async componentDidMount() {
    window.addEventListener('resize', this._pureResize);
    this._pureResize();
    this.timer = setInterval(this._pureResize, 250);

    // Load N first chucks
    await this._loadChunks(0);

    this.renderFrame = () => {
      // No change on progress, skip rendering
      if (Date.now() - this.previousProgress <= PLAYER_FPS) {
        this.refreshId = requestAnimationFrame(this.renderFrame);
        return;
      }

      // Update last progress
      this.previousProgress = Date.now();

      const progress = this.progress * 1000;

      const chunkToShow = this.timeline
        .filter(
          ({ element }) =>
            ['VIDEO', 'IMAGE'].includes(element.type) &&
            floor(element.position) <= floor(progress) &&
            floor(element.position + element.duration) > floor(progress)
        )
        .sort((a, b) => a.element.line - b.element.line)
        .shift();

      this._drawFrame(chunkToShow?.dom);

      // Request Animation
      this.refreshId = requestAnimationFrame(this.renderFrame);
    };
    // Load videos
    this.refreshId = requestAnimationFrame(this.renderFrame);
  }

  componentWillUnmount() {
    this._stop();
    if (this.refreshId) window.cancelAnimationFrame(this.refreshId);
    window.removeEventListener('resize', this._pureResize);
    clearInterval(this.timer);
  }

  shouldComponentUpdate(prevProps, prevState) {
    return (
      prevProps.playing !== this.props.playing ||
      !isEqual(prevProps.timeline, this.timeline) ||
      prevState.width !== this.state.width ||
      prevState.height !== this.state.height
    );
  }

  render() {
    return (
      <div className={this.ratioWidth > this.ratioHeight ? styles.container : styles.containerVertical} ref={this.refContainer}>
        <div>
          <canvas
            className={styles.player}
            width={PLAYER_RESOLUTION_WIDTH}
            height={PLAYER_RESOLUTION_HEIGHT}
            ref={this.canvasRef}
            style={{
              width: `${this.state.resolution[0]}px`,
              height: `${this.state.resolution[1]}px`,
              top: `${Math.round((this.state.height - this.state.resolution[1]) / 2)}px`,
              left: this.state.leftMargin,
            }}
          />
        </div>
        {this.state.isLoading === true && (
          <div className={styles.spinnerContainer}>
            <Spinner />
          </div>
        )}
      </div>
    );
  }
}

TimelinePlayerV2.propTypes = {
  timeline: PropTypes.array,
  playing: PropTypes.bool,
  onProgress: PropTypes.func,
  onStop: PropTypes.func,
  ratio: PropTypes.string,
  streams: PropTypes.object,
};

TimelinePlayerV2.defaultProps = {
  timeline: [],
  playing: false,
  onProgress: (t) => {},
  onStop: (t) => {},
};

export default TimelinePlayerV2;
