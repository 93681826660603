import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import { icons, oldIcons } from './icons';
import style from './style.module.css';

export const SkeepersIcon = ({ type, color = 'currentColor', title = '', spin = false, className = '', onClick = null, dataCy = null }) => {
  const icon = icons[type];
  const iClassName = classNames({ [style.clickable]: onClick, [style.spin]: spin }, style.icon, className);

  if (icon) {
    return (
      <FontAwesomeIcon icon={icon} className={iClassName} title={title} color={color} spin={spin} onClick={onClick} {...(dataCy && { 'data-cy': dataCy })} />
    );
  }

  const oldIcon = oldIcons[type];
  if (oldIcon) {
    const { Icon, iconStyle } = oldIcon;
    return <Icon className={classNames(iClassName)} style={iconStyle(color)} title={title} onClick={onClick} {...(dataCy && { 'data-cy': dataCy })} />;
  }

  return null;
};

SkeepersIcon.propTypes = {
  type: PropTypes.oneOf(Object.keys(icons).concat(Object.keys(oldIcons))).isRequired,
  color: PropTypes.string,
  title: PropTypes.string,
  spin: PropTypes.bool,
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default SkeepersIcon;
