import { useTranslation } from 'react-i18next';

import UpdateGroupForm from '@components/Form/UpdateGroupForm';
import Window from '@components/Window/Window';

const UpdateGroupWindow = ({ group = {}, onSubmit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Update group')} open={open} onClose={onClose}>
      <UpdateGroupForm group={group} onSubmit={onSubmit} isUpdating={isLoading} />
    </Window>
  );
};

export default UpdateGroupWindow;
