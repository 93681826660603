import SocialMediaEmbeddedPlayer from '@components/SocialMediaEmbeddedPlayer';
import Window from '@components/Window/Window';

const SocialMediaPlayer = ({ onClose = () => {}, open = false, task }) => {
  return (
    <Window open={open} onClose={onClose}>
      <SocialMediaEmbeddedPlayer task={task} />
    </Window>
  );
};

export default SocialMediaPlayer;
