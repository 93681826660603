import PropTypes from 'prop-types';

import Tag from '@components/Common/Tag';
import Cell from '@components/TableElement/Cell';

import style from './style.module.css';

const colorOptions = [
  { value: 'blue', color: 'var(--tag-blue)' },
  { value: 'green', color: 'var(--tag-green)' },
  { value: 'yellow', color: 'var(--tag-yellow' },
  { value: 'red', color: 'var(--tag-red)' },
  { value: 'grey', color: 'var(--tag-grey)' },
];

const getColor = (i) => colorOptions[i % colorOptions.length].color;

const AdaptativeCell = ({ value, type = '', className = '', onError = null }) => {
  if (type === 'picture') {
    return (
      <div className={`${style.cell} ${style.cell_picture}`}>
        <img className={style.picture} alt="" src={value} onError={onError} />
      </div>
    );
  }
  if (type === 'tags') {
    value = Array.isArray(value) ? value : [value];
    return (
      <Cell className={`${className} ${style.cell} ${style.cellTag}`}>
        {value
          .filter((v) => v)
          .map((t, i) => (
            <Tag key={i} value={t} color={getColor(i)} />
          ))}
      </Cell>
    );
  }
  if (type === 'categories') {
    value = Array.isArray(value) ? value : [value];
    return (
      <Cell className={`${className} ${style.cell} ${style.cellTag}`}>
        {value
          .filter((v) => v)
          .map((t, i) => (
            <Tag key={i} value={t} color="#da3698" />
          ))}
      </Cell>
    );
  }

  if (type === 'projectUrl') {
    return (
      <Cell className={`${className} ${style.cell}`}>
        <span>
          <a href={value} target="_blank" rel="noreferrer">
            {value}
          </a>
        </span>
      </Cell>
    );
  }

  return (
    <Cell className={`${className} ${style.cell}`}>
      <span>{value}</span>
    </Cell>
  );
};

export default AdaptativeCell;

AdaptativeCell.propTypes = {
  value: PropTypes.any,
  type: PropTypes.string,
  className: PropTypes.string,
};
