import PropTypes from 'prop-types';

import OrderForm from '@components/Form/OrderForm';
import Window from '@components/Window/Window';

const OrderWindow = ({ onSubmit = () => {}, order = {}, isLoading = false, title = '', open = false, onClose = () => {} }) => {
  return (
    <Window title={title} open={open} onClose={onClose}>
      <OrderForm order={order} onSubmit={onSubmit} isUpdating={isLoading} />
    </Window>
  );
};

export default OrderWindow;

OrderWindow.propTypes = {
  onSubmit: PropTypes.func,
  order: PropTypes.shape({
    orderId: PropTypes.string,
    trackingUrl: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
