import { useState, useEffect } from 'react';

import ChangelogWindow from '@components/Window/ChangelogWindow';
import { useMarkChangelogAsRead } from '@hooks/changelogs';

const Changelog = ({ connectedUser }) => {
  const changelog = connectedUser?.unreadChangelog;
  const [windowStatus, setWindowStatus] = useState(false);
  const [updateChangelogUser] = useMarkChangelogAsRead();

  useEffect(() => {
    if (changelog) {
      setWindowStatus(true);
    }
  }, [changelog]);

  const onClose = () => {
    setWindowStatus(false);
    updateChangelogUser(changelog.id);
  };

  if (!changelog) {
    return null;
  }

  return <ChangelogWindow open={windowStatus} onClose={onClose} changelog={changelog} />;
};

export default Changelog;
