import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Menu from '@components/Layout/Menu';
import { useRedirect } from '@contexts/RedirectContext';
import { useWindows, WINDOW_TYPES } from '@contexts/WindowContext';
import { useGetCompanyForMenu } from '@hooks/companies';
import { useCreateSolution } from '@hooks/solutions';

import useGetMenuConfig from './useGetMenuConfig';

export const MenuContainer = ({ open = true, companyId = '', solutionType = '', pathname = '' }) => {
  const { t } = useTranslation();
  const { actions } = useWindows();
  const { redirect } = useRedirect();
  const { company, loading, error } = useGetCompanyForMenu(companyId);

  const [createSolution, creationProps] = useCreateSolution(companyId, {
    onCompleted: (solutionRoute) => {
      actions.close();
      redirect(solutionRoute, true);
    },
  });

  const addCallback =
    (solutionType = '') =>
    () => {
      actions.open(WINDOW_TYPES.ADD_SOLUTION, {
        type: solutionType,
        ...creationProps,
        onEdit: (values) => {
          // If it's a specific solution type (and not provided by the Form/Window), we force set the value in the form result
          const type = values.type !== 'SMART-UPLOADER' ? values.type : 'SMART_UPLOADER';

          createSolution({ ...values, type });
          actions.loading();
        },
      });
    };

  const { config, title } = useGetMenuConfig({ company, solutionType, pathname }, addCallback, t);

  if (error || (!loading && !company)) {
    console.error('no company found');
    console.error(error);
    return null;
  }

  return <Menu open={open} config={config} title={title} />;
};

export default MenuContainer;

MenuContainer.propTypes = {
  companyId: PropTypes.string,
  solutionType: PropTypes.string,
  pathname: PropTypes.string,
};
