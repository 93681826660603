import PropTypes from 'prop-types';

import Action from '@components/Common/Action';

import style from './style.module.css';

const Tag = ({ value = '', textColor = null, color = null, action = false, className = '' }) => (
  <Action action={action}>
    <span
      style={{ ...(color ? { backgroundColor: color } : {}), ...(textColor ? { color: textColor } : {}) }}
      className={`${style.tag} ${action ? style.action : ''} ${className}`}
    >
      {value}
    </span>
  </Action>
);

Tag.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.string,
  action: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.bool]),
  className: PropTypes.string,
};

export default Tag;
