import { countries } from '@app/translations';

import { ReactComponent as FlagAE } from './assets/ae.svg';
import { ReactComponent as FlagBE } from './assets/be.svg';
import { ReactComponent as FlagCN } from './assets/cn.svg';
import { ReactComponent as FlagDE } from './assets/de.svg';
import { ReactComponent as FlagDK } from './assets/dk.svg';
import { ReactComponent as FlagES } from './assets/es.svg';
import { ReactComponent as FlagFI } from './assets/fi.svg';
import { ReactComponent as FlagFR } from './assets/fr.svg';
import { ReactComponent as FlagCY } from './assets/gb-wls.svg';
import { ReactComponent as FlagIT } from './assets/it.svg';
import { ReactComponent as FlagJP } from './assets/jp.svg';
import { ReactComponent as FlagKO } from './assets/ko.svg';
import { ReactComponent as FlagNL } from './assets/nl.svg';
import { ReactComponent as FlagNO } from './assets/no.svg';
import { ReactComponent as FlagPL } from './assets/pl.svg';
import { ReactComponent as FlagPT } from './assets/pt.svg';
import { ReactComponent as FlagRU } from './assets/ru.svg';
import { ReactComponent as FlagSE } from './assets/se.svg';
import { ReactComponent as FlagUS } from './assets/us.svg';

const flags = {
  [countries.BE]: FlagBE,
  [countries.DK]: FlagDK,
  [countries.DE]: FlagDE,
  [countries.ES]: FlagES,
  [countries.FI]: FlagFI,
  [countries.FR]: FlagFR,
  [countries.IT]: FlagIT,
  [countries.NL]: FlagNL,
  [countries.NO]: FlagNO,
  [countries.PT]: FlagPT,
  [countries.SE]: FlagSE,
  [countries.US]: FlagUS,
  [countries.PL]: FlagPL,
  [countries.JP]: FlagJP,
  [countries.AE]: FlagAE,
  [countries.EN]: FlagUS,
  [countries.CN]: FlagCN,
  [countries.KO]: FlagKO,
  [countries.RU]: FlagRU,
  [countries.CY]: FlagCY,
};

export default flags;
