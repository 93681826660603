import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import style from './style.module.css';

const TimeInput = forwardRef(
  (
    {
      value = '',
      name = '',
      placeholder = '',
      readOnly = false,
      disabled = false,
      required = false,
      className = '',
      min = '',
      max = '',
      step = '60',
      onFocus = () => {},
      onBlur = () => {},
      onEdit = () => {},
      error = false,
    },
    ref
  ) => {
    const onChange = onEdit ? (event) => onEdit(event.target.value) : null;

    return (
      <input
        type="time"
        value={value || ''}
        className={classNames(style.input, className, { [style.error]: error })}
        name={name}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled || readOnly}
        required={required}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        min={min}
        max={max}
        step={step}
        ref={ref}
      />
    );
  }
);

TimeInput.displayName = 'TimeInput';

TimeInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  /**The minimum (seconds) value to accept for this input*/
  min: PropTypes.string,
  /**The maximum (seconds) value to accept for this input*/
  max: PropTypes.string,
  /**A stepping interval (seconds) to use when using up and down arrows to adjust the value, as well as for validation. Default is 60 seconds*/
  step: PropTypes.number,
};

export default TimeInput;
