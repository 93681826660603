import { useEffect, useState } from 'react';

const VimeoPlayer = ({ videoId }) => {
  const [oembed, setOembed] = useState(null);

  useEffect(() => {
    if (!oembed) {
      fetch(`https://vimeo.com/api/oembed.json?url=https://vimeo.com/${videoId}&responsive=true&byline`)
        .then((res) => res.json())
        .then((json) => setOembed(json));
    }
  });

  if (!oembed) {
    return null;
  }

  return <div dangerouslySetInnerHTML={{ __html: oembed.html }} />;
};

export default VimeoPlayer;
