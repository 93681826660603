import { useQuery, useMutation } from '@apollo/client';

import { PAGINATION_DEFAULT } from '@app/config';
import CREATE_COMPANY from '@graphql/companies/createCompany.graphql';
import CREATE_COMPANY_CONFIG from '@graphql/companies/createCompanyConfig.graphql';
import DELETE_COMPANY from '@graphql/companies/deleteCompany.graphql';
import DELETE_COMPANY_CONFIG from '@graphql/companies/deleteCompanyConfig.graphql';
import GET_COMPANIES from '@graphql/companies/getCompanies.graphql';
import GET_COMPANIES_AUDIT_TASKS from '@graphql/companies/getCompaniesAuditTasks.graphql';
import GET_COMPANY_MENU from '@graphql/companies/getCompaniesMenu.graphql';
import GET_COMPANY from '@graphql/companies/getCompany.graphql';
import GET_COMPANY_AUDIT_TASKS from '@graphql/companies/getCompanyAuditTasks.graphql';
import GET_COMPANY_CONFIGS from '@graphql/companies/getCompanyConfigs.graphql';
import GET_COMPANY_INJECTIONS from '@graphql/companies/getCompanyInjections.graphql';
import GET_COMPANY_OPTIONS from '@graphql/companies/getCompanyOptions.graphql';
import GET_COMPANY_PREFERENCES from '@graphql/companies/getCompanyPreferences.graphql';
import GET_COMPANY_WITH_ADDITIONNAL_INFO from '@graphql/companies/getCompanyWithAdditionalInformation.graphql';
import GET_HOMEPAGE_DATA from '@graphql/companies/getHomepageData.graphql';
import GET_PARTIAL_COMPANIES from '@graphql/companies/getPartialCompanies.graphql';
import GET_PARTIAL_COMPANY from '@graphql/companies/getPartialCompany.graphql';
import UPDATE_COMPANY from '@graphql/companies/updateCompany.graphql';
import UPDATE_COMPANY_CONFIG from '@graphql/companies/updateCompanyConfig.graphql';
import UPSERT_COMPANY_PREFERENCES from '@graphql/companies/upsertCompanyPreferences.graphql';
import GET_COMPANY_CATEGORIES from '@graphql/companyCategories/getCompanyCategories.graphql';

export const isUserClient = (company) => !company?.allowedActions['menu.advanced'];

export const useGetCompanyForMenu = (id) => {
  const { data, loading, error } = useQuery(GET_COMPANY_MENU, { variables: { id } });
  return {
    company: data?.getCompany,
    loading,
    error,
  };
};

export const useGetCompany = (id) => {
  const { data, loading, error } = useQuery(GET_COMPANY, { variables: { id } });
  return {
    company: data?.getCompany,
    isClient: isUserClient(data?.getCompany),
    loading,
    error,
  };
};

export const useGetHomepageData = (id, resetCache, company) => {
  const { data, loading, error, refetch } = useQuery(GET_HOMEPAGE_DATA, {
    fetchPolicy: 'network-only',
    variables: { id, resetCache },
    notifyOnNetworkStatusChange: true,
    skip: !company,
  });

  return {
    homepageData: data?.getHomepageData,
    isClient: isUserClient(data?.getCompany),
    loading,
    error,
    refetch,
  };
};

export const useGetCompanyInjections = (id) => {
  const { data, loading, error } = useQuery(GET_COMPANY_INJECTIONS, { variables: { id } });
  return { company: data?.getCompany, loading, error };
};

export const useGetCompanyAuditTasks = (id) => {
  const { data, loading, error } = useQuery(GET_COMPANY_AUDIT_TASKS, { variables: { id } });
  return { company: data?.getCompany, loading, error };
};

export const useGetCompanyWithAdditionnalInfo = (id) => {
  const { data, loading, error } = useQuery(GET_COMPANY_WITH_ADDITIONNAL_INFO, { variables: { id } });
  return { company: data?.getCompany, loading, error, isClient: isUserClient(data?.getCompany) };
};

export const useGetCompanyOptions = (id) => {
  const { data, loading, error } = useQuery(GET_COMPANY_OPTIONS, {
    fetchPolicy: 'network-only',
    variables: { id }
  });
  return { company: data?.getCompany, loading, error };
};

export const useGetCompanies = ({ page, size } = {}) => {
  const { loading, error, data } = useQuery(GET_COMPANIES, {
    fetchPolicy: 'cache-and-network',
    variables: {
      page:
        page && size
          ? {
            page,
            size,
          }
          : PAGINATION_DEFAULT,
    },
  });
  return { companies: data?.getCompanies?.companies ?? [], pageInfo: data?.getCompanies?.pageInfo, error, loading };
};

export const useGetPartialCompanies = () => {
  const { loading, error, data } = useQuery(GET_PARTIAL_COMPANIES);
  return { companies: data?.getCompanies?.companies ?? [], error, loading };
};

export const useGetCompaniesAuditTasks = () => {
  const { loading, error, data } = useQuery(GET_COMPANIES_AUDIT_TASKS, {
    fetchPolicy: 'cache-and-network',
  });
  return { companies: data?.getCompanies?.companies ?? [], pageInfo: data?.getCompanies?.pageInfo, error, loading };
};

export const useUpdateCompany = (id, { onCompleted = () => { }, onError = () => { } } = {}) => {
  const [update, { data, error, loading }] = useMutation(UPDATE_COMPANY, { onCompleted, onError });

  const updateSolution = (company) => {
    return update({ variables: { id, company } });
  };

  return [updateSolution, { data: data?.updateCompany, isUpdating: loading, error }];
};

export const useCreateCompany = ({ onCompleted = () => { }, onError = () => { } } = {}) => {
  const [mutate, { data, error, loading }] = useMutation(CREATE_COMPANY, {
    onCompleted,
    onError,
    refetchQueries: [{ query: GET_COMPANIES, variables: { page: PAGINATION_DEFAULT } }, { query: GET_PARTIAL_COMPANIES }],
  });

  const createCompany = (company) => {
    mutate({
      variables: {
        company,
      },
    });
  };

  return [createCompany, { data: data?.createCompany, isUpdating: loading, error }];
};

export const useDeleteCompany = (companyId, { onCompleted = () => { }, onError = () => { } } = {}) => {
  const [deleteFunction, { data, error, loading }] = useMutation(DELETE_COMPANY, {
    onCompleted,
    onError,
    refetchQueries: [{ query: GET_COMPANIES }, { query: GET_PARTIAL_COMPANIES }],
  });

  const deleteCompany = () => {
    return deleteFunction({ variables: { id: companyId } });
  };

  return [deleteCompany, { data: data?.createRouteSolution, isUpdating: loading, error }];
};

export const useGetPartialCompany = (id) => {
  const { data, loading, error } = useQuery(GET_PARTIAL_COMPANY, { variables: { id } });
  return {
    company: data?.getCompany,
    isClient: isUserClient(data?.getCompany),
    loading,
    error,
  };
};

export const useGetCompanyCategories = (companyId, { withExternalVideos, withProductTags } = {}) => {
  const { data, loading, error } = useQuery(GET_COMPANY_CATEGORIES, {
    variables: {
      companyId,
      withExternalVideos,
      withProductTags,
    },
  });

  return {
    categories: data?.companyCategories?.categories,
    loading,
    error,
  };
};

export const useGetCompanyPreferences = (companyId) => {
  const { data, loading, error } = useQuery(GET_COMPANY_PREFERENCES, { variables: { companyId } });
  return {
    preferences: data?.getCompanyPreferences,
    loading,
    error,
  };
};

export const useUpsertCompanyPreferences = (companyId, { onCompleted = () => { }, onError = () => { } } = {}) => {
  const [upsertFunction, { data, error, loading }] = useMutation(UPSERT_COMPANY_PREFERENCES, {
    onCompleted,
    onError,
  });

  const upsertCompanyPreferences = (preferences) => {
    return upsertFunction({ variables: { companyId, preferences } });
  };

  return [upsertCompanyPreferences, { data: data?.upsertCompanyPreferences, isUpdating: loading, error }];
};

export const useCreateCompanyConfig = (companyId, { onCompleted = () => { }, onError = () => { } } = {}) => {
  const [mutate, { data, error, loading }] = useMutation(CREATE_COMPANY_CONFIG, {
    onCompleted,
    onError,
    refetchQueries: [
      {
        query: GET_COMPANY_CONFIGS,
        variables: {
          companyId
        }
      }
    ],
    awaitRefetchQueries: true,
  });

  const createCompanyConfig = (config) => {
    mutate({
      variables: {
        config,
      },
    });
  };

  return [createCompanyConfig, { data: data?.createCompanyConfig, isUpdating: loading, error }];
};

export const useGetCompanyConfigs = (companyId) => {
  const { data, loading, error } = useQuery(GET_COMPANY_CONFIGS, { variables: { companyId } });
  return {
    configs: data?.getCompanyConfigs,
    loading,
    error,
  };
};

export const useUpdateCompanyConfig = (companyId, { onCompleted = () => { }, onError = () => { } } = {}) => {
  const [updateConfig, { data, loading, error }] = useMutation(UPDATE_COMPANY_CONFIG, {
    onCompleted,
    onError,
    refetchQueries: [
      {
        query: GET_COMPANY_CONFIGS,
        variables: {
          companyId
        }
      }
    ],
    awaitRefetchQueries: true,
  });

  const updateCompanyConfig = (config) => {
    updateConfig({
      variables: {
        config: {
          ...config,
          companyId
        }
      },
    });
  };

  return [updateCompanyConfig, { data, loading, error }];
};

export const useDeleteCompanyConfig = (companyId, { onCompleted = () => { }, onError = () => { } } = {}) => {
  const [deleteConfig, { data, loading, error }] = useMutation(DELETE_COMPANY_CONFIG, {
    onCompleted,
    onError,
    refetchQueries: [
      {
        query: GET_COMPANY_CONFIGS,
        variables: {
          companyId
        }
      }
    ],
    awaitRefetchQueries: true,
  });

  const deleteCompanyConfig = (id) => {
    deleteConfig({
      variables: {
        companyId,
        id,
      },
    });
  };

  return [deleteCompanyConfig, { data, loading, error }];
};
