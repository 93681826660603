import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import DateForm from '@components/Form/DateForm';
import Window from '@components/Window/Window';

import style from './style.module.css';

const DateWindow = ({ title, value, allowedRange, onSubmit = () => {}, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();
  const titleToUse = title ?? t('Select a date');
  const [isLoading, setIsLoading] = useState(false);
  const onBeforeSubmit = (formData) => {
    setIsLoading(true);
    return onSubmit(formData);
  };

  return (
    <Window title={titleToUse} open={open} onClose={onClose} className={style.overflowVisible}>
      <DateForm value={value} allowedRange={allowedRange} onSubmit={onBeforeSubmit} isLoading={isLoading} />
    </Window>
  );
};

export default DateWindow;
