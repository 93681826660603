import { SkpMultiselect } from '@skeepers/skeepers-ui-react';
import { useTranslation } from 'react-i18next';

import Button from '@components/Common/Button';
import ButtonContainer from '@components/Common/ButtonContainer';
import Flex from '@components/Common/Flex';
import Text from '@components/Common/Text';
import { Form, useForm } from '@components/FormElement/Form';
import Window from '@components/Window/Window';

import style from './style.module.css';

const ValidationBroadcastingStatusWindow = ({
  open = false,
  onClose = () => {},
  onUpdate = () => {},
  title,
  message,
  distributionsSolutions,
}) => {
  const { t } = useTranslation();
  const methods = useForm();

  const updateDistributors = async (distributors) => {
    if (methods.getValues()?.distributors !== distributors?.detail) {
      methods.setValue('distributors', distributors?.detail, { shouldDirty: true });
    }
  };

  return (
    <Window title={title} open={open} onClose={onClose}>
      <Flex justifyContent="SPACE-BETWEEN" flexDirection="COLUMN" alignItems="CENTER" className={style.container}>
        <Text className={style.title}>{message}</Text>
        {distributionsSolutions.length > 0 && (
          <Form methods={methods} onSubmit={() => onUpdate(methods.getValues().distributors)}>
            <Text>{t('Please select the diffusion canal(s) for selected videos:')}</Text>
            <Text size="small" italic className={style.information}>{t('If selected video(s) already have diffusion canal(s), it will be preserved and added to selected ones.')}</Text>
            <Form.Label className={style.label} title={t('Diffusion canal')} name="distributors" data-cy="ev-modal-diffusion-input">
              <SkpMultiselect
                activePlaceholder={t('Channels')}
                name="distributors"
                placeholder={t('Choose channels')}
                size="medium"
                options={distributionsSolutions.map((solution) => ({
                  value: solution.id,
                  text: solution.name,
                }))}
                values={methods.getValues()?.distributors || []}
                onSkpSelectChange={updateDistributors}
              ></SkpMultiselect>
            </Form.Label>
            <ButtonContainer position="CENTER" data-cy="ev-modal-buttons">
              <Button label={t('Cancel')} action={onClose} theme={Button.themes.SECONDARY} color="var(--neutral-300)"/>
              <Form.SubmitButton label={t('Confirm')} />
            </ButtonContainer>
          </Form>
        )}
      </Flex>
    </Window>
  );
};

export default ValidationBroadcastingStatusWindow;
