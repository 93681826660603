import classNames from 'classnames';
import PropTypes from 'prop-types';

import style from './style.module.css';

const Divider = ({ className }) => <hr className={classNames(style.hr, className)} />;

export default Divider;

Divider.propTypes = {
  className: PropTypes.string,
};
