import { Line } from '@ant-design/plots';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Block from '@components/Common/Block';

import { joinDataByRange } from './utils';
import './registerTheme';

const LineChart = ({ axis, filter, title, label, customConfig = {} }) => {
  const { t } = useTranslation();
  const catData = axis
    .map((e) => ({
      time: e.time,
      value: e.value,
      type: title,
      ...(e.detail ? { detail: e.detail } : {}),
    }))
    .sort((a, b) => a.time - b.time);

  const filteredData = joinDataByRange(catData, filter);

  const config = {
    appendPadding: 20,
    theme: 'custom-theme',
    data: filteredData,
    xField: 'time',
    yField: 'value',
    renderer: 'svg',
    tooltip: {
      formatter: (item) => ({ name: label, value: item.value }),
    },
    color: 'rgb(20,122,101)',
    ...customConfig,
  };
  return (
    <Block>
      <h3>{title}</h3>
      <div>{catData.find((e) => e.value !== null) ? <Line {...config} /> : <h4>{t('No available data')}</h4>}</div>
    </Block>
  );
};

LineChart.propTypes = {
  axis: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.number.isRequired, PropTypes.string.isRequired]),
      time: PropTypes.number.isRequired,
    })
  ),
  title: PropTypes.string,
  label: PropTypes.string,
  filter: PropTypes.oneOf(['daily', 'weekly', 'monthly']),
  customConfig: PropTypes.object,
};

export default LineChart;
