import FacebookPlayer from '@components/SocialMediaEmbeddedPlayer/FacebookPlayer';
import InstagramPlayer from '@components/SocialMediaEmbeddedPlayer/InstagramPlayer';
import LinkedinPlayer from '@components/SocialMediaEmbeddedPlayer/LinkedinPlayer';
import TiktokPlayer from '@components/SocialMediaEmbeddedPlayer/TiktokPlayer';
import TwitterPlayer from '@components/SocialMediaEmbeddedPlayer/TwitterPlayer';
import VimeoPlayer from '@components/SocialMediaEmbeddedPlayer/VimeoPlayer';
import YoutubePlayer from '@components/SocialMediaEmbeddedPlayer/YoutubePlayer';

const SocialMediaEmbeddedPlayer = ({ task }) => {
  const player = () => {
    switch (task.type) {
      default:
        return null;
      case 'FACEBOOK':
        return <FacebookPlayer embed={task.embed} />;
      case 'TWITTER':
        return <TwitterPlayer embed={task.embed} />;
      case 'YOUTUBE':
        return <YoutubePlayer videoId={task.videoId} />;
      case 'VIMEO':
        return <VimeoPlayer videoId={task.videoId} />;
      case 'INSTAGRAM':
        return <InstagramPlayer embed={task.embed} />;
      case 'TIKTOK':
        return <TiktokPlayer videoId={task.videoId} />;
      case 'LINKEDIN':
        return <LinkedinPlayer postId={task.postId} />;
    }
  };

  return player();
};

export default SocialMediaEmbeddedPlayer;
