import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { textLengthValidate } from '@components/FormElement/Inputs/utils';

import style from './style.module.css';

const Textarea = forwardRef(
  (
    {
      value = '',
      name = '',
      placeholder = '',
      readOnly = false,
      disabled = false,
      required = false,
      className = '',
      onFocus = () => {},
      onBlur = () => {},
      onEdit = () => {},
      onKeyDown = () => {},
      onPaste,
      maxLength = null,
      minLength = null,
      error = false,
    },
    ref
  ) => {
    const onChange = onEdit ? (event) => onEdit(event.target.value) : null;
    return (
      <textarea
        value={value || ''}
        {...(onPaste
          ? {
              onPaste: (e) => {
                e.preventDefault();
                const text = onPaste(e);
                e.target.value = text;
                onEdit(text);
              },
            }
          : {})}
        className={classNames(style.input, style.text, className, { [style.error]: error })}
        name={name}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled || readOnly}
        required={required}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onChange={onChange}
        ref={ref}
        minLength={minLength}
        maxLength={maxLength}
      />
    );
  }
);

Textarea.displayName = 'TextArea';

Textarea.customValidate = textLengthValidate;

Textarea.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  /**Event handler to capture the paste event and transform the pasted value*/
  onPaste: PropTypes.func,
  error: PropTypes.bool,
};

export default Textarea;
