import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { isChecked } from '@app/utils';
import SkeepersIcon from '@components/Common/SkeepersIcon';

import style from './style.module.css';

const CheckboxGroup = forwardRef(
  ({ multi = false, value = null, name = '', readOnly = false, options = [], onBlur = () => {}, onEdit = () => {} }, forwardedRef) => {
    const onChange = ({ key, checked }) => {
      if (!multi) {
        return onEdit(checked ? key : null);
      }
      const previousValue = value || [];
      onEdit(checked ? [...previousValue, key] : previousValue.filter((v) => v !== key));
    };

    return (
      <div className={style.root}>
        {options.map(({ value: v, label, disabled, icon }) => {
          // This piece of code is ugly and should be replaced
          // by a real markdown parser in the future
          let displayedLabel = label;
          const regex = /(.*)\*(.*)\*(.*)/;
          if (regex.test(displayedLabel)) {
            const parsed = regex.exec(displayedLabel);
            displayedLabel = (
              <>
                {parsed[1]}
                <strong>{parsed[2]}</strong>
                {parsed[3]}
              </>
            );
          }

          const _disabled = disabled || readOnly;

          const params =
            multi || options.length === 1
              ? {
                  type: 'checkbox',
                  icon: 'CHECK',
                  color: '#fff',
                }
              : {
                  type: 'radio',
                  icon: 'CIRCLE',
                  color: _disabled ? 'var(--color-disabled)' : 'var(--layout-primary-color)',
                };

          const checkedAttribute = value
            ? {
                checked: isChecked(v, value, multi),
              }
            : {
                defaultChecked: isChecked(v, value, multi),
              };

          return (
            <label htmlFor={v} key={v} className={classNames(style.container, { [style.disabled]: _disabled })}>
              <input
                className={style.input}
                type={params.type}
                name={name}
                id={v}
                value={v}
                readOnly={readOnly}
                disabled={_disabled}
                onChange={({ target: { checked } }) => onChange({ key: v, checked })}
                onBlur={onBlur}
                ref={forwardedRef}
                {...checkedAttribute}
              />
              <div className={classNames(style.styledInput, style.boxNotChecked)} />
              <div className={classNames(style.styledInput, style.boxChecked)}>
                <SkeepersIcon type={params.icon} color={params.color} className={style.checkedIcon} />
              </div>
              <div className={style.label}>
                {icon && <SkeepersIcon type={icon} color="inherit" className={style.icon} />}
                <div>{displayedLabel}</div>
              </div>
            </label>
          );
        })}
      </div>
    );
  }
);

CheckboxGroup.displayName = 'CheckboxGroup';

export default CheckboxGroup;

CheckboxGroup.propTypes = {
  multi: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string), PropTypes.string]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
      disabled: PropTypes.bool,
    })
  ),
  name: PropTypes.string,
  onEdit: PropTypes.func,
};
