import { useTranslation } from 'react-i18next';

import ImportCardsForm from '@components/Form/ImportCardsForm';
import Window from '@components/Window/Window';

const ImportCardsWindow = ({ availableOutputsCards = [], onImport = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Import cards')} open={open} onClose={onClose}>
      <ImportCardsForm availableOutputsCards={availableOutputsCards} onImport={onImport} isLoading={isLoading} />
    </Window>
  );
};

export default ImportCardsWindow;
