import animateScrollTo from 'animated-scroll-to';
import classNames from 'classnames';
import { t } from 'i18next';
import isEqual from 'lodash.isequal';
import PropTypes from 'prop-types';
import { createRef, Component } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { FEATURE_FLAG_NAMES, UnleashClassFlagProvider } from '@app/utils/featureFlags';
import { PROJECT_INPUT_STATUS } from '@app/utils/projects';
import PromptBeforeUnload from '@components/Common/PromptBeforeUnload';
import { showBrowserWarning } from '@components/Studio/browserDetect';
import { GRID } from '@components/Studio/Common/config';
import { TimelineJS } from '@components/Studio/Common/TimelineJS.js';
import TimelineKeyboardHandler from '@components/Studio/TimelineKeyboardHandler';
import TimelinePlayerV2 from '@components/Studio/TimelinePlayerV2';

import BrowserWarning from '../BrowserWarning';
import TimeCursor from '../TimeCursor';
import TimelineBase from '../TimelineBase';
import TimelineDirectory from '../TimelineDirectory';
import TimelineElement from '../TimelineElement';
import TimelineMenu from '../TimelineMenu';
import TimelineNav from '../TimelineNav';
import TimelinePlayer from '../TimelinePlayer';

import styles from './style.module.css';

const AUTO_SCROLL_VALUE = 10;
const PLAYER_FPS = 1 / GRID;

const floor = (d) => Math.round(d * PLAYER_FPS) / PLAYER_FPS;
class Timeline extends Component {
  timelineJS = new TimelineJS(
    [
      ...this.props.timelineObject.timeline.map((element) => ({
        ...element,
        maxDuration: floor(this.props.inputs.filter((input) => input.id === element.inputId)[0]?.duration / element.speed),
      })),
    ],
    { gridSize: 25, scaleX: 32, scaleY: 32, clientWidth: 1920, id: this.props.projectId }
  );

  constructor(props) {
    super(props);
    this.refTimeline = createRef();
    this.player = createRef();
    this.timelinePlayer = [];
    this.state = {
      inputBeingRenamed: false,
      magnet: true,
      scaleX: 32,
      scaleY: 32,
      offsetX: 0,
      time: 0,
      playing: false,
      seeking: false,
      ctrlKeyPressed: false,
      streams: (props.inputs || []).reduce(
        (acc, input) => ({ ...acc, [input.id]: input.normalizedPreviewUrl || input.normalizedUrl || input.originalUrl || '' }),
        {}
      ),
      timeline: this.timelineJS.timeline,
      currentTimelineId: this.props.timelineObject.id,
      history: this.timelineJS.history,
      historyIdx: this.timelineJS.historyIdx,
      exportedTimeline: [],
    };
    this.ratio = props.ratio;
    this.autoScrollInterval = null;

    window.addEventListener('blur', () => (this.timelineJS.ctrlKeyPressed = false));
  }

  _calcDuration = (timeline) => timeline.reduce((acc, e) => (acc > e.position + e.duration ? acc : e.position + e.duration), 0);

  _onShortcuts = () => {
    this.props.onShortcuts();
  };

  _onEditingBrief = () => {
    this.props.onEditingBrief();
  };

  isDragging = false;
  isResizingRight = false;
  isResizingLeft = false;
  previousX = 0;
  previousY = 0;

  _pureScroll = () => {
    this.setState({
      offsetX: this.refTimeline.current.scrollLeft,
    });
  };

  _onSave() {
    if (!(isEqual(this.timelineJS.history[0] || [], this.state.timeline) || this.timelineJS.history.length <= 1)) {
      this.timelineJS.resetHistory();
      this.props.onSave(this.state.timeline);
    }
  }
  _onExport() {
    this.setState({ exportedTimeline: this.state.timeline });
    this.props.onExport(this.state.timeline);
  }

  _extractPositionDelta = (event) => {
    const x = Math.round(event.screenX);
    const y = Math.round(event.screenY);
    const delta = {
      x: Math.round(x - this.previousX),
      y: Math.round(y - this.previousY),
    };
    this.previousX = x;
    this.previousY = y;
    return delta;
  };

  _autoScroll(x) {
    if (this.autoScrollInterval) {
      clearInterval(this.autoScrollInterval);
      this.autoScrollInterval = null;
    }

    if (x && this.refTimeline.current && x < this.refTimeline.current.getBoundingClientRect().left + 100) {
      this.refTimeline.current.scrollLeft -= AUTO_SCROLL_VALUE;
      this.previousX += AUTO_SCROLL_VALUE;
      this.autoScrollInterval = setInterval(() => {
        this.refTimeline.current.scrollLeft -= AUTO_SCROLL_VALUE;
        this.timelineJS.moveElement(-AUTO_SCROLL_VALUE, 0);
      }, 50);
    } else if (x && this.refTimeline.current && x > window.innerWidth - 100) {
      this.refTimeline.current.scrollLeft += AUTO_SCROLL_VALUE;
      this.previousX -= AUTO_SCROLL_VALUE;
      this.autoScrollInterval = setInterval(() => {
        this.refTimeline.current.scrollLeft += AUTO_SCROLL_VALUE;
        this.timelineJS.moveElement(AUTO_SCROLL_VALUE, 0);
      }, 50);
    }
  }

  _onEvent(element, type, event) {
    event.preventDefault();

    if (type === 'CLICK') {
      this.isDragging = true;
      event.target.setPointerCapture(event.pointerId);

      // We store the initial coordinates to be able to calculate the changes later on
      this._extractPositionDelta(event);
      this._initialY = event.clientY - event.target.getBoundingClientRect().top;
      this._initialX = event.clientX - event.target.getBoundingClientRect().left;
      this.timelineJS.pointerDownElement(element.id, this._initialX, this._initialY);
    }

    if (type === 'STOP_CLICK') {
      this.isDragging = false;
      this.timelineJS.pointerUpElement(element.id);
    }

    if (type === 'RESIZE_LEFT') {
      this.isResizingLeft = true;
      event.target.setPointerCapture(event.pointerId);

      // We store the initial coordinates to be able to calculate the changes later on
      this._extractPositionDelta(event);
      this.timelineJS.pointerDownResizeElement(element.id);
    }

    if (type === 'STOP_RESIZE_LEFT') {
      this.isResizingLeft = false;
    }

    if (type === 'RESIZE_RIGHT') {
      this.isResizingRight = true;
      event.target.setPointerCapture(event.pointerId);

      // We store the initial coordinates to be able to calculate the changes later on
      this._extractPositionDelta(event);
      this.timelineJS.pointerDownResizeElement(element.id);
    }

    if (type === 'STOP_RESIZE_RIGHT') {
      this.isResizingRight = false;
    }

    if (type === 'MOVE') {
      const { x, y } = this._extractPositionDelta(event);

      if (this.isDragging) {
        this.timelineJS.moveElement(x, y);
        this._autoScroll(event.pageX);
      }
      if (this.isResizingLeft) this.timelineJS.resizeElementLeft(element.id, x);
      if (this.isResizingRight) this.timelineJS.resizeElementRight(element.id, x);
    } else {
      this._autoScroll();
    }

    // Auto seek
    if (typeof element.position === 'number' && (this.isDragging || this.isResizingLeft || this.isResizingRight)) {
      const elem = this.timelineJS?.timeline.find((e) => e.id === element.id);
      this._onSeek({ time: elem?.position || 0, isMoving: false, isResizing: false });
    }
  }

  _onSeek(e) {
    const time = this._round(e.time, GRID);
    this._stopPlaying();
    if (!Number.isInteger(this._round(time / GRID))) return;
    const previousTime = this.state.time;
    this.timelineJS.cursor = time < 0 ? 0 : time;
    this.setState({
      time: time < 0 ? 0 : time,
      seeking: e.isMoving || e.isResizing || e.isSeeking,
    });
    if (previousTime !== time) {
      this.player.pause();
      this.player.seekTo(time);
    }
  }

  _stopPlaying() {
    this.setState({
      playing: false,
    });
    if (this.props.onPause) {
      this.props.onPause();
    }
    this.player.pause();
  }

  _onVolumePrompt() {
    this._stopPlaying();
    const value = window.prompt(t('Enter a value between 0 and 100.\n0 to mute the sound, 100 for 100%.'));
    if (typeof value !== 'string') return;
    const volume = parseInt(value, 10) / 100;
    this.timelineJS.volumeElement(volume);
  }

  _onVolumeMute() {
    this._stopPlaying();
    this.timelineJS.muteElement();
  }

  _onVolumeMore() {
    this._stopPlaying();
    this.timelineJS.volumeElementUp();
  }

  _onVolumeLess() {
    this._stopPlaying();
    this.timelineJS.volumeElementDown();
  }

  _onPlay(status) {
    if (!status) {
      this.setState({ playing: false });
      this.player.pause();
      if (this.props.onPause) {
        this.props.onPause();
      }
    } else if (this._calcDuration(this.timelineJS.timeline) > this.state.time) {
      this.setState({ playing: true });
      this.player.play();
      if (this.props.onPlay) {
        this.props.onPlay();
      }
    }
  }

  _onDevEdit() {
    let data = window.prompt(t('DEV MODE\nInsert timeline object here (JSON format)'));
    try {
      data = JSON.parse(data);
    } catch (err) {}
    if (!data || !Array.isArray(data)) return alert(t('Failed to import'));
    const tml = data;
    this.timelineJS.timeline = tml;
    this._onSeek({ time: this.state.time });
  }

  _onDevExport() {
    const cleanTimeline = this.timelineJS.timeline
      .map((e) => {
        const input = this.props.inputs.find((i) => i.id === e.inputId);
        if (!input) return false;
        return {
          duration: e.duration,
          id: e.id,
          inputId: input.id,
          path: input.normalizedPreviewUrl || input.normalizedUrl || input.originalUrl,
          line: e.line,
          maxDuration: input.duration,
          name: input.filename,
          position: e.position,
          speed: e.speed,
          start: e.start,
          volume: e.volume,
          type: e.type,
        };
      })
      .filter((e) => !!e);

    console.log(JSON.stringify(cleanTimeline));
    alert(this.props.t("Timeline available in browser's console"));
  }

  _onDelete() {
    this._stopPlaying();
    this.timelineJS.deleteElement();
  }

  _onCut() {
    this._stopPlaying();
    this.timelineJS.cutElement();
  }

  _onMerge() {
    this._stopPlaying();
    this.timelineJS.mergeTimeline();
  }

  _countSteps(value, step, overflow) {
    //if (val/step == 0) mod oveflow will return NaN, so ~~ converts it to 0
    return overflow ? ~~(Math.floor(value / step) % overflow) : Math.floor(value / step);
  }

  _floor(value, step = 1) {
    return this._countSteps(value, step) * step;
  }

  _round(value, radix = 1) {
    return this._floor(Math.round((value + Number.EPSILON) * 100) / 100 + radix / 2, radix);
  }

  _onVideoLeft() {
    const time = Math.max(
      ...this.state.timeline
        .reduce((acc, e) => [...acc, this._round(e.position, GRID), this._round(e.position + e.duration, GRID)], [])
        .filter((e) => e < this.state.time),
      0
    );
    this._onSeek({ time });
  }

  _onVideoRight() {
    const time = Math.min(
      ...this.state.timeline
        .reduce((acc, e) => [...acc, this._round(e.position, GRID), this._round(e.position + e.duration, GRID)], [])
        .filter((e) => e > this.state.time),
      this._calcDuration(this.state.timeline)
    );
    this._onSeek({ time });
  }

  _onKeyAction(action, params) {
    if (action === 'HISTORY_PREVIOUS') this.timelineJS.undo();
    else if (action === 'HISTORY_NEXT') this.timelineJS.redo();
    else if (action === 'PLAY') this._onPlay(!this.state.playing);
    else if (action === 'FRAME_LEFT') this._onSeek({ time: this.state.time - GRID, isSeeking: false });
    else if (action === 'FRAME_RIGHT') this._onSeek({ time: this.state.time + GRID, isSeeking: false });
    else if (action === 'FRAME_LAST') this._onSeek({ time: this._calcDuration(this.state.timeline), isSeeking: false });
    else if (action === 'FRAME_FIRST') this._onSeek({ time: 0, isSeeking: false });
    else if (action === 'MODE_POINTER') this._onModeChange('MOVE');
    else if (action === 'MODE_CUT') this._onModeChange('CUT');
    else if (action === 'ANCHOR') this.timelineJS.switchMagnet();
    else if (action === 'SAVE') this._onSave();
    else if (action === 'ZOOM') {
      this.player.pause();
      this.timelineJS.zoom(this.state.scaleX + params * 8);
      this._centerTimeCursor();
    } else if (action === 'ZOOM_MORE') {
      this.player.pause();
      this.timelineJS.zoom(this.state.scaleX + 1);
      this._centerTimeCursor();
    } else if (action === 'ZOOM_LESS') {
      this.player.pause();
      this.timelineJS.zoom(this.state.scaleX - 1);
      this._centerTimeCursor();
    } else if (action === 'SELECT') this.timelineJS.ctrlKeyPressed = params;
    else if (action === 'DELETE') this._onDelete();
    else if (action === 'SELECT_ALL') this.timelineJS.selectAllElements();
    else if (action === 'UNSELECT_ALL') this.timelineJS.unselectAllElements();
    else if (action === 'VOLUME_MUTE') this._onVolumeMute();
    else if (action === 'VOLUME_MORE') this._onVolumeMore();
    else if (action === 'VOLUME_LESS') this._onVolumeLess();
    else if (action === 'VOLUME_PROMPT') this._onVolumePrompt();
    else if (action === 'SPEED_PROMPT') this._onSpeedPrompt();
    else if (action === 'SPEED_MORE') this._onSpeedMore();
    else if (action === 'SPEED_LESS') this._onSpeedLess();
    else if (action === 'DUPLICATE') this.timelineJS.duplicateElement();
    else if (action === 'CUT_REMOVE_BEFORE') this.timelineJS.cutElementAndRemoveBefore();
    else if (action === 'CUT_REMOVE_AFTER') this.timelineJS.cutElementAndRemoveAfter();
    else if (action === 'VIDEO_LEFT') this._onVideoLeft();
    else if (action === 'VIDEO_RIGHT') this._onVideoRight();
    else if (action === 'CUT') this._onCut();
    else if (action === 'MERGE') this._onMerge();
    else if (action === 'DEV_EDIT') this._onDevEdit();
    else if (action === 'DEV_EXPORT') this._onDevExport();
    else console.log('Action not supported yet', action);
  }

  _centerTimeCursor() {
    animateScrollTo([this.state.time * this.state.scaleX - this.refTimeline.current.clientWidth / 2, null], {
      elementToScroll: this.refTimeline.current,
      horizontal: true,
      speed: 0,
      minDuration: 0,
      maxDuration: 0,
      cancelOnUserAction: false,
    });
  }

  _onSpeedPrompt() {
    this._stopPlaying();
    const speed = prompt(t('Choose your speed between 0.5 and 10.0\nUse a dot for float speed (Eg: 2.4 for 240%)'));
    if (!speed || parseFloat(speed) < 0.5 || parseFloat(speed) > 10) return;
    this.timelineJS.setSpeedElement(speed);
  }

  _onSpeedMore() {
    this._stopPlaying();
    this.timelineJS.addToSpeedElement(0.5);
  }

  _onSpeedLess() {
    this._stopPlaying();
    this.timelineJS.addToSpeedElement(-0.5);
  }

  _onUnselectAllClick(e) {
    if (e.target.dataset.type === 'timeline-background' || e.target.dataset.type === 'timeline-line') this.timelineJS.unselectAllElements();
  }

  _onAddInput = (inputData) => {
    const input = typeof inputData !== 'object' ? this.props.inputs.find((e) => e.id === inputData) : inputData;
    this._stopPlaying();
    this.timelineJS.addInputElement(input);
  };

  componentDidUpdate(prevProps) {
    if (!isEqual(prevProps.inputs, this.props.inputs)) {
      const streams = this.props.inputs
        .filter((input) => input && (input.normalizedPreviewUrl || input.normalizedUrl))
        .reduce((acc, input) => ({ ...acc, [input.id]: input.normalizedPreviewUrl || input.normalizedUrl || '' }), {});
      this.setState({ ...this.state.streams, ...streams });
    }
  }

  shouldComponentUpdate(prevProps, prevState) {
    return !(
      isEqual(this.props.preventKeyboardShortcuts, prevProps.preventKeyboardShortcuts) &&
      isEqual(this.props.inputs, prevProps.inputs) &&
      isEqual(this.props.projectId, prevProps.projectId) &&
      isEqual(this.timelineJS.timeline, prevProps.timelineObject.timeline) &&
      isEqual(this.timelineJS.timeline, prevState.timeline) &&
      this.timelineJS.scaleX === prevState.scaleX &&
      this.timelineJS.scaleY === prevState.scaleY &&
      this.timelineJS.magnet === prevState.magnet &&
      this.state.offsetX === prevState.offsetX &&
      this.state.time === prevState.time &&
      this.state.playing === prevState.playing &&
      this.state.seeking === prevState.seeking &&
      this.state.inputBeingRenamed === prevState.inputBeingRenamed &&
      this.state.magnet === prevState.magnet &&
      this.state.history === prevState.history &&
      this.state.historyIdx === prevState.historyIdx
    );
  }

  getTimelinePlayer = () => {
    const timeline = this.timelineJS.timeline.map((e) => ({ ...e, input: this.props.inputs.find((i) => i.id === e.inputId) }));
    const timelinePlayer = timeline.length ? this.timelineJS.mergeTimeline(timeline) : [];
    return timelinePlayer.map((element) => ({
      id: element.id,
      inputId: element.inputId,
      line: element.line,
      start: element.start,
      speed: element.speed,
      volume: element.volume,
      duration: element.duration,
      position: element.position,
      input: element.input,
    }));
  };

  componentDidMount() {
    // Use componentDidMount to set timelineJS.onChange because this.player is needed to be mounted on the DOM
    this.timelineJS.onChange = () => {
      const newId = uuidv4();
      this.setState({
        timeline: this.timelineJS.timeline,
        scaleX: this.timelineJS.scaleX,
        history: this.timelineJS.history,
        historyIdx: this.timelineJS.historyIdx,
        magnet: this.timelineJS.magnet,
        currentTimelineId: newId,
      });
      if (this.props.onChange) {
        const empty = isEqual([], this.timelineJS.timeline);
        this.props.onChange(this.timelineJS.timeline, {
          withGap: empty ? true : !this.timelineJS.checkGap(),
          empty,
          hasChanged: this.timelineJS.history.length > 1,
        });
      }
      const timelinePlayer = this.getTimelinePlayer();
      if (!isEqual(timelinePlayer, this.timelinePlayer)) {
        this.timelinePlayer = timelinePlayer;
        this.player.updateTimeline(timelinePlayer);
      }
    };
  }

  _fitToScreen = () => {
    const width = this.refTimeline.current.getBoundingClientRect().width;

    const timeEnd = this.state.timeline.reduce((acc, e) => {
      const t = e.position + e.duration;
      return t > acc ? t : acc;
    }, 0);

    this.timelineJS.zoom(width / timeEnd);
    this.refTimeline.current.scrollLeft = 0;
  };

  render() {
    const timeline = this.state.timeline.map((e) => ({ ...e, input: this.props.inputs.find((i) => i.id === e.inputId) }));
    const timelineMaxDuration = this._calcDuration(timeline) + 30 < 8 * 60 ? 8 * 60 : this._calcDuration(timeline) + 30;

    return (
      <div onContextMenu={(e) => e.preventDefault()}>
        <PromptBeforeUnload
          when={
            timeline.length > 0 &&
            !(isEqual(this.timelineJS.history[0] || [], this.state.timeline) || this.timelineJS.history.length <= 1) &&
            !isEqual(this.state.exportedTimeline || [], this.state.timeline)
          }
        />
        <BrowserWarning />
        <div
          className={classNames(
            styles.bodyContainer,
            showBrowserWarning() ? styles.warningBodyContainer : null,
            this.ratio === '9:16' ? styles.bodyContainerVertical : null
          )}
        >
          <TimelineDirectory
            inputs={this.props.inputs.map((input) => ({
              ...input,
              used: !!timeline.find((e) => e.input.id === input.id),
              refused: input.status === PROJECT_INPUT_STATUS.refused,
            }))}
            onAdd={(input) => {
              this._onAddInput(input);
            }}
            onRenameInputTitle={(isRenaming) => this.setState({ inputBeingRenamed: isRenaming })}
            onSaveInputTitle={(inputId, title) => this.props.updateInputTitle(inputId, title)}
          />
            <UnleashClassFlagProvider
              flagName={FEATURE_FLAG_NAMES.SHOW_PLAYER_OPTIMIZE}
              render={({ isEnabled }) => (
                !isEnabled ? (
                  <TimelinePlayer
                    ref={(player) => {
                      this.player = player;
                    }}
                    timeline={this.getTimelinePlayer()}
                    streams={this.state.streams}
                    onProgress={(p) => {
                      this.timelineJS.cursor = p;
                      this.setState({ time: p });
                      this._centerTimeCursor();
                    }}
                    onStop={() => {
                      if (this.state.isPlaying) {
                        this.setState({ playing: false });
                        if (this.props.onPause) {
                          this.props.onPause();
                        }
                      }
                    }}
                    ratio={this.ratio}
                  />
                ) : (
                  <TimelinePlayerV2
                    ref={(player) => {
                      this.player = player;
                    }}
                    timeline={this.getTimelinePlayer()}
                    streams={this.state.streams}
                    onProgress={(p) => {
                      this.timelineJS.cursor = p;
                      this.setState({ time: p });
                      this._centerTimeCursor();
                    }}
                    onStop={() => {
                      if (this.state.isPlaying) {
                        this.setState({ playing: false });
                        if (this.props.onPause) {
                          this.props.onPause();
                        }
                      }
                    }}
                    ratio={this.ratio}
                  />
                )
              )}
            >
            </UnleashClassFlagProvider>
          <TimelineMenu
            selectedElements={timeline.filter((e) => !!e.selected)}
            onSpeedChange={(speed) => this.timelineJS.setSpeedElement(speed)}
            onVolumeChange={(volume) => this.timelineJS.volumeElement(volume)}
            ratio={this.ratio}
          />
        </div>

        <div className={styles.timeline}>
          <div className={styles.panel}>
            <TimelineNav
              workflows={this.props.workflows}
              scaleX={this.state.scaleX}
              currentTimelineId={this.state.currentTimelineId}
              timelines={this.props.timelines}
              onChangeTimeline={(timeline) => (this.timelineJS.timeline = timeline)}
              onZoomChange={(e) => {
                this.timelineJS.zoom(e);
                this._centerTimeCursor();
              }}
              onExport={() => {
                this._onExport();
              }}
              onSave={() => {
                this._onSave();
              }}
              onModeChange={(e) => this._onModeChange(e)}
              onPlay={(e) => this._onPlay(e)}
              onSeek={(e) => {
                this._onSeek({
                  time: e,
                  isMoving: false,
                });
              }}
              time={this.state.time}
              playing={this.state.playing}
              onUndo={() => this.timelineJS.undo()}
              onRedo={() => this.timelineJS.redo()}
              onShortcuts={() => this._onShortcuts()}
              onEditingBrief={() => this._onEditingBrief()}
              onSwitchMagnet={() => this.timelineJS.switchMagnet()}
              magnet={this.state.magnet}
              duration={this._calcDuration(timeline)}
              canUndo={this.state.historyIdx > 0}
              canRedo={this.state.history.length > this.state.historyIdx + 1}
              canSave={
                timeline.length > 0 &&
                !(isEqual(this.timelineJS.history[0] || [], this.state.timeline) || this.timelineJS.history.length <= 1) &&
                !isEqual(this.state.exportedTimeline || [], this.state.timeline)
              }
              canExport={timeline.length > 0 && !isEqual(this.state.exportedTimeline || [], this.state.timeline)}
              checkGap={() => this.timelineJS.checkGap()}
              onFitToScreen={() => this._fitToScreen()}
              canFitToScreen={timeline.length > 0}
            />
            <TimelineBase
              scaleX={this.state.scaleX}
              scaleY={this.state.scaleY}
              offsetX={this.state.offsetX}
              duration={timelineMaxDuration}
              onSeek={(e) => {
                this._onSeek(e);
              }}
            />
            <div
              datatype="timeline-background"
              className={`${styles.container} ${this.state.playing ? styles.scroll : ''}`}
              ref={this.refTimeline}
              onScroll={() => {
                this._pureScroll();
              }}
              onMouseDown={() => this.timelineJS.unselectAllElements()}
            >
              <div
                datatype="timeline-line"
                className={styles.content}
                style={{
                  width: `${timelineMaxDuration * this.state.scaleX}px`,
                  background: `linear-gradient(#313131 1px, transparent 1px) 0% 0% / ${this.state.scaleY}px ${this.state.scaleY}px repeat`,
                }}
              >
                {timeline.map((e) => (
                  <TimelineElement
                    id={e.id}
                    key={e.id}
                    filename={e?.input?.filename || ''}
                    type={e?.input?.type || ''}
                    maxDuration={e?.input?.duration || 0}
                    line={e.line}
                    position={e.position}
                    start={e.start}
                    duration={e.duration}
                    speed={e.speed}
                    volume={e.volume}
                    borderLeftWarning={!!e.borderLeftWarning}
                    borderRightWarning={!!e.borderRightWarning}
                    isMoving={!!e.isMoving}
                    isResizing={!!e.isResizing}
                    selected={!!e.selected}
                    scaleX={this.state.scaleX}
                    scaleY={this.state.scaleY}
                    onEvent={(type, event) => this._onEvent(e, type, event)}
                  />
                ))}
              </div>
            </div>
            <TimeCursor
              offsetX={this.state.offsetX}
              scaleX={this.state.scaleX}
              isPlaying={this.state.playing}
              time={this.state.time}
              scrollLeft={this.refTimeline?.current?.scrollLeft || 0}
              scrollWidth={this.refTimeline?.current?.scrollWidth || 0}
              clientWidth={this.refTimeline?.current?.clientWidth || 0}
              onClick={() => {
                this.timelineJS.cutElement();
              }}
            />
          </div>
        </div>
        <TimelineKeyboardHandler
          disabled={this.state.inputBeingRenamed || this.props.preventKeyboardShortcuts}
          onAction={(e, p) => this._onKeyAction(e, p)}
        />
      </div>
    );
  }
}

Timeline.propTypes = {
  timelines: PropTypes.array,
  inputs: PropTypes.array,
  onExport: PropTypes.func,
  onSave: PropTypes.func,
  projectId: PropTypes.string,
  onPause: PropTypes.func,
  preventKeyboardShortcuts: PropTypes.bool,
  ratio: PropTypes.string,
};

Timeline.defaultProps = {
  timelines: [],
  inputs: [],
  onSave: (e) => {
    console.log('[Timeline] onSave()', e);
  },
  onExport: (e) => {
    console.log('[Timeline] onExport()', e);
  },
  projectId: 'DEV-DEBUG',
};

export default Timeline;
