import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import Action from '@components/Common/Action';
import DropDown from '@components/Common/DropDown';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import Spinner from '@components/Common/Spinner';

import style from './style.module.css';

const MenuIcon = ({ type }) => <SkeepersIcon type={type} color="var(--picto-color)" />;

const ClientMenu = ({ open = false, config = [], isLoading }) => {
  const { t } = useTranslation();

  return (
    <nav data-cy="client-menu" className={classNames(style.nav, { [style.open]: open, [style.boxShadow]: open })}>
      <div className={style.tabContainer}>
        {isLoading ? (
          <Spinner type="SECONDARY"></Spinner>
        ) : (
          config.map((e, i) => (
            <div key={i}>
              <Action
                action={e.onClick}
                className={classNames(style.blockTitle, { [style.selected]: e.isSelected, [style.blockTitleAndDropdownOpen]: e.open })}
              >
                <SkeepersIcon type={e.icon} />
                <span className={style.name}>{t(e.title)}</span>
                {e?.children?.length > 0 && (
                  <div className={classNames(style.dropdownIcon, { [style.open]: e.open })}>
                    <MenuIcon type="DROPDOWN" />
                  </div>
                )}
              </Action>
              {e?.children?.length > 0 && (
                <DropDown open={e.open}>
                  <ul className={style.dropdownList}>
                    {e.children.map((child, childIndex) => (
                      <li title={child.name} key={child.key || childIndex}>
                        <Action
                          action={child.onClick}
                          className={classNames(style.itemActionContainer, { [style.selected]: child.selected, [style.dropdown]: child?.children?.length })}
                        >
                          <span className={style.itemName}>{child.name}</span>
                          {child?.children?.length && (
                            <div className={classNames(style.dropdownIcon, { [style.open]: child.open })}>
                              <MenuIcon type="DROPDOWN" />
                            </div>
                          )}
                        </Action>
                        <DropDown open={child.open}>
                          <ul className={style.dropdownList}>
                            {child?.children?.map((c) => (
                              <li key={c.key}>
                                <Action action={c.onClick} className={classNames(style.itemActionContainer, style.itemSub, { [style.selected]: c.selected })}>
                                  <span className={style.itemName}>{c.name}</span>
                                </Action>
                              </li>
                            ))}
                          </ul>
                        </DropDown>
                      </li>
                    ))}
                  </ul>
                </DropDown>
              )}
            </div>
          ))
        )}
      </div>
    </nav>
  );
};

export default ClientMenu;
