import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BlockList from '@components/Common/BlockList';
import Divider from '@components/Common/Divider';
import LabelAction from '@components/Common/LabelAction';
import Input from '@components/FormElement/Inputs/Input';
import Label from '@components/FormElement/Label';

import style from './style.module.css';

const Element = ({ children, label, onDelete }) => {
  const { t } = useTranslation();

  return (
    <BlockList.Element className={style.field}>
      <div className={style.name}>{label}</div>
      <div className={style.input}> {children}</div>
      {onDelete && <LabelAction className={style.action} text={t('Delete')} icon="TRASH" action={onDelete} />}
    </BlockList.Element>
  );
}

const Search = ({ onChange }) => {
  const { t } = useTranslation();
  const timeoutRef = useRef();
  const [text, setText] = useState();
  const onEdit = (fullText) => {
    setText(fullText);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => onChange(fullText), 500);
  };

  return (
    <>
      <Label title={t('Search')}>
        <Input onEdit={onEdit} value={text} />
      </Label>
      <Divider className={style.divider} />
    </>
  );
};

const FormList = (props) => <BlockList {...props} preserveMargin />;

export default FormList;

FormList.Element = Element;
FormList.Search = Search;
