const menu = (solutions, selected = '', callback = () => {}, addCallback = () => {}, t = (v) => v) => {
  return [
    {
      id: 'GENERAL',
      name: t('General'),
      icon: 'GENERAL',
      background: 'var(--solutions-background-color)',
      color: 'var(--solutions-color)',
    },
    ...(solutions.find((s) => s.type === 'CASTING')
      ? [
          {
            id: 'CASTING',
            name: t('Casting'),
            icon: 'CASTING',
            background: 'var(--solutions-background-color)',
            color: 'var(--solutions-color)',
          },
        ]
      : []),
    ...(solutions.find((s) => ['PLATFORM_BASIC', 'PLATFORM_BOOK', 'PLATFORM_PRODUCT', 'PLATFORM_CHALLENGE'].includes(s.type))
      ? [
          {
            id: 'PLATFORM',
            name: t('Platform'),
            icon: 'PLATFORM',
            background: 'var(--solutions-background-color)',
            color: 'var(--solutions-color)',
          },
        ]
      : []),
    ...(solutions.find((s) => s.type === 'TRACKING')
      ? [
          {
            id: 'TRACKING',
            name: t('Tracking'),
            icon: 'TRACKING',
            background: 'var(--solutions-background-color)',
            color: 'var(--solutions-color)',
          },
        ]
      : []),
    ...(solutions.find((s) => s.type === 'VSG')
      ? [
          {
            id: 'VSG',
            name: t('VSG'),
            icon: 'VSG',
            background: 'var(--solutions-background-color)',
            color: 'var(--solutions-color)',
          },
        ]
      : []),
    ...(solutions.find((s) => s.type === 'PLAYER')
      ? [
          {
            id: 'PLAYER',
            name: t('Player'),
            icon: 'PLAYER',
            background: 'var(--solutions-background-color)',
            color: 'var(--solutions-color)',
          },
        ]
      : []),
    ...(solutions.find((s) => s.type === 'SMART_UPLOADER')
      ? [
          {
            id: 'SMART-UPLOADER',
            name: t('Smart Uploader'),
            icon: 'SMART_UPLOADER',
            background: 'var(--solutions-background-color)',
            color: 'var(--solutions-color)',
          },
        ]
      : []),
    {
      id: 'ADD',
      name: '',
      icon: 'ADD',
      background: 'var(--solutions-background-color)',
      color: 'var(--solutions-color)',
      onClick: addCallback,
    },
  ]
    .map((c) => ({
      onClick: () => {
        callback(c.id);
      },
      ...c,
      selected: c.id === selected,
    }))
    .map((e, i, tab) => ({
      ...e,
      selected: tab.some((m) => m.selected) ? e.selected : i === 0,
    }));
};

export default menu;
