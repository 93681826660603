import { useTranslation } from 'react-i18next';

import CreateContractForm from '@components/Form/CreateContractForm';
import Window from '@components/Window/Window';

const ContractWindow = ({ title, contract = {}, onEdit = () => {}, isUpdating = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();
  const titleToUse = title ?? t('Add a contract');

  return (
    <Window title={titleToUse} open={open} onClose={onClose}>
      <CreateContractForm contract={contract} onSubmit={onEdit} isUpdating={isUpdating} />
    </Window>
  );
};

export default ContractWindow;
