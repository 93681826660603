import moment from 'moment';
import { useCallback } from 'react';

export const useValidateBeforeStart = ({ subscriptionStartDate }, t) =>
  useCallback(
    (subscriptionEndDate) => {
      if (!subscriptionEndDate) {
        return null;
      }
      if (moment(subscriptionEndDate).isBefore(moment(subscriptionStartDate))) {
        return t('The subscription end cannot be before the subscription start');
      }
      return null;
    },
    [subscriptionStartDate, t]
  );

export const useValidateInRange = ({ subscriptionStartDate, subscriptionEndDate }, t) =>
  useCallback(
    (date) => {
      if (!date) {
        return null;
      }
      if (!moment(date).isBetween(moment(subscriptionStartDate), moment(subscriptionEndDate), null, '[]')) {
        return t('This date should between the subscription start and subscription end.');
      }
      return null;
    },
    [subscriptionStartDate, subscriptionEndDate, t]
  );

export const useValidateAfterEnd = ({ subscriptionEndDate }, t) =>
  useCallback(
    (date) => {
      if (!date) {
        return null;
      }
      if (moment(date).isAfter(moment(subscriptionEndDate))) {
        return t("This date shouldn't be after the subscription end.");
      }
      return null;
    },
    [subscriptionEndDate, t]
  );

export const companyContractTypes = {
  videoProduction: 'VIDEO_PRODUCTION',
  videoBroadcasting: 'VIDEO_BROADCASTING',
  inactive: 'INACTIVE',
};
