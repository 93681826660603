import { useTranslation } from 'react-i18next';

import Window from '@components/Window/Window';

import style from './style.module.css';

const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'];
const macOs = macosPlatforms.includes(window.navigator.platform);

const getKeys = (t = (v) => v) => [
  {
    action: t('Play/Pause'),
    keys: [t('Space bar'), '0', 'k'],
  },
  {
    action: t('Previous frame'),
    keys: ['←', '1', 'j'],
  },
  {
    action: t('Next frame'),
    keys: ['→', '2', 'l'],
  },
  {
    action: t('Last frame'),
    keys: ['↑', '3'],
  },
  {
    action: t('First frame'),
    keys: ['↓'],
  },
  {
    action: t('Undo'),
    keys: macOs ? ['⌘ + z'] : ['ctrl + z'],
  },
  {
    action: t('Redo'),
    keys: macOs ? ['⌘ + y', '⌘ + ⇧ + z'] : ['ctrl + y', 'ctrl + ⇧ + z'],
  },
  {
    action: t('Save'),
    keys: macOs ? ['⌘ + s'] : ['ctrl + s'],
  },
  {
    action: t('Enable/Disable anchors'),
    keys: ['a'],
  },
  {
    action: t('Zoom'),
    keys: macOs ? [t('⌘ + mouse scroll')] : [t('ctrl + mouse scroll')],
  },
  {
    action: t('Zoom in'),
    keys: macOs ? [t('⌘ + plus')] : [t('ctrl + plus')],
  },
  {
    action: t('Zoom out'),
    keys: macOs ? [t('⌘ + minus')] : [t('ctrl + minus')],
  },
  {
    action: t('Go to the beginning of the chunk'),
    keys: macOs ? ['⌘ + ←'] : ['ctrl + ←'],
  },
  {
    action: t('Go to the end of the chunk'),
    keys: macOs ? ['⌘ + →'] : ['ctrl + →'],
  },
  {
    action: t('Select all'),
    keys: macOs ? ['⌘ + a'] : ['ctrl + a'],
  },
  {
    action: t('Unselect all'),
    keys: [t('esc')],
  },
  {
    action: t('Add 10% to volume'),
    keys: ['+'],
  },
  {
    action: t('Remove 10% to volume'),
    keys: ['-'],
  },
  {
    action: t('Custom volume'),
    keys: ['v'],
  },
  {
    action: t('Mute'),
    keys: ['m', '/'],
  },
  {
    action: t('Speed x2'),
    keys: ['e'],
  },
  {
    action: t('Speed /2'),
    keys: ['z'],
  },
  {
    action: t('Custom speed'),
    keys: ['s'],
  },
  {
    action: t('Remove'),
    keys: ['⌫', t('del')],
  },
  {
    action: t('Duplicate'),
    keys: ['d'],
  },
  {
    action: t('Cut'),
    keys: ['u', 'c'],
  },
  {
    action: t('Cut before'),
    keys: ['i'],
  },
  {
    action: t('Cut after'),
    keys: ['o'],
  },
];

/*
    TECHNICAL KEYS
    DEV_EDIT: ['ctrl+d'],
    DEV_EXPORT: ['ctrl+e'],
    MERGE: ['f'],
*/

const StudioShortcuts = ({ onClose = () => {}, open = false }) => {
  const { t } = useTranslation();
  const keys = getKeys(t);

  return (
    <Window title={t('Studio shortcuts')} open={open} onClose={onClose}>
      <table>
        <tbody>
          {keys.map((keySet) => (
            <tr key={keySet.action}>
              <td className={style.action}>{keySet.action}</td>
              <td>
                {keySet.keys.reduce(
                  (acc, v, i) => [
                    ...acc,
                    ...(i ? [` ${t('or')} `] : []),
                    <span key={v} className={style.key}>
                      {v}
                    </span>,
                  ],
                  []
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Window>
  );
};

export default StudioShortcuts;
