import PropTypes from 'prop-types';

import { useTabContext } from '@components/Common/Tabs/TabContext';

const TabPanel = ({ name = '', children }) => {
  const { current } = useTabContext();

  if (current !== name) return null;

  return <div>{children}</div>;
};

TabPanel.propTypes = {
  name: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default TabPanel;
