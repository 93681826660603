import classNames from 'classnames';
import PropTypes from 'prop-types';

import SkeepersIcon from '@components/Common/SkeepersIcon';

import style from './style.module.css';

const SolutionButton = ({ type = 'GENERAL', title = '', size = 'SMALL', isSelected = false, onClick = () => {} }) => {
  return (
    <div
      className={classNames(style.button, {
        [style.large]: size === 'LARGE',
        [style.medium]: size === 'MEDIUM',
        [style.selected]: isSelected,
      })}
      onClick={onClick}
    >
      <div className={classNames(style.icon, { [style.bigIcon]: !title })}>
        <SkeepersIcon type={type} color={isSelected ? 'white' : 'var(--skp-color-neutral-700)'} />
      </div>
      {title && <span className={classNames(style.title, { [style.titleSelected]: isSelected, [style.longText]: title.length > 9 })}>{title}</span>}
    </div>
  );
};

SolutionButton.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  size: PropTypes.oneOf(['SMALL', 'MEDIUM', 'LARGE']),
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SolutionButton;
