import { ApolloProvider } from '@apollo/client';
import FlagProvider from '@unleash/proxy-client-react';
import React from "react";
import ReactDOM from "react-dom/client";

import client from '@app/apollo';
import App from '@app/App';
import { FEATURE_FLAG_CONFIG, PUBLIC_SDK } from '@app/config';
import { init as i18n } from '@app/i18n';

window.process = {
  ...window.process,
  browser: true,
  version: 'v14.0',
};

/**
 * Initialize i18next
 */
const initI18n = i18n;

/**
 * Load the SDK
 */
const initSDK = async () => {
  const head = document.getElementsByTagName('head').item(0);
  const script = document.createElement('script');
  script.setAttribute('type', 'text/javascript');
  script.setAttribute('src', PUBLIC_SDK);
  script.setAttribute('defer', 'true');
  script.setAttribute('async', 'true');
  head.appendChild(script);
};

/**
 * Log the user in if the connect was instructed to handoff login/logout responsibility to the caller (for local development)
 */
const initUser = async () => {
  try {
    const url = new URL(window.location.href);
    const handoff = url.searchParams.get('handoff');

    if (handoff) {
      const payload = JSON.parse(atob(handoff));
      await client.mutate(payload);
    }
  } catch (err) {
    console.error(err);
  }
};

/**
 * Render app once all init functions have run, regardless of their success
 */
Promise.allSettled([initI18n(), initSDK(), initUser()]).then(() => {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <ApolloProvider client={client}>
      <FlagProvider config={FEATURE_FLAG_CONFIG}>
        <App />
      </FlagProvider>
    </ApolloProvider>
  );
});
