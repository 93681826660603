import classNames from 'classnames';

import Action from '@components/Common/Action';

import style from './style.module.css';

const BlockList = ({ preserveMargin = false, children, className = '' }) => (
  <ul
    className={classNames(style.list, className, {
      [style.suppress]: !preserveMargin,
    })}
  >
    {children}
  </ul>
);

BlockList.Element = ({ action, children, className = '' }) =>
  action ? (
    <li className={style.row}>
      <Action className={className} action={action}>
        {children}
      </Action>
    </li>
  ) : (
    <li className={classNames(style.row, className)}>{children}</li>
  );

export default BlockList;
