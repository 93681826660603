import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonContainer from '@components/Common/ButtonContainer';
import { Form, useForm } from '@components/FormElement/Form';

const CommentForm = ({ content, onSubmit = () => {}, isLoading = false }) => {
  const { t } = useTranslation();
  const methods = useForm();

  return (
    <Form onSubmit={onSubmit} methods={methods} isUpdating={isLoading}>
      <Form.Label title={t('Message')} name="message">
        <Form.Textarea name="message" defaultValue={content} required />
      </Form.Label>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default CommentForm;

CommentForm.propTypes = {
  content: PropTypes.string,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
};
