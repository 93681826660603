import { useQuery, useMutation } from '@apollo/client';

import FACEBOOK_AUTH from '@graphql/projects/facebookAuth.graphql';
import GET_PROJECT_UPLOAD_DATA from '@graphql/projects/getProjectUploadData.graphql';
import GET_TWITTER_AUTH_URL from '@graphql/projects/getTwitterAuthUrl.graphql';
import GET_UPLOAD_TASK from '@graphql/projects/getUploadTask.graphql';
import GET_UPLOAD_TASKS from '@graphql/projects/getUploadTasks.graphql';
import GET_UPLOAD_TASKS_STATUS from '@graphql/projects/getUploadTasksStatus.graphql';
import GET_VIMEO_AUTH_URL from '@graphql/projects/getVimeoAuthUrl.graphql';
import GET_YOUTUBE_AUTH_URL from '@graphql/projects/getYoutubeAuthUrl.graphql';
import LINKEDIN_AUTH from '@graphql/projects/linkedinAuth.graphql';
import TWITTER_AUTH from '@graphql/projects/twitterAuth.graphql';
import UPDATE_VIMEO_SUBTITLES from '@graphql/projects/updateVimeoSubtitles.graphql';
import UPDATE_YOUTUBE_SUBTITLES from '@graphql/projects/updateYoutubeSubtitles.graphql';
import UPLOAD_FACEBOOK from '@graphql/projects/uploadFacebook.graphql';
import UPLOAD_INSTAGRAM from '@graphql/projects/uploadInstagram.graphql';
import UPLOAD_INSTAGRAM_STORY from '@graphql/projects/uploadInstagramStory.graphql';
import UPLOAD_LINKEDIN from '@graphql/projects/uploadLinkedin.graphql';
import UPLOAD_TIKTOK from '@graphql/projects/uploadTiktok.graphql';
import UPLOAD_TWITTER from '@graphql/projects/uploadTwitter.graphql';
import UPLOAD_VIMEO from '@graphql/projects/uploadVimeo.graphql';
import VIMEO_AUTH from '@graphql/projects/vimeoAuth.graphql';
import BULK_UPLOAD_YOUTUBE from '@graphql/projects/youtube/bulkUploadYoutube.graphql';
import YOUTUBE_AUTH from '@graphql/projects/youtubeAuth.graphql';

export const useUploadFacebook = (uploadVideoInput, { onCompleted = () => {} }) => {
  const [upload] = useMutation(UPLOAD_FACEBOOK, { onCompleted: (data) => onCompleted(data?.uploadFacebook) });

  const uploadFacebook = ({ pageId, title, description, thumbnail }, pages) => {
    const page = pages.find((value) => value.id === pageId);

    upload({
      variables: {
        uploadVideoInput,
        input: {
          pageId,
          accessToken: page.access_token,
          title,
          description,
          thumbnail,
        },
      },
    });
  };

  return [uploadFacebook];
};

export const useGetTwitterAuthUrl = (redirectUri) => {
  const { loading, error, data, refetch } = useQuery(GET_TWITTER_AUTH_URL, {
    variables: {
      redirectUri: redirectUri,
    },
  });

  return {
    url: data?.getTwitterAuthUrl,
    loading,
    error,
    refetch,
  };
};

export const useTwitterAuth = ({ onCompleted = () => {} }) => {
  const [auth] = useMutation(TWITTER_AUTH, { onCompleted: (data) => onCompleted(data?.twitterAuth) });

  const twitterAuth = (token, verifier) => {
    auth({
      variables: {
        token,
        verifier,
      },
      onCompleted,
    });
  };

  return [twitterAuth];
};

export const useUploadTwitter = (uploadVideoInput, { onCompleted = () => {} }) => {
  const [upload] = useMutation(UPLOAD_TWITTER, { onCompleted: (data) => onCompleted(data?.uploadTwitter) });

  const uploadTwitter = ({ description }, accessToken) => {
    upload({
      variables: {
        uploadVideoInput,
        input: {
          accessToken,
          description,
        },
      },
    });
  };

  return [uploadTwitter];
};

export const useGetVimeoAuthUrl = (redirectUri) => {
  const { loading, error, data } = useQuery(GET_VIMEO_AUTH_URL, {
    variables: {
      redirectUri: redirectUri,
    },
  });

  return {
    url: data?.getVimeoAuthUrl,
    loading,
    error,
  };
};

export const useVimeoAuth = ({ onCompleted = () => {} }) => {
  const [auth] = useMutation(VIMEO_AUTH, { onCompleted: (data) => onCompleted(data?.vimeoAuth) });

  const vimeoAuth = (code, redirectUri) => {
    auth({
      variables: {
        code,
        redirectUri,
      },
      onCompleted,
    });
  };

  return [vimeoAuth];
};

export const useUploadVimeo = (uploadVideoInput, { onCompleted = () => {} }) => {
  const [upload] = useMutation(UPLOAD_VIMEO, { onCompleted: (data) => onCompleted(data?.uploadVimeo) });

  const uploadVimeo = ({ title, description, thumbnail }, accessToken, subtitles) => {
    upload({
      variables: {
        uploadVideoInput,
        input: {
          accessToken: accessToken,
          title,
          description,
          thumbnail,
          subtitles,
        },
      },
    });
  };

  return [uploadVimeo];
};

export const useGetUploadTask = (taskId, type, { pollInterval }) => {
  const { loading, error, data, previousData, stopPolling } = useQuery(GET_UPLOAD_TASK, {
    variables: {
      taskId,
      type,
    },
    pollInterval,
  });

  return {
    task: data?.getUploadTask,
    lastTask: previousData?.getUploadTask,
    loading,
    error,
    stopPolling,
  };
};

export const useGetUploadTasksStatus = (processingTaskIds, uploadTaskIds, type, { pollInterval }) => {
  const { loading, error, data, stopPolling } = useQuery(GET_UPLOAD_TASKS_STATUS, {
    variables: {
      processingTaskIds,
      uploadTaskIds,
      type,
    },
    pollInterval,
  });

  return {
    processingTasksStatus: data?.getUploadTasksStatus?.processingTasks,
    uploadTasksStatus: data?.getUploadTasksStatus?.tasks,
    loading,
    error,
    stopPolling,
  };
};

export const useBulkUploadYoutube = (credentials, { onCompleted = () => {} }) => {
  const [upload] = useMutation(BULK_UPLOAD_YOUTUBE, { onCompleted: (data) => onCompleted(data?.bulkUploadYoutube) });

  const update = (videos, playlistId, companyId) => {
    upload({
      variables: {
        credentials,
        playlistId,
        companyId,
        videos: videos.map((v) => ({
          id: v.id,
          title: v.title,
          description: v.description,
          thumbnail: v.poster,
          privacyStatus: v.privacyStatus.toLowerCase(),
          tags: v.tags,
          publicStatsViewable: true, // TODO replace me when  : UGV-1482
          videoId: v.videoId,
          solutionId: v.solutionId,
          type: v.type,
          legalInfo: v.legalInfo,
          introTexts: v.introTexts,
          subtitles: v.lastOutput?.subtitles
            .filter((subtitle) => subtitle.status === 'READY')
            .map((subtitle) => ({
              language: subtitle.language,
              path: subtitle.srtUrl,
            })),
        })),
      },
    });
  };
  return [update];
};

export const useGetProjectUploadData = (id, { skip = false } = {}) => {
  const { data, loading, error } = useQuery(GET_PROJECT_UPLOAD_DATA, { variables: { id }, skip: !id || skip });

  return { projectWithUploadData: data?.getProject || {}, loading, error };
};

export const useUploadInstagram = (uploadVideoInput, { onCompleted = () => {} }) => {
  const [upload] = useMutation(UPLOAD_INSTAGRAM, { onCompleted: (data) => onCompleted(data?.uploadInstagram) });

  const uploadInstagram = ({ thumbnail }, credentials) => {
    upload({
      variables: {
        uploadVideoInput,
        input: {
          username: credentials.username,
          password: credentials.password,
          thumbnail,
        },
      },
    });
  };

  return [uploadInstagram];
};

export const useFacebookAuth = ({ onCompleted = () => {} }) => {
  const [auth] = useMutation(FACEBOOK_AUTH, { onCompleted: (data) => onCompleted(data?.facebookAuth) });

  const facebookAuth = (code, redirectUri) => {
    auth({
      variables: {
        code,
        redirectUri,
      },
      onCompleted,
    });
  };

  return [facebookAuth];
};

export const useUploadTiktok = (uploadVideoInput, { onCompleted = () => {} }) => {
  const [upload] = useMutation(UPLOAD_TIKTOK, { onCompleted: (data) => onCompleted(data?.uploadTiktok) });

  const uploadTiktok = ({ legend, privacy, comments, duet, stitch }, credentials) => {
    upload({
      variables: {
        uploadVideoInput,
        input: {
          credentials: Buffer.from(JSON.stringify(credentials)).toString('base64'),
          legend,
          privacy: parseInt(privacy),
          comments,
          duet,
          stitch,
        },
      },
    });
  };

  return [uploadTiktok];
};

export const useGetYoutubeAuthUrl = (redirectUri) => {
  const { loading, error, data, refetch } = useQuery(GET_YOUTUBE_AUTH_URL, {
    variables: {
      redirectUri: redirectUri,
    },
  });

  return {
    url: data?.getYoutubeAuthUrl,
    loading,
    error,
    refetch,
  };
};

export const useYoutubeAuth = ({ onCompleted = () => {} }) => {
  const [auth] = useMutation(YOUTUBE_AUTH, { onCompleted: (data) => onCompleted(data?.youtubeAuth) });

  const youtubeAuth = (code, redirectUri) => {
    auth({
      variables: {
        code,
        redirectUri,
      },
    });
  };

  return [youtubeAuth];
};

export const useUploadInstagramStory = (uploadVideoInput, { onCompleted = () => {} }) => {
  const [upload] = useMutation(UPLOAD_INSTAGRAM_STORY, { onCompleted: (data) => onCompleted(data?.uploadInstagramStory) });

  const uploadInstagramStory = ({ thumbnail }, credentials) => {
    upload({
      variables: {
        uploadVideoInput,
        input: {
          username: credentials.username,
          password: credentials.password,
          thumbnail,
        },
      },
    });
  };

  return [uploadInstagramStory];
};

export const useLinkedinAuth = ({ onCompleted = () => {} }) => {
  const [auth] = useMutation(LINKEDIN_AUTH, { onCompleted: (data) => onCompleted(data?.linkedinAuth) });

  const linkedinAuth = (code, redirectUri) => {
    auth({
      variables: {
        code,
        redirectUri,
      },
      onCompleted,
    });
  };

  return [linkedinAuth];
};

export const useUploadLinkedin = (uploadVideoInput, { onCompleted = () => {} }) => {
  const [upload] = useMutation(UPLOAD_LINKEDIN, { onCompleted: (data) => onCompleted(data?.uploadLinkedin) });

  const uploadLinkedin = ({ title, description, company, visibility }, access_token) => {
    upload({
      variables: {
        uploadVideoInput,
        input: {
          access_token,
          title,
          description,
          company,
          visibility,
        },
      },
    });
  };

  return [uploadLinkedin];
};

export const useGetUploadTasks = (projectIds) => {
  const { loading, error, data, refetch } = useQuery(GET_UPLOAD_TASKS, {
    variables: {
      filter: {
        projectIds,
        status: 'ENDED',
      },
      skip: !projectIds?.length,
    },
  });

  return {
    tasks: data?.getUploadTasks,
    loading,
    error,
    refetch,
  };
};

export const useUpdateVimeoSubtitles = ({ onCompleted = () => {} }) => {
  const [update] = useMutation(UPDATE_VIMEO_SUBTITLES, { onCompleted: (data) => onCompleted(data?.updateVimeoSubtitles) });

  const updateVimeoSubtitles = (taskId, access_token, subtitles) => {
    update({
      variables: {
        input: {
          taskId,
          accessToken: access_token,
          subtitles,
        },
      },
    });
  };

  return [updateVimeoSubtitles];
};

export const useUpdateYoutubeSubtitles = ({ onCompleted = () => {} }) => {
  const [update] = useMutation(UPDATE_YOUTUBE_SUBTITLES, { onCompleted: (data) => onCompleted(data?.updateYoutubeSubtitles) });

  const updateYoutubeSubtitles = (taskId, credentials, subtitles) => {
    update({
      variables: {
        input: {
          taskId,
          credentials,
          subtitles,
        },
      },
    });
  };

  return [updateYoutubeSubtitles];
};
