import { WebSocketLink } from '@apollo/client/link/ws';

import { WS_URL } from '@app/config';

import IdleTimeoutHandler from './idleTimeoutHandler';

class Timer {
  constructor({ taskId, onIdle = () => {}, onTick = () => {}, previousActions = [], onError = () => {} }) {
    this.taskId = taskId;
    this.ws = null;
    this.onTick = onTick;
    this.additionalTime = previousActions
      .filter((action) => !!action.endedAt)
      .reduce((acc, action) => acc + (action.endedAt * 1000 - action.startedAt * 1000), 0);
    this.startAt = null;
    this.interval = null;
    this.onError = onError;
    this.errorTimeout = null;
    this.idleTimeoutHandler = new IdleTimeoutHandler({
      callback: () => {
        this.stop();
        onIdle();
      },
    });
    window.addEventListener('offline', () => this.onOffline());
  }

  start() {
    if (this.ws) {
      throw new Error('Websocket already connected'); // !not sure
    }

    if (this.errorTimeout) {
      clearTimeout(this.errorTimeout);
      this.errorTimeout = null;
    }

    // Init websocket
    this.ws = new WebSocketLink({
      uri: `${WS_URL}timer?taskId=${this.taskId}`,
      options: {
        reconnect: true,
      },
    });
    this.ws.subscriptionClient.onError(() => {
      this.onDisconnected();
    });

    // Start timer
    this.startAt = Date.now();

    // Call onTick callback
    this.interval = setInterval(() => {
      this.onTick((Date.now() - this.startAt + this.additionalTime) / 1000);
    }, 1000);

    // Idle detection
    this.idleTimeoutHandler.start();
  }

  stop() {
    if (!this.ws) {
      return;
    }
    if (this.ws.subscriptionClient.status !== WebSocket.CLOSED) {
      this.ws.subscriptionClient.close();
    }
    this.ws = null;
    this.idleTimeoutHandler.stop();
    clearInterval(this.interval);
    this.interval = null;
    this.additionalTime += Date.now() - this.startAt;
    this.startAt = null;
  }

  onDisconnected() {
    if (!this.errorTimeout) {
      this.errorTimeout = setTimeout(() => {
        this.errorTimeout = null;
        if (this.ws && this.ws.subscriptionClient.status !== WebSocket.OPEN) {
          this.stop();
          this.onError();
        }
      }, 5000);
    }
  }

  // Use by Studio component to stop idle detection while playing video
  stopIdle() {
    this.idleTimeoutHandler.stop();
  }

  // Use by Studio component to resume idle detection
  startIdle() {
    if (this.ws) {
      this.idleTimeoutHandler.start();
    }
  }

  isPlaying() {
    return !!this.ws;
  }

  onOffline() {
    this.ws.subscriptionClient.close(false);
    this.onDisconnected();
  }

  getTaskId() {
    return this.taskId;
  }
}

export default Timer;
