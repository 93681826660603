import PropTypes from 'prop-types';

import { timecodeToTime } from '@app/utils';
import Block from '@components/Common/Block';
import SkeepersIcon from '@components/Common/SkeepersIcon';

import style from './style.module.css';

const WorkflowTimer = ({ onPlay = () => {}, onPause = () => {}, time = 0, isPlaying = false }) => {
  const { minutes, seconds } = timecodeToTime(time * 1000);
  const handlePlay = () => {
    !isPlaying && onPlay();
  };
  const handlePause = () => {
    isPlaying && onPause();
  };
  return (
    <Block padding="SMALL" className={style.timer}>
      <span className={style.time}>
        {`${minutes}`.padStart(2, '0')}:{`${seconds}`.padStart(2, '0')}
      </span>
      {isPlaying ? (
        <SkeepersIcon type="PAUSE" onClick={handlePause} className={`${style.pause} ${!isPlaying ? style.disabled : ''}`} />
      ) : (
        <SkeepersIcon type="PLAY" onClick={handlePlay} className={`${style.play} ${isPlaying ? style.disabled : ''}`} />
      )}
    </Block>
  );
};

export default WorkflowTimer;

WorkflowTimer.propTypes = {
  onPlay: PropTypes.func,
  onPause: PropTypes.func,
  time: PropTypes.number,
  isPlaying: PropTypes.bool,
};
