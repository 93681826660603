import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import SkeepersIcon from '@components/Common/SkeepersIcon';

import style from './style.module.css';

export const ClipboardCopy = ({ text = '', color = 'currentColor', mini = true, className = '' }) => {
  const { t } = useTranslation();
  const [copied, setCopied] = useState(false);
  const onCopy = useCallback(() => copy(text), [text]);

  const onClick = () => {
    onCopy();
    setCopied(true);
  };

  useEffect(() => {
    if (copied) {
      const timeout = setTimeout(() => {
        setCopied(false);
      }, 1500);
      return () => clearTimeout(timeout);
    }
  }, [copied]);

  if (mini) {
    return (
      <div className={`${style.rootMini} ${className}`}>
        {copied ? (
          <span className={style.infoCopy}>{t('Copied!')}</span>
        ) : (
          <SkeepersIcon type="COPY" className={style.mini} onClick={onClick} title={t('Copy to clipboard')} />
        )}
      </div>
    );
  }
  return (
    <div className={`${style.root} ${className}`}>
      {copied ? (
        <span className={style.copied}>{t('Copied!')}</span>
      ) : (
        <>
          <a className={style.text} href={`${text}`} target="_blank" rel="noreferrer">
            {text}
          </a>
          <SkeepersIcon type="COPY" onClick={onClick} color={color} className={style.icon} title={t('Copy to clipboard')} />
        </>
      )}
    </div>
  );
};

ClipboardCopy.propTypes = {
  text: PropTypes.string,
  color: PropTypes.string,
  mini: PropTypes.bool,
  className: PropTypes.string,
};

export default ClipboardCopy;
