import { useTranslation } from 'react-i18next';

import { getRoles } from '@app/translations';
import { sortByProp } from '@app/utils';
import BlockList from '@components/Common/BlockList';
import Divider from '@components/Common/Divider';
import Tag from '@components/Common/Tag';
import Window from '@components/Window/Window';

import style from './style.module.css';

const tagColors = {
  create: 'var(--tag-blue)',
  read: 'var(--tag-blue)',
  update: 'var(--tag-blue)',
  delete: 'var(--tag-blue)',
  default: 'var(--tag-grey)',
};

const PermissionRoleWindow = ({ role: permissionRole = {}, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();
  const roles = getRoles(t);
  const role = roles[permissionRole.name] || roles.DEFAULT;
  const actions = Object.keys(permissionRole.actions).reduce((acc, actionStr) => {
    const [resource, action] = actionStr.split('.');
    acc[resource] = (acc[resource] || []).concat(action).sort(sortByProp());
    return acc;
  }, {});

  return (
    <Window title={t('Role actions')} open={open} onClose={onClose}>
      {t('The {{role}} role gives access to the following actions:', { role: role.name })}
      <Divider />
      {Object.keys(actions).length > 0 ? (
        <BlockList preserveMargin>
          {Object.entries(actions)
            .sort(sortByProp(([resource]) => resource))
            .map(([resource, actions]) => (
              <BlockList.Element key={resource} className={style.action}>
                <div className={style.name}>{t(resource)}</div>
                <div className={style.tags}>
                  {actions.map((action) => (
                    <Tag key={action} value={t(action)} color={tagColors[action] || tagColors.default} />
                  ))}
                </div>
              </BlockList.Element>
            ))}
        </BlockList>
      ) : (
        <p>{t('This role does not give access to anything')}</p>
      )}
    </Window>
  );
};

export default PermissionRoleWindow;
