import { useTranslation } from 'react-i18next';

import Hint from '@components/Common/Hint';
import Form from '@components/FormElement/Form/Form';

import style from './style.module.css';

const Destination = () => {
  const { t } = useTranslation();

  return (
    <div className={style.destinationContainer}>
      <Form.Label title={t('Scanning Destination')} name="scanningDestination">
        <Form.SelectCard
          name="scanningDestination"
          required
          options={[
            { title: t('Product page'), description: t('On an online product page url'), value: 'PRODUCT_PAGE', icon: 'GLOBE' },
            { title: t('Product video'), description: t('On a single video player'), value: 'VIDEO', icon: 'FILM' },
          ]}
          defaultValue="PRODUCT_PAGE"
        />
      </Form.Label>
      <Form.Label title={t('Default scanning URL')} name="fallbackUrl">
        <Form.UrlInput
          name="fallbackUrl"
          placeholder={t('URL')}
          hint={
            <Hint
              message={t(`If something goes wrong define the url on wich the users wil be redirected to.
                        We recommend you to put the URL of your website.`)}
              type="INFO"
            />
          }
          required
        />
      </Form.Label>
    </div>
  );
};

export default Destination;
