import PropTypes from 'prop-types';
import { forwardRef, useCallback } from 'react';

import { FormList } from '@components/FormElement/Lists/base';

const toList = (strings) => strings?.map((title) => ({ title }));

const List = forwardRef(({ value, placeholder, orginalValue, onEdit, ...otherProps }, ref) => {
  //values can be null

  const onSimpleEdit = useCallback((values) => onEdit(values ? values.map(({ title }) => title) : values), [onEdit]);

  return (
    <FormList value={toList(value)} placeholder={toList(placeholder)} orginalValue={toList(orginalValue)} onEdit={onSimpleEdit} {...otherProps} ref={ref} />
  );
});

List.displayName = 'List';

List.propTypes = {
  error: PropTypes.bool,
  value: PropTypes.arrayOf(PropTypes.string),
  readOnly: PropTypes.bool,
  originalValue: PropTypes.arrayOf(PropTypes.string),
  placeholder: PropTypes.arrayOf(PropTypes.string),
  onEdit: PropTypes.func,
};

export default List;
