import classNames from 'classnames';
import PropTypes from 'prop-types';

import { GIT_REVISION } from '@app/config';

import style from './style.module.css';

const menuStatus = {
  LARGE: style.navLarge,
  SMALL: style.navSmall,
  HIDDEN: style.navHidden,
};

const ContentArea = ({ children, menu = 'HIDDEN', padding = 'AUTO', commitTheme = 'LIGHT' }) => (
  <>
    <div className={classNames(style.content, menuStatus[menu], padding === 'NONE' ? style.none : '', 'custom-scrollbar')}>{children}</div>
    <span unselectable="on" className={`${style.commitHash} ${commitTheme === 'LIGHT' ? style.light : style.dark}`}>
      {GIT_REVISION.substring(0, 8)}
    </span>
  </>
);

ContentArea.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  menu: PropTypes.oneOf(['HIDDEN', 'SMALL', 'LARGE']),
  commitTheme: PropTypes.oneOf(['LIGHT', 'DARK']),
};

export default ContentArea;
