import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import { textLengthValidate } from '@components/FormElement/Inputs/utils';

import style from './style.module.css';

const Input = forwardRef(
  (
    {
      value = '',
      name = '',
      placeholder = '',
      readOnly = false,
      disabled = false,
      required = false,
      className = '',
      onFocus = () => { },
      onBlur = () => { },
      onEdit = () => { },
      maxLength = null,
      minLength = null,
      error = false,
      dataTestId = '',
    },
    ref
  ) => {
    const onChange = onEdit ? (event) => onEdit(event.target.value) : null;
    return (
      <input
        type="string"
        value={value || ''}
        className={classNames(style.input, className, { [style.error]: error })}
        name={name}
        data-testid={dataTestId}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled || readOnly}
        required={required}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={onChange}
        ref={ref}
        minLength={minLength}
        maxLength={maxLength}
      />
    );
  }
);

Input.displayName = 'Input';

Input.customValidate = textLengthValidate;

Input.propTypes = {
  name: PropTypes.string,
  value: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
};

export default Input;
