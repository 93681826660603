import { createRef, memo } from 'react';
import { useTranslation } from 'react-i18next';

import { isDebug } from '@app/utils';

import style from './style.module.css';

const TimelineElement = ({
  id = '',
  filename = '',
  type = 'VIDEO',

  line = 0,
  position = 0,
  start = 0,
  duration = 0,
  maxDuration = 0,
  speed = 1,

  volume = 1,

  scaleX = 0,
  scaleY = 0,

  borderLeftWarning = false,
  borderRightWarning = false,
  isMoving = false,
  isResizing = false,
  selected = false,

  onEvent = (e) => {
    console.log('[TimelineElement] onEvent()', e);
  },
}) => {
  const { t } = useTranslation();

  const title = `${isDebug() ? `${id}\n` : ''}${start === 0 ? '[' : ']'}${start}-${start + duration}${start + duration === maxDuration ? ']' : '['}`;
  const className = `${style.block} ${isResizing ? style.isresizing : ''} ${isMoving ? style.ismoving : ''} ${selected ? style.selected : ''}
  ${type === 'VIDEO' ? style.video : ''} ${type === 'AUDIO' ? style.audio : ''} ${type === 'IMAGE' ? style.image : ''}`;

  const height = Math.round(scaleY - 2);
  const left = Math.round(scaleX * position) + 1;
  const top = Math.round(scaleY * line + 1);
  const width = Math.round(scaleX * duration < 1 ? 0 : scaleX * duration - 1);

  const refLeft = createRef();
  const refRight = createRef();

  const handleLeftResize = (e) => onEvent('RESIZE_LEFT', e);
  const unHandleLeftResize = (e) => onEvent('STOP_RESIZE_LEFT', e);
  const handleRightResize = (e) => onEvent('RESIZE_RIGHT', e);
  const unHandleRightResize = (e) => onEvent('STOP_RESIZE_RIGHT', e);
  const handleClick = (e) => {
    if (e.target !== refLeft.current && e.target !== refRight.current) {
      onEvent('CLICK', e);
    }
  };
  const unHandleClick = (e) => onEvent('STOP_CLICK', e);
  const move = (e) => onEvent('MOVE', e);

  return (
    <div
      title={title}
      className={className}
      style={{
        height: `${height}px`,
        transform: `translate(${left}px, ${top}px)`,
        width: `${width}px`,
      }}
      onPointerDown={handleClick}
      onPointerMove={move}
      onPointerUp={unHandleClick}
      datatype="timeline-block"
    >
      <div
        ref={refLeft}
        className={`${style.blockResizeLeft} ${borderLeftWarning ? style.warning : ''}`}
        onPointerDown={handleLeftResize}
        onPointerUp={unHandleLeftResize}
      />
      <div className={style.blockName} style={{ lineHeight: `${scaleY - 2}px` }}>
        {filename}
        {parseFloat(volume) === 0 && ` [${t('Muted')}]`}
        {parseFloat(volume) > 0 && ` [${Math.round(volume * 100)}%]`}
        {` [${Math.round(speed * 100) / 100}x]`}
      </div>
      <div
        ref={refRight}
        className={`${style.blockResizeRight} ${borderRightWarning ? style.warning : ''}`}
        onPointerDown={handleRightResize}
        onPointerUp={unHandleRightResize}
      />
    </div>
  );
};

export default memo(TimelineElement);
