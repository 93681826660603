import PropTypes from 'prop-types';

import Block from '@components/Common/Block';
import BlockTitles from '@components/Common/BlockTitles';
import Divider from '@components/Common/Divider';
import SkeepersIcon from '@components/Common/SkeepersIcon';

import style from './style.module.css';

const DropdownBlock = ({
  boxShadow = true,
  title = '',
  subtitle = '',
  tagText = '',
  tagColor = '',
  children = '',
  open = false,
  padding = 'MEDIUM',
  toggle = () => {},
  additionnalHeaderButton = undefined,
}) => (
  <Block padding={padding} boxShadow={boxShadow}>
    <div className={style.header}>
      <div className={style.headerTitle} onClick={toggle}>
        <BlockTitles title={title} subtitle={subtitle} tagColor={tagColor} tagText={tagText} />
      </div>
      {additionnalHeaderButton && <div className={style.additionnalMargin}>{additionnalHeaderButton}</div>}
      <div onClick={toggle}>
        <SkeepersIcon type={open ? 'CHEVRON_UP' : 'CHEVRON_DOWN'} className={!additionnalHeaderButton ? style.marginLeft : undefined} color="var(--skp-color-neutral-900)" />
      </div>
    </div>
    {open && (
      <>
        <Divider className={style.divider} />
        {children}
      </>
    )}
  </Block>
);

export default DropdownBlock;

DropdownBlock.propTypes = {
  boxShadow: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  toggle: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  open: PropTypes.bool,
  classname: PropTypes.string,
  padding: PropTypes.oneOf(['NONE', 'SMALL', 'MEDIUM', 'BIG', 'HUGE']),
};
