import classNames from 'classnames';
import PropTypes from 'prop-types';

import { countries } from '@app/translations';
import icons from '@components/Common/SkeepersFlag/icons';

import style from './style.module.css';

const SkeepersFlag = ({ country, title, className = '' }) => {
  const Icon = icons[country];
  return Icon ? <Icon className={classNames(className, style.flag)} title={title} /> : '?';
};

SkeepersFlag.propTypes = {
  /**
   * ISO 3166-1 country representation
   */
  country: PropTypes.oneOf(Object.keys(countries)),
  title: PropTypes.string,
  className: PropTypes.string,
};

export default SkeepersFlag;
