import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonContainer from '@components/Common/ButtonContainer';
import { Form, useForm } from '@components/FormElement/Form';

const CreatePresetForm = ({ template, isUpdating = false, onSubmit = () => {} }) => {
  const { t } = useTranslation();
  const methods = useForm();

  return (
    <Form methods={methods} onSubmit={onSubmit} isUpdating={isUpdating}>
      <Form.Label title={t('Language')} name="language">
        <Form.LanguageSelect name="language" required filterLanguages={(language) => !template.presets.some((preset) => preset.language.code === language)} />
      </Form.Label>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default CreatePresetForm;

CreatePresetForm.propTypes = {
  isUpdating: PropTypes.bool,
  onSubmit: PropTypes.func,
};
