import JSZip from 'jszip';
import QRCode from 'qrcode.react';

const QRCODE_SIZE = 2048;
const LOGO_PERCENT = 0.15;

export const getBlobFromUrl = async (url) => {
  const res = await fetch(url);
  return await res.blob();
};

export const canvasToBlob = async (canvas) => new Promise((resolve) => canvas.toBlob((blob) => resolve(blob)));

export const downloadBlobAsZip = (blob, filename) => {
  const blobUrl = URL.createObjectURL(blob);
  const downloadLink = document.createElement('a');
  downloadLink.href = blobUrl;
  downloadLink.download = filename;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const downloadCanvasAsPng = (canvas, filename) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = canvas.toDataURL('image/png');
  downloadLink.download = filename;
  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const getImageRatio = async (url) =>
  new Promise((resolve) => {
    const img = new Image();
    img.onload = function () {
      resolve(this.width / this.height);
    };
    img.src = url;
  });

export const getQrcodeComponent = (destinationUrl, { logoBlob, logoRatio } = {}, color) => {
  return (
    <QRCode
      value={destinationUrl}
      imageSettings={
        logoBlob && logoRatio
          ? {
              src: URL.createObjectURL(logoBlob),
              excavate: true,
              width: (QRCODE_SIZE * LOGO_PERCENT) / (logoRatio > 1 ? 1 : logoRatio),
              height: (QRCODE_SIZE * LOGO_PERCENT) / (logoRatio < 1 ? 1 : logoRatio),
            }
          : null
      }
      size={QRCODE_SIZE}
      level={'Q'}
      fgColor={color}
    />
  );
};

const fillQrcodeVariable = (filenameTemplate, { title, productTitle, user: { displayName: userDisplayName } = {} } = {}) => {
  return filenameTemplate
    .replaceAll('{{{TITLE}}}', title)
    .replaceAll('{{{PRODUCT_NAME}}}', productTitle)
    .replaceAll('{{{USER_DISPLAY_NAME}}}', userDisplayName);
};

export const downloadQrcodes = async (projectIds, templateIds, generateQrcode, setRenderedQrcodes, refQrcodesWrapper) => {
  const generateQrcodeRes = await generateQrcode({
    variables: {
      input: {
        templateIds: templateIds,
        projectIds: projectIds,
      },
    },
  });
  const qrcodes = generateQrcodeRes?.data?.generateQrcode?.qrcodes;
  const generatedBasenames = [];

  const getFilename = (filenameTemplate, project, index = 0) => {
    const fileBasename = fillQrcodeVariable(index === 0 ? filenameTemplate : `${filenameTemplate}_${index}`, project);

    if (generatedBasenames.includes(fileBasename)) {
      return getFilename(filenameTemplate, project, index + 1);
    }

    generatedBasenames.push(fileBasename);
    return `${fileBasename}.png`;
  };

  if (qrcodes.length >= 1) {
    const qrcodeData = await Promise.all(
      qrcodes.map(async (qrcode) => ({
        id: qrcode.id,
        catchLine: qrcode.template.catchLine || null,
        destinationUrl: qrcode.destinationUrl,
        fallbackUrl: qrcode.template.fallbackUrl,
        color: qrcode.template.color,
        filename: getFilename(qrcode.template.filename, qrcode.project),
        project: qrcode.project,
        logo: qrcode.template.logo
          ? {
              blob: await getBlobFromUrl(qrcode.template.logo),
              ratio: await getImageRatio(qrcode.template.logo),
            }
          : null,
      }))
    );

    setRenderedQrcodes(
      <>
        {qrcodeData.map((qrcode) =>
          getQrcodeComponent(qrcode.destinationUrl || '', qrcode.logo ? { logoBlob: qrcode.logo.blob, logoRatio: qrcode.logo.ratio } : undefined, qrcode.color)
        )}
      </>
    );

    return new Promise((resolve) => {
      setTimeout(async () => {
        const canvases = [...refQrcodesWrapper.current.querySelectorAll('canvas')];
        const canvasesWithCatchLine = [];

        const fontSize = Math.floor(QRCODE_SIZE * 0.068);
        const marginCatchline = Math.floor(QRCODE_SIZE * 0.02);
        const heightCatchline = Math.floor(QRCODE_SIZE * 0.08);

        for (let i = 0; i < canvases.length; i++) {
          if (qrcodeData[i].catchLine) {
            const canvasTmp = document.createElement('canvas');
            const ctx = canvasTmp.getContext('2d');
            ctx.font = `italic ${fontSize}px Roboto`;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';

            const catchLine = fillQrcodeVariable(qrcodeData[i].catchLine, qrcodeData[i].project);
            const words = catchLine.split(' ');
            const catchLines = [];
            let currentLineIndex = 0;
            words.forEach((word) => {
              const currentLine = catchLines[currentLineIndex] ? `${catchLines[currentLineIndex]} ${word}` : word;
              if (ctx.measureText(currentLine).width >= canvases[0].width * 0.9) {
                currentLineIndex++;
              }
              if (catchLines[currentLineIndex]) {
                catchLines[currentLineIndex] = currentLine;
              } else {
                catchLines.push(word);
              }
            });

            canvasTmp.width = canvases[0].width;
            canvasTmp.height = canvases[0].height + marginCatchline * 2 + catchLines.length * heightCatchline;

            ctx.drawImage(canvases[i], 0, 0);

            ctx.fillStyle = 'white';
            ctx.fillRect(0, canvases[0].height, canvases[0].width, canvases[0].height + marginCatchline * 2 + catchLines.length * heightCatchline);
            // We need to reassign font stuff because updating the canvas height reset those attributes
            ctx.font = `italic ${fontSize}px Roboto`;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = '#7E7E7E';
            catchLines.forEach((cl, i) => {
              ctx.fillText(cl, canvases[0].width / 2, canvases[0].height + (marginCatchline + heightCatchline / 2) + heightCatchline * i);
            });
            canvasesWithCatchLine.push(canvasTmp);
          } else {
            canvasesWithCatchLine.push(canvases[i]);
          }
        }

        if (canvasesWithCatchLine.length === 1) {
          downloadCanvasAsPng(canvasesWithCatchLine[0], qrcodeData[0].filename);
        } else if (canvasesWithCatchLine.length > 1) {
          const qrcodeBlobs = await Promise.all(canvasesWithCatchLine.map(canvasToBlob));
          const zip = new JSZip();
          for (let i = 0; i < qrcodeBlobs.length; i++) {
            zip.file(qrcodeData[i].filename, qrcodeBlobs[i], { base64: true });
          }
          zip.generateAsync({ type: 'blob' }).then((blob) => downloadBlobAsZip(blob, 'qrcodes.zip'));
        }
        resolve();
      }, 500);
    });
  }
};
