import { useLazyQuery } from '@apollo/client';
import { SkpLoader } from '@skeepers/skeepers-ui-react';
import PropTypes from 'prop-types';
import { useState } from 'react';

import { FACEBOOK_APP_ID, SOCIAL_MEDIA_CONNECT_URL, LINKEDIN_CLIENT_ID } from '@app/config';
import GET_YOUTUBE_PLAYLIST from '@graphql/projects/youtube/getYoutubePlaylist.graphql';
import {
  useGetYoutubeAuthUrl,
  useYoutubeAuth,
  useFacebookAuth,
  useGetTwitterAuthUrl,
  useTwitterAuth,
  useGetVimeoAuthUrl,
  useVimeoAuth,
  useLinkedinAuth,
} from '@hooks/projects/upload';

import Popup from './Popup';

const Youtube = ({ onClose = () => {}, onLogin = () => {}, onLoginError = () => {} }) => {
  const { url, loading } = useGetYoutubeAuthUrl(window.location.origin);
  const [getYoutubePlaylist] = useLazyQuery(GET_YOUTUBE_PLAYLIST);
  const [isLoadingPlaylist, setIsLoadingPlaylist] = useState(false);

  const [youtubeAuth] = useYoutubeAuth({
    onCompleted: async (youtubeAuth) => {
      if (youtubeAuth && youtubeAuth.credentials) {
        setIsLoadingPlaylist(true);
        const { data } = await getYoutubePlaylist({
          fetchPolicy: 'no-cache',
          variables: {
            credentials: youtubeAuth.credentials,
          },
        });
        onLogin(youtubeAuth.credentials, youtubeAuth.userName, data.getYoutubePlaylist);
      }
    },
  });

  const onPopupLogin = (query) => {
    const code = query.get('code');
    if (code) {
      youtubeAuth(code, window.location.origin);
    } else {
      onLoginError();
      onClose();
    }
  };

  return loading || isLoadingPlaylist ? <SkpLoader type="secondary"></SkpLoader> : <Popup url={url} onClose={onClose} onLogin={onPopupLogin} />;
};

const Facebook = ({ onClose = () => {}, onLogin = () => {} }) => {
  const [facebookAuth] = useFacebookAuth({
    onCompleted: (data) => {
      onLogin(data.pages, data.userName);
    },
  });

  const getUrl = () => {
    const facebookParams = new URLSearchParams();
    facebookParams.append('client_id', FACEBOOK_APP_ID);
    facebookParams.append('redirect_uri', `${window.location.origin}/`);
    facebookParams.append('scope', 'pages_read_engagement,pages_show_list,pages_manage_posts');
    facebookParams.append('auth_type', 'reauthenticate');
    return `https://www.facebook.com/v10.0/dialog/oauth?${facebookParams}`;
  };

  const onPopupLogin = (query) => {
    const code = query.get('code');
    facebookAuth(code, window.location.origin);
  };

  return <Popup url={getUrl()} onClose={onClose} onLogin={onPopupLogin} />;
};

const Twitter = ({ onClose = () => {}, onLogin = () => {} }) => {
  const { url } = useGetTwitterAuthUrl(window.location.origin);
  const [twitterAuth] = useTwitterAuth({
    onCompleted: (data) => {
      onLogin(data.accessToken, data.userName);
    },
  });

  const onPopupLogin = (query) => {
    const oauthToken = query.get('oauth_token');
    const oauthVerifier = query.get('oauth_verifier');
    twitterAuth(oauthToken, oauthVerifier);
  };

  return <Popup url={url} onClose={onClose} onLogin={onPopupLogin} />;
};

const Vimeo = ({ onClose = () => {}, onLogin = () => {} }) => {
  const { url } = useGetVimeoAuthUrl(window.location.origin);
  const [vimeoAuth] = useVimeoAuth({
    onCompleted: (data) => {
      onLogin(data.accessToken, data.userName);
    },
  });

  const onPopupLogin = (query) => {
    const code = query.get('code');
    vimeoAuth(code, window.location.origin);
  };

  return <Popup url={url} onClose={onClose} onLogin={onPopupLogin} />;
};

const Instagram = ({ onClose = () => {}, onLogin = () => {} }) => {
  const onPopupLogin = (query) => {
    const username = query.get('username').replace(/ /g, '+');
    const password = query.get('password').replace(/ /g, '+');
    onLogin({ username, password }, username);
  };

  return <Popup url={`${SOCIAL_MEDIA_CONNECT_URL}instagram?redirect_uri=${window.location.origin}`} onClose={onClose} onLogin={onPopupLogin} />;
};

const Tiktok = ({ onClose = () => {}, onLogin = () => {} }) => {
  const onPopupLogin = (query) => {
    const creds = JSON.parse(Buffer.from(query.get('credentials').replace(/ /g, '+'), 'base64').toString());
    onLogin(creds, creds.username);
  };

  return <Popup url={`${SOCIAL_MEDIA_CONNECT_URL}tiktok?redirect_uri=${window.location.origin}`} onClose={onClose} onLogin={onPopupLogin} />;
};

const Linkedin = ({ onClose = () => {}, onLogin = () => {} }) => {
  const [linkedinAuth] = useLinkedinAuth({
    onCompleted: (data) => {
      onLogin({ accessToken: data.accessToken, companies: data.companies }, data.userName);
    },
  });

  const getUrl = () => {
    const linkedinParams = new URLSearchParams();
    linkedinParams.append('response_type', 'code');
    linkedinParams.append('client_id', LINKEDIN_CLIENT_ID);
    linkedinParams.append('redirect_uri', window.location.origin);
    linkedinParams.append('scope', 'r_liteprofile w_organization_social rw_organization_admin');
    return `https://www.linkedin.com/oauth/v2/authorization?${linkedinParams}`;
  };

  const onPopupLogin = (query) => {
    const code = query.get('code');
    linkedinAuth(code, window.location.origin);
  };

  return <Popup url={getUrl()} onClose={onClose} onLogin={onPopupLogin} />;
};

const SocialMediaAuth = {
  Youtube,
  Facebook,
  Twitter,
  Vimeo,
  Instagram,
  Tiktok,
  Linkedin,
};

Object.keys(SocialMediaAuth).forEach((key) => {
  SocialMediaAuth[key].propTypes = {
    onClose: PropTypes.func,
    onLogin: PropTypes.func,
  };
});

export default SocialMediaAuth;
