import { useTranslation } from 'react-i18next';

import CreateTemplateKeyForm from '@components/Form/CreateTemplateKeyForm';
import Window from '@components/Window/Window';

const CreateTemplateKeyWindow = ({ onSubmit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Add a key')} open={open} onClose={onClose}>
      <CreateTemplateKeyForm onSubmit={onSubmit} isUpdating={isLoading} />
    </Window>
  );
};

export default CreateTemplateKeyWindow;
