import { useTranslation } from 'react-i18next';

import Flex from '@components/Common/Flex';

import Window from '../Window';

const InProgressVideoInformationWindow = ({ onClose = () => {}, open = false }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Your video is “in progress”')} open={open} onClose={onClose}>
      <Flex justifyContent="CENTER">{t('No preview available for the moment, please wait the end of generation.')}</Flex>
    </Window>
  );
};
export default InProgressVideoInformationWindow;
