import { useTranslation } from 'react-i18next';

import CreateBrowserWorkerForm from '@components/Form/CreateBrowserWorkerForm';
import Window from '@components/Window/Window';

const CreateBrowserWorkerWindow = ({ subtitles = [], onSubmit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Create a worker')} open={open} onClose={onClose}>
      <CreateBrowserWorkerForm subtitles={subtitles} onSubmit={onSubmit} isLoading={isLoading} />
    </Window>
  );
};

export default CreateBrowserWorkerWindow;
