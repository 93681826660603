import { useEffect } from 'react';

const loadFacebookSdk = () =>
  new Promise((resolve) => {
    if (!document.getElementById('facebook-jssdk')) {
      const script = document.createElement('script');
      script.src = 'https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v3.2';
      script.id = 'facebook-jssdk';
      document.body.appendChild(script);
      script.onload = resolve;
    } else {
      resolve();
    }
  });

const FacebookPlayer = ({ embed }) => {
  useEffect(() => {
    loadFacebookSdk();
    if (window.FB) {
      window.FB.XFBML.parse();
    }
  });

  return <div dangerouslySetInnerHTML={{ __html: embed }} />;
};

export default FacebookPlayer;
