import isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import { createRef, Component } from 'react';

import Spinner from '@components/Common/Spinner';
import { GRID } from '@components/Studio/Common/config';

import styles from './style.module.css';

const PLAYER_FPS = 1 / GRID;
const PLAYER_RESOLUTION_WIDTH = 640;
const PLAYER_RESOLUTION_HEIGHT = 360;

const floor = (d) => Math.round(d * PLAYER_FPS) / PLAYER_FPS;

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

class Clock {
  constructor(onProgress, interval) {
    let timeout = null;
    let startedAt = null;
    this.interval = interval;

    this.start = () => {
      startedAt = Date.now();
      timeout = setTimeout(step, this.interval);
    };

    this.stop = () => clearTimeout(timeout);

    const step = () => {
      const delay = Date.now() - startedAt;
      onProgress(delay);
      timeout = setTimeout(step, this.interval);
    };
  }
}

class TimelinePlayer extends Component {
  constructor(props) {
    super(props);
    this.canvasRef = createRef();
    this.refContainer = createRef();
    this.state = {
      isLoading: false,
    };
    this.progress = 0;
    this.progressOffSet = 0;
    this.previousProgress = Date.now();
    this.clockFrame = false;
    this.isReadeableInterval = false;
    this.refreshId = false;
    this.renderFrame = false;
    this.timer = false;
    this.state = { width: 0, height: 0 };
    this.streams = this.props.streams;
    this.blobUrls = {};
    this.timeline = this.props.timeline.map((element) => ({
      dom: null,
      id: element.id,
      startSetTimeout: null,
      isPreloaded: false,
      stopSetTimeout: null,
      unloadSetTimeout: null,
      element: {
        inputId: element.inputId,
        type: element.input?.type,
        filename: element.input?.filename,
        line: element.line,
        playPromise: null,
        isLoading: true,
        position: element.position * 1000,
        duration: element.duration * 1000,
        start: element.start * 1000,
        speed: element.speed,
        volume: element.volume,
      },
    }));
    this._pureResize = () => {
      if (this.state.width !== this.refContainer.current.clientWidth || this.state.height !== this.refContainer.current.clientHeight) {
        this.setState({
          width: this.refContainer.current.clientWidth,
          height: this.refContainer.current.clientHeight,
        });
      }
    };
    const [ratioWidth, ratioHeight] = props.ratio.split(':').map((e) => parseInt(e, 10));
    this.ratioWidth = ratioWidth;
    this.ratioHeight = ratioHeight;
  }

  updateTimeline(newTimeline) {
    const oldTimeline = this.timeline;
    this.timeline = newTimeline.map((newElement) => {
      const element = oldTimeline.find((e) => e.id === newElement.id);
      if (!element && !this.blobUrls[newElement.inputId]) {
        // Download the video and store the blob url
        this._downloadVideo(newElement.inputId).then(() => (this.blobUrls[newElement.inputId].isLoading = false));
      }
      return {
        dom: element?.dom || null,
        id: newElement.id,
        startSetTimeout: null,
        isPreloaded: false,
        stopSetTimeout: null,
        unloadSetTimeout: null,
        element: {
          playPromise: null,
          inputId: newElement.inputId,
          type: newElement.input?.type,
          filename: newElement.input?.filename,
          line: newElement.line,
          position: newElement.position * 1000,
          duration: newElement.duration * 1000,
          start: newElement.start * 1000,
          speed: newElement.speed,
          volume: newElement.volume,
        },
      };
    });
  }

  _unloadSetTimeout = (chunk, t) => {
    const time = t < 0 ? 0 : t;
    return setTimeout(() => {
      if (chunk.dom) {
        try {
          const prom = chunk.dom.remove();
          if (prom) prom.catch((err) => console.log('Google Chrome Sucks', err));
        } catch (e) {
          console.log('Google Chrome Sucks', e);
        }
      }
      chunk.dom = false;
    }, time);
  };

  _stopSetTimeout = (chunk, t) => {
    const time = t < 0 ? 0 : t;
    return setTimeout(() => {
      try {
        const prom = chunk.element.playPromise ? chunk.element.playPromise.then((_) => chunk.dom.pause()) : chunk.dom.pause();
        if (prom) prom.catch((e) => console.log('Google Chrome Sucks', e));
      } catch (e) {
        console.log('Google Chrome Sucks', e);
      }
    }, time);
  };

  _startSetTimeout = (chunk, t) => {
    const time = t < 0 ? 0 : t;
    return setTimeout(() => {
      try {
        chunk.element.playPromise = chunk.dom.play();
        if (chunk.element.playPromise) chunk.element.playPromise.catch((err) => console.log('Google Chrome Sucks', err));
      } catch (e) {
        console.log('Google chrome sucks', e);
      }
    }, time);
  };

  _downloadVideo = (inputId) => {
    const p = (async () => {
      while (true) {
        const url = await fetch(this.streams[inputId])
          .then((r) => r.blob())
          .then((blob) => blob.arrayBuffer())
          .then((buff) => URL.createObjectURL(new Blob([buff])))
          .catch(() => false);
        if (url) {
          return url;
        }
        await sleep(1000);
      }
    })();

    this.blobUrls = {
      ...this.blobUrls,
      [inputId]: {
        isLoading: true,
        blobUrl: p,
      },
    };
    return this.blobUrls[inputId].blobUrl;
  };

  _preload = (chunk) => {
    if (!chunk.dom) {
      chunk.dom = document.createElement('video');
      if (!this.blobUrls[chunk.element.inputId]) {
        // Trying to figure out what's wrong and prevent crash
        console.error('this.blobUrls[chunk.element.inputId] is undefined', { timeline: this.timeline, blobUrls: this.blobUrls });
      } else {
        this.blobUrls[chunk.element.inputId].blobUrl.then((blobUrl) => (chunk.dom.src = blobUrl));
      }
    } else if (!chunk.dom.src) {
      // Retrying to initialize blobUrl as video src
      this.blobUrls[chunk.element.inputId].blobUrl.then((blobUrl) => (chunk.dom.src = blobUrl));
    }
    chunk.dom.volume = chunk.element.volume === 0 ? 0 : parseFloat(chunk.element.volume) || 1;
    chunk.dom.defaultPlaybackRate = 1;
    chunk.dom.playbackRate = parseFloat(chunk.element.speed) || 1;
    // Seek to
    chunk.dom.currentTime = chunk.element.start / 1000 + (this.progress > chunk.element.position / 1000 ? this.progress - chunk.element.position / 1000 : 0);
  };

  _playController = () => {
    const progress = this.progress * 1000;
    this._startClock();
    this.timeline.forEach((chunk) => {
      // START
      if (!chunk.startSetTimeout && progress < chunk.element.position + chunk.element.duration)
        chunk.startSetTimeout = this._startSetTimeout(chunk, chunk.element.position - progress);

      // STOP
      if (!chunk.stopSetTimeout && progress < chunk.element.position + chunk.element.duration)
        chunk.stopSetTimeout = this._stopSetTimeout(chunk, chunk.element.position + chunk.element.duration - progress);

      // UNLOAD
      if (!chunk.unloadSetTimeout) chunk.unloadSetTimeout = this._unloadSetTimeout(chunk, chunk.element.position + chunk.element.duration + 5000 - progress);
    });
  };

  _isLoading = () => {
    const isLoading = this.timeline.some((chunk) => this.blobUrls[chunk.element.inputId].isLoading);
    this.setState({ isLoading });
    return isLoading;
  };

  _startClock = () => {
    const timelineDuration = this._calcDuration(this.timeline);
    this.clockFrame = new Clock((delay) => {
      if (timelineDuration <= this.progress) {
        this.pause();
      } else {
        this.progress = floor(this.progressOffSet + delay / (1000 / PLAYER_FPS) / PLAYER_FPS);
        this.props.onProgress(this.progress);
      }
    }, 0);
    this.clockFrame.start();
  };

  play() {
    // Out of timeline
    if (this._calcDuration(this.timeline) <= this.progress) return;

    this.timeline.forEach((chunk) => {
      // PRELOAD
      this._preload(chunk);
      chunk.isPreloaded = true;
    });

    this.isReadeableInterval = setInterval(() => {
      const progress = this.progress * 1000;
      const activeElements = this.timeline.filter((chunk) => (chunk.element.position <= progress) && (chunk.element.position + chunk.element.duration) >= progress);
      const isLoading = this.timeline.some((chunk) => this.blobUrls[chunk.element.inputId].isLoading) || activeElements.some((chunk) => chunk.dom.readyState < 2);
      this.setState({ isLoading });

      if (!isLoading) {
        clearInterval(this.isReadeableInterval);
        this._playController();
      }
    }, 100);
  }

  pause() {
    this.progressOffSet = this.progress;
    this._stop();
    this.timeline.forEach((chunk) => {
      if (chunk.dom) this._stopSetTimeout(chunk, 0);
      if (chunk.stopSetTimeout) clearTimeout(chunk.stopSetTimeout);
    });
    this._clearStreams();
  }

  componentDidMount() {
    window.addEventListener('resize', this._pureResize);
    this._pureResize();
    this.timer = setInterval(this._pureResize, 250);

    this.renderFrame = () => {
      // No change on progress, skip rendering
      if (Date.now() - this.previousProgress <= PLAYER_FPS) {
        this.refreshId = requestAnimationFrame(this.renderFrame);
        return;
      }

      // Update last progress
      this.previousProgress = Date.now();

      const progress = this.progress * 1000;

      const elems = this.timeline
        .filter(
          ({ element }) =>
            (element.type === 'VIDEO' || element.type === 'IMAGE') &&
            floor(element.position) <= floor(progress) &&
            floor(element.position + element.duration) > floor(progress)
        )
        .sort((a, b) => a.element.line - b.element.line);

      const backgroundContent = elems.find(({ element }) => element.type === 'VIDEO' || element.type === 'IMAGE');

      // Black screen if we don't have a background content
      if (!backgroundContent) {
        this._drawFrame(false);
      }

      // Render IMAGE or VIDEO as background image
      if (backgroundContent) {
        this.timeline.forEach((chunk) => {
          if (!this.blobUrls[chunk.element.inputId]) {
            this._downloadVideo(chunk.element.inputId).then(() => (this.blobUrls[chunk.element.inputId].isLoading = false));
          }
          if (!chunk.isPreloaded) {
            chunk.isPreloaded = true;
            this._preload(chunk);
          }
          if (chunk.id === backgroundContent.id && chunk.dom) this._drawFrame(chunk.dom);
        });
      }

      // Request Animation
      this.refreshId = requestAnimationFrame(this.renderFrame);
    };
    // Load videos
    this.refreshId = requestAnimationFrame(this.renderFrame);
  }

  componentWillUnmount() {
    this._stop();
    if (this.refreshId) window.cancelAnimationFrame(this.refreshId);
    window.removeEventListener('resize', this._pureResize);
    clearInterval(this.timer);
  }

  _calcDuration = (timeline) => timeline.reduce((acc, { element: e }) => (acc > e.position + e.duration ? acc : e.position + e.duration), 0) / 1000;

  _drawFrame(dom, pX = 0, pY = 0) {
    if (!this.canvasRef.current) return;
    const ctx = this.canvasRef.current.getContext('2d', { alpha: false });
    if (dom === false) {
      ctx.fillStyle = '#000';
      ctx.fillRect(0, 0, PLAYER_RESOLUTION_WIDTH, PLAYER_RESOLUTION_HEIGHT);
    } else {
      ctx.drawImage(dom, (PLAYER_RESOLUTION_WIDTH * pX) / 100, (PLAYER_RESOLUTION_HEIGHT * pY) / 100, PLAYER_RESOLUTION_WIDTH, PLAYER_RESOLUTION_HEIGHT);
    }
  }

  _stop() {
    this.props.onStop();
    this.setState({ isLoading: false });
    if (this.isReadeableInterval !== false) clearInterval(this.isReadeableInterval);
    if (this.clockFrame) this.clockFrame.stop();
  }

  _clearStreams() {
    this.timeline.forEach((chunk) => {
      if (chunk.startSetTimeout) clearTimeout(chunk.startSetTimeout);
      if (chunk.stopSetTimeout) clearTimeout(chunk.stopSetTimeout);
      if (chunk.unloadSetTimeout) clearTimeout(chunk.unloadSetTimeout);
    });
    this.timeline = this.timeline.map((chunk) => ({ ...chunk, startSetTimeout: null, stopSetTimeout: null, unloadSetTimeout: null, isPreloaded: false }));
  }

  seekTo(time) {
    const newTime = floor(time) < 0 ? 0 : floor(time);
    this.progress = newTime;
    this.progressOffSet = newTime;
    this._clearStreams();
  }

  shouldComponentUpdate(prevProps, prevState) {
    return (
      prevProps.playing !== this.props.playing ||
      !isEqual(prevProps.timeline, this.timeline) ||
      prevState.width !== this.state.width ||
      prevState.height !== this.state.height
    );
  }

  render() {
    const reso1 = [Math.round(this.state.width), Math.round((this.state.width * this.ratioHeight) / this.ratioWidth)];
    const reso2 = [Math.round((this.state.height * this.ratioWidth) / this.ratioHeight), Math.round(this.state.height)];
    const resolution = reso1[0] <= this.state.width && reso1[1] <= this.state.height ? reso1 : reso2;
    const leftMargin = this.ratioWidth > this.ratioHeight ? `${Math.round((this.state.width - resolution[0]) / 2)}px` : '0';
    return (
      <div className={this.ratioWidth > this.ratioHeight ? styles.container : styles.containerVertical} ref={this.refContainer}>
        <div>
          <canvas
            className={styles.player}
            width={PLAYER_RESOLUTION_WIDTH}
            height={PLAYER_RESOLUTION_HEIGHT}
            ref={this.canvasRef}
            style={{
              width: `${resolution[0]}px`,
              height: `${resolution[1]}px`,
              top: `${Math.round((this.state.height - resolution[1]) / 2)}px`,
              left: leftMargin,
            }}
          />
        </div>
        {this.state.isLoading === true && (
          <div className={styles.spinnerContainer}>
            <Spinner />
          </div>
        )}
      </div>
    );
  }
}

TimelinePlayer.propTypes = {
  timeline: PropTypes.array,
  playing: PropTypes.bool,
  onProgress: PropTypes.func,
  onStop: PropTypes.func,
  ratio: PropTypes.string,
};

TimelinePlayer.defaultProps = {
  timeline: [],
  playing: false,
  onProgress: (t) => {},
  onStop: (t) => {},
};

export default TimelinePlayer;
