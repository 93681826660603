import { formatTime } from '../Common/config';

import style from './style.module.css';

const TimeCursor = ({
  offsetX = 0,
  scaleX = 0,

  scrollLeft = 0,
  scrollWidth = 0,
  clientWidth = 0,

  isPlaying = false,
  time = 0,
  onClick = () => {},
}) => {
  let marginLeft = 0;

  if (isPlaying && scrollLeft !== scrollWidth - clientWidth && Math.round(time * scaleX - offsetX) > clientWidth / 2) {
    marginLeft = clientWidth / 2;
  } else {
    marginLeft = Math.round(time * scaleX - offsetX);
  }

  return (
    <div className={style.clock} style={{ marginLeft: `${marginLeft}px` }}>
      <div className={style.time} onClick={onClick}>
        {formatTime(time, true)}
      </div>
    </div>
  );
};

export default TimeCursor;
