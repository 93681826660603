import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getLanguages } from '@app/translations';
import ProjectCardsWorkspace from '@components/ProjectCards/ProjectCardsWorkspace';
import Window from '@components/Window/Window';

const CardsWindow = ({ title, cards, language, url, outputs, outputId, onSubmit = () => {}, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();
  const languages = useMemo(() => getLanguages(t), [t]);
  const titleToUse = title ?? t('Cards window');

  return (
    <Window title={titleToUse} open={open} onClose={onClose} maxWidth={'calc(100% - 8rem)'}>
      <ProjectCardsWorkspace
        url={url}
        outputs={[] || outputs /* TODO : re-enable outputs when we know how to design the page to avoid double modal */}
        outputId={outputId}
        cards={cards}
        language={languages[language?.toUpperCase()]}
        languageCode={language}
        onSaveCards={onSubmit}
        updating={false}
      />
    </Window>
  );
};

export default CardsWindow;
