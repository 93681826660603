import Flex from '@components/Common/Flex';

import style from './style.module.css';

const TabList = ({ children }) => {
  return (
    <Flex justifyContent="CENTER" margin="MEDIUM" className={style.root}>
      {children}
    </Flex>
  );
};

export default TabList;
