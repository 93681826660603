import moment from 'moment';
import PropTypes from 'prop-types';
import { useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { getOutputWithGroupedCards, timecodeToInputStr } from '@app/utils';
import Player from '@components/Project/Player';
import ProjectCardsTable from '@components/ProjectCards/ProjectCardsTable';
import { useWindows, WINDOW_TYPES } from '@contexts/WindowContext';

import style from './style.module.css';

const minSecBetweenCards = 5;
const defaultNewCard = { displayedInTimeline: true, title: null, description: null, position: 0, errors: null };

const populateErrors = (localCards, card, duration, t) => {
  const errorPositionOverlap =
    card.position !== null && localCards.some((card2) => card2.id !== card.id && Math.abs(card2.position - card.position) < minSecBetweenCards);
  const errorPositionAfterEnd = duration > 0 && card.position && card.position > moment.duration(timecodeToInputStr(duration)).asSeconds();
  const positionErrorsArray = [
    ...(errorPositionOverlap ? [t('Too close from another card')] : []),
    ...(errorPositionAfterEnd ? [t("Position is after video's end")] : []),
  ];

  const titleErrors = card.title !== null && !card.title?.length && [t("Field can't be empty")];
  const descriptionErrors = card.description !== null && !card.description?.length && [t("Field can't be empty")];
  const positionErrors = positionErrorsArray.length && positionErrorsArray;

  return {
    ...card,
    errors:
      titleErrors || descriptionErrors || positionErrors
        ? {
            ...(titleErrors ? { title: titleErrors } : {}),
            ...(descriptionErrors ? { description: descriptionErrors } : {}),
            ...(positionErrors ? { position: positionErrors } : {}),
          }
        : null,
  };
};

const sortCards = (cards) => cards.sort((c1, c2) => c1.position - c2.position);

const ProjectCardsWorkspace = ({ url, outputs, outputId, cards, language, languageCode, onSaveCards, updating }) => {
  const { t } = useTranslation();
  const { actions } = useWindows();
  const [canSubmit, setCanSubmit] = useState(false);
  const [duration, setDuration] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [displayedCard, setDisplayedCard] = useState();
  const [localCards, setLocalCards] = useState(
    cards.map((card) => {
      return {
        id: card.id,
        title: card.title,
        description: card.description,
        position: card.position,
        language: card.language,
        displayedInTimeline: card.displayedInTimeline,
      };
    })
  );
  const playerRef = useRef();

  const localCardsFilled = localCards.map((card) => populateErrors(localCards, card, duration, t));

  const containsError = localCardsFilled.length && localCardsFilled.some((card) => card.errors);

  // https://developer.mozilla.org/fr/docs/Web/API/HTMLMediaElement/timeupdate_event
  const onPlayerTimeUpdate = useCallback(
    (currentTime) => {
      const time = currentTime / 1000;
      setDisplayedCard(localCards.find((card) => card.position > time - 5 && card.position <= time));
      setCurrentTime(Math.round(time * 1000));
    },
    [localCards]
  );

  const onFocus = (card) => {
    playerRef.current.seek(card.position * 1000);
  };

  const onRemoveLocalCard = (id) => {
    setLocalCards((previousLocalCards) => previousLocalCards.filter((card) => card.id !== id));
    setCanSubmit(true);
  };

  const onEditLocalCards = (id, payload) => {
    if (payload.position) {
      playerRef.current.currentTime = payload.position;
    }
    setLocalCards((previousLocalCards) => sortCards(previousLocalCards.map((c) => (c.id === id ? { ...c, ...payload } : c))));
    setCanSubmit(true);
  };

  const availableOutputsCards = outputs
    ? getOutputWithGroupedCards(outputs).filter(
        (availableOutputCards) => !(availableOutputCards.id === outputId && availableOutputCards.language === languageCode)
      )
    : [];

  const onImport = () => {
    actions.open(WINDOW_TYPES.IMPORT_CARDS, {
      availableOutputsCards,
      onImport: (cards) => {
        const cardsFrom = cards.map(({ __typename, ...card }) => ({
          ...card,
          language: languageCode,
          id: uuid(),
        }));
        setLocalCards((previousLocalCards) => sortCards([...previousLocalCards, ...cardsFrom]));
        setCanSubmit(true);
        actions.close();
      },
    });
  };

  const onInsertAfter = (card) => {
    const position = card ? card.position + minSecBetweenCards : 0;
    setLocalCards((previousLocalCards) => sortCards([...previousLocalCards, { ...defaultNewCard, language: languageCode, id: uuid(), position }]));
    setCanSubmit(true);
  };

  const saveCards = () => {
    if (localCardsFilled.some((card) => card.title === null || card.description === null)) {
      setLocalCards((previousLocalCards) =>
        previousLocalCards.map((card) =>
          card.title !== null && card.description !== null ? card : { ...card, title: card.title || '', description: card.description || '' }
        )
      );
      return;
    }
    setCanSubmit(false);
    return onSaveCards(localCards);
  };

  return (
    <div className={style.grid}>
      <Player ref={playerRef} url={url} onCanPlay={setDuration} onTimeUpdate={onPlayerTimeUpdate} card={displayedCard} formatTimecode={timecodeToInputStr} />

      <ProjectCardsTable
        localCards={localCardsFilled}
        minSecBetweenCards={minSecBetweenCards}
        language={language}
        duration={duration}
        currentTime={currentTime}
        onImport={onImport}
        onInsertAfter={onInsertAfter}
        onRemoveLocalCard={onRemoveLocalCard}
        onEditLocalCards={onEditLocalCards}
        onSaveCards={saveCards}
        onFocus={onFocus}
        updating={updating}
        canImport={availableOutputsCards.length > 0}
        canSubmit={canSubmit && !containsError}
      />
    </div>
  );
};

ProjectCardsWorkspace.propTypes = {
  url: PropTypes.string,
  outputs: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      filename: PropTypes.string,
      language: PropTypes.string,
      cards: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          title: PropTypes.string,
          description: PropTypes.string,
          language: PropTypes.string,
          position: PropTypes.number,
          displayedInTimeline: PropTypes.bool,
        })
      ),
    })
  ),
  outputId: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      language: PropTypes.string,
      position: PropTypes.number,
      displayedInTimeline: PropTypes.bool,
    })
  ),
  language: PropTypes.shape({
    name: PropTypes.string,
    country: PropTypes.string,
  }),
  languageCode: PropTypes.string,
  onSaveCards: PropTypes.func,
  updating: PropTypes.bool,
};

export default ProjectCardsWorkspace;
