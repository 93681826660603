import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useCallback, useEffect, useRef } from 'react';

import SkeepersIcon from '@components/Common/SkeepersIcon';

import style from './style.module.css';

const Window = ({ children, maxWidth, title = '', subtitle = '', padding = true, onClose = () => {}, open = false, className, closeOnClickOutside = true }) => {
  const ref = useRef();
  const mouseDownTarget = useRef();

  const esc = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        onClose(event);
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener('keydown', esc);
    return () => {
      document.removeEventListener('keydown', esc);
    };
  }, [esc]);

  return (
    <div
      className={classNames(style.bgd, open ? style.open : style.close)}
      onMouseUp={(event) => {
        if (!closeOnClickOutside) return;
        if (mouseDownTarget.current && !ref.current.contains(mouseDownTarget.current) && !ref.current.contains(event.target)) {
          onClose(event);
        }
      }}
      onMouseDown={(event) => {
        mouseDownTarget.current = event.target;
      }}
    >
      <div className={style.pwindow}>
        <div style={{ maxWidth }} className={classNames(style.window, 'custom-scrollbar', className, { [style.windowPadding]: padding })} ref={ref}>
          <div className={classNames({ [style.titleContainer]: title || subtitle })}>
            {title && <h1 className={style.title}>{title}</h1>}
            {subtitle && <h2 className={style.subtitle}>{subtitle}</h2>}
            <div className={style.closeIcon} onClick={onClose}>
              <SkeepersIcon type="CLOSE" />
            </div>
          </div>
          <div className={style.content}>{children}</div>
        </div>
      </div>
    </div>
  );
};

Window.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  maxWidth: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  padding: PropTypes.bool,
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default Window;
