import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { sortByProp } from '@app/utils';
import ButtonContainer from '@components/Common/ButtonContainer';
import { ClipboardCopy } from '@components/Common/ClipboardCopy';
import Hint from '@components/Common/Hint';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import { Form, useForm } from '@components/FormElement/Form';
import FormList from '@components/FormElement/FormList';
import PageLoading from '@components/Layout/PageLoading';

export const getTypes = (t) => ({
  COLOR: {
    name: t('Color'),
    icon: 'PALETTE',
  },
  INT: {
    name: t('Number'),
    icon: 'ADD',
  },
  KEY: {
    name: t('Key'),
    icon: 'KEY',
  },
  STRING: {
    name: t('String'),
    icon: 'QUOTE',
  },
  URL: {
    name: t('URL'),
    icon: 'LINK',
  },
  IMAGE: {
    name: t('Picture'),
    icon: 'IMAGE',
  },
  LIST: {
    name: t('List'),
    icon: 'LIST',
  },
  ADVANCED_LIST: {
    name: t('Advanced list'),
    icon: 'LIST',
  },
  FILE: {
    name: t('File'),
    icon: 'FILE',
  },
  BOOL: {
    name: t('Boolean'),
    icon: 'TOGGLE',
  },
  VIDEO: {
    name: t('Video'),
    icon: 'VIDEO',
  },
  LANGUAGE: {
    name: t('Language'),
    icon: 'LANGUAGE',
  },
  RANGE: {
    name: t('Range'),
    icon: 'BATTERY',
  },
});

const TemplateKeysForm = ({ keys = [], onDelete = () => {}, onSubmit = () => {}, isUpdating = false, onSearch = () => {}, isLoding = false }) => {
  const { t } = useTranslation();
  const methods = useForm();
  const types = getTypes(t);
  const keyfForForm = cloneDeep(keys);

  const options = Object.entries(types)
    .map(([value, { name, icon }]) => ({ label: name, value, icon: <SkeepersIcon type={icon} /> }))
    .sort(sortByProp(({ label }) => label));
  const hintResolver = (type) => (type === 'UNKNOWN' ? <Hint type="ERROR" message={t('Type is not set, this may cause issues')} /> : null);

  const _onSubmit = (values) => {
    const updatedValues = keys.reduce((acc, key) => {
      const type = values[key.node.id];
      if (type && type !== key.node.type) {
        return [...acc, { id: key.node.id, name: key.node.name, type }];
      }
      return acc;
    }, []);
    onSubmit(updatedValues);
  };

  return (
    <>
      <Form methods={methods} onSubmit={_onSubmit} keepUnregistered>
        <FormList>
          <FormList.Search onChange={(fullText) => onSearch(fullText)} />
          {isLoding ? (
            <PageLoading applyRelativePositionStyle={true} />
          ) : (
            <>
              {keyfForForm.map((cursor) => {
                const key = cursor.node;
                const icon = key.isTranslatable ? 'TRANSLATE' : key.isLocalizable ? 'LANGUAGE' : null;
                return (
                  <FormList.Element
                    key={key.id}
                    label={
                      <>
                        <ClipboardCopy text={key.id} />
                        {key.name}
                        {icon && <SkeepersIcon type={icon} color="var(--color-info)" />}
                      </>
                    }
                    onDelete={() => onDelete(key)}
                  >
                    <Form.Select name={key.id} defaultValue={key.type} options={options} key={key.id} hint={hintResolver} />
                  </FormList.Element>
                );
              })}
            </>
          )}
        </FormList>
        <ButtonContainer position="RIGHT">
          <Form.SubmitButton isUpdating={isUpdating} />
        </ButtonContainer>
      </Form>
    </>
  );
};

TemplateKeysForm.propTypes = {
  keys: PropTypes.array,
  onDelete: PropTypes.func,
  onSubmit: PropTypes.func,
  onSearch: PropTypes.func,
  isUpdating: PropTypes.bool,
  isLoding: PropTypes.bool,
};

export default TemplateKeysForm;
