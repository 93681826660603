import { useTranslation } from 'react-i18next';

import CreateTemplateForm from '@components/Form/CreateTemplateForm';
import Window from '@components/Window/Window';

const CreateTemplateWindow = ({ templates = [], onSubmit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Create a template')} open={open} onClose={onClose}>
      <CreateTemplateForm templates={templates} onSubmit={onSubmit} isUpdating={isLoading} />
    </Window>
  );
};

export default CreateTemplateWindow;
