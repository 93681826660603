import PropTypes from 'prop-types';

import AddressForm from '@components/Form/AddressForm';
import Window from '@components/Window/Window';

const AddressWindow = ({ onSubmit = () => {}, searchAddress = () => {}, address = {}, isLoading = false, title = '', open = false, onClose = () => {} }) => {
  return (
    <Window title={title} open={open} onClose={onClose}>
      <AddressForm address={address} onSubmit={onSubmit} isUpdating={isLoading} searchAddress={searchAddress} />
    </Window>
  );
};

export default AddressWindow;

AddressWindow.propTypes = {
  onSubmit: PropTypes.func,
  searchAddress: PropTypes.func,
  address: PropTypes.shape({
    city: PropTypes.string,
    country: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    optionalInfo: PropTypes.string,
    zipcode: PropTypes.string,
    state: PropTypes.string,
    street: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  title: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
