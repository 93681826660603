import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import Block from '@components/Common/Block';
import Divider from '@components/Common/Divider';
import Header from '@components/Common/Header';
import SkeepersIcon from '@components/Common/SkeepersIcon';

import style from './style.module.css';

export const CodeSnippet = ({ content = '', description = '', title = '', defaultOpen = false, secondaryAction, language = 'htmlbars' }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(defaultOpen);
  const [copied, setCopied] = useState(false);

  const onOpen = () => {
    setOpen(!open);
  };

  //To copy the code
  const onCopy = (e) => {
    e.stopPropagation();
    copy(content);
    setCopied(true);
  };

  return (
    <Block bgColor="#f5f6fa" boxShadow={false}>
      <div className={style.headerSnippet} onClick={onOpen}>
        <div>
          <Header className={style.titleSnippet} as="h2">
            {title}
          </Header>
          {description && (
            <Header className={style.descriptionSnippet} as="p">
              {description}
            </Header>
          )}
        </div>
        <div className={style.rightHeaderContainer}>
          {secondaryAction && secondaryAction.title && secondaryAction.action && (
            <div
              onClick={(e) => {
                e.stopPropagation();
                return secondaryAction.action();
              }}
            >
              {secondaryAction.title}
            </div>
          )}
          <div onClick={onCopy} className={style.copyButton}>
            {copied ? t('Copied') : t('Copy / Paste')}
          </div>
          <SkeepersIcon type={open ? 'CHEVRON_UP' : 'CHEVRON_DOWN'} className={style.chevron} />
        </div>
      </div>
      {open && (
        <>
          <Divider className={style.dividerSnippet} />
          <SyntaxHighlighter language={language} style={vs2015}>
            {content}
          </SyntaxHighlighter>
        </>
      )}
    </Block>
  );
};
export default CodeSnippet;

CodeSnippet.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  content: PropTypes.string,
  defaultOpen: PropTypes.bool,
  secondaryAction: PropTypes.shape({
    title: PropTypes.string,
    action: PropTypes.func,
  }),
  language: PropTypes.string,
};
