import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import SequenceForm from '@components/Form/SequenceForm';
import Window from '@components/Window/Window';

const SequencesWindow = ({
  sequence = {},
  availableVideoSequences = [],
  timelineTemplates = [],
  isNew,
  open = false,
  onClose = () => {},
  onSubmit,
  isLoading,
}) => {
  const { t } = useTranslation();

  return (
    <Window open={open} onClose={onClose} title={t('Sequences')}>
      <SequenceForm
        sequence={sequence}
        availableVideoSequences={availableVideoSequences}
        timelineTemplates={timelineTemplates}
        isNew={isNew}
        onSubmit={onSubmit}
        isLoading={isLoading}
      />
    </Window>
  );
};

export default SequencesWindow;

SequencesWindow.propTypes = {
  onEdit: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  backgroundRelativeHeight: PropTypes.number,
};
