import { useEffect } from 'react';

const loadInstagramSdk = () =>
  new Promise((resolve) => {
    if (!document.getElementById('instagram-jssdk')) {
      const script = document.createElement('script');
      script.src = 'https://www.instagram.com/static/bundles/metro/EmbedSDK.js/33cd2c5d5d59.js';
      script.id = 'instagram-jssdk';
      document.body.appendChild(script);
      script.onload = resolve;
    } else {
      resolve();
    }
  });

const InstagramPlayer = ({ embed }) => {
  useEffect(() => {
    loadInstagramSdk();
    if (window.instgrm) {
      window.instgrm.Embeds.process();
    }
  });

  return <div dangerouslySetInnerHTML={{ __html: embed }} />;
};

export default InstagramPlayer;
