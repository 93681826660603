export const showUnsuportedBrowserWarning = () => {
  const isOpera = !!window.opr || !!window.opera || navigator.userAgent.indexOf(' OPR/') >= 0;
  const isChrome = !!window.chrome;
  return !((isChrome || isOpera) && !!window.CSS);
};

export const showChromeUpdateWarning = () => {
  const raw = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
  const version = raw ? parseInt(raw[2], 10) : false;
  return version !== false && parseInt(version, 10) < 80;
};

export const showZoomWarning = () => {
  // We can't use window.devicePixelRatio !== 1 it false positive on Retina screens
  return window.screen.width / window.innerWidth !== 1;
};

export const showBrowserWarning = () => showUnsuportedBrowserWarning() || showChromeUpdateWarning() || showZoomWarning();
