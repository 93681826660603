
import { useTranslation } from 'react-i18next';

import { DateTime } from '@components/Common/DateTime';
import IconList from '@components/Common/IconList';
import Tag from '@components/Common/Tag';

const getTags = (t) => ({
  ACTIVE: <Tag value={t('Active')} color="var(--tag-green)" />,
  SCARCE: <Tag value={t('Scarce')} color="var(--tag-blue)" />,
  STALE: <Tag value={t('Stale')} color="var(--tag-yellow)" />,
  INACTIVE: <Tag value={t('Inactive')} color="var(--tag-red)" />,
});

const TemplateKeyInsights = ({ usage, definitions }) => {
  const { t } = useTranslation();
  const tags = getTags(t);

  return (
    <IconList>
      {usage.timesUsed > 0 ? (
        <>
          <IconList.Element icon="CLOCK">
            <div>
              {t('Last used')} <DateTime timestamp={usage.lastUsed} relative />
              {tags[usage.status]}
            </div>
          </IconList.Element>
          <IconList.Element icon="DATA">{t('Used {{count}} times total', { count: usage.timesUsed })}</IconList.Element>
        </>
      ) : (
        <IconList.Element icon="CHECK" color="var(--color-success)">
          {t('Never rendered by front-ends')}
        </IconList.Element>
      )}
      {definitions.presets.length > 0 ? (
        <IconList.Element icon="LANGUAGE">{t('Defined by {{count}} preset', { count: definitions.presets.length })}</IconList.Element>
      ) : (
        <IconList.Element icon="CHECK" color="var(--color-success)">
          {t('Not defined by any presets')}
        </IconList.Element>
      )}
      {definitions.configurations.length > 0 ? (
        <IconList.Element icon="FILE">{t('Defined by {{count}} configuration', { count: definitions.configurations.length })}</IconList.Element>
      ) : (
        <IconList.Element icon="CHECK" color="var(--color-success)">
          {t('Not defined by any configurations')}
        </IconList.Element>
      )}
    </IconList>
  );
};

export default TemplateKeyInsights;
