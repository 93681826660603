import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { getYoutubeIdFromUrl } from '@app/utils/externalVideo';
import CREATE_EXTERNAL_VIDEO from '@graphql/externalVideos/createExternalVideo.gql';
import DELETE_EXTERNAL_VIDEO from '@graphql/externalVideos/deleteExternalVideo.gql';
import GET_EXTERNAL_VIDEO from '@graphql/externalVideos/getExternalVideos.gql';
import GET_YOUTUBE_METADATA from '@graphql/externalVideos/getYoutubeMetadata.gql';
import IMPORT_EXTERNAL_VIDEOS from '@graphql/externalVideos/importExternalVideos.gql';
import UPDATE_EXTERNAL_VIDEO from '@graphql/externalVideos/updateExternalVideo.gql';

export const useGetExternalVideos = ({ companyId, first, after, last, before, orderBy }) => {
  const { data, loading, error, fetchMore, networkStatus, refetch } = useQuery(GET_EXTERNAL_VIDEO, {
    fetchPolicy: 'network-only',
    variables: {
      companyId,
      first,
      after,
      last,
      before,
      orderBy,
    },
    notifyOnNetworkStatusChange: true,
  });

  return {
    externalVideos: data?.externalVideos,
    total: data?.externalVideos?.totalCount,
    loading,
    error,
    fetchMore,
    networkStatus,
    refetch,
  };
};

export const useDeleteExternalVideo = ({ companyId }) => {
  const [mutate, { loading, error }] = useMutation(DELETE_EXTERNAL_VIDEO, {
    refetchQueries: [{ query: GET_EXTERNAL_VIDEO, variables: { companyId } }],
    awaitRefetchQueries: true,
    onError: console.error,
  });

  const deleteExternalVideo = (externalVideoId) => mutate({ variables: { input: { externalVideoId } } });

  return [
    deleteExternalVideo,
    {
      loading,
      error,
    },
  ];
};

export const useGetYoutubeMetadata = async (youtubeId) => {
  const [getYoutubeMetadata] = useLazyQuery(GET_YOUTUBE_METADATA, {
    fetchPolicy: 'no-cache',
    variables: {
      youtubeId,
    },
  });

  return getYoutubeMetadata;
};

export const useCreateExternalVideo = (companyId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [createFunction, { data, error, loading }] = useMutation(CREATE_EXTERNAL_VIDEO, {
    onCompleted,
    onError,
  });

  const createExternalVideos = (externalVideo) => {
    return createFunction({
      variables: { input: { companyId, ...externalVideo } },
      refetchQueries: [{ query: GET_EXTERNAL_VIDEO, variables: { companyId } }],
      awaitRefetchQueries: true,
    });
  };

  return [createExternalVideos, { data: data?.createExternalVideo, isUpdating: loading, error }];
};

export const useUpdateExternalVideo = (companyId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [updateFunction, { data, error, loading }] = useMutation(UPDATE_EXTERNAL_VIDEO, {
    onCompleted,
    onError,
  });

  const updateExternalVideos = ({ id, externalVideo }) => {
    return updateFunction({
      variables: { input: { id, externalVideo } },
      refetchQueries: [{ query: GET_EXTERNAL_VIDEO, variables: { companyId } }],
      awaitRefetchQueries: true,
    });
  };

  return [updateExternalVideos, { data: data?.updateExternalVideo, isUpdating: loading, error }];
};

export const useImportExternalVideos = (companyId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [importFunction, { data, error, loading }] = useMutation(IMPORT_EXTERNAL_VIDEOS, {
    onCompleted,
    onError,
  });

  const importExternalVideos = (externalVideos) => {
    const videos = externalVideos.map((video) => ({
      productReferences: video.productReferences,
      projectUrl: video.externalVideoURL,
      videoId: getYoutubeIdFromUrl(video.externalVideoURL),
      distributions: video.distributions,
    }));
    return importFunction({ variables: { input: { companyId, externalVideos: videos } } });
  };

  return [importExternalVideos, { data: data?.importExternalVideos, isUpdating: loading, error }];
};
