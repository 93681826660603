import PropTypes from 'prop-types';

import style from './style.module.css';

const classNames = {
  PRIMARY: style.primary,
  SECONDARY: style.secondary,
  TERTIARY: style.tertiary,
};

const Spinner = ({ type = 'PRIMARY' }) => (
  <div className={`${style.spinner} ${classNames[type]}`}>
    <div />
    <div />
    <div />
    <div />
  </div>
);

Spinner.propTypes = {
  type: PropTypes.oneOf(['PRIMARY', 'SECONDARY', 'TERTIARY']),
};

export default Spinner;
