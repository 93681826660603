export const stepsNames = {
  GENERATE: 'GENERATE',
  SPREAD: 'SPREAD',
  CLASSIC: 'CLASSIC',
};

export const getSteps = (t) => ({
  [stepsNames.GENERATE]: {
    breadcrumbTitle: t('Generate'),
    title: t('Generate my video'),
    subtitle: t(
      'The generation of the video makes it possible to have an exportable format. To have a video in the original format, please click on the "Classique" button. The "Spread solution" version allows an export in a format suitable for social media.'
    ),
  },
  [stepsNames.SPREAD]: {
    breadcrumbTitle: t('Spread'),
    previous: stepsNames.GENERATE,
    title: t('Choice of template'),
    subtitle: t('The template is determined upstream by the choice of format. Each template has options depending on its usefulness.'),
  },
  [stepsNames.CLASSIC]: {
    breadcrumbTitle: t('Classic'),
    previous: stepsNames.GENERATE,
    title: t('Choice of template'),
    subtitle: t('The template is determined upstream by the choice of format. Each template has options depending on its usefulness.'),
  },
});

export const youtubeTemplateVariables = ['PRODUCT_NAME', 'PRODUCT_DESCRIPTION', 'CREATOR_FIRST_NAME', 'CREATOR_LAST_NAME', 'PRODUCT_URL', 'PRODUCT_REF', 'PRODUCT_EAN', 'CLIENT_NAME'];

export const getStringInBytes = (string) => new TextEncoder().encode(string).length;

export const youtubeExportValidation = {
  // YouTube: title has a maximum length of 100 characters and may contain all valid UTF-8 characters except < and >. (https://developers.google.com/youtube/v3/docs/videos#resource)
  title: {
    length: (title) => title.length <= 100,
    content: (title) => new RegExp(/^[^<>]*$/, 'u').test(title),
  },
  // YouTube: description has a maximum length of 5000 bytes and may contain all valid UTF-8 characters except < and >. (https://developers.google.com/youtube/v3/docs/videos#resource)
  description: {
    length: (description) => getStringInBytes(description) <= 5000,
    content: (description) => new RegExp(/^[^<>]*$/, 'u').test(description),
  },
};
