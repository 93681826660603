import { useLayoutEffect, useRef } from 'react';

import Action from '@components/Common/Action';
import ImageRatio from '@components/Common/ImageRatio';
import SkeepersIcon from '@components/Common/SkeepersIcon';

import style from './style.module.css';

const SimplePlayer = ({ playing = false, videoUrl, downloadUrl, filename, type = 'VIDEO', thumbnail, onPlay = () => {}, onPause = () => {} }) => {
  const ref = useRef();

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    if (playing) {
      ref.current.play();
    } else {
      ref.current.pause();
    }
  }, [playing]);

  return (
    <div className={style.slide}>
      {type === 'IMAGE' ? (
        <ImageRatio src={thumbnail} className={style.image} />
      ) : (
        <video onPlay={() => onPlay()} onPause={() => onPause()} src={videoUrl} className={style.video} controls ref={ref} poster={thumbnail} controlsList="nodownload" disablePictureInPicture />
      )}
      {(filename || downloadUrl) && (
        <div className={style.flex}>
          <div className={style.filename}>{filename}</div>
          {downloadUrl && (
            <Action action={downloadUrl} download>
              <SkeepersIcon type="DOWNLOAD" color="var(--layout-primary-color)" />
            </Action>
          )}
        </div>
      )}
    </div>
  );
};

export default SimplePlayer;
