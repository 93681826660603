import classNames from 'classnames';
import { forwardRef } from 'react';

import style from './style.module.css';

const Selector = forwardRef(({ options = [], value: selected = null, error = false, readOnly = false, onEdit = () => {} }, ref) => (
  <div className={classNames(style.root, { [style.error]: error })} ref={ref}>
    {options.map(({ label, value, disabled }) => (
      <div
        key={`options-${label}`}
        className={classNames(style.option, { [style.selected]: value === selected, [style.disabled]: disabled, [style.readOnly]: readOnly || disabled })}
        onClick={() => {
          !disabled && onEdit(value);
        }}
      >
        {label}
      </div>
    ))}
  </div>
));

Selector.displayName = 'Selector';

export default Selector;
