import { useParams } from 'react-router';

import WorkflowStudio from '@components/Workflows/WorkflowStudio';

const WorkflowStudioPage = () => {
  const { taskId } = useParams();

  return <WorkflowStudio key={taskId} taskId={taskId} />;
};

export default WorkflowStudioPage;
