import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@components/Common/Button';
import ButtonContainer from '@components/Common/ButtonContainer';
import Hint from '@components/Common/Hint';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import ValuesPreview from '@components/Configuration/ValuesPreview';
import { getTypes } from '@components/Form/TemplateKeysForm';
import UploadInput from '@components/FormElement/UploadInput';
import { useWindows } from '@contexts/WindowContext';
import { useUpdateConfigurationPresetValues } from '@hooks/configuration';

import {
  getImportPresetHint,
  useFileProcessor,
  useAllPresetKeyValues
} from './importPresetValuesHelpers';

const ImportPresetValuesForm = ({ presetId, onSuccess, onError }) => {
  const { t } = useTranslation();
  const { actions } = useWindows();
  const { keys, loading: keysLoading } = useAllPresetKeyValues(presetId, actions);
  const { file, fileLoading, fileError, onEdit } = useFileProcessor(handleParsedKeys);
  const [isUpdating, setIsUpdating] = useState(false);
  const [values, setValues] = useState(null);

  function handleParsedKeys(data) {
    const keysMap = new Map(keys.map(k => {
      const keyName = Object.keys(k)[0];
      return [keyName, {
        value: k[keyName].value,
        keyId: k[keyName].key.id,
        type: k[keyName].key.type,
      }];
    }));

    const filteredValues = Object.entries(data).reduce((acc, [originalKey, newValue]) => {
      if (newValue !== null && newValue !== '' && typeof newValue !== 'undefined') {
        const matchedKey = keysMap.get(originalKey);
        if (matchedKey && newValue !== matchedKey.value) {
          acc.push({
            originalKey,
            value: newValue,
            initialValue: matchedKey.value,
            matchedKey: matchedKey.keyId,
            type: matchedKey.type,
            matched: true,
            included: true
          });
        }
      }
      return acc;
    }, []);

    setValues(filteredValues);
  }

  const keyTypes = getTypes(t);
  const validValues = values?.filter(({ included, empty }) => included && !empty);
  const [updateConfigurationPresetValues] = useUpdateConfigurationPresetValues();

  const onSubmitPressed = async () => {
    setIsUpdating(true)
    const updatedData = validValues.map(({ matchedKey, value }) => ({
      keyId: matchedKey,
      value,
    }))

    const { error } = await updateConfigurationPresetValues(presetId, updatedData);
    setIsUpdating(false)
    if (error) {
      onError();
      actions.close();
      return
    }
    onSuccess(validValues)
    actions.close();
  }

  return (
    <>
      {!keysLoading && (
        <>
          <UploadInput title={t('Source file')} value={file} onEdit={onEdit} local filenamesMode="HIDDEN" />
          <ButtonContainer position="CENTER">
            <Button
              label={t('Import')}
              disabled={!validValues || validValues.length === 0 || isUpdating}
              action={onSubmitPressed}
              loading={isUpdating}
            />
          </ButtonContainer>
          {fileError && <Hint type="ERROR" message={t(fileError)} />}
        </>
      )}
      {keysLoading && (
        <div>
          <SkeepersIcon type="SPINNER" color="var(--layout-primary-color)" spin />
          {t('Loading')}
        </div>
      )}
      {fileLoading && (
        <div>
          <SkeepersIcon type="SPINNER" color="var(--layout-primary-color)" spin />
          {t('Reading file contents...')}
        </div>
      )}
      {values && (
        <>
          <h3>{t('Keys ({{count}})', { count: values.length })}</h3>
          {values.length > 0 ? (
            <>
              <ValuesPreview>
                {values.map(({ originalKey, matchedKey, included, matched, empty, value }) => (
                  <ValuesPreview.Item
                    key={originalKey}
                    icon={matchedKey ? keyTypes[matchedKey.type]?.icon || 'CHECK' : 'CLOSE'}
                    name={originalKey}
                    value={value}
                    success={included}
                    warning={empty}
                    ignored={!matched || !included}
                  />
                ))}
              </ValuesPreview>
              <Hint {...getImportPresetHint(t)(values, validValues)} />
            </>
          ) : (
              <p>{t('No new keys found in file')}</p>
          )}
        </>
      )}
    </>
  );
};

ImportPresetValuesForm.propTypes = {
  presetId: PropTypes.string.isRequired,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
};

export default ImportPresetValuesForm;
