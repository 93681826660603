import { createContext, useContext } from 'react';
import { Navigate as RouterRedirect, useNavigate } from 'react-router-dom';

export const RedirectContext = createContext({ redirect: (url = '', forceRenderMenu = false) => {}, onRedirect: () => {} });

export const RedirectProvider = ({ onRedirect = () => {}, children }) => {
  const navigate = useNavigate();
  const redirect = (url, forceRenderMenu = false) => {
    navigate(url);
    if (forceRenderMenu) {
      onRedirect();
    }
  };
  return <RedirectContext.Provider value={{ redirect, onRedirect }}>{children}</RedirectContext.Provider>;
};

export const Redirect = ({ forceRenderMenu = false, ...otherProps }) => {
  const { onRedirect } = useContext(RedirectContext);
  if (forceRenderMenu) {
    onRedirect();
  }

  return <RouterRedirect {...otherProps} />;
};

export const useRedirect = () => useContext(RedirectContext);
