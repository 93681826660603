import { useUploadFiles, MediaInfo } from '@skeepers/upload-core';
import { forwardRef, useRef, useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { MEDIAINFO_URL } from '@app/config';
import { getPictureLink } from '@app/imageResizer';
import { retry } from '@app/utils';
import Button from '@components/Common/Button';
import Hint from '@components/Common/Hint';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import Spinner from '@components/Common/Spinner';
import Label from '@components/FormElement/Label';
import FileElement from '@components/FormElement/UploadInput/FileElement';
import { useUploadFile } from '@hooks/file';

import DragAndDropZone from './DragAndDropZone';
import style from './style.module.css';

const UploadInput = forwardRef(
  (
    {
      disabled = false,
      disableOnUploading = false,
      multiple = false,
      maxFiles = null,
      lock = () => {},
      unlock = () => {},
      name = '',
      onEdit = () => {},
      deletedFileIndexes = [],
      value = null,
      filenamesMode = 'READONLY',
      required,
      title,
      validTypes = [], // IMAGE, VIDEO, AUDIO
      local = false,
      children,
    },
    forwardedRef
  ) => {
    const { t } = useTranslation();
    const ref = useRef();
    const [uploading, setUploading] = useState(false);
    const [checkingType, setCheckingType] = useState(false);
    const [getSession] = useUploadFile();
    const [urls, setUrls] = useState({});
    const [error, setError] = useState(null);
    const componentWillUnmount = useRef(false);
    const [filesUploaded, setFilesUploaded] = useState(null);
    const type = useMemo(() => {
      if (validTypes.length === 0) {
        return 'FILE';
      } else if (validTypes.every((type) => type.startsWith('IMAGE'))) {
        return 'IMAGE';
      } else {
        return 'VIDEO';
      }
    }, [validTypes]);
    const internValue = (() => {
      if (local) {
        return value ? (multiple ? value : [value]) : [];
      } else if (multiple && filenamesMode === 'HIDDEN') {
        return value ? value.map((v) => ({ url: v })) : [];
      } else if (!multiple && filenamesMode === 'HIDDEN') {
        return value ? [{ url: value }] : [];
      } else if (!multiple && filenamesMode !== 'HIDDEN') {
        return value ? [value] : [];
      } else {
        return value ? value : [];
      }
    })().map((file) => ({ ...file, url: file.url ? file.url : urls[file.id] }));

    const _onEdit = (data) => {
      if (multiple && filenamesMode === 'HIDDEN') {
        onEdit(data.map(({ url }) => url));
      } else if (!multiple && filenamesMode === 'HIDDEN') {
        onEdit(data.length ? data[0].url : null);
      } else if (!multiple && filenamesMode !== 'HIDDEN') {
        onEdit(data.length ? data[0] : null);
      } else {
        onEdit(data);
      }
    };
    const [internFiles, { progress, addFiles, deleteFile, deleteAllFiles, renameFile }] = useUploadFiles(
      internValue.map((file) => ({ ...file, isUploaded: true })),
      {
        getSession: async (file) => {
          const { data } = await retry(
            async () => {
              const result = await getSession({
                type: 'BACKOFFICE',
                origin: window.location.origin,
                contentType: file.blob.type,
                filename: file.blob.name,
              });
              if (result.errors) {
                throw result.errors;
              }
              return result;
            },
            {
              retries: Infinity,
              delay: 2000,
            }
          );
          setUrls((previousUrls) => ({ ...previousUrls, [file.id]: data.uploadFile.url }));
          return data.uploadFile.session;
        },
        onEdit: (newValue, isEnded = false) => {
          setFilesUploaded(newValue);

          if (isEnded) {
            setUploading(false);
            unlock(name);
          }
        },
      }
    );

    useEffect(() => {
      MediaInfo.init(MEDIAINFO_URL);
    }, []);

    const checkTypes = async (files) => {
      if (validTypes.length === 0) {
        return files;
      }
      setCheckingType(true);
      setError(null);
      const validFiles = [];
      await Promise.all(
        files.map(async (file) => {
          const results = await Promise.all(
            validTypes.map(async (type) => {
              let func;
              switch (type) {
                case 'IMAGE':
                  func = MediaInfo.isImage;
                  break;
                case 'VIDEO':
                  func = MediaInfo.isVideo;
                  break;
                case 'AUDIO':
                  func = MediaInfo.isAudio;
                  break;
                default:
                  return true;
              }
              try {
                return await func(file);
              } catch (e) {
                return true;
              }
            })
          );
          if (results.some((result) => result)) {
            validFiles.push(file);
          }
        })
      );
      if (validFiles.length < files.length) {
        setError(t(`Your file is invalid: please provide a ${validTypes.length === 1 ? validTypes[0].toLowerCase() : 'media'} file`));
      }
      setCheckingType(false);
      return validFiles;
    };

    const onUpload = async (files) => {
      if (files.length === 0) {
        return;
      }
      if (local) {
        onEdit(multiple ? (maxFiles ? files.slice(0, maxFiles) : files) : files[0]);
      } else {
        lock(name);
        if (!multiple) {
          deleteAllFiles();
        }
        addFiles(
          (maxFiles ? files.slice(0, maxFiles) : files).map((file) => ({
            url: null,
            fileName: file.name,
            blob: file,
          }))
        );
        setUploading(true);
      }
    };

    const actions = [
      ...(internFiles.length
        ? [
            {
              name: multiple ? t('Add') : t('Edit'),
              icon: multiple ? 'ADD' : 'EDIT',
              onClick: ref?.current?.open,
            },
            {
              name: t('Delete'),
              icon: 'TRASH',
              onClick: () => {
                deleteAllFiles();
              },
            },
          ]
        : []),
    ];

    useEffect(() => {
      return () => {
        componentWillUnmount.current = true;
      };
    }, []);

    useEffect(() => {
      return () => {
        if (componentWillUnmount.current) {
          deleteAllFiles();
        }
      };
    }, [deleteAllFiles]);

    useEffect(() => {
      if (filesUploaded) {
        _onEdit(
          filesUploaded.map((file) => {
            return {
              ...file,
              url: file.url ? file.url : urls[file.id],
            };
          })
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filesUploaded]);

    useEffect(() => {
      if (deletedFileIndexes.length) {
        deletedFileIndexes.forEach((index) => {
          deleteFile(index)
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deletedFileIndexes]);

    const calcRemainingTime = (seconds) => {
      if (seconds < 15) {
        return t('Few seconds left');
      } else if (seconds > 5 * 3600) {
        return t('Upload may take a while, try to switch to 4G/Wifi');
      } else {
        const hrs = Math.floor(seconds / 3600);
        const mins = Math.floor((seconds % 3600) / 60);
        const secs = Math.floor(seconds % 60);

        if (hrs > 0) {
          return `${t('{{count}} hour left', { count: hrs + 1 })} (${Math.round(progress.total)}%)`;
        } else if (mins > 0) {
          return `${t('{{count}} minute left', { count: mins + 1 })} (${Math.round(progress.total)}%)`;
        } else {
          return `${t('{{count}} second left', { count: secs + 1 })} (${Math.round(progress.total)}%)`;
        }
      }
    };

    if (children) {
      const elements = typeof children === 'function' ? children(uploading) : children;

      if (disabled || (disableOnUploading && uploading)) {
        return elements;
      }

      return (
        <DragAndDropZone forwardRef={ref} onEdit={onUpload} multiple={multiple} dropzone={false}>
          <div onClick={() => ref?.current?.open()}>{elements}</div>
        </DragAndDropZone>
      );
    }

    return (
      <Label required={required} name={name} title={title} rows={3} ref={forwardedRef} actions={actions}>
        <DragAndDropZone forwardRef={ref} onEdit={(files) => checkTypes(files).then(onUpload)} multiple={multiple}>
          {!uploading && !checkingType && internFiles.length === 0 && (
            <div className={style.preview}>
              <>
                <div className={style.icon}>
                  <SkeepersIcon type={type} color={'var(--video-text-color-secondary)'} />
                </div>
                <div className={`${style.message}  ${style.p}`}>{t('Drag & Drop')}</div>
                <div className={`${style.or}  ${style.p}`}>{t('or')}</div>
                <Button label={t('Upload')} theme={Button.themes.SECONDARY} action={() => ref?.current?.open()} />
              </>
            </div>
          )}
          <div className={style.filesList}>
            {type === 'IMAGE' && !multiple && !uploading && internFiles.length > 0 && (
              <div className={style.image} style={{ backgroundImage: `url(${getPictureLink(internFiles[0].url, { h: 450, w: 450 })}})` }} />
            )}
            {type !== 'IMAGE' && !uploading && internFiles.length > 0 && (
              <div className={style.iconPreview}>
                <SkeepersIcon type={type} onClick={ref?.current?.open} />
              </div>
            )}
            {checkingType && (
              <div className={style.spinnerContainer}>
                <Spinner type="SECONDARY" />
              </div>
            )}
            {uploading && (!multiple || type !== 'IMAGE') && (
              <div className={style.spinnerContainer}>
                <Spinner type="SECONDARY" />
                {progress.eta ? (
                  <span className={style.percent}>{`${calcRemainingTime(progress.eta)}`}</span>
                ) : (
                  <span className={style.percent}>{`${Math.round(progress.total)}%`}</span>
                )}
              </div>
            )}
            <div className={style.links} style={{ flexDirection: type === 'IMAGE' ? 'row' : 'column', flexWrap: type === 'IMAGE' ? 'wrap' : null }}>
              {!local &&
                (multiple || !uploading) &&
                internFiles.map((file, i) => (
                  <FileElement
                    key={i}
                    onChangeFileName={(name) => renameFile(i, name)}
                    onDeleteFile={() => deleteFile(i)}
                    type={type}
                    filenamesMode={filenamesMode}
                    file={file}
                    local={local}
                    multiple={multiple}
                  />
                ))}
            </div>
          </div>
        </DragAndDropZone>
        {error && <Hint message={error} type="ERROR" />}
      </Label>
    );
  }
);

export default UploadInput;
