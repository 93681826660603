import { useMutation, useQuery } from '@apollo/client';

import client from '@app/apollo';
import CREATE_KEY from '@graphql/configurations/createConfigurationKey.gql';
import CREATE_CONFIGURATION_PRESET from '@graphql/configurations/createConfigurationPreset.graphql';
import CREATE_CONFIGURATION_TEMPLATE from '@graphql/configurations/createConfigurationTemplate.graphql';
import DELETE_CONFIGURATION_KEY from '@graphql/configurations/deleteConfigurationKey.graphql';
import DELETE_CONFIGURATION_PRESET from '@graphql/configurations/deleteConfigurationPreset.graphql';
import DELETE_CONFIGURATION_TEMPLATE from '@graphql/configurations/deleteConfigurationTemplate.graphql';
import GET_KEY from '@graphql/configurations/getConfigurationKey.gql';
import GET_PRESET from '@graphql/configurations/getConfigurationPreset.gql';
import GET_PRESETS_VALUES from '@graphql/configurations/getConfigurationPresetValues.gql';
import GET_TEMPLATE from '@graphql/configurations/getConfigurationTemplate.gql';
import GET_TEMPLATE_KEYS from '@graphql/configurations/getConfigurationTemplateKeys.gql';
import GET_TEMPLATES from '@graphql/configurations/getConfigurationTemplates.gql';
import GET_TEMPLATE_VALUES from '@graphql/configurations/getConfigurationTemplateValues.gql';
import UPDATE_CONFIGURATION_PRESET from '@graphql/configurations/updateConfigurationPresetValues.graphql';
import UPDATE_CONFIGURATION_TEMPLATE from '@graphql/configurations/updateConfigurationTemplateKeys.graphql';
import UPDATE_CONFIGURATION_TEMPLATE_VALUES from '@graphql/configurations/updateConfigurationTemplateValues.graphql';
import UPDATE_CONFIGURATION from '@graphql/configurations/updateConfigurationValues.graphql';

const typesNeedingTransformation = ['ADVANCED_LIST', 'LIST', 'BOOL', 'INT', 'RANGE'];

const transformValue = (value) =>
  typesNeedingTransformation.includes(value.key.type)
    ? {
        ...value,
        value: value.value ? JSON.parse(value.value) : null,
        defaultValue: value.defaultValue ? JSON.parse(value.defaultValue) : null,
        computedValue: value.computedValue ? JSON.parse(value.computedValue) : null,
      }
    : value;

export const parseValues = (values = []) => values.map(transformValue);

export const useGetKey = (id) => {
  const { data, loading, error } = useQuery(GET_KEY, { variables: { id } });

  return {
    key: data?.getConfigurationKey,
    loading,
    error,
  };
};

export const useGetTemplates = () => {
  const { data, loading, error } = useQuery(GET_TEMPLATES);

  return {
    templates: data?.getConfigurationTemplates,
    loading,
    error,
  };
};

export const useGetTemplate = (id) => {
  const { data, loading, error } = useQuery(GET_TEMPLATE, {
    variables: {
      id,
    },
  });

  return {
    template: data?.getConfigurationTemplate,
    loading,
    error,
  };
};

export const useGetTemplateKeys = ({ templateId, first, after, last, before, filters }) => {
  const { data, fetchMore, refetch, loading, error, networkStatus } = useQuery(GET_TEMPLATE_KEYS, {
    fetchPolicy: 'network-only',
    variables: {
      id: templateId,
      first,
      after,
      last,
      before,
      filters,
    },
    notifyOnNetworkStatusChange: true,
  });

  return {
    keys: data?.templateKeys,
    fetchMore,
    refetch,
    loading,
    error,
    networkStatus,
  };
};

/**
 * getvalues for the given template ID
 * If forceRefresh is true, need to refetch values (this value is at true only if a key was added, deteled or modifed)
 */
export const useGetTemplateValues = ({ templateId, first, after, last, before, filters, forceRefresh }) => {
  const { data, fetchMore, refetch, loading, error, networkStatus } = useQuery(GET_TEMPLATE_VALUES, {
    fetchPolicy: 'network-only',
    variables: {
      id: templateId,
      first,
      after,
      last,
      before,
      filters,
    },
    notifyOnNetworkStatusChange: true,
  });

  return {
    values: data?.templateValues,
    fetchMore,
    refetch,
    loading,
    error,
    networkStatus,
  };
};

export const useGetPreset = (id) => {
  const { data, loading, error } = useQuery(GET_PRESET, { variables: { id } });

  return {
    preset: {
      ...data?.getConfigurationPreset,
      values: parseValues(data?.getConfigurationPreset?.values),
    },
    loading,
    error,
  };
};

export const useGetPresetsValues = ({ presetId, first, after, last, before, filters }) => {
  const { data, loading, error, fetchMore, refetch, networkStatus } = useQuery(GET_PRESETS_VALUES, {
    fetchPolicy: 'network-only',
    notifyOnNetworkStatusChange: true,
    variables: {
      id: presetId,
      first,
      after,
      last,
      before,
      filters,
      orderBy: [{ field: 'NAME', direction: 'ASC' }],
    },
  });

  return {
    presets: data?.getPresetValues,
    loading,
    error,
    fetchMore,
    refetch,
    networkStatus,
  };
};

export const getAllPresetValues = async ({ presetId, filters, chunkSize = 800 }) => {
  const fetchChunk = async (acc = [], after = null) => {
    try {
      const { data } = await client.query({
        query: GET_PRESETS_VALUES,
        variables: {
          id: presetId,
          first: chunkSize,
          after: after,
          filters
        },
        fetchPolicy: 'no-cache'

      });
      const newAcc = acc.concat(data.getPresetValues.edges);
      const pageInfo = data.getPresetValues.pageInfo;
      if (pageInfo.hasNextPage) {
        return fetchChunk(newAcc, pageInfo.endCursor);
      }
      return {
        values: newAcc,
        loading: false,
        error: null
      };
    } catch (error) {
      return {
        values: acc,
        loading: false,
        error
      };
    }
  };

  return fetchChunk();
};


export const useCreateConfigurationTemplate = ({ onCompleted = () => {} } = {}) => {
  const [mutate, { data, error, loading }] = useMutation(CREATE_CONFIGURATION_TEMPLATE, {
    onCompleted: (data) => onCompleted(data?.createConfigurationTemplate),
    refetchQueries: [{ query: GET_TEMPLATES }],
  });

  const createConfigurationTemplate = ({ name, type, parentId }) => {
    return mutate({ variables: { name, type, parentId } });
  };

  return [createConfigurationTemplate, { data: data?.createConfigurationTemplate, isUpdating: loading, error }];
};

export const useUpdateConfigurationTemplateKeys = (templateId, { onCompleted = () => {} } = {}) => {
  const [updateFunction, { data, error, loading }] = useMutation(UPDATE_CONFIGURATION_TEMPLATE, {
    onCompleted,
    refetchQueries: [GET_TEMPLATE_KEYS, GET_TEMPLATE_VALUES],
    awaitRefetchQueries: true,
  });

  const updateConfigurationTemplateKeys = (keys) => {
    return updateFunction({ variables: { templateId, keys } });
  };

  return [updateConfigurationTemplateKeys, { data: data?.updateConfigurationTemplateKeys, isUpdating: loading, error }];
};

export const useUpdateConfigurationTemplateValues = (templateId, { onCompleted = () => {} } = {}) => {
  const [updateFunction, { data, error, loading }] = useMutation(UPDATE_CONFIGURATION_TEMPLATE_VALUES, {
    onCompleted,
    awaitRefetchQueries: true,
  });

  const updateConfigurationTemplateValues = (values) => {
    const _values = values
      .map((c) => ({ ...c, value: typesNeedingTransformation.includes(c.type) && c.value !== null ? JSON.stringify(c.value) : c.value }))
      .map(({ keyId, value }) => ({ keyId, value }));
    return updateFunction({ variables: { templateId, values: _values } });
  };

  return [updateConfigurationTemplateValues, { data: data?.updateConfigurationTemplateValues, isUpdating: loading, error }];
};

export const useDeleteConfigurationTemplate = (templateId, { onCompleted = () => {} } = {}) => {
  const [deleteFunction, { data, error, loading }] = useMutation(DELETE_CONFIGURATION_TEMPLATE, {
    onCompleted,
    // Refetch the list of templates
    refetchQueries: [{ query: GET_TEMPLATES }],
    awaitRefetchQueries: true,
  });

  const deleteConfigurationTemplate = () => deleteFunction({ variables: { templateId } });

  return [deleteConfigurationTemplate, { data: data?.deleteConfigurationTemplate, isUpdating: loading, error }];
};

export const useCreateConfigurationTemplateKey = (templateId, { onCompleted = () => {} } = {}) => {
  const [createFunction, { data, error, loading }] = useMutation(CREATE_KEY, {
    onCompleted,
    refetchQueries: [GET_TEMPLATE_KEYS, GET_TEMPLATE_VALUES],
    awaitRefetchQueries: true,
  });

  const createConfigurationTemplateKey = ({ name, type }) => {
    return createFunction({ variables: { templateId, name, type } });
  };

  return [createConfigurationTemplateKey, { data: data?.createConfigurationKey, isUpdating: loading, error }];
};

export const useDeleteConfigurationTemplateKey = (templateId, { onCompleted = () => {} } = {}) => {
  const [deleteFunction, { data, error, loading }] = useMutation(DELETE_CONFIGURATION_KEY, {
    onCompleted,
    refetchQueries: [GET_TEMPLATE_KEYS, GET_TEMPLATE_VALUES],
    awaitRefetchQueries: true,
  });

  const deleteConfigurationTemplateKey = (id) => deleteFunction({ variables: { id } });

  return [deleteConfigurationTemplateKey, { data: data?.deleteConfigurationKey, isUpdating: loading, error }];
};

export const useCreateConfigurationPreset = (templateId, { onCompleted = () => {} } = {}) => {
  const [createFunction, { data, error, loading }] = useMutation(CREATE_CONFIGURATION_PRESET, {
    onCompleted: (data) => onCompleted(data?.createConfigurationPreset),
  });

  const createConfigurationPreset = ({ language }) => {
    return createFunction({ variables: { templateId, language } });
  };

  return [createConfigurationPreset, { data: data?.createConfigurationPreset, isUpdating: loading, error }];
};

export const useUpdateConfigurationPresetValues = ({ onCompleted = () => {} } = {}) => {
  const [updateFunction, { data, error, loading }] = useMutation(UPDATE_CONFIGURATION_PRESET, {
    onCompleted,
    refetchQueries: [GET_PRESETS_VALUES],
    awaitRefetchQueries: true,
  });

  const updateConfigurationPresetValues = (presetId, values) => {
    return updateFunction({ variables: { presetId, values } });
  };

  return [updateConfigurationPresetValues, { data: data?.updateConfigurationPresetValues, isUpdating: loading, error }];
};

export const useDeleteConfigurationPreset = (preset, { onCompleted = () => {} } = {}) => {
  const [deleteFunction, { data, error, loading }] = useMutation(DELETE_CONFIGURATION_PRESET, {
    onCompleted,
    // Refetch the list of presets for the template that this one belonged to
    refetchQueries: [{ query: GET_TEMPLATE, variables: { id: preset?.template?.id } }],
    awaitRefetchQueries: true,
  });

  const deleteConfigurationPreset = () => deleteFunction({ variables: { presetId: preset?.id } });

  return [deleteConfigurationPreset, { data: data?.deleteConfigurationPreset, isUpdating: loading, error }];
};

export const useUpdateConfigurationValues = () => {
  const [updateFunction, { data, error, loading }] = useMutation(UPDATE_CONFIGURATION);

  const updateSolution = (configurationId) => (formData) => {
    const values = formData
      // The backend only accept values as string. The List and AdvancedList components return the value as array and should be stringified.
      .map((c) => ({ ...c, value: typesNeedingTransformation.includes(c.type) && c.value !== null ? JSON.stringify(c.value) : c.value }))
      .map(({ keyId, value }) => ({ keyId, value }));

    return updateFunction({ variables: { configurationId, values } });
  };

  return [updateSolution, { data: data?.updateConfigurationValues, isUpdating: loading, error }];
};
