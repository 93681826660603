import { faExclamationTriangle, faCheck } from '@fortawesome/pro-solid-svg-icons';
import { SkpIcon } from '@skeepers/skeepers-ui-react';
import classNames from 'classnames';
import { useState } from 'react';

import { durationInMinutes } from '@app/utils';

import AudioPicture from './assets/audio.jpg';
import style from './style.module.css';

const InputCard = ({
  refused,
  id = '',
  disabled = false,
  filename = 'Unknown',
  type = 'OTHER',
  defaultFrame = null,
  used = false,
  onClick = () => {},
  onRename = () => {},
  onSave = null,
  duration = 0,
}) => {
  const [title, setTile] = useState(filename);
  const handleClick = () => {
    if (type !== 'OTHER' && !disabled) {
      onClick(id);
    }
  };

  const _onFocus = () => onRename(true);

  const _onChange = (e) => {
    setTile(e.target.value);
  };

  const _onBlur = () => {
    if (!title) setTile(filename);
    onRename(false);
    if (title !== filename) onSave(id, title);
  };

  const _stopPropagation = (e) => {
    e.stopPropagation();
  };

  return (
    <div
      onClick={handleClick}
      className={classNames(style.elem, disabled ? style.disabled : null)}
      style={{ backgroundImage: `url('${type === 'AUDIO' ? AudioPicture : defaultFrame}')` }}
    >
      <div className={classNames(style.info, { [style.refused]: refused, [style.used]: used })} />
      {used && <SkpIcon size="small" color="--skp-color-white" icon={faCheck} className={style.usedIcon} />}
      {refused && !used && <SkpIcon size="small" color="--skp-color-white" icon={faExclamationTriangle} className={style.refusedIcon} />}
      <span className={style.duration}>{durationInMinutes(duration)}</span>
      {onSave ? (
        <input
          onFocus={_onFocus}
          onClick={_stopPropagation}
          className={style.text}
          type="text"
          title={filename}
          placeholder={filename}
          value={title}
          onChange={_onChange}
          onBlur={_onBlur}
        />
      ) : (
        <div className={style.text}>{title}</div>
      )}
    </div>
  );
};

const TimelineDirectory = ({ inputs = [], onAdd = () => {}, onRenameInputTitle = () => {}, onSaveInputTitle = null }) => {
  return (
    <div className={style.root}>
      {inputs
        .filter((input) => input.duration > 0 && input.type !== 'OTHER')
        .map((input) => (
          <InputCard
            {...input}
            onClick={onAdd}
            key={input.id}
            onRename={onRenameInputTitle}
            onSave={onSaveInputTitle}
            disabled={!!(!input.normalizedUrl && !input.normalizedPreviewUrl)}
          />
        ))}
      {Array.apply(null, { length: 10 })
        .map(Number.call, Number)
        .map(
          (
            _,
            key // eslint-disable-line prefer-spread
          ) => (
            <div
              key={`empty-${key}`} // eslint-disable-line react/no-array-index-key
              className={`${style.elem} ${style.empty}`}
            />
          )
        )}
    </div>
  );
};

export default TimelineDirectory;
