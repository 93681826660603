import DayPickerInput from '@skeepers/react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from '@skeepers/react-day-picker/moment';
import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';

import { toDate } from '@app/utils';
import SkeepersIcon from '@components/Common/SkeepersIcon';

import style from './style.module.css';

import '@skeepers/react-day-picker/style.css';

const DateInput = forwardRef(
  ({ value = null, allowedRange = {}, onEdit = () => {}, error = false }, forwardedRef) => {
    const { t } = useTranslation();
    const _onEdit = (date) => onEdit(date ? moment(date).format('YYYY-MM-DD') : null);

    return (
      <div className={classNames(style.datePicker, style.root, { [style.error]: error })} ref={forwardedRef}>
        <DayPickerInput
          placeholder={t('Date')}
          value={toDate(value)}
          dayPickerProps={{
            selectedDays: toDate(value),
            disabledDays: {
              ...(allowedRange?.to ? { after: toDate(allowedRange?.to) } : {}),
              ...(allowedRange?.from ? { before: toDate(allowedRange?.from) } : {}),
            },
            localeUtils: MomentLocaleUtils,
            locale: t('_TECHNICAL_MOMENT_LANGUAGE'),
          }}
          formatDate={formatDate}
          parseDate={parseDate}
          onDayChange={_onEdit}
        />
        <SkeepersIcon className={style.calendarIcon} type="CALENDAR" color="var(--layout-primary-color)" />
      </div>
    );
  }
);

export default DateInput;

DateInput.displayName = 'DateInput';

DateInput.propTypes = {
  value: PropTypes.string,
  error: PropTypes.bool,
  allowedRange: PropTypes.shape({
    to: PropTypes.string,
    from: PropTypes.string,
  }),
  onEdit: PropTypes.func,
};
