import { useTranslation } from 'react-i18next';

import AvailableKeys from '@components/Common/AvailableKeys';
import { Grid } from '@components/Common/Grid';
import Hint from '@components/Common/Hint';
import Form from '@components/FormElement/Form/Form';

import style from './style.module.css';

const TitleAndPreview = ({ previewQrCode }) => {
  const { t } = useTranslation();

  return (
    <Grid columns={2}>
      <div className={style.inputContainer}>
        <Form.Label title={t('Export QR code file name')} name="filename">
          <Form.Input
            name="filename"
            required
            placeholder={t('QR code name')}
            hint={<Hint message={t('Your QR code export name: Needle package - Product page - Nantes shop')} type="INFO" />}
          />
        </Form.Label>
        <Form.Label title={t('Catch line scan QR code')} name="catchLine">
          <Form.Input
            name="catchLine"
            placeholder={t('Your catchline here!')}
            hint={<Hint message={t('Optionnal: add a short text to lead users to scan it')} type="INFO" />}
          />
        </Form.Label>
        <AvailableKeys
          keys={['{{{PRODUCT_NAME}}}', '{{{TITLE}}}', '{{{USER_DISPLAY_NAME}}}']}
          tagColor={'var(--layout-primary-color)'}
          TagTextColor="white"
          required
          hintMessage={t('Click on the tag to copy the key.')}
          hintType="INFO"
        />
      </div>
      {previewQrCode}
    </Grid>
  );
};

export default TitleAndPreview;
