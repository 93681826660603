import classNames from 'classnames';
import PropTypes from 'prop-types';

import Action from '@components/Common/Action';
import Flex from '@components/Common/Flex';
import Text from '@components/Common/Text';

import style from './style.module.css';

const Breadcrumb = ({ history = [], className }) => (
  <Flex justifyContent="SPACE-BETWEEN" alignItems="CENTER" className={classNames(style.root, className)}>
    <Text size="small" color="var(--video-text-color-secondary)">
      {history.map(({ url, name, func }, index) =>
        index < history.length - 1 ? (
          func ? (
            <span onClick={func} className={style.action} key={`${name}-${index}`}>
              {name} {' > '}
            </span>
          ) : (
            <Action action={url} className={style.action} key={`${name}-${index}`}>
              {name} {' > '}
            </Action>
          )
        ) : (
          <Text as="span" size="small" color="var(--skp-color-neutral-900)" bold="medium" key={`${name}-${index}`}>
            {name}
          </Text>
        )
      )}
    </Text>
  </Flex>
);

export default Breadcrumb;

Breadcrumb.propTypes = {
  history: PropTypes.array,
};
