import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonContainer from '@components/Common/ButtonContainer';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import { getTypes } from '@components/Form/TemplateKeysForm';
import { Form, useForm } from '@components/FormElement/Form';

const CreateTemplateKeyForm = ({ isUpdating = false, onSubmit = () => {} }) => {
  const { t } = useTranslation();
  const types = getTypes(t);
  const options = Object.entries(types).map(([value, { name, icon }]) => ({ label: name, value, icon: <SkeepersIcon type={icon} /> }));
  const methods = useForm();

  return (
    <Form onSubmit={onSubmit} methods={methods} isUpdating={isUpdating}>
      <Form.Label title={t('Name')} name="name">
        <Form.Input name="name" required />
      </Form.Label>
      <Form.Label title={t('Type')} name="type">
        <Form.Select name="type" required options={options} />
      </Form.Label>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default CreateTemplateKeyForm;

CreateTemplateKeyForm.propTypes = {
  isUpdating: PropTypes.bool,
  onSubmit: PropTypes.func,
};
