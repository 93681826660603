import { useTranslation } from 'react-i18next';

import UpdateAvatarForm from '@components/Form/UpdateAvatarForm';
import Window from '@components/Window/Window';

const UpdateAvatarWindow = ({ onSubmit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Upload avatar')} open={open} onClose={onClose}>
      <UpdateAvatarForm onSubmit={onSubmit} isUpdating={isLoading} />
    </Window>
  );
};

export default UpdateAvatarWindow;
