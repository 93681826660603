import { useTranslation } from 'react-i18next';

import Action from '@components/Common/Action';
import { showChromeUpdateWarning, showUnsuportedBrowserWarning, showZoomWarning } from '@components/Studio/browserDetect';

import style from './style.module.css';

const BrowserWarning = () => {
  const { t } = useTranslation();

  if (showUnsuportedBrowserWarning()) {
    return (
      <Action action="https://www.google.com/chrome/" className={style.warning}>
        {t('This video editor has not been tested with your browser, switch to Google Chrome.')}
      </Action>
    );
  } else if (showChromeUpdateWarning()) {
    return (
      <Action action="https://www.google.com/chrome/" className={style.warning}>
        {t('Your browser version is old, unknown bugs could appear.')}
      </Action>
    );
  } else if (showZoomWarning()) {
    return <span className={style.warning}>{t('Please set your browser zoom to 100% and refresh.')}</span>;
  }
  return null;
};

export default BrowserWarning;
