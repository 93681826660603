import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@components/Common/Button';
import ButtonContainer from '@components/Common/ButtonContainer';
import Select from '@components/FormElement/Select';
import SocialMediaAuth from '@components/Project/UploadSocialMedia/Auth/SocialMediaAuth';

import style from './style.module.css';

const UploadSocialMediaAuth = ({ onLogin = () => {}, onLogout = () => {} }) => {
  const { t } = useTranslation();
  const [credentials, setCredentials] = useState({});
  const [usernames, setUsernames] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [loginError, setLoginError] = useState(null);

  const onChange = (value) => {
    onLogin('', null);
    setSelectedValue(value);
  };

  const login = (credential, username, playlist = []) => {
    setLoginError(null);
    setOpen(false);
    setCredentials({ ...credentials, [selectedValue]: credential });
    setUsernames({ ...usernames, [selectedValue]: username });
    onLogin(selectedValue, credential, playlist);
  };

  const logout = () => {
    if (selectedValue) {
      setCredentials({ ...credentials, [selectedValue]: null });
      setUsernames({ ...usernames, [selectedValue]: null });
      onLogout(selectedValue);
    }
  };

  return (
    <div className={style.container}>
      {(!selectedValue || !credentials[selectedValue]) && (
        <>
          <Select options={[{ value: 'YOUTUBE', label: `${t('Youtube')}` }]} onEdit={onChange} />
          <div className={style.subcontent}>
            {loginError && <div className={style.loginError}>{loginError}</div>}
            {selectedValue === 'YOUTUBE' && (
              <div>
                <a href="https://www.youtube.com/t/terms" target="_blank" rel="noreferrer">
                  {t("YouTube's Terms of Service")}
                </a>
                <br />
                <a href="https://policies.google.com/privacy" target="_blank" rel="noreferrer">
                  {t('Google Privacy Policy')}
                </a>
                <br />
                <a href="https://security.google.com/settings/security/permissions" target="_blank" rel="noreferrer">
                  {t(' Revocation link')}
                </a>
              </div>
            )}
          </div>
        </>
      )}
      {open && (
        <>
          {selectedValue === 'YOUTUBE' && (
            <SocialMediaAuth.Youtube
              onClose={() => setOpen(false)}
              onLogin={login}
              onLoginError={() => setLoginError(t('An error happened during the login.'))}
            />
          )}
        </>
      )}
      {selectedValue &&
        (!credentials[selectedValue] ? (
          <ButtonContainer>
            <Button label={t('Login')} theme={Button.themes.SECONDARY} action={() => setOpen(true)} />
          </ButtonContainer>
        ) : (
          <div className={style.loginContainer}>
            <p>
              {t('Connected as ')}
              {usernames[selectedValue]}
            </p>
            <Button label={t('Logout')} theme={Button.themes.SECONDARY} action={logout} />
          </div>
        ))}
    </div>
  );
};

export default UploadSocialMediaAuth;

UploadSocialMediaAuth.propTypes = {
  onLogin: PropTypes.func.isRequired,
  onLogout: PropTypes.func.isRequired,
};
