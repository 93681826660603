import { useMutation, useQuery } from '@apollo/client';

import { PAGINATION_DEFAULT } from '@app/config';
import ASSIGN_ROLE from '@graphql/permissions/assignRole.graphql';
import CREATE_RESOURCE_POLICY from '@graphql/permissions/createResourcePolicy.graphql';
import DELETE_RESOURCE_POLICY from '@graphql/permissions/deleteResourcePolicy.graphql';
import GET_GRANTEES from '@graphql/permissions/getGrantees.graphql';
import GET_RESOURCE_POLICIES from '@graphql/permissions/getResourcePolicies.graphql';
import GET_ROLE_HOLDERS from '@graphql/permissions/getRoleHolders.graphql';
import REVOKE_ROLE from '@graphql/permissions/revokeRole.graphql';

export const useGetGrantees = ({
  ids = [],
  fullTextSearch = undefined
} = {}) => {
  const { loading, error, data } = useQuery(GET_GRANTEES, {
    variables: {
      ids: ids.length ? ids : undefined,
      fullText: fullTextSearch,
    },
    skip: !ids.length && !fullTextSearch,
  });

  return {
    grantees: data?.getGrantees ?? [],
    error,
    loading,
  };
};

export const useGetResourcePolicies = (grantees = null) => {
  const { data, loading, error } = useQuery(GET_RESOURCE_POLICIES, {
    variables: {
      ...(grantees ? { grantees } : {}),
    },
    skip: !grantees,
  });

  return {
    policies: data?.getResourcePolicies,
    loading,
    error,
  };
};

export const useGetRoleHolders = (search = '', page = 1) => {
  const { data, loading, error, refetch } = useQuery(GET_ROLE_HOLDERS, {
    variables: {
      search,
      page: {
        page,
        size: PAGINATION_DEFAULT.size,
      }
    },
    fetchPolicy: 'cache-and-network',
    refetchWritePolicy: 'cache-and-network',
  });

  return {
    users: data?.getRoleHolders.users,
    count: data?.getRoleHolders.count,
    loading,
    error,
    refetch,
  };
};

export const useDeleteResourcePolicy = () => {
  const [mutate, { data, loading, error }] = useMutation(DELETE_RESOURCE_POLICY);

  const deletePolicy = (policy) =>
    mutate({
      variables: { id: policy.id },
      refetchQueries: [{ query: GET_RESOURCE_POLICIES }],
      awaitRefetchQueries: true,
    });

  return [deletePolicy, { data: data?.deleteResourcePolicy, loading, error }];
};

export const useCreateResourcePolicy = ({ onCompleted }) => {
  const [mutate, { data, loading, error }] = useMutation(CREATE_RESOURCE_POLICY, { onCompleted });

  const createPolicy = ({ grantee, role, resourceType, resourceId }) => {
    return mutate({
      variables: {
        resourceId: resourceId || '*',
        resourceType: resourceType || '*',
        grantee,
        role,
      },
      refetchQueries: [{ query: GET_RESOURCE_POLICIES }],
      awaitRefetchQueries: true,
    });
  };

  return [createPolicy, { data: data?.createResourcePolicy, loading, error }];
};

export const useAssignRole = ({ onCompleted }) => {
  const [mutate, { data, loading, error }] = useMutation(ASSIGN_ROLE, { onCompleted });

  const assignRole = ({ userId, role }) => {
    return mutate({
      variables: {
        userId,
        role,
      },
      refetchQueries: [{ query: GET_ROLE_HOLDERS }],
      awaitRefetchQueries: true,
    });
  };

  return [assignRole, { data: data?.assignRole, loading, error }];
};

export const useRevokeRole = () => {
  const [mutate, { data, loading, error }] = useMutation(REVOKE_ROLE);

  const revokeRole = ({ id }) => {
    return mutate({
      variables: {
        userId: id,
      },
      refetchQueries: [{ query: GET_ROLE_HOLDERS }],
      awaitRefetchQueries: true,
    });
  };

  return [revokeRole, { data: data?.revokeRole, loading, error }];
};
