import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Block from '@components/Common/Block';
import Flex from '@components/Common/Flex';
import Header from '@components/Common/Header';
import LabelAction from '@components/Common/LabelAction';
import CheckboxGroup from '@components/FormElement/CheckboxGroup';

import style from './style.module.css';

const WorkflowBriefBlock = ({ checklist = null, onBriefClick = null, onShortcutClick = null }) => {
  const { t } = useTranslation();

  return (
    <Block padding="SMALL">
      {checklist?.length > 0 && (
        <div className={style.checklist}>
          <Header as="h1" className={style.title}>
            {t('Checklist')}
          </Header>
          <CheckboxGroup options={checklist.map((i) => ({ value: i, label: i }))} multi />
        </div>
      )}
      <Flex justifyContent="SPACE-BETWEEN">
        {onBriefClick && <LabelAction icon="INFO" text={t('Brief')} onClick={onBriefClick} />}
        {onShortcutClick && <LabelAction icon="KEYBOARD" text={t('Shortcut')} onClick={onShortcutClick} />}
      </Flex>
    </Block>
  );
};

export default WorkflowBriefBlock;

WorkflowBriefBlock.propTypes = {
  checklist: PropTypes.array,
  onBriefClick: PropTypes.func,
  onShortcutClick: PropTypes.func,
};
