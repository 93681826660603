import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ROUTES from '@app/routes';
import Action from '@components/Common/Action';

import { ReactComponent as Page404Image } from './assets/page404.svg';
import { ReactComponent as Page500Image } from './assets/page500.svg';
import style from './style.module.css';

const PageError = ({ title, subtitle, image: Image }) => (
  <div className={style.root}>
    <div className={style.title}>{title}</div>
    {/* <Image className={style.icon} /> */}
    <div className={style.subtitle}>{subtitle}</div>
  </div>
);

export const Page500 = ({ actions }) => {
  const { t } = useTranslation();

  return (<PageError
    title={t('Oh no! Something bad happened!')}
    subtitle={actions || t('It seems that an error happened while loading the page')}
    image={Page500Image}
  />);
};

export const Page404 = () => {
  const { t } = useTranslation();

  return (<PageError title={t('Oups...')} subtitle={t('It seems that you are lost')} image={Page404Image} />);
};

export const ForbiddenError = ({ route = null }) => {
  const { t } = useTranslation();
  const { companyId } = useParams();
  const action = companyId ? ROUTES.DASHBOARD.path({ companyId }) : ROUTES.HOME.path();

  return (
    <PageError title={t('Your user account does not allow you to access this page.')} subtitle={<Action action={route || action}>{t('Go back')}</Action>} />
  );
};

Page500.propTypes = {
  actions: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
};
