import { useTranslation } from 'react-i18next';

import SetThumbnailForm from '@components/Form/SetThumbnailForm';
import Window from '@components/Window/Window';

const SetThumbnailWindow = ({ poster = '', frames = [], videoRatio = '16:9', onEdit = () => {}, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Thumbnail')} open={open} onClose={onClose} overflowAuto>
      <SetThumbnailForm currentThumbnail={poster} frames={frames} onSubmit={onEdit} videoRatio={videoRatio} />
    </Window>
  );
};

export default SetThumbnailWindow;
