import { SkpAlert, SkpButton, SkpFlex } from '@skeepers/skeepers-ui-react';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Player from '@components/Project/Player';
import Window from '@components/Window/Window';
import { useCreateThumbnail } from '@hooks/projects/thumbnails';

const ExtractThumbnailWindow = ({
  project,
  defaultTime,
  onClose = () => { },
  open = false,
  onCompleted = () => { },
  isPreview
}) => {
  const { t } = useTranslation();
  const { lastOutput } = project;
  const playerRef = useRef();

  const [time, setTime] = useState(defaultTime > 0 && defaultTime < lastOutput?.duration ? defaultTime.toFixed(3) * 1000 : 0);

  useEffect(() => {
    if (isPreview && playerRef.current) {
      setTime(defaultTime);
      playerRef.current.seek(defaultTime);
    }
  }, [defaultTime, isPreview]);

  const [createThumbnail, { updating }] = useCreateThumbnail(project.id, {
    onCompleted: () => {
      onCompleted();
    },
    onError: (error) => {
      console.error('Error creating thumbnail:', error);
    }
  });

  const extractThumbnail = async () => {
    if (lastOutput && time !== null) {
      const position = time / 1000;
      try {
        await createThumbnail(lastOutput.id, position, lastOutput.url);
        onCompleted();
      } catch (error) {
        console.error('Error creating thumbnail:', error);
      }
    }
  };

  return (
    <Window title={isPreview ? t('Thumbnail preview') : t('Create custom thumbnail')} open={open} onClose={onClose}>
      <SkpFlex flexDirection="column" alignItems="stretch" gap={6}>
        <Player
          ref={playerRef}
          url={lastOutput?.url}
          onTimeUpdate={setTime}
          currentTime={time}
          ratio={project.ratio}
          controlsDisabled={isPreview}
        />
        {!isPreview &&
          <>
            <SkpAlert type="info" size="small" text={t('To generate a new frame you need to select a value on the timeline')} />
            <SkpFlex justifyContent="center" gap={4}>
              <SkpButton
                data-testid="extract-thumbnail-button"
                text={t('Extract thumbnail')}
                disabled={!time || updating}
                loading={updating}
                onSkpClick={extractThumbnail}
              />
              <SkpButton data-testid="cancel-extract-thumbnail-button" type="secondary" text={t('Cancel')} onSkpClick={onClose} />
            </SkpFlex>
        </>
        }
      </SkpFlex>
    </Window>
  );
};

ExtractThumbnailWindow.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    ratio: PropTypes.oneOf(["9:16", "16:9"]),
    lastOutput: PropTypes.shape({
      id: PropTypes.string.isRequired,
      duration: PropTypes.number,
      url: PropTypes.string,
    })
  }).isRequired,
  defaultTime: PropTypes.number,
  onClose: PropTypes.func,
  open: PropTypes.bool,
  onCompleted: PropTypes.func,
}

export default ExtractThumbnailWindow;
