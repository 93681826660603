import DayPickerInput from '@skeepers/react-day-picker/DayPickerInput';
import MomentLocaleUtils, { formatDate, parseDate } from '@skeepers/react-day-picker/moment';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { toDate } from '@app/utils';
import SkeepersIcon from '@components/Common/SkeepersIcon';

import style from './style.module.css';

import '@skeepers/react-day-picker/style.css';

const DateRangePicker = forwardRef(({ value = {}, allowedRange = {}, onChange = () => {}, className = '' }, externalRef) => {
  const { t } = useTranslation();

  const ref = useRef();
  if (!value) {
    return null;
  }

  const { to, from } = value;
  const modifiers = { start: from, end: to };
  const disabledDays = {
    from: {
      ...(allowedRange.from ? { before: toDate(allowedRange.from) } : {}),
      after: to,
    },
    to: {
      before: from,
      ...(allowedRange.to ? { after: toDate(allowedRange.to) } : {}),
    },
  };

  const onFromChange = (from) => onChange({ from });
  const onToChange = (to) => onChange({ to });

  return (
    <div className={classNames(style.dateRangePicker, style.root, className)} ref={externalRef}>
      <DayPickerInput
        placeholder={t('from')}
        value={from}
        dayPickerProps={{
          selectedDays: [from, { from, to }],
          disabledDays: disabledDays.from,
          toMonth: new Date(),
          modifiers,
          onDayClick: () => ref.current.getInput().focus(),
          localeUtils: MomentLocaleUtils,
          locale: t('_TECHNICAL_MOMENT_LANGUAGE'),
        }}
        formatDate={formatDate}
        parseDate={parseDate}
        onDayChange={onFromChange}
      />
      <span className={style.to}> {t('_DATE_to')} </span>
      <DayPickerInput
        ref={(el) => (ref.current = el)}
        placeholder={t('to')}
        value={to}
        dayPickerProps={{
          selectedDays: [from, { from, to }],
          disabledDays: disabledDays.to,
          toMonth: new Date(),
          modifiers,
          localeUtils: MomentLocaleUtils,
          locale: t('_TECHNICAL_MOMENT_LANGUAGE'),
        }}
        formatDate={formatDate}
        parseDate={parseDate}
        onDayChange={onToChange}
      />
      <SkeepersIcon className={style.calendarIcon} type="CALENDAR" color="var(--layout-primary-color)" />
    </div>
  );
});

DateRangePicker.displayName = 'DateRangePicker';

DateRangePicker.propTypes = {
  range: PropTypes.shape({
    to: PropTypes.instanceOf(Date),
    from: PropTypes.instanceOf(Date),
  }),
  allowedRange: PropTypes.shape({
    to: PropTypes.instanceOf(Date),
    from: PropTypes.instanceOf(Date),
  }),
  onChange: PropTypes.func,
};

export default DateRangePicker;
