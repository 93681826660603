import { useTranslation } from 'react-i18next';

import Button from '@components/Common/Button';
import Flex from '@components/Common/Flex';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import Window from '@components/Window/Window';

import style from './style.module.css';

const WorlkflowExitWindow = ({ onClose = () => {}, open = false, onExit = () => {} }) => {
  const { t } = useTranslation();

  return (
    <Window open={open} onClose={onClose} maxWidth="500px">
      <Flex justifyContent="SPACE-BETWEEN" flexDirection="COLUMN" alignItems="CENTER" className={style.container}>
        <SkeepersIcon type="DOOR" className={style.icon} />
        <span className={style.title}>{t('Exit studio')}</span>
        <div className={style.textContainer}>
          <span>{t('You are about to exit the studio.')}</span>
          <br />
          <span>{t('If your work is not done, it will not be save')}</span>
        </div>
        <span>{t('Are you sure you want to exit ?')}</span>
        <Button
          label={t('Confirm')}
          action={() => {
            onExit();
            onClose();
          }}
        />
      </Flex>
    </Window>
  );
};

export default WorlkflowExitWindow;
