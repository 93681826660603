import classNames from 'classnames';
import PropTypes from 'prop-types';

import Action from '@components/Common/Action';
import DropDown from '@components/Common/DropDown';
import SkeepersIcon from '@components/Common/SkeepersIcon';

import style from './style.module.css';

const MenuIcon = ({ type, color = 'var(--picto-color)' }) => <SkeepersIcon type={type} color={color} />;

const MenuRight = ({ open, title, menu }) => {
  return (
    <nav className={classNames(style.nav, { [style.open]: open })} style={{ boxShadow: open ? 'rgba(0, 0, 0, 0.26) 9px 6px 10px -6px' : '' }}>
      <h1 className={style.title}>{title}</h1>
      {menu.map((e, i) => (
        <div className={style.block} key={`main-${i}`}>
          <Action action={e.onClick}>
            <div className={classNames(style.blockTitle, { [style.selected]: e.selected })} title={e.name}>
              <div className={classNames(style.blockTitleInner, { [style.selected]: e.selected })}>
                {e.icon && (
                  <div className={style.icon}>
                    <MenuIcon type={e.icon} />
                  </div>
                )}
                <div
                  className={classNames(style.blockTitleInnerContent, {
                    [style.dropdown]: e?.children?.length,
                    [style.withIcon]: style.withIcon,
                  })}
                >
                  {e.name}
                </div>
                {e?.children?.length > 0 && (
                  <div className={classNames(style.dropdownIcon, { [style.open]: e.open })}>
                    <MenuIcon type="DROPDOWN" color="var(--skp-color-neutral-700)" />
                  </div>
                )}
              </div>
            </div>
          </Action>
          {e?.children?.length > 0 && (
            <DropDown open={e.open}>
              <ul className={style.list1}>
                {e.children.map((child, childIndex) => (
                  <li title={child.name} key={child.key || `main-${childIndex}`}>
                    <Action action={child.onClick}>
                      <div className={classNames(style.elem, { [style.selected]: child.selected })}>
                        {child.icon && (
                          <div className={style.icon}>
                            <MenuIcon type={child.icon} />
                          </div>
                        )}
                        <div className={classNames(style.elemContent, { [style.dropdown]: child?.children?.length })}>{child.name}</div>
                        {child?.children?.length && (
                          <div className={classNames(style.dropdownIconSubMenu, { [style.open]: child.open })}>
                            <MenuIcon type="DROPDOWN" color="var(--skp-color-neutral-700)" />
                          </div>
                        )}
                      </div>
                    </Action>
                    {child.children && child.children.length > 0 && child.open && (
                      <DropDown open={child.open} className={classNames(style.dropdown, { [style.dropdownTiny]: child.open })}>
                        <ul className={style.list2}>
                          {child.children.map((subElem, subElemIndex) => (
                            <li title={subElem.name} key={subElem.key || `main-${subElemIndex}`}>
                              <Action action={subElem.onClick}>
                                <div className={classNames(style.subElem, { [style.selected]: subElem.selected })}>
                                  <div className={style.subElemContent}>{subElem.name}</div>
                                </div>
                              </Action>
                            </li>
                          ))}
                        </ul>
                      </DropDown>
                    )}
                  </li>
                ))}
              </ul>
            </DropDown>
          )}
        </div>
      ))}
    </nav>
  );
};

MenuRight.propTypes = {
  title: PropTypes.string,
  open: PropTypes.bool,
  menu: PropTypes.arrayOf(
    PropTypes.exact({
      name: PropTypes.string.isRequired,
      selected: PropTypes.bool,
      open: PropTypes.bool,
      icon: PropTypes.string,
      onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.bool]),
      children: PropTypes.arrayOf(
        PropTypes.exact({
          name: PropTypes.string.isRequired,
          selected: PropTypes.bool,
          open: PropTypes.bool,
          icon: PropTypes.string,
          onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.bool]),
          children: PropTypes.arrayOf(
            PropTypes.exact({
              key: PropTypes.string,
              name: PropTypes.string.isRequired,
              selected: PropTypes.bool,
              onClick: PropTypes.oneOfType([PropTypes.func, PropTypes.string, PropTypes.bool]),
            })
          ),
        })
      ),
    })
  ),
};

MenuRight.defaultProps = {
  title: '',
  open: false,
  menu: [],
};

export default MenuRight;
