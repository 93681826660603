import { useTranslation } from 'react-i18next';

import CreateSubtitleForm from '@components/Form/CreateSubtitleForm';
import Window from '@components/Window/Window';

const CreateSubtitleWindow = ({ subtitles = [], onSubmit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Add subtitles')} open={open} onClose={onClose}>
      <CreateSubtitleForm subtitles={subtitles} onSubmit={onSubmit} isLoading={isLoading} />
    </Window>
  );
};

export default CreateSubtitleWindow;
