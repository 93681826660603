import { useTranslation } from 'react-i18next';

import AddMediaForm from '@components/Form/AddMediaForm';
import Window from '@components/Window/Window';

const AddMediaWindow = ({ onEdit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Add a new media')} open={open} onClose={onClose}>
      <AddMediaForm onSubmit={onEdit} isUpdating={isLoading} />
    </Window>
  );
};

export default AddMediaWindow;
