import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { timecodeToInputStr, positionToInputStr } from '@app/utils';
import Action from '@components/Common/Action';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import Input from '@components/FormElement/Inputs/Input';
import TimeInput from '@components/FormElement/Inputs/TimeInput';
import Switch from '@components/FormElement/Switch';
import SpanError from '@components/ProjectCards/SpanError';

import style from './style.module.css';

const getInputClass = (errors) => (errors && errors.length > 0 ? style.inputError : '');

const ProjectCardsRow = ({
  card = null,
  isCurrent,
  index,
  duration = '',
  maxTitleLength,
  maxDescriptionLength,
  onInsertAfter = () => {},
  onEditCard = () => {},
  onRemoveCard = () => {},
  onFocus = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <tr className={style.cardRow} onFocus={() => onFocus(card)}>
      <td className={classNames(style.index, { [style.selectedIndex]: isCurrent })}>{index}</td>
      <td className={style.diplayedInTimeline}>
        <Switch value={card?.displayedInTimeline} onEdit={(displayedInTimeline) => onEditCard(card.id, { displayedInTimeline })} />
      </td>
      <td>
        <span className={style.inputContainer}>
          <div className={style.fixedHeight}>
            <Input
              placeholder={t('Title')}
              value={card?.title}
              onEdit={(title) => onEditCard(card.id, { title })}
              name="title"
              maxLength={maxTitleLength}
              className={classNames({ [style.editInput]: card }, getInputClass(card?.errors?.title))}
            />
          </div>
          <SpanError errors={card?.errors?.title} />
        </span>
      </td>
      <td>
        <span className={style.inputContainer}>
          <div className={style.fixedHeight}>
            <Input
              placeholder={t('Description')}
              value={card?.description}
              onEdit={(description) => onEditCard(card.id, { description })}
              name="description"
              maxLength={maxDescriptionLength}
              className={classNames({ [style.editInput]: card }, getInputClass(card?.errors?.description))}
            />
          </div>
          <SpanError errors={card?.errors?.description} />
        </span>
      </td>
      <td>
        <span className={style.inputContainer}>
          <div className={style.fixedHeight}>
            <TimeInput
              type="TIME"
              seconds
              value={positionToInputStr(card?.position)}
              min="00:00:00"
              max={timecodeToInputStr(duration)}
              onEdit={(position) => onEditCard(card.id, { position: moment.duration(position).asSeconds() })}
              name="position"
              className={classNames({ [style.editInput]: card }, getInputClass(card?.errors?.position))}
              step={1}
            />
          </div>
          <SpanError errors={card?.errors?.position} />
        </span>
      </td>
      <td>
        <div className={classNames(style.fixedHeight, style.actions)}>
          <Action className={style.action} action={() => onRemoveCard(card.id)}>
            <SkeepersIcon type="TRASH" color="var(--layout-primary-color)" title={t('Delete')} />
          </Action>
          <Action className={style.action} action={() => onInsertAfter(card)}>
            <SkeepersIcon type="ADD" color="var(--layout-primary-color)" title={t('Insert after')} />
          </Action>
        </div>
      </td>
    </tr>
  );
};

ProjectCardsRow.propTypes = {
  card: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    position: PropTypes.number,
    language: PropTypes.string,
  }),
  isCurrent: PropTypes.bool,
  index: PropTypes.number,
  duration: PropTypes.number,
  maxTitleLength: PropTypes.number,
  maxDescriptionLength: PropTypes.number,
  onInsertAfter: PropTypes.func,
  onEditCard: PropTypes.func,
  onRemoveCard: PropTypes.func,
  onFocus: PropTypes.func,
};

export default ProjectCardsRow;
