import { useTranslation } from 'react-i18next';

import { CanvasPrevisualisation } from '@components/Common/CanvasPrevisualisation';
import Flex from '@components/Common/Flex';
import { TabList, TabListItem, TabPanel, Tabs } from '@components/Common/Tabs';
import Form from '@components/FormElement/Form/Form';

import style from './style.module.css';

const TemplateTexts = ({ texts = [], name }) => {
  const { t } = useTranslation();
  if (texts.length === 0) {
    return null;
  }

  return (
    <div className={style.textInputContainer}>
      {texts.map((text, idx) => {
        const _name = `customDesign.${name}.${idx}`;
        return (
          <Form.Label title={t('{{name}} text {{idx}}', { name, idx: idx + 1 })} name={_name} key={_name}>
            <Form.Input name={_name} defaultValue={text.value} />
          </Form.Label>
        );
      })}
    </div>
  );
};

const CustomDesign = ({ defaultTab, template, config }) => {
  const { t } = useTranslation();

  return (
    <Flex flexDirection="COLUMN" margin="NONE" justifyContent="CENTER" alignItems="UNSET" className={style.subtitleContainer}>
      <div className={style.panelInformationText}>
        {t(
          'The custom design applies to texts that are not fixed in the pre-saved template. For any change in the template, please contact the Skeepers team.'
        )}
      </div>
      <Tabs defaultTab={defaultTab}>
        <TabList>
          {template?.customDesign?.layout && <TabListItem name="layout" title={t('Layout')} />}
          {template?.customDesign?.intro && <TabListItem name="intro" title={t('Intro')} />}
          {template?.customDesign?.outro && <TabListItem name="outro" title={t('Outro')} />}
        </TabList>
        <TabPanel name="layout">
          <Flex margin="NONE" justifyContent="CENTER" alignItems="CENTER" flexDirection="COLUMN">
            <div className={style.infoCustomText}>{t('This layout is applied over the video')}</div>
            <CanvasPrevisualisation template={template} layout={template?.customDesign?.layout} texts={config?.customDesign?.layout} />
          </Flex>
          <TemplateTexts texts={template?.customDesign?.layout?.texts} name="layout" />
        </TabPanel>
        <TabPanel name="intro">
          <Flex margin="NONE" justifyContent="CENTER" alignItems="CENTER" flexDirection="COLUMN">
            <div className={style.infoCustomText}>
              {t('The intro is applied over the layout during the first {{count}} second of video', {
                count: config?.customDesign?.intro?.duration,
              })}
            </div>
            <CanvasPrevisualisation template={template} layout={template?.customDesign?.intro} texts={config?.customDesign?.intro} />
          </Flex>
          <TemplateTexts texts={template?.customDesign?.intro?.texts} name="intro" />
        </TabPanel>
        <TabPanel name="outro">
          <Flex margin="NONE" justifyContent="CENTER" alignItems="CENTER" flexDirection="COLUMN">
            <div className={style.infoCustomText}>
              {t('The outro is applied over the layout during the last {{count}} second of video', { count: config?.customDesign?.outro?.duration })}
            </div>
            <CanvasPrevisualisation template={template} layout={template?.customDesign?.outro} texts={config?.customDesign?.outro} />
          </Flex>
          <TemplateTexts texts={template?.customDesign?.outro?.texts} name="outro" />
        </TabPanel>
      </Tabs>
    </Flex>
  );
};

export default CustomDesign;
