import renderer from '@skeepers/custom-design-renderer/lib';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { calcHeight, calcWidth } from '@components/CustomDesignEditor/utils';

import style from './style.module.css';

//Change de value of obj in texts array with the text set by the input
const updateTextLayout = (layout, texts) => ({
  ...layout,
  texts:
    layout?.texts.map((text, index) => ({
      ...text,
      value: texts[index] || text.value,
    })) || [],
});

export const CanvasPrevisualisation = ({ template = {}, layout = {}, texts = [] }) => {
  const [canvasRef, setCanvasRef] = useState(null);
  const canvasWidth = calcWidth(template.ratio, template.resolution);
  const canvasHeight = calcHeight(template.resolution);
  const layoutTextsUpdated = updateTextLayout(layout, texts);

  useEffect(() => {
    if (canvasRef) {
      renderer(canvasRef, layoutTextsUpdated);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [canvasRef, ...layoutTextsUpdated?.texts]);

  return (
    <div className={style.canvasContainer}>
      <canvas ref={(ref) => setCanvasRef(ref)} className={style.canvas} width={`${canvasWidth}px`} height={`${canvasHeight}px`} />
    </div>
  );
};

export default CanvasPrevisualisation;

CanvasPrevisualisation.propTypes = {
  template: PropTypes.object,
  layout: PropTypes.object,
  texts: PropTypes.array,
};
