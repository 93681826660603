import { useEffect } from 'react';

const loadTwitterSdk = () =>
  new Promise((resolve) => {
    if (!document.getElementById('twitter-jssdk')) {
      const script = document.createElement('script');
      script.src = 'https://platform.twitter.com/widgets.js';
      script.id = 'twitter-jssdk';
      document.body.appendChild(script);
      script.onload = resolve;
    } else {
      resolve();
    }
  });

const TwitterPlayer = ({ embed }) => {
  useEffect(() => {
    loadTwitterSdk();
    if (window.twttr) {
      window.twttr.widgets.load();
    }
  });

  return <div dangerouslySetInnerHTML={{ __html: embed }} />;
};

export default TwitterPlayer;
