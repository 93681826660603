import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';

import { allRoutes } from '@app/routes';
import { loadExternalModules, identifyStonlyUser } from '@app/utils/externalModules';
import AppContainer from '@components/Layout/AppContainer';
import { Page404 } from '@components/Layout/PageError';
import WelcomePage from '@components/Layout/WelcomePage';
import { NotificationModalProvider } from '@contexts/NotificationModalContext';
import { WindowProvider } from '@contexts/WindowContext';
import { useGetConnectedUser } from '@hooks/auth';
import Layout from '@views/Layout/Layout';
import Changelog from '@views/Other/Changelog';
import './app.css';


const App = () => {
  const { connectedUser, loading } = useGetConnectedUser();
  
  loadExternalModules();
  if (connectedUser) {
    identifyStonlyUser(connectedUser.id);
  }

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Layout connectedUser={connectedUser} />}>
        {allRoutes.map(({ path, url = path(), Component }) => {
          return <Route exact key={url} path={url} element={<Component />} />;
        })}
        <Route key="404" path="*" element={<Page404 />} />
      </Route>
    )
  );

  return (
    <HelmetProvider>
      <Helmet>
        <title>Skeepers Backoffice</title>
      </Helmet>
      
        <AppContainer>
          {!loading && (
            <WindowProvider>
              <NotificationModalProvider>
                {connectedUser ? (
                  <>
                    <RouterProvider router={router} />
                    <Changelog connectedUser={connectedUser} />
                  </>
                ) : (
                  <WelcomePage />
                )}
              </NotificationModalProvider>
            </WindowProvider>
          )}
        </AppContainer>
    </HelmetProvider>
  );
};

export default App;
