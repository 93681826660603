import { useQuery, useMutation } from '@apollo/client';

import ROUTES from '@app/routes';
import GET_COMPANY_MENU from '@graphql/companies/getCompaniesMenu.graphql';
import GET_COMPANY_PROJECT_DATA from '@graphql/companies/getCompanyProjectData.graphql';
import CREATE_SOLUTION_ROUTE from '@graphql/solutions/createRoute.graphql';
import CREATE_SOLUTION from '@graphql/solutions/createSolution.graphql';
import DELETE_SOLUTION_ROUTE from '@graphql/solutions/deleteRoute.graphql';
import DELETE_SOLUTION from '@graphql/solutions/deleteSolution.graphql';
import GET_ASSOCIATED_SOLUTION from '@graphql/solutions/getAssociatedSolution.graphql';
import GET_DEFAULT_PLAYER_ID from '@graphql/solutions/getDefaultPlayerId.graphql';
import GET_SOLUTION from '@graphql/solutions/getSolution.graphql';
import GET_SOLUTION_AUDIT_TASKS from '@graphql/solutions/getSolutionAuditTasks.graphql';
import GET_SOLUTIONS_FOR_SMART_UPLOADER from '@graphql/solutions/getSolutionsForSmartUploader.graphql';
import GET_TRACKING from '@graphql/solutions/getTracking.graphql';
import UPDATE_SOLUTION_ROUTE from '@graphql/solutions/updateRoute.graphql';
import UPDATE_SOLUTION from '@graphql/solutions/updateSolution.graphql';
import { useGetCompany } from '@hooks/companies';
import { parseValues, useGetTemplates } from '@hooks/configuration';

export const PLATFORM_TYPE = 'PLATFORM_TYPE';
export const PLATFORM_BASIC = 'PLATFORM_BASIC';
export const CASTING = 'CASTING';
export const PLATFORM_CHALLENGE = 'PLATFORM_CHALLENGE';
export const PLATFORM_BOOK = 'PLATFORM_BOOK';
export const PLATFORM_PRODUCT = 'PLATFORM_PRODUCT';
export const LANGUAGE = 'LANGUAGE';

const parseSolution = (solution) => {
  if (!solution || !solution.configuration) {
    return solution;
  }

  /**
   * The value and defaultValue returned by the backend are always strings.
   * The value and defaultValue of ADVANCED_LIST and LIST are stringified json and need to parsed
   */

  return {
    ...solution,
    configuration: {
      ...solution.configuration,
      values: parseValues(solution?.configuration?.values),
    },
  };
};

export const useGetSolution = (id, { skip, fetchPolicy = 'cache-and-network' } = {}) => {
  const { loading, error, data } = useQuery(GET_SOLUTION, {
    variables: { id },
    skip: !id || skip,
    fetchPolicy,
  });
  return { solution: parseSolution(data?.getSolution), loading, error };
};

export const useGetAssociatedSolutions = (companyId, solutionId) => {
  const { loading, error, data } = useQuery(GET_ASSOCIATED_SOLUTION, {
    fetchPolicy: 'cache-and-network',
    variables: { companyId, solutionId },
    skip: !companyId && !solutionId,
  });
  return {
    castingId: data?.getAssociatedSolution?.castingId,
    platformIds: data?.getAssociatedSolution?.platformIds,
    loading,
    error,
  };
};

export const useGetSolutionAuditTasks = (id) => {
  const { data, loading, error } = useQuery(GET_SOLUTION_AUDIT_TASKS, { variables: { id } });
  return { solution: data?.getSolution, loading, error };
};

export const useGetTracking = (id) => {
  const { loading, error, data } = useQuery(GET_TRACKING, { variables: { id } });
  return { tracking: parseSolution(data?.getSolution), loading, error };
};

export const useGetCastings = (companyId) => {
  const { company, loading, error } = useGetCompany(companyId);

  return {
    castings: company?.solutions.filter(({ type }) => type === 'CASTING'),
    loading,
    error,
  };
};

const useGetSolutionCreationProps = (companyId) => {
  const { castings, loading: castingsLoading, error: castingsError } = useGetCastings(companyId);
  const { templates, loading: templateLoading, error: templateError } = useGetTemplates();

  return {
    templates,
    castings,
    loading: castingsLoading || templateLoading,
    error: castingsError || templateError,
  };
};

export const routingMap = {
  CASTING: 'casting',
  PLATFORM_BOOK: 'platform',
  PLATFORM_BASIC: 'platform',
  PLATFORM_CHALLENGE: 'platform',
  PLATFORM_PRODUCT: 'platform',
  PLAYER: 'player',
  TRACKING: 'tracking',
  VSG: 'vsg',
};

export const useCreateSolution = (companyId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const creationProps = useGetSolutionCreationProps(companyId);

  const [mutate, { data, error, loading }] = useMutation(CREATE_SOLUTION, {
    onCompleted: ({ createSolution: data }) => {
      const solutionRoute =
        data.type === 'TRACKING'
          ? ROUTES.TRACKING_OVERVIEW.path({ companyId, solutionId: data.id })
          : data.type === 'SMART_UPLOADER'
          ? ROUTES.SMART_UPLOADER_CONFIGURATION.path({ companyId, solutionId: data.id })
          : ROUTES.SOLUTION_CONFIGURATION.path({ companyId, solutionType: routingMap[data.type], solutionId: data.id });
      return onCompleted(solutionRoute);
    },
    onError,
    // Refetch GET_COMPANY_MENU to update solution menu
    refetchQueries: [{ query: GET_COMPANY_MENU, variables: { id: companyId } }],
  });

  const createSolution = ({ name, language, route, castingId, type }) => {
    return mutate({
      variables: {
        solution: { name },
        ...(castingId ? { castingId } : {}),
        ...(language ? { language } : {}),
        ...(route ? { route } : {}),
        companyId,
        type,
      },
    });
  };

  return [createSolution, creationProps, { data: data?.createSolution, isUpdating: loading, error }];
};

export const useUpdateSolution = (solutionId) => {
  const [mutate, { data, error, loading }] = useMutation(UPDATE_SOLUTION);

  const updateSolution = ({ name }) => {
    return mutate({ variables: { id: solutionId, solution: { name } } });
  };

  return [updateSolution, { data: data?.updateSolution, isUpdating: loading, error }];
};

export const useUpdateRoute = (solutionId, { onCompleted = () => {} }) => {
  const [mutate, { data, error, loading }] = useMutation(UPDATE_SOLUTION_ROUTE, {
    onCompleted,
  });

  const updateRoute = (routeId, { route }) => {
    return mutate({ variables: { routeId, route: { ...route, solutionId } } });
  };

  return [updateRoute, { data: data?.updateRoute, isUpdating: loading, error }];
};

export const useCreateRoute = (solutionId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [mutate, { data, error, loading }] = useMutation(CREATE_SOLUTION_ROUTE, {
    onCompleted,
    onError,
    // Refetch GET_SOLUTION to update the solution routes
    refetchQueries: [{ query: GET_SOLUTION, variables: { id: solutionId } }],
  });

  const createRouteSolution = ({ route }) => {
    return mutate({ variables: { route: { ...route, solutionId } } });
  };

  return [createRouteSolution, { data: data?.createRouteSolution, isUpdating: loading, error }];
};

export const useDeleteRoute = (solutionId, { onCompleted = () => {} }) => {
  const [mutate, { data, error, loading }] = useMutation(DELETE_SOLUTION_ROUTE, {
    onCompleted,
    // Refetch GET_SOLUTION to update the solution routes
    refetchQueries: [{ query: GET_SOLUTION, variables: { id: solutionId } }],
  });

  const deleteRoute = (routeId) => {
    return mutate({ variables: { routeId } });
  };

  return [deleteRoute, { data: data?.deleteRoute, isUpdating: loading, error }];
};

export const useDeleteSolution = (solutionId, companyId, { onCompleted = () => {}, onError = () => {} } = {}) => {
  const [mutate, { data, error, loading }] = useMutation(DELETE_SOLUTION, {
    onCompleted,
    onError,
    // Refetch GET_COMPANY_MENU to update solution menu
    refetchQueries: [{ query: GET_COMPANY_MENU, variables: { id: companyId } }],
  });

  const deleteSolution = () => {
    return mutate({ variables: { id: solutionId } });
  };

  return [deleteSolution, { data: data?.createRouteSolution, isUpdating: loading, error }];
};

export const useGetCompanyProjectData = (companyId) => {
  const { data, loading, error } = useQuery(GET_COMPANY_PROJECT_DATA, { variables: { companyId } });

  return {
    options: {
      qrcode: data?.getCompany?.qrcodeOption,
    },
    distributors: data?.getCompany?.distributors,
    qrcodeTemplates: data?.getCompany?.qrcodeTemplates,
    loading,
    error,
  };
};

export const useGetDefaultPlayerIdForSolution = (solutionId) => {
  const { data, loading, error } = useQuery(GET_DEFAULT_PLAYER_ID, { variables: { solutionId } });

  return {
    player: data?.getDefaultPlayerId,
    loading,
    error,
  };
};

export const useGetSolutionsForSmartUploader = (companyId, solutionId) => {
  const { data, loading, error } = useQuery(GET_SOLUTIONS_FOR_SMART_UPLOADER, {
    fetchPolicy: 'network-only',
    variables: {
      companyId,
      solutionId,
    },
    skip: !companyId || !solutionId,
  });

  return {
    solutions: data?.getSolutionsForSmartUploader,
    loading,
    error,
  };
};
