import { HOTJAR_ID } from '@app/config';

export const loadHotjar = () => {
  if (HOTJAR_ID) {
    ((h, o, t, j, a, r) => {
      h.hj =
        h.hj ||
        /* tslint:disable:only-arrow-functions */
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };
      h._hjSettings = { hjid: HOTJAR_ID, hjsv: 6 };
      a = o.getElementsByTagName('head')[0];
      r = o.createElement('script');
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, window.document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
  }
};
