import { useTranslation } from 'react-i18next';

import ChangelogForm from '@components/Form/ChangelogForm';
import Window from '@components/Window/Window';

const AddChangelogWindow = ({ onEdit = () => {}, isLoading = false, onClose = () => {}, open = false, changelog = {} }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Add a new changelog')} open={open} onClose={onClose}>
      <ChangelogForm onSubmit={onEdit} isUpdating={isLoading} changelog={changelog} />
    </Window>
  );
};

export default AddChangelogWindow;
