import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { SkpIcon } from '@skeepers/skeepers-ui-react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { forwardRef } from 'react';

import LabelAction from '@components/Common/LabelAction';

import style from './style.module.css';

const Label = forwardRef(({ title = '', name = '', info = '', actions = [], className = '', required = false, error = false, children }, ref) => {
  return (
    <div className={className} ref={ref}>
      <div className={style.labelContainer}>
        <label className={classNames(style.label, info ? style.info : null)} htmlFor={name} style={error ? { '--label-color': 'var(--color-error)' } : null}>
          <span>{title} {required && '*'}</span>
          {info && <SkpIcon skpTitle={info} className={style.infoIcon} icon={faInfoCircle}></SkpIcon>}
        </label>
        {actions && actions.length > 0 && (
          <div className={style.actions}>
            {actions.map(({ icon, name, onClick }) => (
              <LabelAction icon={icon} text={name} onClick={onClick} key={name} />
            ))}
          </div>
        )}
      </div>
      {children}
    </div>
  );
});

Label.displayName = 'Label';

Label.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.string,
      name: PropTypes.string,
      onClick: PropTypes.func,
    })
  ),
};

export default Label;
