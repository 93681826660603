import { makePlugins } from '@skeepers/i18n/i18next';
import i18n from 'i18next';
import moment from 'moment';
import { initReactI18next as react } from 'react-i18next';
import 'moment/locale/fr';

import { API_URL, CDN_CACHE_DELAY } from '@app/config';

const { backend, postProcessor, detector, initOptions } = makePlugins({
  url: API_URL,
  template: 'backoffice',
  cacheDuration: CDN_CACHE_DELAY,
});

export const init = () =>
  i18n
    .use(backend)
    .use(detector)
    .use(postProcessor)
    .use(react)
    .on('languageChanged', (lng) => {
      moment.locale(lng);
    })
    .init({
      ...initOptions,
      compatibilityJSON: 'v3', // Requires until i18next-scanners supports JSONv4: https://github.com/i18next/i18next-scanner/issues/228
      react: {
        useSuspense: false,
      },
    });

export const getLanguageCode = () => {
  return i18n.language || (typeof window !== 'undefined' && window.localStorage.i18nextLng) || 'en';
};

moment.locale(getLanguageCode());

export default i18n;
