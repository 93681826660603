import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { findSubtitleCodeByLang } from '@app/translations';
import ButtonContainer from '@components/Common/ButtonContainer';
import { Form, useForm } from '@components/FormElement/Form';

const CreateSubtitleForm = ({ subtitles = [], isLoading = false, onSubmit = () => { } }) => {
  const { t } = useTranslation();
  const methods = useForm();
  const { type } = methods.watch();

  const _createSubtitle = (formData) => {
    onSubmit({
      type: formData.type,
      language: findSubtitleCodeByLang(formData.language),
    });
  }

  return (
    <Form onSubmit={_createSubtitle} methods={methods} isUpdating={isLoading}>
      <Form.Label title={t('Language')} name="language">
        <Form.LanguageSelect name="language" filterLanguages={(language) => !subtitles.some((subtitle) => subtitle.language === language)} required />
      </Form.Label>
      <Form.Label title={t('Type')} name="type">
        <Form.SelectCard
          name="type"
          required
          options={[
            { title: t('Manual'), description: t('Create the subtitles from scratch'), value: 'MANUAL', icon: 'EDIT' },
            { title: t('Automatic'), description: t("Generated with OpenAI speech to text technology"), value: 'GENERATED', icon: 'AUDIO' },
            { title: t('Imported'), description: t('Import an existing subtitle file'), value: 'IMPORTED', icon: 'DOWNLOAD' },
          ]}
          defaultValue="MANUAL"
        />
      </Form.Label>
      {type === 'IMPORTED' && <Form.UploadInput required name="file" title={t('Subtitle file')} filenamesMode="HIDDEN" local />}
      <ButtonContainer position="CENTER">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default CreateSubtitleForm;

CreateSubtitleForm.propTypes = {
  isUpdating: PropTypes.bool,
  onSubmit: PropTypes.func,
};
