import PropTypes from 'prop-types';

import style from './style.module.css';

const SpanError = ({ errors = [] }) =>
  errors && errors.length > 0 ? (
    <span className={style.errorText}>
      {errors.map((error, i) => (
        <p key={i} className={style.rowError}>
          {error}
        </p>
      ))}
    </span>
  ) : null;

SpanError.propTypes = {
  errors: PropTypes.array,
};

export default SpanError;
