import { useFlag } from '@unleash/proxy-client-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { FEATURE_FLAG_NAMES } from '@app/utils/featureFlags';
import ButtonContainer from '@components/Common/ButtonContainer';
import { Grid } from '@components/Common/Grid';
import { Form, useForm } from '@components/FormElement/Form';

const ProductForm = ({ product = {}, categories = [], isUpdating = false, onSubmit = () => {} }) => {
  const { t } = useTranslation();
  const methods = useForm();
  const eanMandatory = useFlag(FEATURE_FLAG_NAMES.EAN_MANDATORY);

  const validate = {
    notEmpty: (text) => (text && text.trim().length > 0) || t('Value should not be empty.'),
  };

  return (
    <Form onSubmit={onSubmit} methods={methods}>
      <Grid>
        <Form.Label title={t('Name')} name="name">
          <Form.Input name="name" defaultValue={product.name} required validate={validate} />
        </Form.Label>
        <Grid columns={2}>
          <Form.UploadInput title={t('Image')} name="picture" defaultValue={product.picture} filenamesMode="HIDDEN" validTypes={['IMAGE']} required />
          <Form.Label title={t('Description')} name="description">
            <Form.Textarea name="description" defaultValue={product.description} required validate={validate} />
          </Form.Label>
        </Grid>
        <Grid columns={3}>
          <Form.Label title={t('Quantity')} name="quantity">
            <Form.NumberInput name="quantity" defaultValue={product.quantity} required min={0} />
          </Form.Label>
          <Form.Label title={t('Tag your categories')} name="categories">
            {/* For product.categories : if it's come from the API it will be an oject, we need to get the name, if it's from the import page it's a string */}
            <Form.TagsSelect
              backgroundColor="#da3698"
              foregroundColor="#f4f4f5"
              options={categories}
              name="categories"
              defaultValue={product.categories?.map((c) => (typeof c === 'object' ? c.name : c))}
            />
          </Form.Label>
          <Form.Label title={t('Tag other properties')} name="tags">
            <Form.TagsSelect backgroundColor="#9e36da" foregroundColor="#f4f4f5" name="tags" defaultValue={product.tags} />
          </Form.Label>
        </Grid>
        <Grid columns={3}>
          <Form.Label title={t('Product page URL')} name="pageUrl">
            <Form.UrlInput name="pageUrl" defaultValue={product.pageUrl} />
          </Form.Label>
          <Form.Label title={t('Product reference')} name="reference">
            <Form.Input name="reference" defaultValue={product.reference} />
          </Form.Label>
          <Form.Label title={t('Product EAN')} name="ean">
            <Form.Input name="ean" defaultValue={product.ean} required={eanMandatory} validate={eanMandatory ? validate : null} />
          </Form.Label>
        </Grid>
        <Form.Label title={t('Brief steps')} name="briefSteps">
          <Form.AdvancedList name="briefSteps" defaultValue={product.briefSteps} />
        </Form.Label>
        <Form.Label title={t('Brief PDF')} name="briefPdf">
          <Form.UploadInput name="briefPdf" defaultValue={product?.briefPdf} filenamesMode="HIDDEN" />
        </Form.Label>
        <Form.Label title={t('Archived status')} name="archived">
          <Form.Switch name="archived" defaultValue={product.archived} />
        </Form.Label>
      </Grid>
      <ButtonContainer position="RIGHT">
        <Form.SubmitButton disabled={!methods.formState.isDirty} isUpdating={isUpdating} />
      </ButtonContainer>
    </Form>
  );
};

export default ProductForm;

ProductForm.propTypes = {
  product: PropTypes.shape({
    name: PropTypes.string,
    tags: PropTypes.array,
    pageUrl: PropTypes.string,
    reference: PropTypes.string,
    picture: PropTypes.string,
    description: PropTypes.string,
    quantity: PropTypes.number,
    archived: PropTypes.bool,
    briefPdf: PropTypes.string,
    briefSteps: PropTypes.array,
  }),
  isUpdating: PropTypes.bool,
  onSubmit: PropTypes.func,
};
