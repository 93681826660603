import CreateQrCodeTemplateForm from '@components/Form/CreateQrCodeTemplateForm';
import Window from '@components/Window/Window';

const CreateQRCodeTemplateWindow = ({ template, existingTemplateNames = [], onSubmit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  return (
    <Window open={open} onClose={onClose} padding={false} maxWidth="1300px">
      <CreateQrCodeTemplateForm template={template} existingTemplateNames={existingTemplateNames} onSubmit={onSubmit} isUpdating={isLoading} />
    </Window>
  );
};

export default CreateQRCodeTemplateWindow;
