import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import Action from '@components/Common/Action';
import Block from '@components/Common/Block';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import CustomDesignEditor from '@components/CustomDesignEditor';
import { calcWidth, calcHeight } from '@components/CustomDesignEditor/utils';
import RangeInput from '@components/FormElement/RangeInput';
import UploadInput from '@components/FormElement/UploadInput';
import Window from '@components/Window/Window';

import Button from '../../Common/Button';
import ButtonContainer from '../../Common/ButtonContainer';

import style from './style.module.css';

// Prevent text box to be larger than the canvas after changes parameters
const getTextBox = (maxWidth, maxHeight, text) => {
  const x = text.box.x > maxWidth ? 0 : text.box.x;
  const y = text.box.y > maxHeight ? 0 : text.box.y;
  return {
    x,
    y,
    sizeX: text.box.sizeX + x > maxWidth ? maxWidth - x : text.box.sizeX,
    sizeY: text.box.sizeY + y > maxHeight ? maxHeight - y : text.box.sizeY,
  };
};

// Prevent videoBox to be larger than the canvas after changes parameters
const resetVideoBox = (ratio, resolution) => ({
  x: 0,
  y: 0,
  sizeX: calcWidth(ratio, resolution),
  sizeY: calcHeight(resolution),
  display: false,
});

const getTemplate = (value) => {
  const maxWidth = calcWidth(value.ratio, value.resolution);
  const maxHeight = calcHeight(value.resolution);
  const x = value.videoBox.x > maxWidth ? 0 : value.videoBox.x;
  const y = value.videoBox.y > maxHeight ? 0 : value.videoBox.y;
  return {
    ...value,
    videoBox: {
      x,
      y,
      sizeX: value.videoBox.sizeX + x > maxWidth ? maxWidth : value.videoBox.sizeX,
      sizeY: value.videoBox.chrsizeY + y > maxHeight ? maxHeight : value.videoBox.sizeY,
    },
    ...['layout', 'intro', 'outro'].reduce(
      (acc, key) => ({
        ...acc,
        [key]: value[key]
          ? {
              ...value[key],
              texts: value[key]?.texts?.map((text) => ({
                ...text,
                id: uuid(),
                box: {
                  ...text.box,
                  ...getTextBox(maxWidth, maxHeight, text),
                },
              })),
            }
          : null,
      }),
      {}
    ),
  };
};

const CustomDesignWindow = ({ onEdit = () => {}, onClose = () => {}, open = false, value }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState('layout');
  const [template, setTemplate] = useState(getTemplate(value));
  const [strTemplate, setStrTemplate] = useState(JSON.stringify(getTemplate(value)));

  // Set background path to the selected mode (layout, intro or outro)
  const onUploadDesign = (file) => {
    // Update the string displayed in the debug input
    setStrTemplate((previous) => {
      const previousParsed = JSON.parse(previous);
      return JSON.stringify({
        ...previousParsed,
        [mode]: { ...previousParsed[mode], path: file },
      });
    });

    // Update the template
    setTemplate((previous) => ({
      ...previous,
      [mode]: { ...previous[mode], path: file },
    }));
  };

  // Remove the background path
  const deleteBackground = () => {
    // Update the string displayed in the debug input
    setStrTemplate((previous) => {
      const previousParsed = JSON.parse(previous);
      return JSON.stringify({
        ...previousParsed,
        [mode]: { ...previousParsed[mode], path: null },
        videoBox: resetVideoBox(value.ratio, value.resolution),
      });
    });

    // Update the template
    setTemplate((previous) => ({
      ...previous,
      [mode]: { ...previous[mode], path: null },
      videoBox: resetVideoBox(value.ratio, value.resolution),
    }));
  };

  const onEditTemplate = (value) => {
    // Update the string displayed in the debug input
    setStrTemplate(value);

    // If the input is a valid json, update the template
    try {
      const parsedTemplate = JSON.parse(value);
      setTemplate(parsedTemplate);
    } catch (e) {
      console.error(e);
    }
  };

  const onEditorChange = (value) => {
    // Extract videoBox
    const { videoBox, ...newTemplate } = value;

    // If the configuration is empty, set [layout, intro, outro] to null
    const newTemplateIsNull = JSON.stringify(newTemplate) === '{}' || (!newTemplate.path && newTemplate?.texts?.length === 0);

    // Update the string displayed in the debug input
    setStrTemplate((oldTemplate) => {
      const oldTemplateParsed = JSON.parse(oldTemplate);
      return JSON.stringify({
        ...oldTemplateParsed,
        videoBox: { ...oldTemplateParsed.videoBox, ...videoBox },
        [mode]: newTemplateIsNull
          ? null
          : {
              ...oldTemplateParsed[mode],
              ...newTemplate,
              ...(['intro', 'outro'].includes(mode) ? { duration: newTemplate.duration || 2, fadeDuration: newTemplate.fadeDuration || 0.5 } : {}),
            },
      });
    });

    // Update the template
    setTemplate((oldTemplate) => ({
      ...oldTemplate,
      videoBox: { ...oldTemplate.videoBox, ...videoBox },
      [mode]: newTemplateIsNull
        ? null
        : {
            ...oldTemplate[mode],
            ...newTemplate,
            ...(['intro', 'outro'].includes(mode) ? { duration: newTemplate.duration || 2, fadeDuration: newTemplate.fadeDuration || 0.5 } : {}),
          },
    }));
  };

  const onFadeDurationChange = (value) => {
    onEditTemplate(
      JSON.stringify({
        ...template,
        [mode]: {
          ...template[mode],
          fadeDuration: value,
        },
      })
    );
  };

  const onDurationChange = (value) => {
    onEditTemplate(
      JSON.stringify({
        ...template,
        [mode]: {
          ...template[mode],
          duration: value,
        },
      })
    );
  };

  return (
    <Window maxWidth="900px" title={t('Custom design editor')} open={open} onClose={onClose}>
      <div className={style.selector}>
        {['layout', 'intro', 'outro'].map((key) => (
          <div key={key} className={classNames(...[style.selectorItem, ...(mode === key ? [style.selected] : [])])} onClick={() => setMode(key)}>
            {key.charAt(0).toUpperCase() + key.substr(1)}
          </div>
        ))}
      </div>
      {['intro', 'outro'].includes(mode) && template?.[mode] && (
        <>
          <Block title={t('Duration')} subtitle={t('You can change the duration of the {{mode}} here', { mode })} boxShadow={false} padding="NONE">
            <RangeInput onEdit={onDurationChange} type="range" max={5} min={0} step={0.1} value={template?.[mode].duration} />
          </Block>
          <Block title={t('Fade')} subtitle={t('You can change the fade duration here')} boxShadow={false} padding="NONE">
            <RangeInput onEdit={onFadeDurationChange} type="range" max={5} min={0.5} step={0.1} value={template?.[mode].fadeDuration} />
          </Block>
        </>
      )}
      {mode === 'layout' && <div className={style.helper}>{t('The layout is applied over the video')}</div>}
      {['intro', 'outro'].includes(mode) && template?.[mode] && (
        <div className={style.helper}>
          {t('The {{mode}} will be visible over the layout during the first {{duration}} seconds of video and will fade in {{fadeDuration}}', {
            mode,
            duration: Math.round((template?.[mode]?.duration + template?.[mode]?.fadeDuration) * 100) / 100 || 2,
            fadeDuration: template?.[mode]?.fadeDuration || '0.5',
          })}
        </div>
      )}
      {['intro', 'outro'].includes(mode) && !template?.[mode] && (
        <div className={style.helper}>
          {t('You can configure an {{mode}} here. It will be visible over the layout during the {{moment}} seconds of video', {
            mode,
            moment: mode === 'intro' ? 'first' : 'last',
          })}
        </div>
      )}
      <textarea
        style={{ minWidth: '100%', maxWidth: '100%', display: 'none' }}
        name="template.json"
        type="text"
        placeholder="template.json"
        value={strTemplate}
        onChange={(e) => onEditTemplate(e.target.value)}
      />
      <UploadInput onEdit={(file) => file && onUploadDesign(file)} filenamesMode="HIDDEN" validTypes={['IMAGE']} />
      {template?.[mode]?.path && (
        <div className={style.resizerLogosContainer}>
          <Action key={template[mode].path} className={style.resizerLogoContainer} action={() => deleteBackground()}>
            <img src={template[mode].path} className={style.resizerLogoPreview} alt={t('Logo')} />
            <div className={style.action}>
              <SkeepersIcon type="TRASH" color="var(--layout-primary-color)" title={t('Delete')} />
            </div>
          </Action>
        </div>
      )}
      <CustomDesignEditor
        template={template[mode]}
        overlay={mode !== 'layout' ? template?.layout : null}
        videoBox={template.videoBox}
        ratio={template.ratio}
        resolution={template.resolution}
        onEdit={onEditorChange}
        allowVideoBoxEdit={mode === 'layout'}
      />
      <ButtonContainer position="CENTER">
        <Button label={t('Cancel')} loading={isLoading} disabled={isLoading} theme={Button.themes.SECONDARY} action={onClose} className={style.marginButton} />
        <Button
          label={t('Confirm')}
          loading={isLoading}
          disabled={isLoading}
          action={async () => {
            try {
              setIsLoading(true);
              await onEdit(template);
              onClose();
            } catch (err) {}
          }}
        />
      </ButtonContainer>
    </Window>
  );
};

export default CustomDesignWindow;

CustomDesignWindow.propTypes = {
  onEdit: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  value: PropTypes.shape({
    path: PropTypes.string,
    ratio: PropTypes.string,
    texts: PropTypes.arrayOf(
      PropTypes.shape({
        font: PropTypes.string,
        size: PropTypes.number,
        align: PropTypes.oneOf(['left', 'center', 'right', 'end', 'start']),
        color: PropTypes.string,
        italic: PropTypes.bool,
        underline: PropTypes.bool,
        strikethrough: PropTypes.bool,
        value: PropTypes.string,
        box: PropTypes.shape({
          x: PropTypes.number,
          y: PropTypes.number,
          sizeX: PropTypes.number,
          sizeY: PropTypes.number,
        }),
      })
    ),
    videoBox: PropTypes.shape({
      x: PropTypes.number,
      y: PropTypes.number,
      sizeX: PropTypes.number,
      sizeY: PropTypes.number,
    }),
  }),
  ratio: PropTypes.string,
};
