import CreateVideoSpreadForm from '@components/Form/CreateVideoSpreadForm';

import Window from '../Window';

const CreateVideoSpreadWindow = ({ onClose = () => {}, open = false, template = {}, output = {}, onSubmit = () => {} }) => {
  return (
    <Window open={open} onClose={onClose} padding={false} maxWidth="1100px">
      <CreateVideoSpreadForm template={template} output={output} onSubmit={onSubmit} />
    </Window>
  );
};
export default CreateVideoSpreadWindow;
