import { useTranslation } from 'react-i18next';

import RouteForm from '@components/Form/RouteForm';
import Window from '@components/Window/Window';

const EditSolutionRouteWindow = ({ route = {}, onSubmit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Edit a route')} open={open} onClose={onClose}>
      <RouteForm route={route} isUpdating={isLoading} onSubmit={onSubmit} />
    </Window>
  );
};

export default EditSolutionRouteWindow;
