import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { findLangBySubtitleCode } from '@app/translations';
import Flex from '@components/Common/Flex';
import Hint from '@components/Common/Hint';
import SkeepersFlag from '@components/Common/SkeepersFlag';
import Form from '@components/FormElement/Form/Form';

import style from './style.module.css';

const Subtitle = ({ languages, output }) => {
  const { t } = useTranslation();
  const availableSubtitles = output.subtitles
    .filter(({ status }) => status === 'READY')
    .map(({ language, id }) => {
      const lang = findLangBySubtitleCode(languages, language);
      return {
        label: lang?.name,
        value: id,
        icon: <SkeepersFlag country={lang?.country} />,
      };
    });

  return (
    <Flex flexDirection="COLUMN" margin="NONE" justifyContent="CENTER" alignItems="UNSET" className={style.subtitleContainer}>
      <div className={style.panelInformationText}>
        {t('The subtitles will be overlaid on the video when it is generated. Subtitles will be located at the bottom of the video.')}
      </div>
      {output.subtitles.some((subtitle) => subtitle.status === 'READY') ? (
        <Form.Label title={t('Subtitles')} name="subtitleId">
          <Form.Select
            name="subtitleId"
            defaultValue="NONE"
            options={[
              { label: t('No subtitle'), value: 'NONE' },
              ...availableSubtitles,
            ]}
          />
        </Form.Label>
      ) : (
        <Hint message={t('No subtitles published')} className={style.hint} />
      )}
    </Flex>
  );
};

Subtitle.propTypes = {
  languages: PropTypes.object.isRequired,
  output: {
    subtitles: PropTypes.arrayOf(
      PropTypes.shape({
        status: PropTypes.string,
        language: PropTypes.string,
        id: PropTypes.string,
      })
    ),
  }
};

export default Subtitle;
