import { useTranslation } from 'react-i18next';

import CreateSolutionForm from '@components/Form/CreateSolutionForm';
import Window from '@components/Window/Window';

const titles = (t) => ({
  CASTING: t('Create a new casting'),
  PLATFORM: t('Create a new platform'),
  TRACKING: t('Create a new tracking'),
  VSG: t('Create a new VSG'),
  PLAYER: t('Create a new player'),
  SOLUTION: t('Create a new solution'),
  SMART_UPLOADER: t('Create a new smart uploader'),
});

const CreateSolutionWindow = ({ onEdit = () => {}, castings = [], templates = [], isLoading = false, onClose = () => {}, type = '', open = false }) => {
  const { t } = useTranslation();
  const title = titles(t)[type || 'SOLUTION'];

  return (
    <Window title={title} open={open} onClose={onClose}>
      <CreateSolutionForm castings={castings} templates={templates} solutionType={type} isUpdating={isLoading} onSubmit={onEdit} />
    </Window>
  );
};
export default CreateSolutionWindow;
