import classNames from 'classnames';
import PropTypes from 'prop-types';
import { memo } from 'react';

import { getPictureLink } from '@app/imageResizer';

import style from './style.module.css';

const sizes = {
  xsmall: {
    pictureResolution: 64,
    pictureHeightAndWidth: '2rem',
  },
  small: {
    pictureResolution: 64,
    pictureHeightAndWidth: '2.4rem',
  },
  medium: {
    pictureResolution: 80,
    pictureHeightAndWidth: '3.5rem',
  },
  large: {
    pictureResolution: 80,
    pictureHeightAndWidth: '4rem',
  },
  xlarge: {
    pictureResolution: 120,
    pictureHeightAndWidth: '7rem',
  },
  xxlarge: {
    pictureResolution: 120,
    pictureHeightAndWidth: '7.5rem',
  },
};

const Avatar = ({ url, size = 'small', title, feeling, displayFeeling = true, onClick, className }) => (
  <div
    className={classNames(className, style.avatar, {
      [style.avatarGood]: displayFeeling && feeling === 'GOOD',
      [style.avatarBad]: displayFeeling && feeling === 'BAD',
      [style.avatarSimple]: displayFeeling && feeling === null,
    })}
    style={
      url
        ? {
            backgroundImage: `url("${getPictureLink(url, { w: sizes[size].pictureResolution, h: sizes[size].pictureResolution })}")`,
            height: sizes[size].pictureHeightAndWidth,
            width: sizes[size].pictureHeightAndWidth,
            minWidth: sizes[size].pictureHeightAndWidth,
          }
        : {}
    }
    title={title}
    onClick={onClick}
  />
);

export default memo(Avatar);

Avatar.propTypes = {
  url: PropTypes.string,
  size: PropTypes.oneOf(Object.keys(sizes)),
  title: PropTypes.string,
  feeling: PropTypes.oneOf([null, 'GOOD', 'BAD']),
  displayFeeling: PropTypes.bool,
  onClick: PropTypes.func,
  classNames: PropTypes.string,
};
