import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonContainer from '@components/Common/ButtonContainer';
import { Form, useForm } from '@components/FormElement/Form';

const AddMediaForm = ({ onSubmit = () => {}, isUpdating = false }) => {
  const { t } = useTranslation();
  const methods = useForm();

  return (
    <Form methods={methods} onSubmit={onSubmit} isUpdating={isUpdating}>
      <Form.UploadInput title={t('Media')} name="url" type="VIDEO" filenamesMode="HIDDEN" required validTypes={['VIDEO']} />
      <ButtonContainer position="CENTER">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default AddMediaForm;

AddMediaForm.propTypes = {
  onSubmit: PropTypes.func,
  isUpdating: PropTypes.bool,
};
