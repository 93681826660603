import moment from 'moment';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getAuditQualities } from '@app/translations';
import { deleteTypename } from '@app/utils';
import Action from '@components/Common/Action';
import Button from '@components/Common/Button';
import ButtonContainer from '@components/Common/ButtonContainer';
import { CodeSnippet } from '@components/Common/CodeSnippet';
import DropdownBlock from '@components/Common/DropdownBlock';
import LineChart from '@components/Data/Charts/LineChart';
import NoDataMessage from '@components/Data/NoDataMessage';
import { DateRangePicker } from '@components/FormElement/DatePickers';
import AuditGraphTooltip from '@components/Integration/AuditGraphTooltip';
import Window from '@components/Window/Window';
import { useGetConnectedUser } from '@hooks/auth';
import useDropdown from '@hooks/useDropdown';

import style from './style.module.css';

const defaultCodeSnippetKey = 'defaultCodeSnippetKey';

const getLineColor = (audits, auditQualities) => {
  const colorsData = audits
    .map((audit) => ({
      color: auditQualities[audit.quality || 'NONE'].color,
      timestamp: audit.finishedAt,
      percent: (audit.finishedAt - audits[0].finishedAt) / (audits[audits.length - 1].finishedAt - audits[0].finishedAt),
    }))
    .filter(
      (colorData, i, colorsData) =>
        i === 0 || i === colorsData.length - 1 || !(colorsData[i - 1].color === colorsData[i].color && colorsData[i].color === colorsData[i + 1].color)
    );

  if (colorsData.length === 0) {
    return auditQualities.NONE.color;
  }

  if (colorsData.length === 1) {
    return colorsData[0].color;
  }

  if (colorsData.every((colorData) => colorData.color === colorsData[0].color)) {
    return colorsData[0].color;
  }

  return `l(0) 0:${colorsData[0].color}${colorsData.slice(1, -1).reduce((acc, colorData, i) => {
    return `${acc} ${colorData.percent}:${colorsData[i].color} ${colorData.percent}:${colorData.color}`;
  }, '')} 1:${colorsData[colorsData.length - 1].color}`;
};

const getTicks = (audits) => {
  const ticks = [];
  if (audits.length) {
    let tick = new Date(audits[0].finishedAt * 1000).setHours(0, 0, 0, 0);
    while (tick < audits[audits.length - 1].finishedAt * 1000) {
      ticks.push(tick);
      tick += 24 * 60 * 60 * 1000;
    }
    ticks.push(tick);
  }
  return ticks;
};

const AuditTaskOverviewWindow = ({ task = {}, solutionId, title, onTaskRun = () => {}, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();
  const titleToUse = title ?? t('Audit task');
  const auditQualities = getAuditQualities(t);
  const dropdownHandler = useDropdown();
  const [loadingRun, setLoadingRun] = useState(false);
  const detailRef = useRef();

  const { connectedUser } = useGetConnectedUser();
  const permissions = connectedUser.role?.actions || {};

  const [selectedData, setSelectedData] = useState(
    task.audits.length > 0
      ? {
          range: {
            from: new Date(new Date(task.audits[0].finishedAt * 1000).setHours(12, 0, 0, 0)),
            to: new Date(new Date(task.audits[task.audits.length - 1].finishedAt * 1000).setHours(12, 0, 0, 0)),
          },
          indexPoint: null,
        }
      : {}
  );

  const displayedAudits = task.audits.filter((audit) => {
    const normalizedAuditDate = new Date(audit.finishedAt * 1000).setHours(12, 0, 0, 0);
    return audit.finishedAt && normalizedAuditDate >= selectedData.range.from.getTime() && normalizedAuditDate <= selectedData.range.to.getTime();
  });

  const displayedIndex = selectedData.indexPoint !== null ? selectedData.indexPoint : displayedAudits.length - 1;

  const [codeSnippetKey, setCodeSnippetKey] = useState(defaultCodeSnippetKey);

  const onDateChange = (r) =>
    setSelectedData(({ range: previousRange, indexPoint }) => ({
      indexPoint,
      range: {
        ...previousRange,
        ...r,
      },
    }));

  const color = getLineColor(displayedAudits, auditQualities);
  const ticks = getTicks(displayedAudits);

  return (
    <Window title={titleToUse} open={open} onClose={onClose}>
      {displayedAudits.length ? (
        <>
          <DropdownBlock title={t('Uptime')} open={dropdownHandler('uptime').opened} toggle={dropdownHandler('uptime').toggle}>
            <div className={style.datePickerWrapper}>
              <DateRangePicker
                value={selectedData.range}
                allowedRange={{ from: new Date(task.audits[0].finishedAt * 1000), to: new Date() }}
                onChange={onDateChange}
              />
            </div>
            <LineChart
              axis={displayedAudits.map((audit, index) => ({
                value: auditQualities[audit.quality || 'NONE'].values[task.type] || auditQualities[audit.quality || 'NONE'].defaultValue,
                time: audit.finishedAt * 1000,
                detail: { ...audit, index },
              }))}
              filter={'daily'}
              label={t('Status')}
              title={t('Evolution of quality')}
              customConfig={{
                stepType: 'hv',
                meta: {
                  value: {
                    values: Object.keys(auditQualities).map((quality) => auditQualities[quality].values[task.type] || auditQualities[quality].defaultValue),
                  },
                  time: {
                    ticks,
                    type: 'time',
                    mask: moment.localeData().longDateFormat('L'),
                  },
                },
                color: '#5B8FF9',
                lineStyle: {
                  stroke: color,
                },
                point: {
                  size: 3,
                  shape: 'circle',
                  style: {
                    fill: 'white',
                    stroke: '#5B8FF9',
                    lineWidth: 1,
                  },
                },
                onReady: (plot) => {
                  plot.on('plot:click', (evt) => {
                    const { x, y } = evt;
                    const index = plot.chart.getTooltipItems({ x, y })?.[0]?.data?.detail?.index;
                    if (index >= 0) {
                      setSelectedData((previousSelectedData) => ({ ...previousSelectedData, indexPoint: index }));
                      setCodeSnippetKey((previousKey) => (previousKey === index ? `${index}_2` : index));
                      detailRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
                    }
                  });
                },
                tooltip: {
                  customContent: (title, [item]) => {
                    if (!item) {
                      return null;
                    }
                    const {
                      value,
                      data: { detail: audit },
                    } = item;

                    return <AuditGraphTooltip value={value} task={task} audit={audit} solutionId={solutionId} />;
                  },
                },
              }}
            />
          </DropdownBlock>

          {displayedAudits[displayedIndex].screenshotUrl && (
            <DropdownBlock
              title={`${t('Screenshot')} (${moment(displayedAudits[displayedIndex].finishedAt * 1000).format('LLL')})`}
              open={dropdownHandler('screenshot').opened}
              toggle={dropdownHandler('screenshot').toggle}
            >
              <Action action={displayedAudits[displayedIndex].screenshotUrl}>
                <img alt={t('Screenshot')} src={displayedAudits[displayedIndex].screenshotUrl} className={style.screenshot} />
              </Action>
            </DropdownBlock>
          )}
          <div ref={detailRef}>
            <CodeSnippet
              key={codeSnippetKey}
              defaultOpen={codeSnippetKey !== defaultCodeSnippetKey}
              title={`${t('Details')} (${moment(displayedAudits[displayedIndex].finishedAt * 1000).format('LLL')})`}
              content={JSON.stringify(deleteTypename({ taskId: task.id, ...displayedAudits[displayedIndex] }), null, 2)}
              language={'json'}
            />
          </div>
        </>
      ) : (
        <NoDataMessage message={t('There is no related audit')} />
      )}

      {permissions['auditTask.run'] && (
        <ButtonContainer position="CENTER">
          <Button
            label={t('Run task now')}
            action={() => {
              setLoadingRun(true);
              return onTaskRun();
            }}
            loading={loadingRun}
          />
        </ButtonContainer>
      )}
    </Window>
  );
};

export default AuditTaskOverviewWindow;
