import { useTranslation } from 'react-i18next';

import Action from '@components/Common/Action';
import { MultilineText } from '@components/Common/MultilineText';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import Label from '@components/FormElement/Label';
import Window from '@components/Window/Window';

import style from './style.module.css';

const EditingBriefWindow = ({ onClose = () => {}, open = false, editingBrief }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Editing brief')} open={open} onClose={onClose}>
      {editingBrief ? (
        <div className={style.brief}>
          {editingBrief.resume && editingBrief.resume.editing && (
            <Label title={t('Editing brief')} className={style.section}>
              <MultilineText content={editingBrief.resume.editing} />
            </Label>
          )}
          {editingBrief.maxDuration && (
            <Label title={t('Max duration')} className={style.section}>
              <span>{t('{{count}} second', { count: editingBrief.maxDuration })}</span>
            </Label>
          )}
          {editingBrief.sampleUrl && (
            <Action action={editingBrief.sampleUrl} target="_blank" className={style.seeSample}>
              <SkeepersIcon type="EYE" className={style.icon} />
              {t('See sample')}
            </Action>
          )}
        </div>
      ) : (
        <div className={style.noEditingBrief}>{t('No editing brief for this project.')}</div>
      )}
    </Window>
  );
};

export default EditingBriefWindow;
