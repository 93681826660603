import { sortBy } from 'lodash';

const addDownloadUrls = (url = '') => (url ? { downloadUrls: [{ url }] } : {});

export const formatOutput = (output) => ({
  ...output,
  defaultFrame: output?.assets?.firstFrame || '',
  ...addDownloadUrls(output?.url),
});

export const addOutputFilename =
  (t = (_) => _) =>
  (o) => ({ ...o, filename: t('Version #{{version}}', { version: o.version }) });

export const markPublishedVideo = (videos = []) => {
  if (!videos.length) {
    return videos;
  }

  const publishedVideo = videos.filter((v) => !!v.url).reduce((acc, v) => (acc.version > v.version ? acc : v), { version: 0 });
  return videos.map((v) => (v.id === publishedVideo.id ? { ...v, published: true } : v));
};

export const formatOutputs = (project) => {
  let videos = project?.outputs?.map(formatOutput) ?? [];
  if (project?.status === 'VALIDATED') {
    videos = markPublishedVideo(videos);
  }
  return sortBy(videos, 'version');
};

const addUrl = (url, type) => (url ? [{ type, url }] : []);

const formatInput = ({ originalUrl, normalizedUrl, normalizedPreviewUrl, ...v }) => ({
  originalUrl,
  normalizedUrl,
  normalizedPreviewUrl,
  ...v,
  downloadUrls: [...addUrl(normalizedUrl, 'NORMALIZED'), ...addUrl(originalUrl, 'ORIGINAL'), ...addUrl(normalizedPreviewUrl, 'PREVIEW')],
});

const formatInputDonwnloadLink = (videos = []) => videos.map(formatInput);

export const formatInputs = (inputs) => formatInputDonwnloadLink(inputs);

export const formatLogs = ({ logs = [] }, t) =>
  logs.map((l) => {
    if (l?.input) {
      return { ...l, input: formatInput(l.input) };
    }
    if (l?.output) {
      return { ...l, input: addOutputFilename(t)(formatOutput(l.output)) };
    }
    return l;
  });
