import { useTranslation } from 'react-i18next';

import CreateVideoTemplateForm from '@components/Form/CreateVideoTemplateForm';
import Window from '@components/Window/Window';

const CreateVideoTemplateWindow = ({ title, onEdit = () => {}, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();
  const titleToUse = title ?? t('Add a template');

  return (
    <Window title={titleToUse} open={open} onClose={onClose}>
      <CreateVideoTemplateForm onSubmit={onEdit} />
    </Window>
  );
};

export default CreateVideoTemplateWindow;
