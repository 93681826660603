import { useTranslation } from 'react-i18next';

import Flex from '@components/Common/Flex';
import Hint from '@components/Common/Hint';
import Form from '@components/FormElement/Form/Form';

import style from './style.module.css';

const CustomLogo = ({ previewQrCode }) => {
  const { t } = useTranslation();

  return (
    <Flex flexWrap='WRAP' className={style.flexGap}>
      <div className={style.uploadInputContainer}>
        <Form.UploadInput
          title={t('Insert your logo')}
          name="logo"
          filenamesMode="HIDDEN"
          validTypes={['IMAGE']}
          hint={<Hint message={t('Please upload the best quality you have of your logo.')} type="INFO" />}
        />
      </div>
      {previewQrCode}
    </Flex>
  );
};

export default CustomLogo;
