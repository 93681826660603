import { useMutation } from '@apollo/client';

import ACQUIRE_OUTSOURCING_TASK from '@graphql/outsourcing/acquireOutsourcingTask.graphql';

export const useAcquireOutsourcingTask = ({ onCompleted = () => {}, onError = () => {} } = {}) => {
  const [mutate, { data, error, loading }] = useMutation(ACQUIRE_OUTSOURCING_TASK, {
    onCompleted,
    onError,
  });

  const acquireOutsourcingTask = (taskType) => {
    return mutate({ variables: { taskType } });
  };

  return [acquireOutsourcingTask, { data: data?.acquireOutsourcingTask, isUpdating: loading, error }];
};
