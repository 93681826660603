import { useTranslation } from 'react-i18next';

import WebhookForm from '@components/Form/WebhookForm';

import Window from '../Window';

const WebhookWindow = ({ webhook = {}, solutionType = null, open = false, onClose = () => {}, onSubmit = () => {}, isLoading = false }) => {
  const { t } = useTranslation();

  return (
    <Window title={webhook.id ? t('Update Webhook') : t('Add Webhook')} open={open} onClose={onClose}>
      <WebhookForm webhook={webhook} onSubmit={onSubmit} isLoading={isLoading} solutionType={solutionType} />
    </Window>
  );
};

export default WebhookWindow;
