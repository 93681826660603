import { useTranslation } from 'react-i18next';

import Window from '@components/Window/Window';

const MessageWindow = ({ onClose = () => {}, open = false, message = '', title }) => {
  const { t } = useTranslation();
  const titleToUse = title ?? t('Message');

  return (
    <Window onClose={onClose} open={open} title={titleToUse} maxWidth={'320px'}>
      <span style={{ textAlign: 'center', display: 'block', marginBottom: '20px' }}>{message}</span>
    </Window>
  );
};

export default MessageWindow;
