import PropTypes from 'prop-types';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { smartUploaderOrientation } from '@app/utils/smartUploader';
import ButtonContainer from '@components/Common/ButtonContainer';
import { Grid } from '@components/Common/Grid';
import { useForm, Form } from '@components/FormElement/Form';

import style from './style.module.css';

const types = {
  AUDIO: 'AUDIO',
  PICTURE: 'PICTURE',
  VIDEO: 'VIDEO',
};

const SequenceForm = ({
  sequence = { type: 'VIDEO' },
  availableVideoSequences = [],
  timelineTemplates = [],
  isNew = true,
  onSubmit = () => { },
  isLoading = false,
}) => {
  const { t } = useTranslation();
  const methods = useForm(sequence);

  const type = methods.watch('type');

  const handleDurationChange = () => {
    const { durationMin, durationMax } = methods.getValues();
    if (durationMin < durationMax) {
      methods.clearErrors(['durationMin', 'durationMax']);
    }
  }

  const templates = timelineTemplates.map((template) => ({
    ...template,
    sequenceIds: Object.values(template?.scheme || {})
      .flatMap((schemaElements) => (Array.isArray(schemaElements) ? schemaElements : []))
      .map((schemaElement) => schemaElement.sequence.id),
  }));
  const templateWithCurrentSequence = templates.find((template) => template.sequenceIds.includes(sequence.id));

  const validateOrientation = (orientation) =>
    !templateWithCurrentSequence ||
    templateWithCurrentSequence.orientation === orientation ||
    t(
      'This sequence is already used in a template {{templateName}} with different orientation. Please create another sequence, or delete it from the template',
      { templateName: templateWithCurrentSequence.title }
    );
  const optional = useWatch({ control: methods.control, name: 'optional' });

  return (
    <Form key={sequence?.id} onSubmit={onSubmit} methods={methods} isUpdating={isLoading}>
      <Grid>
        <Grid columns={2}>
          <Form.Label name="type" title={t('Type')}>
            <Form.Select
              name="type"
              value={sequence?.type || types.VIDEO}
              defaultValue={sequence?.type || types.VIDEO}
              options={[
                { value: 'AUDIO', label: t('Audio') },
                { value: 'VIDEO', label: t('Video') },
              ]}
              required
              readOnly={!isNew}
            />
          </Form.Label>
          <Form.Label title={t('Step')} name="optional">
            <Grid columns={2} className={style.radioGrid}>
              <Form.Radio
                label={t("Mandatory")}
                name="optional"
                value={optional}
                radioValue={false}
                defaultValue={false}
              />
              <Form.Radio
                label={t("Optional")}
                name="optional"
                value={optional}
                radioValue={true}
              />
            </Grid>
          </Form.Label>
        </Grid>
        <Form.Label name="title" title={t('Title')}>
          <Form.Input name="title" defaultValue={sequence?.title} required />
        </Form.Label>
        <Form.Label name="description" title={t('Description')}>
          <Form.Textarea name="description" defaultValue={sequence?.description} required />
        </Form.Label>
        {[types.AUDIO, types.VIDEO].includes(type) && (
          <Grid columns={2}>
            <Form.Label title={t('Min duration (in Sec)')} name="durationMin">
              <Form.NumberInput
                name="durationMin"
                min={1}
                defaultValue={sequence?.durationMin}
                onAfterChange={handleDurationChange}
                validate={(durationMin, { durationMax }) => !durationMax || durationMin < durationMax || t('Maximum duration must be greater than minimum duration')}
                required
              />
            </Form.Label>
            <Form.Label title={t('Max duration (in Sec)')} name="durationMax">
              <Form.NumberInput
                name="durationMax"
                defaultValue={sequence?.durationMax}
                onAfterChange={handleDurationChange}
                validate={(durationMax, { durationMin }) => !durationMin || durationMin < durationMax || t('Maximum duration must be greater than minimum duration')}
                required
              />
            </Form.Label>
          </Grid>
        )}
        {type === types.PICTURE && (
          <Form.Label name="quantity" title={t('Quantity')}>
            <Form.NumberInput name="quantity" defaultValue={sequence?.quantity} required min={0} />
          </Form.Label>
        )}
        {[types.PICTURE, types.VIDEO].includes(type) && (
          <>
            <Form.Label name="orientation" title={t('Orientation')}>
              <Form.Select
                name="orientation"
                defaultValue={sequence?.orientation}
                required
                options={[
                  { value: smartUploaderOrientation.horizontal, label: t('Horizontal') },
                  { value: smartUploaderOrientation.vertical, label: t('Vertical') },
                ]}
                validate={validateOrientation}
              />
            </Form.Label>
            <Grid columns={2}>
              <Form.Label name="requireFace" title={t('Require face')}>
                <Form.Switch name="requireFace" defaultValue={sequence?.requireFace} required />
              </Form.Label>
            </Grid>
          </>
        )}

        {types.VIDEO === type && (
          <Form.Label name="mute" title={t('Mute')}>
            <Form.Switch name="mute" defaultValue={sequence?.mute} required />
          </Form.Label>
        )}

        {types.AUDIO === type && (
          <Form.Label name="relatedVideoId" title={t('Related video')}>
            <Form.Select
              name="relatedVideoId"
              defaultValue={sequence?.relatedVideo?.id}
              options={[
                { value: null, label: t('None') },
                ...availableVideoSequences.map((videoSeq) => ({ value: videoSeq.id, label: `${videoSeq.index} - ${videoSeq.title}` })),
              ]}
            />
          </Form.Label>
        )}
      </Grid>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default SequenceForm;

SequenceForm.propTypes = {
  availableVideoSequences: PropTypes.array,
  timelineTemplates: PropTypes.array,
  isNew: PropTypes.bool,
  onSubmit: PropTypes.func,
  isUpdating: PropTypes.bool,
};
