import { useTranslation } from 'react-i18next';

import Button from '@components/Common/Button';
import ButtonContainer from '@components/Common/ButtonContainer';
import Divider from '@components/Common/Divider';
import Hint from '@components/Common/Hint';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import TemplateKeyInsights from '@components/Configuration/TemplateKeyInsights';
import Window from '@components/Window/Window';
import { useGetKey } from '@hooks/configuration';

const DeleteTemplateKeyWindow = ({ keyId, keyName, onSubmit = () => {}, isLoading = false, onClose = () => {}, open = false }) => {
  const { t } = useTranslation();
  const { key, loading, error } = useGetKey(keyId);

  return (
    <Window title={t('Delete a key')} open={open} onClose={onClose}>
      {t('Do you really want to delete the {{name}} key? It will also delete preset and/or configuration values using this key.', { name: keyName })}
      <h3>{t('Insights')}</h3>
      {error ? (
        <Hint type="ERROR" message={t('Could not determine whether this key was used or not. Proceed at your own risk.')} />
      ) : loading ? (
        <div>
          <SkeepersIcon type="SPINNER" color="var(--layout-primary-color)" spin />
          {t('Retrieving key information...')}
        </div>
      ) : (
        <div>
          <TemplateKeyInsights {...key} />
          <Divider />
          <Hint type="INFO" message={t('These insights are here to help you in making the decision to delete this key or not.')} />
        </div>
      )}
      <ButtonContainer position="CENTER">
        <Button label={t('Delete')} action={onSubmit} loading={isLoading} disabled={isLoading} />
      </ButtonContainer>
    </Window>
  );
};

export default DeleteTemplateKeyWindow;
