import classNames from 'classnames';

import Spinner from '@components/Common/Spinner';

import style from './style.module.css';

const PageLoading = ({ applyRelativePositionStyle }) => (
  <div data-testid="page-loading" className={classNames(style.spinnerContainer, applyRelativePositionStyle ? style.relativeContainer : null)}>
    <Spinner type="SECONDARY" />
  </div>
);

export default PageLoading;
