import { useTranslation } from 'react-i18next';

import UploadSocialMedia from '@components/Project/UploadSocialMedia';
import Window from '@components/Window/Window';
import { UploadSocialModalProvider } from '@contexts/UploadSocialModalContext';

const UploadSocialMediaVideo = ({ videos, onClose = () => {}, open = false, canUpload = {}, onEnded = () => {} }) => {
  const { t } = useTranslation();

  return (
    <Window title={t('Upload social media')} open={open} onClose={onClose} closeOnClickOutside={false}>
      <UploadSocialModalProvider sourceVideos={videos}>
        <UploadSocialMedia canUpload={canUpload} onEnded={onEnded} onClose={onClose} />
      </UploadSocialModalProvider>
    </Window>
  );
};

export default UploadSocialMediaVideo;
