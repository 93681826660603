import { SkpFlex } from '@skeepers/skeepers-ui-react';
import classNames from 'classnames';
import moment from 'moment';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Block from '@components/Common/Block';
import ClipboardCopy from '@components/Common/ClipboardCopy';
import Flex from '@components/Common/Flex';
import { MultilineText } from '@components/Common/MultilineText';
import Label from '@components/FormElement/Label';
import Window from '@components/Window/Window';

import style from './style.module.css';

const InfoBlock = ({ title, children }) => (
  <Block className={style.fullWidth}>
    <Flex justifyContent="SPACE-BETWEEN">
      <div className={style.blockTitle}>{title}</div>
      <div>{children}</div>
      <ClipboardCopy text={children} />
    </Flex>
  </Block>
);

const titlesMap = {
  EDITING: (t) => t('Editing brief'),
  META: (t) => t('Meta brief'),
  SUBTITLES: (t) => t('Subtitles brief'),
  LAST_COMMENT: (t) => t('Last comment'),
};

const BriefTitle = ({ type, selected, onClick }) => {
  const { t } = useTranslation();

  return (
    <div className={classNames(style.briefTitle, { [style.selected]: selected })} {...(onClick ? { onClick } : {})}>
      {titlesMap[type](t)}
    </div>
  );
}

const WorkflowBriefWindow = ({ project, type, open = false, onClose = () => {} }) => {
  const { t } = useTranslation();
  const lastMessages = [
    ...project?.logs?.filter((log) => log.message && log.eventType !== 'PROJECT_CLIENT_REVIEW_REFUSE'),
    ...project?.sequenceMessages,
  ].sort((a, b) => b.createdAt - a.createdAt);
  const [selected, setSelected] = useState('EDITING');
  const resumeType = type === 'REVIEW' ? selected : type;
  const resume = project?.solution?.editingBrief?.resume?.[resumeType.toLowerCase()];

  return (
    <Window open={open} onClose={onClose} maxWidth='60vw'>
      <SkpFlex flexDirection='column' alignItems='stretch'>
        <SkpFlex flexDirection='column' alignItems='stretch'>
          <SkpFlex flexDirection='row' alignItems='center' justifyContent='center'>
            <InfoBlock title={t('Ambassador')}>{project?.user?.displayName}</InfoBlock>
            <InfoBlock title={t('Client')}>{project?.solution?.company?.name}</InfoBlock>
          </SkpFlex>
          <SkpFlex flexDirection='row' alignItems='center' justifyContent='center'>
            {project?.productTitle && <InfoBlock title={t('Product')}>{project?.productTitle}</InfoBlock>}
            {type === 'EDITING' && project?.solution?.editingBrief?.maxDuration && (
              <InfoBlock title={t('Video')}>{t('{{maxDuration}} seconds MAX', { maxDuration: project?.solution?.editingBrief?.maxDuration })}</InfoBlock>
            )}
          </SkpFlex>
        </SkpFlex>
        <div className={style.resumeContainer}>
          <div padding="NONE" className={style.resume}>
            <Flex justifyContent="SPACE-BETWEEN" className={style.titles}>
              {['EDITING', 'SUBTITLES'].includes(type) && <BriefTitle type={type} />}
              {type === 'REVIEW' && (
                <>
                  <BriefTitle type="EDITING" selected={selected === 'EDITING'} onClick={() => setSelected('EDITING')} />
                  <BriefTitle type="META" selected={selected === 'META'} onClick={() => setSelected('META')} />
                </>
              )}
            </Flex>
            <MultilineText className={style.resumeText} maxLine={10} content={resume || t('No brief')} />
            {lastMessages.length > 0 && (
              <>
                <Flex justifyContent="SPACE-BETWEEN" className={style.titles}>
                  <BriefTitle type='LAST_COMMENT' />
                </Flex>
                {
                  lastMessages.map((message) => {
                    return (
                      <>
                        <p className={style.contentTitle}>
                          {`${moment(new Date(message.createdAt * 1000)).format('L')}${message.user?.displayName ? ` - ${message.user?.displayName}` : ''}`}
                        </p>
                        {
                          message.sequenceTitle && (
                            <span>
                              {t('Sequence to redo : {{sequence}}', {sequence: message.sequenceTitle})}
                            </span>
                          )
                        }
                        <MultilineText content={message.message} className={style.content} maxLine={5} />
                      </>
                    );
                  })
                }
              </>
            )}
          </div>
        </div>
        {type === 'EDITING' && project?.solution?.editingBrief?.sampleUrl && (
          <Label className={style.inputs} title={t('Sample video')}>
            <ClipboardCopy text={project?.solution?.editingBrief?.sampleUrl} mini={false} />
          </Label>
        )}
        {project?.productUrl && (
          <Label className={style.inputs} title={t('Product URL')}>
            <ClipboardCopy text={project?.productUrl} mini={false} />
          </Label>
        )}
      </SkpFlex>
    </Window>
  );
};

export default WorkflowBriefWindow;
