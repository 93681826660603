import { useCallback, useEffect, useRef, useState } from 'react';

import Timer from '@app/utils/Timer';
import { useWindows, WINDOW_TYPES } from '@contexts/WindowContext';

const callback = () => {};

export const useTimer = ({ taskId, previousActions, skip = false, onPause = callback, onResume = callback }) => {
  const timer = useRef(null);
  const [time, setTime] = useState(0);
  const { actions } = useWindows();

  const onTimerError = useCallback(() => {
    timer.current?.start();
  }, []);

  const _onPause = useCallback(
    (isIdle) => {
      timer.current?.stop();
      onPause();
      actions.open(WINDOW_TYPES.WORKFLOW_PAUSE, {
        idleDuration: isIdle ? 30 : null,
        onResume: () => {
          onResume();
          timer.current?.start();
        },
      });
    },
    [timer, actions, onPause, onResume]
  );

  useEffect(() => {
    if ((!timer.current || timer.current.getTaskId() !== taskId) && !skip) {
      timer.current = new Timer({
        onError: onTimerError,
        onIdle: () => {
          _onPause(true);
        },
        onTick: setTime,
        previousActions,
        taskId,
      });
      timer.current.start();
    }
  }, [skip, time, timer, _onPause, onTimerError, taskId, previousActions]);

  useEffect(() => {
    //trigger when component will unmount
    return () => {
      timer?.current?.stop();
    };
  }, []);

  return {
    onPause: _onPause,
    timer: timer.current,
    time,
  };
};
