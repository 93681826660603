import ROUTES, { compareRoute } from '@app/routes';

const submenu = (companyId, solutions, selectedDropDown, currentRoute, _, callback, addCallback, t) => [
  ...solutions
    .filter((t) => t.type === 'SMART_UPLOADER')
    .map((s, i) => ({
      name: s.name ? s.name : t('SMART UPLOADER #{{index}}', { index: i + 1 }),
      selected: false,
      open: selectedDropDown === `SMART-UPLOADER-${s.id}` || selectedDropDown === `SMART-UPLOADER-CONFIGURATION-${s.id}`,
      onClick: () => {
        callback(`SMART-UPLOADER-${s.id}`);
      },
      children: [
        {
          name: t('Videos'),
          icon: 'VIDEO',
          selected: compareRoute(ROUTES.PROJECT_LIST.path({ companyId, solutionId: s.id, solutionType: 'smart-uploader' }), currentRoute),
          onClick: ROUTES.PROJECT_LIST.path({ companyId, solutionId: s.id, solutionType: 'smart-uploader' }),
        },
        {
          name: t('Configuration'),
          icon: 'SETTING',
          selected: compareRoute(ROUTES.SOLUTION_CONFIGURATION.path({ solutionId: s.id, solutionType: 'smart-uploader', companyId }), currentRoute),
          onClick: ROUTES.SOLUTION_CONFIGURATION.path({ solutionId: s.id, solutionType: 'smart-uploader', companyId }),
        },
        {
          name: t('Webhooks'),
          icon: 'LINK',
          selected: compareRoute(ROUTES.WEBHOOKS_SOLUTION_LIST.path({ companyId, solutionId: s.id, solutionType: 'smart-uploader' }), currentRoute),
          onClick: ROUTES.WEBHOOKS_SOLUTION_LIST.path({ companyId, solutionId: s.id, solutionType: 'smart-uploader' }),
        },
      ],
    })),
  {
    name: t('New smart uploader'),
    icon: 'ADD',
    selected: false,
    onClick: addCallback,
  },
];

export default submenu;
