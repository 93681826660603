import { loadStonlyWidget, identifyStonlyUser } from '@app/utils/externalModules/stonlyWidget';

import { loadHotjar } from './hotjar';
import { loadCSATModule, updateCSATUserVariableAndStart } from './ifmCSATModule';

export { updateCSATUserVariableAndStart, identifyStonlyUser };

export const loadExternalModules = async () => {
  loadHotjar();
  loadStonlyWidget();
  loadCSATModule();
};
