import PropTypes from 'prop-types';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getLanguages } from '@app/translations';
import ButtonContainer from '@components/Common/ButtonContainer';
import SkeepersFlag from '@components/Common/SkeepersFlag';
import { Form, useForm } from '@components/FormElement/Form';

const CreateCardsForm = ({ cardsLanguages = [], availableOutputsCards = [], isLoading = false, onSubmit = () => { } }) => {
  const { t } = useTranslation();
  const methods = useForm();
  const languages = useMemo(() => getLanguages(t), [t]);
  const canImport = availableOutputsCards.length > 0;
  const { type } = methods.watch();

  return (
    <Form
      methods={methods}
      onSubmit={({ from, ...values }) => onSubmit({ ...values, cards: availableOutputsCards.find(({ id }) => id === from)?.cards ?? [] })}
      isUpdating={isLoading}
    >
      <Form.Label title={t('Language')} name="language">
        <Form.LanguageSelect
          required
          name="language"
          filterLanguages={(language) => !cardsLanguages.some((cardsLanguage) => cardsLanguage.language === language)}
        />
      </Form.Label>
      {canImport && (
        <>
          <Form.Label title={t('Type')} name="type">
            <Form.SelectCard
              name="type"
              required
              options={[
                { title: t('Manual'), description: t('Create new cards'), value: 'MANUAL', icon: 'EDIT' },
                { title: t('Imported'), description: t('Copy cards from a previous output'), value: 'IMPORTED', icon: 'DOWNLOAD' },
              ]}
              defaultValue="MANUAL"
            />
          </Form.Label>
          {type === 'IMPORTED' && (
            <Form.Label title={t('Base output')} name="from">
              <Form.Select
                name="from"
                required
                options={availableOutputsCards.map((availableOutputCards) => ({
                  label: availableOutputCards.filename,
                  value: availableOutputCards.id,
                  icon: <SkeepersFlag country={languages[availableOutputCards.language]?.country} />,
                }))}
              />
            </Form.Label>
          )}
        </>
      )}
      <ButtonContainer position="CENTER">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default CreateCardsForm;

CreateCardsForm.propTypes = {
  cardsLanguages: PropTypes.arrayOf(
    PropTypes.shape({
      language: PropTypes.string,
      count: PropTypes.number,
    })
  ),
  availableOutputsCards: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      filename: PropTypes.string,
      language: PropTypes.string,
      cards: PropTypes.arrayOf(
        PropTypes.shape({
          title: PropTypes.string,
          description: PropTypes.string,
          language: PropTypes.string,
          position: PropTypes.number,
          displayedInTimeline: PropTypes.bool,
        })
      ),
    })
  ),
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func,
};
