import { useState } from 'react';

import { isValidURL } from '@app/utils';
import { isYoutubeUrl } from '@app/utils/externalVideo';

const isValidUrl = (type, value) => {
  if (type === 'URL') {
    return isValidURL(value);
  }
  return true;
};

const isValidExternalVideoUrl = (type, value) => {
  if (type === 'externalVideoURL') {
    return isYoutubeUrl(value);
  }
  return true;
};

export const ERROR_TYPES = {
  NO_ERROR: 'NO_ERROR',
  INVALID_URL: 'INVALID_URL',
  MISSING_VALUE: 'MISSING_VALUE',
  LOADING: 'LOADING',
  INVALID_EXTERNAL_VIDEO_URL: 'INVALID_EXTERNAL_VIDEO_URL',
};

export const useTable = (config, data) => {
  const [disabledRowIndexes, setDisabledRowIndexes] = useState([]);
  const [openRowIndexes, setOpenRowIndexes] = useState([]);
  const [isEditable, setIsEditable] = useState(false);
  const [hints, setHints] = useState(() => {
    return data.map((_, index) => {
      return {
        id: index,
        isUpdatable: false,
        fields: [],
        errorType: config.reduce((acc, c) => {
          return { ...acc, [c.value]: ERROR_TYPES.NO_ERROR };
        }, {}),
      };
    });
  });
  const [configuration, setConfiguration] = useState(config);
  const [save] = useState(data.map((d) => d.map((e) => e)));

  // ------- disabledRawIndexes -----------

  const updateDisabledRow = (index) => (value) => {
    setDisabledRowIndexes((indexes) => (value ? indexes.filter((i) => i !== index) : indexes.includes(index) ? indexes : [...indexes, index]));
  };

  // ---------- openRawIndexes ------------

  const updateOpenRow = (index) => (value) => {
    setOpenRowIndexes((indexes) => (value ? indexes.filter((i) => i !== index) : indexes.includes(index) ? indexes : [...indexes, index]));
  };

  // ------------ isEditable --------------

  const updateIsEditable = (newConfig) => {
    const isRequiredFields = newConfig.filter(({ isRequired, csvKey }) => isRequired && !csvKey);
    setIsEditable(!isRequiredFields.length);
  };

  // -------------- hints -----------------

  const getHint = (index) => {
    const hint = hints.find((h) => h.id === index);

    const missingFields = hint.fields.filter((f) => hint.errorType[f] === ERROR_TYPES.MISSING_VALUE);
    const invalidUrlFields = hint.fields.filter((f) => hint.errorType[f] === ERROR_TYPES.INVALID_URL || hint.errorType[f] === ERROR_TYPES.LOADING);
    const invalidExternVideoUrlFields = hint.fields.filter((f) => hint.errorType[f] === ERROR_TYPES.INVALID_EXTERNAL_VIDEO_URL);
    return [{ missingFields, invalidUrlFields, invalidExternVideoUrlFields }];
  };

  const getHints = () => {
    return hints.reduce((acc, h) => (h.fields.length ? [...acc, { id: h.id, isUpdatable: h.isUpdatable, fields: h.fields }] : acc), []);
  };

  const isUpdatable = (d, field) => {
    const fields = configuration.reduce((acc, c) => (c.type === 'URL' && c.csvKey && !!d[c.csvKey] ? [...acc, c] : acc), []);

    if (field && !fields.find((f) => f.value === field.value) && field.type === 'URL' && !!d[field.csvKey]) {
      fields.push(field);
    }
    return !fields.every((f) => d[f.csvKey].toLowerCase().substring(0, 4) === 'http');
  };

  const setHintById = (index, field, error, isUpdatable = null) => {
    setHints((previousHints) =>
      previousHints.map((h) => {
        if (h.id === index) {
          const newHint = {
            ...h,
            isUpdatable: isUpdatable !== null ? isUpdatable : h.isUpdatable,
            fields: h.fields.includes(field.value) ? h.fields : [...h.fields, field.value],
            errorType: h.fields.includes(field.value) ? h.errorType : { ...h.errorType, [field.value]: error },
          };
          updateDisabledRow(index)(!newHint.fields.length);
          return newHint;
        }
        return h;
      })
    );
  };

  const setKeyOnHints = (key, field, data) => {
    for (const [index, d] of data.entries()) {
      if (field.isRequired && !d[key]) {
        setHintById(index, field, ERROR_TYPES.MISSING_VALUE, isUpdatable(d, field));
      } else if (!isValidUrl(field.type, d[key]) && !!d[key]) {
        setHintById(index, field, ERROR_TYPES.INVALID_URL, isUpdatable(d, field));
      } else if (!isValidExternalVideoUrl(field.type, d[key]) && !!d[key]) {
        setHintById(index, field, ERROR_TYPES.INVALID_EXTERNAL_VIDEO_URL, isUpdatable(d, field));
      }
    }
  };

  const removeHintById = (index, field, isUpdatable = null) => {
    setHints((previousHints) =>
      previousHints.map((h) => {
        if (h.id === index) {
          const newHint = {
            ...h,
            isUpdatable: isUpdatable !== null ? isUpdatable : h.isUpdatable,
            fields: h.fields.filter((e) => e !== field.value),
            errorType: { ...h.errorType, [field.value]: ERROR_TYPES.NO_ERROR },
          };
          updateDisabledRow(index)(!newHint.fields.length);
          return newHint;
        }
        return h;
      })
    );
  };

  const removeKeyOnHints = (data, field) => {
    for (const [index, d] of data.entries()) {
      removeHintById(index, field, isUpdatable(d));
    }
  };

  const setErrorLoading = (index, field) => {
    const error = hints.find((h) => h.id === index && h.errorType[field.value] !== ERROR_TYPES.NO_ERROR);
    if (!error) {
      setHintById(index, field, ERROR_TYPES.LOADING);
    }
  };

  // ------------- setTable ---------------

  const setTable = (key, value, data) => {
    setConfiguration((previousConfig) => {
      const newConfig = previousConfig.map((c) => {
        if (key === c.csvKey) {
          c.csvKey = null;
          c.isDisabled = false;
          setData(data, { ...c, csvKey: key });
          removeKeyOnHints(data, { ...c, csvKey: key });
        }
        if (c.multi && c.csvKey?.some((e) => e === key)) {
          setData(data, { ...c, csvKey: key });
          c.csvKey = c.csvKey.filter((e) => e !== key);
        }
        if (c.value === value) {
          setKeyOnHints(key, { ...c, csvKey: key }, data);
          if (c.multi) {
            setMulti(data, { ...c, csvKey: key });
          }
          return c.multi
            ? {
                ...c,
                csvKey: c.csvKey ? [...c.csvKey, key] : [key],
              }
            : {
                ...c,
                isDisabled: true,
                csvKey: key,
              };
        }
        return c;
      });
      updateIsEditable(newConfig);
      return newConfig;
    });
  };

  const setTableHint = (key, value, data) => {
    configuration.forEach((c) => {
      if (key === c.csvKey) {
        removeKeyOnHints(data, { ...c, csvKey: key });
      }
      if (c.value === value) {
        setKeyOnHints(key, { ...c, csvKey: key }, data);
      }
    });
  };

  // -------------- setUrl ----------------

  const setUrl = (data) => {
    for (const [index, d] of data.entries()) {
      configuration.forEach((c) => {
        if (d[c.csvKey] && !isValidUrl(c.type, d[c.csvKey])) {
          d[c.csvKey] = d[c.csvKey]?.toLowerCase().substring(0, 4) === 'http' ? d[c.csvKey] : `https://${d[c.csvKey]}`;
          isValidUrl(c.type, d[c.csvKey]) ? removeHintById(index, c, isUpdatable(d)) : setHintById(index, c, isUpdatable(d, c));
        }
      });
    }
  };

  // -------------- setRow ----------------

  const setRow = (data, dataElement, index) => {
    configuration.forEach((c) => {
      if (c.csvKey) {
        if (c.multi) {
          c.csvKey.map((tag) => (data[index][tag] = []));
          dataElement[c.value].forEach((t) => {
            data[index][c.csvKey[0]] = data[index][c.csvKey[0]].concat(t.split());
            c.csvKey.push(c.csvKey.shift());
          });
        } else {
          data[index][c.csvKey] = dataElement[c.value];
          removeHintById(index, c, isUpdatable(data[index]));
        }
      }
    });
  };

  const setMulti = (data, field) => {
    for (const [index] of data.entries()) {
      if (data[index][field.csvKey].includes(',')) {
        data[index][field.csvKey] = data[index][field.csvKey].split(',');
      } else if (data[index][field.csvKey].includes(';')) {
        data[index][field.csvKey] = data[index][field.csvKey].split(';');
      } else if (data[index][field.csvKey].includes('/')) {
        data[index][field.csvKey] = data[index][field.csvKey].split('/');
      }
    }
  };

  const setData = (data, field) => {
    for (const [index] of data.entries()) {
      data[index][field.csvKey] = save[index][field.csvKey];
    }
  };

  return [
    { disabledRowIndexes, openRowIndexes, isEditable, configuration },
    setTable,
    setRow,
    updateDisabledRow,
    updateOpenRow,
    setUrl,
    setErrorLoading,
    getHint,
    getHints,
    setTableHint,
  ];
};
