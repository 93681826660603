import ROUTES, { compareRoute } from '@app/routes';

const submenu = (companyId, solutions, selectedDropDown, currentRoute, { qrcodeOption, followUpOption }, callback, addCallback, t) => [
  // {
  //   name: t('Overview'),
  //   icon: 'DASHBOARD',
  //   selected: compareRoute(`company/${companyId}/player/overview`, currentRoute),
  //   onClick: `/company/${companyId}/player/overview`,
  // },
  ...solutions
    .filter((t) => t.type === 'PLAYER')
    .map((s, i) => ({
      name: s.name || t('Player #{{index}}', { index: i + 1 }),
      selected: false,
      open: selectedDropDown === `PLAYER-${s.id}`,
      onClick: () => {
        callback(`PLAYER-${s.id}`);
      },
      children: [
        {
          name: t('Audits'),
          icon: 'COGS',
          selected: compareRoute(ROUTES.AUDITS_SOLUTION.path({ companyId, solutionId: s.id, solutionType: 'player' }), currentRoute),
          onClick: ROUTES.AUDITS_SOLUTION.path({ companyId, solutionId: s.id, solutionType: 'player' }),
        },
        {
          name: t('Configuration'),
          icon: 'SETTING',
          selected: compareRoute(ROUTES.SOLUTION_CONFIGURATION.path({ companyId, solutionType: 'player', solutionId: s.id }), currentRoute),
          onClick: ROUTES.SOLUTION_CONFIGURATION.path({ solutionType: 'player', companyId, solutionId: s.id }),
        },
      ],
    })),
  {
    name: t('New player'),
    icon: 'ADD',
    selected: false,
    onClick: addCallback,
  },
];

export default submenu;
