import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ButtonContainer from '@components/Common/ButtonContainer';
import { useForm, Form } from '@components/FormElement/Form';

const RouteForm = ({ route = {}, isUpdating = false, onSubmit = () => {} }) => {
  const { t } = useTranslation();
  const methods = useForm();

  return (
    <Form key={route?.id} isUpdating={isUpdating} onSubmit={onSubmit} methods={methods}>
      <Form.Label name="route" title={t('URL')}>
        <Form.PlatformRouteInput
          name="route"
          defaultValue={{
            subdomain: route?.subdomain,
            path: route?.path,
          }}
          required
        />
      </Form.Label>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default RouteForm;

RouteForm.propTypes = {
  route: PropTypes.shape({
    computedUrl: PropTypes.string,
  }),
  isUpdating: PropTypes.bool,
  onSubmit: PropTypes.func,
};
