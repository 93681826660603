import { useEffect, useState } from 'react';

const loadTiktokSdk = () =>
  new Promise((resolve) => {
    const script = document.createElement('script');
    script.src = 'https://www.tiktok.com/embed.js';
    script.id = 'tiktok-jssdk';
    document.body.appendChild(script);
    script.onload = resolve;
    resolve();
  });

const TiktokPlayer = ({ videoId }) => {
  const [oembed, setOembed] = useState(null);

  useEffect(() => {
    loadTiktokSdk();
    if (!oembed) {
      fetch(`https://www.tiktok.com/oembed?url=https://www.tiktok.com/@/video/${videoId}`)
        .then((res) => res.json())
        .then((json) => setOembed(json));
    }
  });

  if (!oembed) {
    return null;
  }

  return <div dangerouslySetInnerHTML={{ __html: oembed.html }} />;
};

export default TiktokPlayer;
