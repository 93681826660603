import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { SkpLink } from '@skeepers/skeepers-ui-react';
import { useUnleashContext } from '@unleash/proxy-client-react';
import PropTypes from 'prop-types';
import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { HELPCENTER } from '@app/config';
import { getLanguageCode } from '@app/i18n';
import ROUTES from '@app/routes';
import Action from '@components/Common/Action';
import Avatar from '@components/Common/Avatar';
import SkeepersIcon from '@components/Common/SkeepersIcon';
import CompanySelect from '@components/Company/CompanySelect';
import { RedirectContext } from '@contexts/RedirectContext';

import HeaderLeft from './HeaderLeft';
import style from './style.module.css';


const Header = ({
  user,
  open,
  freezeActions = false,
  userNotification,
  onHome,
  onLogout,
  onToogleClick,
  onNotification,
  onSetting,
  canOpenMenu,
  companyId,
  companies,
}) => {
  const { t } = useTranslation();
  const { redirect } = useContext(RedirectContext);
  const updateContext = useUnleashContext();

  const onCompanyChange = (companyId) => {
    updateContext({ userId: companyId });
    redirect(ROUTES.DASHBOARD.path({ companyId }));
  };

  const getHelpCenterUrl = (lng) => {
    const helpCenter = {
      ...HELPCENTER,
      'en-US': HELPCENTER.en,
      'fr-FR': HELPCENTER.fr,
    };
    return !helpCenter[lng] ? helpCenter.en : helpCenter[lng];
  };

  const ref = useRef(null);

  const menuActions = freezeActions
    ? []
    : [
        {
          action: ROUTES.CONFIG_TEMPLATES.path(),
          label: t('Configurations'),
          require: 'ui.configs',
          icon: 'LANGUAGE',
        },
        {
          action: ROUTES.PROJECT_FAVORITE_LIST.path(),
          label: t('Favorite videos'),
          require: 'favorites.read',
          icon: 'FAV',
        },
        {
          action: ROUTES.CHANGELOGS.path(),
          label: t('Changelogs'),
          require: 'changelogs.read',
          icon: 'VERSION',
        },
        {
          action: ROUTES.WORKFLOW_OVERVIEW_OPERATOR.path(),
          label: t('Dashboard Operator'),
          require: 'ui.workflows',
          icon: 'TODO',
        },
        {
          action: ROUTES.DASHBOARD_CUSM.path(),
          label: t('Dashboard USM'),
          require: 'ui.workflows',
          icon: 'TODO',
        },
        {
          action: ROUTES.IAM.path(),
          label: t('Groups & permissions'),
          require: 'ui.iam',
          icon: 'SHIELD',
        },
        {
          action: ROUTES.USERS_LIST.path(),
          label: t('User list'),
          require: 'user.search',
          icon: 'USERS',
        },
        {
          action: ROUTES.USER.path({ id: user?.id }),
          label: t('Profile'),
          require: 'user.read',
          icon: 'USER',
        },
        {
          action: ROUTES.MAILS.path(),
          label: t('Mails'),
          require: 'ui.mailsAdministration',
          icon: 'EMAIL',
        },
        {
          action: ROUTES.MAILS_WRAPPERS_CONFIGURATION.path(),
          label: t('Mails - Wrappers'),
          require: 'ui.mailsAdministration',
          icon: 'EMAIL',
        },
        {
          action: ROUTES.MAILS_DEFAULT_TEMPLATES_CONFIGURATION.path(),
          label: t('Mails - Templates'),
          require: 'ui.mailsAdministration',
          icon: 'EMAIL',
        },
        {
          action: ROUTES.AUDITS_COMPANIES.path(),
          label: t('Audits'),
          require: 'auditTask.read',
          icon: 'COGS',
        },
        /* {
          action: ROUTES.AUDITS_VIDEO_CHECKERS.path(),
          label: t('Audits - Video checkers'),
          require: 'videoChecker.read',
          icon: 'COGS',
        }, */
        {
          action: ROUTES.BROWSER_WORKERS.path(),
          label: t('Browser automation'),
          require: 'browser.read',
          icon: 'BROWSER',
        },
        {
          action: ROUTES.LEGAL.path(),
          label: t('Legal'),
          require: 'legal.read',
          icon: 'CERTIFICATE',
        },
        {
          action: ROUTES.WEBHOOKS_GLOBAL_LIST.path(),
          label: t('Webhooks'),
          require: 'webhook.global',
          icon: 'SITEMAP',
        },
      ]
        .filter((action) => user?.role?.actions[action.require] || user?.allowedActions[action.require])
        .sort((a, b) => a.label.localeCompare(b.label));
  return (
    <>
      <HeaderLeft
        hide={!canOpenMenu}
        menuOpen={open}
        {...(freezeActions ? {} : { onHome })}
        className={style.bugerMenuContainer}
        onClick={() => {
          if (canOpenMenu) {
            onToogleClick();
          }
        }}
      />
      <header className={style.root} ref={ref}>
        <div className={style.headerRight}>
          {!freezeActions && companies.length > 1 && (
            <div className={style.containerSelect}>
              <CompanySelect companyId={companyId} companies={companies} onChange={onCompanyChange} />
            </div>
          )}
          <div className={style.user}>
            <Avatar className={style.avatar} url={user.picture} size="small" />
            <div className={style.infosUserContainer}>
              <span className={style.userName}>{user.displayName}</span>
              <span className={style.userMail}>{user.email}</span>
            </div>
            <SkeepersIcon type="CHEVRON_DOWN" color="var(--skp-color-neutral-700)" className={style.iconChevronDown} />
            <div className={style.userMenu}>
              {menuActions.map(({ action, label, icon }) => (
                <Action className={style.subMenu} action={action} key={label}>
                  <div className={style.iconContainer}>
                    <SkeepersIcon type={icon} color="var(--skp-color-neutral-700)" />
                  </div>
                  {label}
                </Action>
              ))}
              <Action dataCy="logout" className={style.subMenu} action={onLogout}>
                <div className={style.iconContainer}>
                  <SkeepersIcon type="LOGOUT" />
                </div>
                {t('Logout')}
              </Action>
            </div>
          </div>
          <SkpLink skpClass={style.helpCenter} iconPosition='right' target='_blank' text={t('HELP')} icon={faQuestionCircle} link={getHelpCenterUrl(getLanguageCode())} standaloneVariant='secondary'></SkpLink>
        </div>
      </header>
    </>
  );
};

Header.propTypes = {
  userPicture: PropTypes.string,
  userName: PropTypes.string,
  userNotification: PropTypes.number,
  onHome: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  onLogout: PropTypes.func,
};

Header.defaultProps = {
  userPicture: '',
  userName: '',
  userNotification: 0,
  onHome: () => {},
  onLogout: () => {},
};

export default Header;
