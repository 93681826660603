import classNames from 'classnames';
import PropTypes from 'prop-types';

import Action from '@components/Common/Action';
import SkeepersIcon from '@components/Common/SkeepersIcon';

import style from './style.module.css';

const HeaderLeft = ({ hide = false, className = '', menuOpen = false, onHome = null, onClick = () => {} }) => {
  const Logo = <SkeepersIcon type="SKEEPERS_LOGO" className={`${style.icon} ${style.logo}`} />;

  return (
    <div className={style.headerLeft}>
      <div className={classNames(style.burger, className, { [style.hide]: hide })} onClick={onClick}>
        <div className={style.container} onClick={onClick}>
          <hr className={menuOpen ? style.hrRotateOne : ''} />
          <hr className={menuOpen ? style.hrOpacity : ''} />
          <hr className={menuOpen ? style.hrRotateTwo : ''} />
        </div>
      </div>
      {onHome ? <Action action={onHome}>{Logo}</Action> : Logo}
    </div>
  );
};

HeaderLeft.propTypes = {
  hide: PropTypes.bool,
  className: PropTypes.string,
  menuOpen: PropTypes.bool,
  onClick: PropTypes.func,
};

export default HeaderLeft;
