import classNames from 'classnames';
import PropTypes from 'prop-types';

import { useTabContext } from '@components/Common/Tabs/TabContext';

import style from './style.module.css';

const TabListItem = ({ title = '', name = '' }) => {
  const { current, onChange } = useTabContext();

  return (
    <div className={classNames(style.root, { [style.selected]: current === name })} onClick={() => onChange(name)}>
      {title}
    </div>
  );
};

TabListItem.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
};

export default TabListItem;
