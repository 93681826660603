import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getUserRoles } from '@app/translations';
import ButtonContainer from '@components/Common/ButtonContainer';
import { Form, useForm } from '@components/FormElement/Form';

const AssignRoleForm = ({ isUpdating = false, onSubmit = () => {} }) => {
  const { t } = useTranslation();
  const methods = useForm();
  const roles = getUserRoles(t);

  return (
    <Form methods={methods} onSubmit={onSubmit} isUpdating={isUpdating}>
      <Form.Label title={t('User')} name="userId">
        <Form.UserSelect name="userId" required />
      </Form.Label>
      <Form.Label title={t('Role')} name="role">
        <Form.Select name="role" options={Object.entries(roles).map(([value, { name: label }]) => ({ label, value }))} required />
      </Form.Label>
      <ButtonContainer position="CENTER">
        <Form.SubmitButton />
      </ButtonContainer>
    </Form>
  );
};

export default AssignRoleForm;

AssignRoleForm.propTypes = {
  isUpdating: PropTypes.bool,
  onSubmit: PropTypes.func,
};
