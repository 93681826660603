import { SIGNIN_URL, SIGNIN_STRATEGY } from '@app/config';
import { getLanguageCode } from '@app/i18n';

const openConnectFront = (path, params, keepUrl = true) => {
  const destination = new URL(SIGNIN_URL);
  const searchParams = {
    ...params,
    mode: 'email',
    solutionId: 'backoffice',
    lng: getLanguageCode(),
    redirectUri: keepUrl ? window.location.href : window.location.origin,
    strategy: SIGNIN_STRATEGY || 'default',
  };

  // Destination
  destination.pathname = path || destination.pathname;

  // Query params
  for (const [key, value] of Object.entries(searchParams)) {
    if (value) {
      destination.searchParams.set(key, value);
    }
  }

  window.location.href = destination.href;
};

export const logout = () => openConnectFront('/logout', { redirectTimeout: 2000 }, false);

export const signIn = () => openConnectFront('/');
